/**
 *
 * PopoverWithLinks
 *
 */

import React from 'react';
import PropTypes from 'prop-types';
import makeStyles from '@mui/styles/makeStyles';
import { Box, Popover, Typography } from '../thirdParty';

const useStyles = makeStyles(theme => ({
  paper: {
    padding: theme.spacing(2),
    borderRadius: 5,
    backgroundColor: theme.palette.background.paper,
    overflow: 'hidden',
  },
  typography: {
    padding: theme.spacing(1),
  },
  anchorTag: {
    color: 'inherit',
    textDecoration: 'none',
  },
}));

export const PopoverWithLinks = ({
  links,
  open,
  openLinksInNewTab,
  currentTarget,
  onClose,
}) => {
  const classes = useStyles();

  return (
    <Box component="div" className={classes.root}>
      <Popover
        classes={{ paper: classes.paper }}
        open={open}
        anchorEl={currentTarget}
        onClose={onClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
      >
        {links.map(link => (
          <Typography className={classes.typography}>
            <a
              className={classes.anchorTag}
              href={link.to}
              target={openLinksInNewTab ? '_blank' : ''}
            >
              {' '}
              {link.displayLabel}
            </a>
          </Typography>
        ))}
      </Popover>
    </Box>
  );
};

PopoverWithLinks.propTypes = {
  /**
   * Array of list items
   */
  links: PropTypes.array,
  /**
   * Whether links should be opened in a new tab or not
   */
  openLinksInNewTab: PropTypes.bool,
  /**
   * Open or close popper
   */
  open: PropTypes.bool,
  /**
   * Current target element where the popover should appear
   */
  currentTarget: PropTypes.string.isRequired,
  /**
   * On popover close handler
   */
  onClose: PropTypes.func,
};
