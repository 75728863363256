import React from 'react';
import { SvgIcon } from '@mui/material';

function Comfort(props) {
  return (
    <SvgIcon
      {...props}
      width="58"
      height="58"
      viewBox="10 17 35 25"
      fill="none"
    >
      <rect width="58" height="58" fill="white" />
      <path
        d="M38.9034 30.945C38.7428 30.8374 38.6352 30.6767 38.6352 30.4623V16.9493C38.6352 15.3405 37.2947 14 35.6859 14C34.0771 14 32.7366 15.3405 32.7366 16.9493V30.5161C32.7366 30.7305 32.6297 30.8912 32.4683 30.9988C30.2161 32.2855 28.9825 34.7528 29.3045 37.3263C29.6266 40.2756 32.0394 42.6347 34.9349 42.9567C36.812 43.1712 38.5814 42.5816 39.9757 41.3479C41.3163 40.1143 42.1203 38.3986 42.1203 36.5223C42.1203 34.2163 40.8866 32.1256 38.9027 30.9457L38.9034 30.945ZM35.6859 40.2756C33.5944 40.2756 31.9318 38.6131 31.9318 36.5216C31.9318 34.6445 33.3261 33.0895 35.1494 32.822V17.2176C35.1494 16.9493 35.4176 16.6811 35.6859 16.6811C35.9541 16.6811 36.2224 16.9493 36.2224 17.2176V32.822C38.0456 33.0903 39.44 34.6452 39.44 36.5216C39.44 38.6131 37.7781 40.2756 35.6866 40.2756H35.6859Z"
        fill="#152C73"
      />
      <path
        d="M38.366 36.5223C38.366 38.0039 37.1665 39.2034 35.685 39.2034C34.2034 39.2034 33.0039 38.0039 33.0039 36.5223C33.0039 35.0408 34.2034 33.8412 35.685 33.8412C37.1665 33.8412 38.366 35.0408 38.366 36.5223Z"
        fill="#152C73"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M39.4883 15.5863C39.4883 15.3507 39.6795 15.1595 39.915 15.1595H43.3267C43.5622 15.1595 43.7534 15.3507 43.7534 15.5863C43.7534 15.8218 43.5622 16.013 43.3267 16.013H39.915C39.6795 16.013 39.4883 15.8218 39.4883 15.5863Z"
        fill="#152C73"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M39.4883 18.9972C39.4883 18.7617 39.6795 18.5705 39.915 18.5705H45.0329C45.2684 18.5705 45.4596 18.7617 45.4596 18.9972C45.4596 19.2328 45.2684 19.424 45.0329 19.424H39.915C39.6795 19.424 39.4883 19.2328 39.4883 18.9972Z"
        fill="#152C73"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M39.4883 22.4096C39.4883 22.1741 39.6795 21.9829 39.915 21.9829H45.0329C45.2684 21.9829 45.4596 22.1741 45.4596 22.4096C45.4596 22.6452 45.2684 22.8364 45.0329 22.8364H39.915C39.6795 22.8364 39.4883 22.6452 39.4883 22.4096Z"
        fill="#152C73"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M39.4883 25.8213C39.4883 25.5857 39.6795 25.3945 39.915 25.3945H43.3267C43.5622 25.3945 43.7534 25.5857 43.7534 25.8213C43.7534 26.0568 43.5622 26.248 43.3267 26.248H39.915C39.6795 26.248 39.4883 26.0568 39.4883 25.8213Z"
        fill="#152C73"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M39.4883 29.2329C39.4883 28.9974 39.6795 28.8062 39.915 28.8062H43.3267C43.5622 28.8062 43.7534 28.9974 43.7534 29.2329C43.7534 29.4685 43.5622 29.6597 43.3267 29.6597H39.915C39.6795 29.6597 39.4883 29.4685 39.4883 29.2329Z"
        fill="#152C73"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M21.3256 20.5929C16.7916 20.5929 13.1632 24.0934 13.1632 28.3548C13.1632 32.6161 16.7916 36.1166 21.3256 36.1166C25.8596 36.1166 29.488 32.6161 29.488 28.3548C29.488 24.0934 25.8596 20.5929 21.3256 20.5929ZM12 28.3548C12 23.4002 16.2012 19.4297 21.3256 19.4297C26.45 19.4297 30.6512 23.4002 30.6512 28.3548C30.6512 33.3093 26.45 37.2798 21.3256 37.2798C16.2012 37.2798 12 33.3093 12 28.3548Z"
        fill="#152C73"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M25.2921 30.9283C25.5253 31.1492 25.5354 31.5173 25.3145 31.7505C24.2564 32.8679 22.9078 33.5672 21.4122 33.5672C19.9182 33.5672 18.6012 32.8846 17.5502 31.7924C17.3275 31.561 17.3346 31.1928 17.566 30.9701C17.7974 30.7474 18.1656 30.7544 18.3883 30.9859C19.2696 31.9016 20.2993 32.404 21.4122 32.404C22.5236 32.404 23.5826 31.8878 24.4699 30.9507C24.6908 30.7175 25.0589 30.7075 25.2921 30.9283Z"
        fill="#152C73"
      />
      <path
        d="M18.1505 28.2523C19.0049 28.2523 19.6975 27.5596 19.6975 26.7053C19.6975 25.8509 19.0049 25.1583 18.1505 25.1583C17.2961 25.1583 16.6035 25.8509 16.6035 26.7053C16.6035 27.5596 17.2961 28.2523 18.1505 28.2523Z"
        fill="#152C73"
      />
      <path
        d="M24.6808 28.2523C25.5352 28.2523 26.2278 27.5596 26.2278 26.7053C26.2278 25.8509 25.5352 25.1583 24.6808 25.1583C23.8264 25.1583 23.1338 25.8509 23.1338 26.7053C23.1338 27.5596 23.8264 28.2523 24.6808 28.2523Z"
        fill="#152C73"
      />
    </SvgIcon>
  );
}
Comfort.displayName = 'Comfort';
Comfort.muiName = 'SvgIcon';

export default Comfort;
