import React from 'react';
import PropTypes from 'prop-types';
import { Box } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { useTranslation } from 'react-i18next';

const useStyles = makeStyles(theme => ({
  root: {
    display: 'inline-block',
    boxSizing: 'border-box',
    padding: '5px 10px',
    border: '2px solid transparent',
    width: '100%',
  },
  children: {
    padding: 10,
  },
  error: {
    border: `2px solid ${theme.color.brightRed}`,
  },
  errorMessage: {
    marginBottom: 3,
    marginLeft: '0px',
    color: theme.color.brightRed,
    fontSize: '0.75rem',
  },
}));

/**
 * Primary UI component for user interaction
 */
export const ErrorContainer = props => {
  const { error, errorMessage, children } = props;
  const classes = useStyles(props);
  const { t } = useTranslation();

  return (
    <Box
      component="div"
      className={`${classes.root} ${error ? classes.error : ''}`}
    >
      {children && (
        <Box component="span" className={classes.children}>
          {children}
        </Box>
      )}
      <Box component="div" className={classes.errorMessage}>
        {error ? t(errorMessage) : ''}
      </Box>
    </Box>
  );
};

ErrorContainer.propTypes = {
  /**
   *
   */
  error: PropTypes.bool,
  errorMessage: PropTypes.string,
  children: PropTypes.element,
};
