import React from 'react';
import PropTypes from 'prop-types';
import { NavLink } from 'react-router-dom';
import { makeStyles, Breadcrumbs, Typography } from '../thirdParty';

import { NavigateNextIcon } from '../icons';

import { BreadCrumbDropDown } from '../breadcrumbDropdown';
const useStyles = makeStyles(theme => ({
  root: {
    '& > * + *': {
      marginTop: theme.spacing(2),
    },
    marginLeft: '15px',
  },
  breadcrumbLinkStyle: {
    fontSize: props => props.breadCrumbFontSize,
    color: props => props.breadCrumbLinkColor,
    textDecoration: 'none',
    '&:hover': {
      textDecoration: 'underline',
    },
  },
  breadcrumbNonLinkStyle: {
    fontSize: props => props.breadCrumbFontSize,
    color: props => props.breadCrumbNonLinkColor,
  },
  BreadcrumbStringAndDropdownStyle: {
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'center',
    color: theme.palette.primary.main,
    fontSize: 12,
  },
}));
export const BreadCrumbsDetail = ({
  maximumWidth,
  borderBottomColor,
  borderBottomThickness,
  dropDownFontSize,
  dropDownFontWeight,
  dropDownPadding,
  breadCrumbData,
  breadCrumbLinkColor,
  breadCrumbNonLinkColor,
  breadCrumbFontSize,
}) => {
  const classes = useStyles({
    breadCrumbLinkColor,
    breadCrumbNonLinkColor,
    breadCrumbFontSize,
  });
  return (
    <div className={classes.root}>
      <Breadcrumbs
        separator={<NavigateNextIcon fontSize="small" />}
        aria-label="breadcrumb"
      >
        {breadCrumbData &&
          breadCrumbData.map((breadCrumb, index) => {
            if (
              breadCrumb.BreadCrumbLink &&
              breadCrumb.BreadCrumbLink.length > 0
            ) {
              return (
                <NavLink
                  color="inherit"
                  to={breadCrumb.BreadCrumbLink}
                  className={classes.breadcrumbLinkStyle}
                  // eslint-disable-next-line react/no-array-index-key
                  key={breadCrumb + index}
                >
                  <div className={classes.BreadcrumbStringAndDropdownStyle}>
                    {breadCrumb.breadCrumbName
                      ? breadCrumb.breadCrumbName
                      : '-'}
                    {
                      /* eslint-disable */
                      breadCrumb.dorpdownValue &&
                        breadCrumb.dorpdownValue.length > 0 && (
                          <BreadCrumbDropDown
                            dorpdownValue={breadCrumb.dorpdownValue}
                            maximumWidth={maximumWidth}
                            borderBottomColor={borderBottomColor}
                            borderBottomThickness={borderBottomThickness}
                            dropDownFontSize={dropDownFontSize}
                            dropDownFontWeight={dropDownFontWeight}
                            dropDownPadding={dropDownPadding}
                          />
                        )
                      /* eslint-enable */
                    }
                  </div>
                </NavLink>
              );
            }
            return (
              <Typography
                className={classes.breadcrumbNonLinkStyle}
                // eslint-disable-next-line react/no-array-index-key
                key={breadCrumb + index}
              >
                <div className={classes.BreadcrumbStringAndDropdownStyle}>
                  {breadCrumb.breadCrumbName ? breadCrumb.breadCrumbName : '-'}
                  {
                    /* eslint-disable */
                    breadCrumb.dorpdownValue &&
                      breadCrumb.dorpdownValue.length > 0 && (
                        <BreadCrumbDropDown
                          dorpdownValue={breadCrumb.dorpdownValue}
                          maximumWidth={maximumWidth}
                          borderBottomColor={borderBottomColor}
                          borderBottomThickness={borderBottomThickness}
                          dropDownFontSize={dropDownFontSize}
                          dropDownFontWeight={dropDownFontWeight}
                          dropDownPadding={dropDownPadding}
                        />
                      )
                    /* eslint-enable */
                  }
                </div>
              </Typography>
            );
          })}
      </Breadcrumbs>
    </div>
  );
};

BreadCrumbsDetail.propTypes = {
  /**
   * The values in dropdown
   */
  // eslint-disable-next-line react/no-unused-prop-types
  dorpdownValue: PropTypes.array.isRequired,
  /**
   * Maximum width of the dropdown
   */
  maximumWidth: PropTypes.number,
  /**
   * Bottom border color of the dropdown
   */
  borderBottomColor: PropTypes.string,
  /**
   * Bottom border thickness of the dropdown
   */
  borderBottomThickness: PropTypes.string,
  /**
   * Font size of the dropdown
   */
  dropDownFontSize: PropTypes.string,
  /**
   * Font weight of the dropdown
   */
  dropDownFontWeight: PropTypes.number,
  /**
   * padding of the dropdown
   */
  dropDownPadding: PropTypes.string,
  /**
   * padding of the dropdown
   */
  breadCrumbLinkColor: PropTypes.string,
  /**
   * padding of the dropdown
   */
  breadCrumbNonLinkColor: PropTypes.string,
  /**
   * padding of the dropdown
   */
  breadCrumbFontSize: PropTypes.string,
  /**
   * padding of the dropdown
   */
  breadCrumbData: PropTypes.array.isRequired,
};

BreadCrumbsDetail.defaultProps = {
  maximumWidth: 99,
  borderBottomColor: '#347cff',
  borderBottomThickness: '2px',
  dropDownFontSize: '0.885rem',
  dropDownFontWeight: 550,
  dropDownPadding: '0px',
  breadCrumbLinkColor: '#347cff',
  breadCrumbNonLinkColor: '#1f2224',
  breadCrumbFontSize: '0.875rem',
};
