import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { isEmpty } from 'lodash';
import { RecentlyViewedLabel } from '../RecentlyViewedLabel';
import { Search } from '../Search';
import { RecentlyViewedTable } from '../Table';
import { makeStyles, Box, Grid } from '../thirdParty';

const useStyles = makeStyles(theme => ({
  table: {
    border: 'none',
  },
  parentDivStyle: {
    margin: '0px 0px 10px 40px',
    maxWidth: '100%',
    [theme.breakpoints.up('sm')]: {
      margin: '0px 0px 0px 0px',
    },
  },
  noRecords: {
    margin: '40px',
  },
}));

export const RecentlyViewed = ({
  primary,
  dataAlignment,
  tableHeaderData,
  tableBodyData,
  isSearchBox,
  iconColor,
  isIconEnabled,
  label,
  labelFontSize,
  labelColor,
  labelFontWeight,
  labelfontFeatureSettings,
  noDataText,
  size,
  hideVerticleScroll,
  locale,
}) => {
  const [searchText, setSearchText] = useState('');
  const classes = useStyles();
  return (
    <div className={classes.parentDivStyle}>
      <Grid container alignItems="baseline" justifyContent="space-between">
        {label && (
          <RecentlyViewedLabel
            label={label}
            labelFontSize={labelFontSize}
            labelColor={labelColor}
            labelFontWeight={labelFontWeight}
            labelfontFeatureSettings={labelfontFeatureSettings}
          />
        )}
        {isSearchBox && (
          <Search
            isSearchBox={isSearchBox}
            isIconEnabled={isIconEnabled}
            iconColor={iconColor}
            onChange={setSearchText}
          />
        )}
      </Grid>
      {!isEmpty(tableHeaderData) && !isEmpty(tableBodyData) ? (
        <RecentlyViewedTable
          primary={primary}
          locale={locale}
          dataAlignment={dataAlignment}
          tableHeaderData={tableHeaderData}
          tableBodyData={tableBodyData}
          filterText={searchText}
          size={size}
          hideVerticleScroll={hideVerticleScroll}
        />
      ) : (
        <Box className={classes.noRecords} component="div">
          {noDataText}
        </Box>
      )}
    </div>
  );
};

RecentlyViewed.propTypes = {
  /**
   * The type of variant is selected
   */
  primary: PropTypes.bool,
  /**
   * The text alignment within table cell i.e
   */
  dataAlignment: PropTypes.string,
  /**
   * The table header strings in array format
   */
  tableHeaderData: PropTypes.array.isRequired,
  /**
   * The tbale contents in array of object format
   */
  tableBodyData: PropTypes.array.isRequired,
  /**
   * Search box for table enable flag
   */
  isSearchBox: PropTypes.bool,
  /**
   * Boolean value for configuring icons. i.e. icon will be there or not
   */
  isIconEnabled: PropTypes.bool,
  /**
   * Color code in icon color default value is #8fc740
   */
  iconColor: PropTypes.string,
  /**
   * Color code in icon color default value is rgba(0, 0, 0, 0.87)
   */
  labelColor: PropTypes.string,
  /**
   * label Size is font size in rem or px default value is 1rem
   */
  labelFontSize: PropTypes.string,
  /**
   * Label weight is font weight. default value is 400
   */
  labelFontWeight: PropTypes.number,
  /**
   * Label string
   */
  label: PropTypes.string,

  /**
   * specify text to be displayed when there is no data
   */
  noDataText: PropTypes.string,
  /**
   *  Font feature settings for the label
   */
  labelfontFeatureSettings: PropTypes.string,
  size: PropTypes.oneOf(['large', 'small', 'x-small']),
  hideVerticleScroll: PropTypes.bool,
  locale: PropTypes.string,
};

RecentlyViewed.defaultProps = {
  hideVerticleScroll: false,
};
