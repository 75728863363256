import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import withStyles from '@mui/styles/withStyles';
import MuiButton from '@mui/material/Button';
import MuiDialog from '@mui/material/Dialog';
import MuiDialogTitle from '@mui/material/DialogTitle';
import MuiDialogContent from '@mui/material/DialogContent';
import MuiDialogActions from '@mui/material/DialogActions';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import Typography from '@mui/material/Typography';

const styles = theme => ({
  root: {
    margin: 0,
    padding: theme.spacing(2),
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
  title: {
    lineHeight: '1.5',
    color: theme.color.black1,
  },
});

const Dialog = withStyles({
  paper: {
    width: 500,
  },
})(MuiDialog);

const DialogTitle = withStyles(styles)(props => {
  const { children, classes, onClose, ...other } = props;
  const { t } = useTranslation();
  return (
    <MuiDialogTitle disableTypography className={classes.root} {...other}>
      <Typography variant="h6" className={classes.title}>
        {children}
      </Typography>
      {onClose ? (
        <IconButton
          aria-label="close"
          className={classes.closeButton}
          onClick={onClose}
          title={t('Close')}
          size="large"
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});

const DialogContent = withStyles({
  root: {
    padding: '16px 16px 32px 16px',
  },
})(MuiDialogContent);
const Button = withStyles(theme => ({
  textSecondary: {
    color: theme.color.originalBlack,
    opacity: 0.87,
  },
}))(MuiButton);

const DialogActions = withStyles(theme => ({
  root: {
    margin: 0,
    padding: theme.spacing(2),
    color: theme.color.white,
  },
}))(MuiDialogActions);

export const TranslationFailureModal = ({
  modalHeaderText,
  modalBodyText,
  open,
  retryTranslation,
}) => {
  const { t } = useTranslation();

  return (
    <div>
      <Dialog
        onClose={() => retryTranslation(false)}
        fullWidth={false}
        maxWidth={500}
        aria-labelledby="customized-dialog-title"
        open={open}
      >
        <DialogTitle
          id="customized-dialog-title"
          onClose={() => retryTranslation(false)}
        >
          {modalHeaderText}
        </DialogTitle>
        <DialogContent>{modalBodyText}</DialogContent>
        <DialogActions>
          <Button
            autoFocus
            color="primary"
            onClick={() => retryTranslation(true)}
          >
            {t('Retry')}
          </Button>
          <Button color="secondary" onClick={() => retryTranslation(false)}>
            {t('Continue')}
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

TranslationFailureModal.propTypes = {
  /**
   * Text to be shown in modal header
   */
  modalHeaderText: PropTypes.string,
  /**
   * Test to be shown in modal body
   */
  modalBodyText: PropTypes.string,
  /**
   * Show modal when true
   */
  open: PropTypes.bool,
  /**
   * Onclick handler on click of retry, continue or close button
   */
  retryTranslation: PropTypes.func,
};
