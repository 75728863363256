/**
 *
 * Feedback Work Status
 *
 */

import React, { useState, useCallback, useRef } from 'react';
import PropTypes from 'prop-types';
import { isEmpty, keyBy } from 'lodash';
import { useTranslation } from 'react-i18next';
import moment from 'moment';
import DatePicker from 'react-multi-date-picker';
import InputIcon from 'react-multi-date-picker/components/input_icon';
import { makeStyles, Box, Grid, EditIcon } from '../thirdParty';
import { SimpleDropdown } from '../SimpleDropdown';
import { ProgressButton } from '../ProgressButton';
import { ListItem } from '../listItem';
import { convertToDtMnthYr } from '../dateUtils';
import { DeleteIconButton } from '../DeleteIconButton';
import { IconButtonWithLoader } from '../IconButtonWithLoader';
import { NoDataText } from '../NoDataText';
import { workSts } from '../constants';
import { localeLanguage, useOnClickOutside } from '../../../utils/common';

const useStyles = makeStyles(() => ({
  listItemWrapper: {
    minWidth: 400,
    maxWidth: 600,
  },
  listItem: {
    alignItems: 'center',
  },
  gridItem: {
    flexGrow: 1,
    flexBasis: props => (props.setDeleteId ? '25%' : 0),
  },
  gridItemStatus: {
    flexGrow: 1,
    flexBasis: props => (props.setDeleteId ? '40%' : 0),
  },
}));

export const FeedbackWorkStatusTable = props => {
  const {
    workStatusList,
    feedbackWorkStatus, // using
    feedbackWorkStatusLoading,
    feedbackWorkStatusLoaded,
    editId, // using
    editWorkOrder, // function using
    setDeleteId, // using
    setEditWorkStatus,
    editWorkStatus,
    editStatus,
    noDataText,
    timezone,
    sendUpdatedDate,
    // locale,
  } = props;
  const classes = useStyles(props);
  const dateRef = useRef();
  const { t } = useTranslation();
  const [date, setDate] = useState();
  const handleDatePickerClose = useCallback(
    () => dateRef?.current?.closeCalendar(),
    [dateRef],
  );

  useOnClickOutside(dateRef, handleDatePickerClose);
  const showLabelOrDatePicker = workStatus => {
    if (
      editId !== workStatus.id ||
      (editId === workStatus.id &&
        (isEmpty(editStatus) || editStatus === workStatus.sts.id))
    ) {
      return true; // show label
    }
    return false; // show date picker
  };

  const showLabelOrDropdown = workStatus => {
    if (
      editId === workStatus.id &&
      (isEmpty(editStatus) || editStatus !== workStatus.sts.id)
    )
      return true; // show dropdown
    return false; // show label
  };

  const onDatePicked = pickedDate => {
    setDate(new Date(pickedDate).getTime());
    sendUpdatedDate(new Date(pickedDate).getTime());
  };

  const setMaxDate = workStatusId => {
    const feedbackWorkStatusObject = keyBy(feedbackWorkStatus, 'id');
    let maxDate = '';
    // when current status is completed and new staus is in progress
    if (
      feedbackWorkStatusObject[workStatusId]?.sts?.id === workSts.COMPLETED &&
      editStatus === workSts.INPRO
    ) {
      maxDate = new Date(feedbackWorkStatusObject[workStatusId]?.woCreateDate);
    }
    // when current status is in progress and new status is completed
    else {
      maxDate = new Date();
    }
    return maxDate;
  };

  const setMinDate = workStatusId => {
    const feedbackWorkStatusObject = keyBy(feedbackWorkStatus, 'id');
    let minDate = '';
    // when current status is in progress and new staus is completed
    if (
      feedbackWorkStatusObject[workStatusId]?.sts?.id === workSts.INPROGRESS &&
      editStatus === workSts.COMPLETED
    ) {
      minDate = new Date(feedbackWorkStatusObject[workStatusId]?.woCreateDate);
    } else {
      minDate = new Date(
        moment(moment().subtract(90, 'days').format('YYYY-MM-DD')),
      );
    }
    return minDate;
  };

  return !isEmpty(feedbackWorkStatus) ? (
    <Box component="div">
      {feedbackWorkStatus?.map(
        workStatus =>
          workStatus && (
            <Box component="div" className={classes.listItemWrapper}>
              <ListItem>
                <Grid container className={classes.listItem}>
                  <Grid item className={[classes.gridItem, 'custom-calendar']}>
                    {showLabelOrDatePicker(workStatus) ? (
                      convertToDtMnthYr(workStatus.woCreateDate, timezone)
                    ) : (
                      <DatePicker
                        locale={localeLanguage(t)}
                        placeholder=""
                        render={<InputIcon />}
                        maxDate={setMaxDate(workStatus.id)}
                        minDate={setMinDate(workStatus.id)}
                        format="DD MMM, YYYY"
                        shouldCloseOnSelect={false}
                        onChange={e => onDatePicked(e)}
                        value={
                          !date
                            ? new Date(workStatus?.woCreateDate)
                            : new Date(date)
                        }
                        ref={dateRef}
                      />
                    )}
                  </Grid>
                  <Grid item className={classes.gridItem}>
                    {workStatus.woId}
                  </Grid>
                  <Grid item className={classes.gridItemStatus}>
                    {showLabelOrDropdown(workStatus) ? (
                      <Grid container wrap="nowrap" alignItems="center">
                        <Grid item>
                          <SimpleDropdown
                            data={
                              (feedbackWorkStatusLoaded && workStatusList) || []
                            }
                            label={t('Select_one')}
                            labelName="dn"
                            valueName="id"
                            selectedValue={editStatus || workStatus.sts.id}
                            loading={feedbackWorkStatusLoading}
                            handleChange={setEditWorkStatus}
                          />
                        </Grid>
                        <Grid item alignItems="center">
                          <ProgressButton
                            onClick={editWorkStatus}
                            buttonText={t('Save')}
                            disabled={
                              isEmpty(editStatus) ||
                              editStatus === workStatus.sts.id
                            }
                          />
                        </Grid>
                      </Grid>
                    ) : (
                      <Grid container alignItems="center">
                        <Grid item>{workStatus.sts.dn}</Grid>
                        {editWorkOrder && (
                          <Grid item>
                            <IconButtonWithLoader
                              UnMarkedIcon={EditIcon}
                              buttonPadding={6}
                              buttonSize="18px"
                              iconColor="rgba(33, 33, 33, 0.8)"
                              onClickHandler={() =>
                                editWorkOrder(workStatus.id)
                              }
                              unMarkedTitle={t('Edit')}
                            />
                          </Grid>
                        )}
                      </Grid>
                    )}
                  </Grid>
                  {setDeleteId && (
                    <Grid item>
                      <DeleteIconButton
                        onClickHandler={() => setDeleteId(workStatus.id)}
                      />
                    </Grid>
                  )}
                </Grid>
              </ListItem>
            </Box>
          ),
      )}
    </Box>
  ) : (
    <NoDataText text={noDataText}></NoDataText>
  );
};

FeedbackWorkStatusTable.propTypes = {
  /**
   * List of available workStatus
   */
  workStatusList: PropTypes.array,
  /**
   * User's added feedback source
   */
  feedbackWorkStatus: PropTypes.array,
  /**
   * true when list work status loading
   */
  feedbackWorkStatusLoading: PropTypes.bool,
  /**
   * true when list work status loaded
   */
  feedbackWorkStatusLoaded: PropTypes.bool,
  editId: PropTypes.number,
  editWorkOrder: PropTypes.func,
  setDeleteId: PropTypes.func,
  setEditWorkStatus: PropTypes.func,
  editWorkStatus: PropTypes.func,
  editStatus: PropTypes.string,
  noDataText: PropTypes.string,
  // locale: PropTypes.string,
  /**
   * show date with tinezone provided
   */
  timezone: PropTypes.string,
  sendUpdatedDate: PropTypes.func,
};

FeedbackWorkStatusTable.defaultProps = {
  sendUpdatedDate: () => {},
};
