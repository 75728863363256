/* eslint-disable jsx-a11y/no-static-element-interactions */
/**
 *
 * CollapsableSidebar
 *
 */

import React from 'react';
import PropTypes from 'prop-types';

import clsx from 'clsx';
import makeStyles from '@mui/styles/makeStyles';
import { Rnd } from 'react-rnd';
import { useTranslation } from 'react-i18next';
import { Drawer, CssBaseline, IconButton, Tooltip } from '../thirdParty';
import { ChevronLeftIcon, ChevronRightIcon, MoreVertIcon } from '../icons';

const drawerWidth = '180';

const useStyles = makeStyles(theme => ({
  drawer: {
    width: props =>
      `${
        props?.parentComponent?.drawerWidthProps
          ? props?.parentComponent?.drawerWidthProps
          : drawerWidth
      }`,
    flexShrink: 0,
    whiteSpace: 'nowrap',
    paddingTop: theme.spacing(6),
  },
  drawerOpen: {
    width: props =>
      `${
        props?.parentComponent?.drawerWidthProps
          ? props?.parentComponent?.drawerWidthProps
          : drawerWidth
      }`,
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  drawerClose: {
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    overflowX: 'hidden',
    width: props =>
      `${
        props?.parentComponent?.drawerWidthProps
          ? props?.parentComponent?.drawerWidthProps
          : drawerWidth
      }px !important`,
    [theme.breakpoints.up('sm')]: {
      width: theme.spacing(9) + 1,
    },
  },
  mt10: {
    marginTop: '10px',
    //  overflow: 'auto',
    'overflow-x': props =>
      `${props?.parentComponent?.componentName}` === 'paiPortal-Home'
        ? 'hidden'
        : 'auto',
    'overflow-y': 'auto',
    // The below lines is not passed as props as the intention is to replace the thick scrollbar
    // with thin scrollbar everywhere the component is used.
    '&::-webkit-scrollbar': {
      width: '7px',
      height: '7px',
    },
    '&::-webkit-scrollbar-track': {
      boxShadow: 'inset 0 0 5px grey',
      webkitBoxShadow: 'inset 0 0 5px grey',
      borderRadius: '10px',
    },
    '&::-webkit-scrollbar-thumb': {
      background: 'grey',
      borderRadius: '10px',
    },
    '& a': {
      textDecoration: 'none',
    },
    marginBottom: props =>
      props?.parentComponent?.marginBottomProps
        ? `${props.parentComponent.marginBottomProps}px`
        : '',
    maxHeight: props =>
      props?.parentComponent?.maxHeightProps
        ? props.parentComponent.maxHeightProps
        : '',
    marginRight: props =>
      props?.parentComponent?.marginRightProps
        ? props.parentComponent.marginRightProps
        : '',
  },
  mt30: {
    marginTop: '30px',
    overflow: 'auto',
  },
  drawerPaper: {
    scrollbarWidth: 'thin',
    position: 'relative',
    height: props => `calc(100vh - ${props.remainingHeight})`,
    backgroundColor: props =>
      props?.parentComponent?.backgroundColorProps
        ? props.parentComponent.backgroundColorProps
        : '#f5f8fd',
    borderRadius: props =>
      props?.parentComponent?.borderRadiusProps
        ? props.parentComponent.borderRadiusProps
        : '',
    marginLeft: props =>
      props?.parentComponent?.marginLeftProps
        ? props.parentComponent.marginLeftProps
        : '',
    overflow: props =>
      props?.parentComponent?.overflowProps
        ? props.parentComponent.overflowProps
        : '',
  },
  restoreContainer: {
    marginLeft: theme.spacing(1.5),
    position: 'absolute',
    right: props =>
      props?.parentComponent?.rightProps
        ? props.parentComponent.rightProps
        : '0px',
    marginTop: '10px',
  },
  resizeHandler: {
    backgroundColor: theme.palette.primary.main,
    width: '2px !important',
    right: '3px !important',
    height: '100%',
    position: 'absolute',
    display: 'flex',
    alignItems: 'center',
  },
  resizeHandlerIcon: {
    marginLeft: '-5px',
    opacity: 0.5,
  },
  collapseBtn: {
    position: 'absolute',
    right: '-24px',
    top: '0px',
    zIndex: 1200,
    background: `${theme.palette.primary.main} !important`,
    padding: 4,
    margin: '12px 7px 0px 0px',
    color: theme.palette.secondary.main,
    '&:hover': {
      color: theme.palette.secondary.main,
    },
    '&:hover .MuiIconButton-label': {
      backgroundColor: theme.palette.primary.main,
    },
    '& .MuiSvgIcon-root': {
      color: `${theme.palette.secondary.main} !important`,
    },
  },
  label: {
    backgroundColor: theme.palette.primary.main,
    borderRadius: '50%',
    padding: '4px',
    boxShadow: `0px 0px 1px 1px ${theme.color.black}`,
  },
  resizeable: {
    transition: 'width 0.5s ease 0s',
  },
  miniSidebar: {
    width: '50px !important',
    minWidth: '0px !important',
    height: props =>
      props?.parentComponent?.heightProps
        ? `${props?.parentComponent?.heightProps} !important`
        : '',
  },
  resizing: {
    transition: 'none',
  },
  drawerRoot: {
    height: '100%',
  },
  verticalDivision: {
    position: 'absolute',
    height: '100%',
    top: '0px',
    right: '-5px',
  },
}));

export const CollapsableSidebar = ({
  menuOpen,
  children,
  containerId,
  miniSidebar,
  remainingHeight,
  parentComponent,
  drawerExpanded,
}) => {
  //  const drawerWidth = parentComponent === 'Home' ? '690' : '180';
  const classes = useStyles({ remainingHeight, parentComponent });
  const [open, setOpen] = React.useState(menuOpen);
  const { t } = useTranslation();

  const handleDrawerOpen = () => {
    setOpen(true);
    //  eslint-disable-next-line
    drawerExpanded(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
    //  eslint-disable-next-line
    drawerExpanded(false);
  };

  const toggleCollapseMenu = () => {
    if (open) {
      handleDrawerClose();
    } else {
      handleDrawerOpen();
    }
  };

  const drawerWidthProps = parentComponent?.drawerWidthProps
    ? parentComponent?.drawerWidthProps
    : drawerWidth;

  const drawerHeightProps = parentComponent?.drawerHeightProps
    ? parentComponent?.drawerHeightProps
    : 'auto';

  const rightPropsEnableResizing = !parentComponent?.rightPropsEnableResizing
    ? parentComponent.rightPropsEnableResizing
    : !!open;

  const disableDraggingProps = parentComponent?.disableDraggingProps
    ? parentComponent?.disableDraggingProps
    : 'true';

  const dragAxisProps = parentComponent?.dragAxisProps
    ? parentComponent.dragAxisProps
    : 'both';

  const sidebarContent = (
    // eslint-disable-next-line react/jsx-fragments
    <React.Fragment>
      <CssBaseline />
      <Drawer
        variant="permanent"
        classes={{
          paper: clsx(classes.drawerPaper),
          root: clsx(classes.drawerRoot),
        }}
        PaperProps={{
          style: {
            position: 'relative',
          },
        }}
        BackdropProps={{ style: { position: 'absolute' } }}
        ModalProps={{
          container: document.getElementById(containerId),
          style: { position: 'absolute' },
        }}
      >
        <div className={open ? clsx(classes.mt10) : clsx(classes.mt30)}>
          {open ? (
            children
          ) : (
            <div className={classes.restoreContainer}>{miniSidebar}</div>
          )}
        </div>
      </Drawer>
      <Tooltip title={open ? t('Collapse') : t('Expand')}>
        <IconButton
          className={classes.collapseBtn}
          classes={{
            root: classes.root,
            label: classes.label,
          }}
          aria-label={open ? t('Collapse') : t('Expand')}
          onClick={toggleCollapseMenu}
          size="large"
        >
          {open ? <ChevronLeftIcon /> : <ChevronRightIcon />}
        </IconButton>
      </Tooltip>
      {!rightPropsEnableResizing && (
        <div className={classes.verticalDivision}>
          <div className={classes.resizeHandler}></div>
        </div>
      )}
    </React.Fragment>
  );

  return (
    <Rnd
      default={{
        width: drawerWidthProps,
        position: 'relative',
        height: drawerHeightProps,
      }}
      className={`${classes.resizeable} ${!open ? classes.miniSidebar : ''}`}
      style={{ position: 'relative' }}
      minWidth={drawerWidthProps}
      height={`calc(100vh - ${remainingHeight})`}
      position="relative"
      enableResizing={{
        top: false,
        right: rightPropsEnableResizing,
        bottom: false,
        left: false,
        topRight: false,
        bottomRight: false,
        bottomLeft: false,
        topLeft: false,
      }}
      // eslint-disable-next-line
      disableDragging={disableDraggingProps}
      dragAxis={dragAxisProps}
      resizeHandleClasses={{
        right: 'resize-handle',
      }}
      onResizeStart={() => {
        document
          .getElementsByClassName(classes.resizeable)[0]
          .classList.add(classes.resizing);
      }}
      onResizeStop={() => {
        document
          .getElementsByClassName(classes.resizeable)[0]
          .classList.remove(classes.resizing);
      }}
      resizeHandleComponent={{
        right: (
          <div className={classes.resizeHandler}>
            <MoreVertIcon className={classes.resizeHandlerIcon} />
          </div>
        ),
      }}
    >
      {sidebarContent}
    </Rnd>
  );
};

CollapsableSidebar.propTypes = {
  /**
   * Is menu open ?
   */
  menuOpen: PropTypes.bool,
  /**
   * Component/Content that goes inside sidebar. Expects a React component
   */
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]).isRequired,

  /**
   * Component/Content that goes inside sidebar when collapsed. Expects a React component
   */
  miniSidebar: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]).isRequired,
  /**
   * Sidebar needs a container element with display set as flex.
   * Requires an id of the container where the sidebar goes in
   */
  containerId: PropTypes.string.isRequired,
  /**
   * Height in pixel to be subtracted from total height of the view port
   */
  remainingHeight: PropTypes.number,
  parentComponent: PropTypes.object,
  drawerExpanded: PropTypes.func,
};

CollapsableSidebar.defaultProps = {
  menuOpen: true,
  parentComponent: {},
  drawerExpanded: () => {},
};
