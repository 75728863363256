import { createTheme } from '@mui/material/styles';

export default createTheme({
  palette: {
    primary: {
      main: '#152C73',
    },
    secondary: {
      main: '#ffffff',
    },
    background: {
      default: 'white',
    },
  },
  typography: {},
  color: {
    primary: 'rgba(0, 0, 0, 0.9)',
    secondary: 'rgba(0, 0, 0, 0.8)',
    red: 'rgb(244, 67, 54)',
    green: 'rgb(76, 175, 80)',
    grey: 'rgb(158, 158, 158)',
    lightGrey: 'rgba(0, 0, 0, 0.5)',
    darkGrey: 'rgba(134, 134, 134, .6)',
    lightBlack: 'rgba(0,0,0,0.12)',
    lightBlack1: 'rgba(0,0,0,0.3)',
    lightBlack2: 'rgba(0,0,0,0.2)',
    black: 'rgba(0,0,0,0.24)',
    darkBlack: 'rgba(0,0,0,0.15)',
    darkblue: 'rgba(0,0,0,0.55)',
    lightblue: 'rgba(51,102,204)',
    lightblue1: 'rgba(0, 120, 212, 0.9)',
    blue: '#0078D4',
    treeItemBlue: '#218cca',
    linkBlue: '#347cff',
    stage: '#FFC000',
    inprogress: '#ffc107',
    darkGreen: 'rgb(122, 173, 55)',
    open: 'rgba(140, 140, 140, 0.6)',
    grey1: '#d3d3d3',
    orange: '#f4811d',
    yellow: '#ffe551',
    lightGreen: '#152C73',
    greyForNull: '#a9a9a9',
    greyForNullN: '#a9a9a9',
    white: '#ffffff',
    lightGreen1: '#7aad37',
    black60: 'rgba(0,0,0,0.6)',
    darkGrey1: '#dadada',
    darkGrey2: '#dadada80',
    blueishGreen: 'rgb(0, 150, 136)',
    lightBlueishGreen: 'rgba(0, 150, 136, 0.2)',
    lightBlue: '#0078d41a',
    redOrange: '#ff3838',
    brightYellow: '#fce83a',
    lightGrayishBlue: '#9ea7ad',
    vividOrange: 'rgb(255, 152, 0)',
    black1: '#22262a',
    grey2: '#6c757d',
    grey3: '#5a6268',
    completeBlack: '#000000',
    resolvedGreen: '#88C457',
    holdGrey: '#AEAEAE',
    planYellow: '#FCFC01',
    urgentYellow: '#FFC000',
    originalBlack: '#000000',
    purple: '#9c27b0',
    suvaGrey: '#8C8C8C',
    limeGreen: '#4caf50',
    veryDarkGrey: '#121212',
    almostBlack: '#212121',
    paleBlue: '#dce6f1',
    almostBlack22: 'rgba(33, 33, 33, 0.2)',
    greyBorder: 'rgba(173, 175, 177, 0.5)',
    brightRed: '#F44336',
    greyForSidebarText: '#565656',
    strongPink: '#9C27B0',
    vividBlue: '#347CFF',
    vividCyan: '#00BCD4',
    palePurple: '#E09DFF',
    paleNavy: '#B4C9FF',
    paleCyan: '#E0F7FA',
    brightOrange: '#ED7D31',
  },
});

export const blueTheme = createTheme({
  palette: {
    primary: {
      light: '#008bf6',
      main: '#0078d4',
      dark: '#0065b2',
    },
  },
});
