import React from 'react';
import PropTypes from 'prop-types';
import makeStyles from '@mui/styles/makeStyles';
import { isEmpty, get } from 'lodash';
import { useTranslation } from 'react-i18next';
import { Grid, Box } from '../thirdParty';
import { HistoryChip } from '../HistoryChip';
import { NoDataText } from '../NoDataText';
import { convertToDtMnthYr } from '../dateUtils';

const useStyles = makeStyles(theme => ({
  historyChipWrapper: {
    margin: '2px 5px 10px 0px',
    maxWidth: '100%',
    position: 'relative',
  },
  actionLabel: {
    position: 'absolute',
    right: 18,
    top: -10,
    background: theme.color.darkGrey3,
    padding: '1px 6px',
    color: theme.color.white,
    fontSize: '12px',
    borderRadius: theme.spacing(0.25),
    zIndex: 10,
  },
}));

const getPartsChipData = (
  t,
  ts,
  timezone,
  category,
  partName,
  serialNo,
  partNo,
  comment,
) =>
  `${convertToDtMnthYr(ts, timezone)}      ${
    // eslint-disable-next-line no-unneeded-ternary
    category ? category : ''
    // eslint-disable-next-line prefer-template
  }    ${partName}${partNo ? `     ${t('P/N')} - ` + partNo : ''}${
    // eslint-disable-next-line prefer-template
    serialNo ? `      ${t('S/N')} - ` + serialNo : ''
  }${
    // eslint-disable-next-line prefer-template
    comment ? `      ${t('Comments')} - ` + comment : ''
  }`;

/**
 * Primary UI component for user interaction
 */
export const FeedbackPartsChip = props => {
  const { parts, noDataText, timezone, filterCheck } = props;
  const { t } = useTranslation();
  const classes = useStyles(props);
  const actionType = {
    PLAN: t('Planned'),
    REPL: t('Replaced'),
  };
  return !isEmpty(parts) ? (
    <Grid container alignItems="center" className={classes.root}>
      {parts.map(part => (
        <Box>
          {((!filterCheck?.deleted && !get(part, 'deleted')) ||
            filterCheck?.deleted) && (
            <Grid item className={classes.historyChipWrapper}>
              {get(part, 'sts.id') && (
                <Box className={classes.actionLabel}>
                  {actionType[get(part, 'sts.id')]}
                </Box>
              )}

              <HistoryChip
                type="parts"
                label={getPartsChipData(
                  t,
                  part.ts,
                  timezone,
                  get(part, 'recommCategory.dn'),
                  get(part, 'partName'),
                  get(part, 'serialNo'),
                  get(part, 'partNo'),
                  get(part, 'comment'),
                )}
                deleted={get(part, 'deleted')}
                filterCheck={filterCheck}
              />
            </Grid>
          )}
        </Box>
      ))}
    </Grid>
  ) : (
    <NoDataText text={noDataText}></NoDataText>
  );
};

FeedbackPartsChip.propTypes = {
  /**
   * list of added parts
   */
  parts: PropTypes.array,
  noDataText: PropTypes.string,
  timezone: PropTypes.string,
  filterCheck: PropTypes.object,
};
