import React from 'react';
import PropTypes from 'prop-types';
import makeStyles from '@mui/styles/makeStyles';
import { isEmpty, get } from 'lodash';
import { useTranslation } from 'react-i18next';
import { Box, Grid } from '../thirdParty';
import { ListItem } from '../listItem';
import { DeleteIconButton } from '../DeleteIconButton';
import { NoDataText } from '../NoDataText';
import { convertToDtMnthYr } from '../dateUtils';
import { VerifiedIcon } from '../verifiedIcon';
import { REC_BY_AI } from '../constants';

const useStyles = makeStyles(theme => ({
  listItemWrapper: {
    maxWidth: '90%',
  },
  listItem: {
    alignItems: 'center',
  },
  gridItem: {
    flexBasis: '22%',
    padding: '0px 12px',
    wordBreak: 'break-word',
  },
  gridRepairItem: {
    flexBasis: '25%',
    padding: '0px 12px',
    wordBreak: 'break-word',
  },
  gridItemLabel: {
    marginRight: 10,
    color: theme.color.originalBlack,
    opacity: 0.7,
  },
  confirmIcon: {
    width: '25px',
    marginLeft: '10px',
  },
}));

/**
 * Primary UI component for user interaction
 */
export const FeedbackRecommendationsTable = props => {
  const { recommendations, setDeleteId, noDataText, timezone } = props;
  const { t } = useTranslation();
  const classes = useStyles(props);
  return !isEmpty(recommendations) ? (
    <div>
      <Box component="div" className={classes.listItemWrapper}>
        <ListItem>
          <Grid container className={classes.listItem}>
            <Grid item className={classes.gridItem}></Grid>
            <Grid item className={classes.gridItem}>
              {t('Category')}
            </Grid>
            <Grid item className={classes.gridItem}>
              {t('Component')}
            </Grid>
            <Grid item className={classes.gridRepairItem}>
              {t('Repair_Type')}
            </Grid>
          </Grid>
        </ListItem>
      </Box>
      {recommendations.map(recommendation => (
        <Box component="div" className={classes.listItemWrapper}>
          <ListItem>
            <Grid container className={classes.listItem}>
              <Grid item className={classes.gridItem}>
                {convertToDtMnthYr(recommendation.ts, timezone)}
              </Grid>
              <Grid item className={classes.gridItem}>
                <Grid container wrap="nowrap">
                  <Grid item>{get(recommendation, 'recommCategory.dn')}</Grid>
                </Grid>
              </Grid>
              <Grid item className={classes.gridItem}>
                <Grid container wrap="nowrap">
                  <Grid item>{get(recommendation, 'recommTechType.dn')}</Grid>
                </Grid>
              </Grid>
              <Grid item className={classes.gridRepairItem}>
                <Grid container wrap="nowrap" alignItems="center">
                  <Grid item>{get(recommendation, 'recommCode.dn')}</Grid>
                  <Grid item className={classes.confirmIcon}>
                    {recommendation.origin === REC_BY_AI && (
                      <VerifiedIcon title={t('Confirmed')} label="verified" />
                    )}
                  </Grid>
                </Grid>
              </Grid>
              {setDeleteId && (
                <Grid item>
                  <DeleteIconButton
                    onClickHandler={() => setDeleteId(recommendation.id)}
                  />
                </Grid>
              )}
            </Grid>
          </ListItem>
        </Box>
      ))}
    </div>
  ) : (
    <NoDataText text={noDataText}></NoDataText>
  );
};

FeedbackRecommendationsTable.propTypes = {
  /**
   * list of added recommendations
   */
  recommendations: PropTypes.array,
  setDeleteId: PropTypes.bool,
  noDataText: PropTypes.string,
  /**
   * show date with tinezone provided
   */
  timezone: PropTypes.string,
};
