/**
 *
 * IconButtonWithLoader
 *
 */

import React from 'react';
import PropTypes from 'prop-types';
import makeStyles from '@mui/styles/makeStyles';
import { IconButton, Grid } from '../thirdParty';
import { BookmarkIcon, BookmarkBorderIcon } from '../icons';
import LoadingIndicator from '../LoadingIndicator';

const buttonVariant = {
  small: '1rem',
  default: '1.5rem',
  large: '2rem',
};

const useStyles = makeStyles(() => ({
  bookmarkIcon: {
    color: props => props.iconColor,
    fontSize: props => buttonVariant[props.buttonSize] || props.buttonSize,
  },
  disabled: {
    opacity: '0.5',
  },
  loader: {
    marginLeft: '-5px',
  },
  padding: {
    padding: props => props.buttonPadding,
  },
}));

export const IconButtonWithLoader = props => {
  const {
    marked,
    markedTitle,
    unMarkedTitle,
    MarkedIcon,
    UnMarkedIcon,
    disabled,
    loading,
    onClickHandler,
    buttonSize,
    buttonPadding,
    iconColor,
    loaderImage,
  } = props;
  const classes = useStyles({
    buttonSize,
    buttonPadding,
    iconColor,
  });

  return (
    <Grid container alignItems="center">
      <IconButton
        onClick={e => {
          onClickHandler(e);
        }}
        classes={{ root: classes.padding }}
        title={marked ? markedTitle : unMarkedTitle}
        disabled={disabled || loading}
        className={disabled || loading ? classes.disabled : ''}
        size="large"
      >
        {marked ? (
          <MarkedIcon className={classes.bookmarkIcon} />
        ) : (
          <UnMarkedIcon Icon className={classes.bookmarkIcon} />
        )}
      </IconButton>
      {loading && (
        <LoadingIndicator
          size={13}
          className={classes.loader}
          loaderImage={loaderImage}
        />
      )}
    </Grid>
  );
};

IconButtonWithLoader.propTypes = {
  /**
   * Show filled icon when true
   */
  marked: PropTypes.bool,
  /**
   * Show this tooltip when marked true
   */
  markedTitle: PropTypes.string,
  /**
   * Show this tooltip when marked false
   */
  unMarkedTitle: PropTypes.string,
  /**
   * Show this icon when marked true
   */
  MarkedIcon: PropTypes.node,
  /**
   * Show this icon when marked false
   */
  UnMarkedIcon: PropTypes.node,
  /**
   * Color to be used in icon
   */
  // eslint-disable-next-line react/no-unused-prop-types
  iconColor: PropTypes.string,
  /**
   * Onclick handler for Icon Button
   */
  onClickHandler: PropTypes.func,
  /**
   * Disable mouse action when true
   */
  disabled: PropTypes.bool,
  /**
   * Disable mouse action and show loader when true
   */
  loading: PropTypes.bool,
  /**
   * size of the bookmark button
   */
  buttonSize: PropTypes.string,
  /**
   * buttonPadding
   */
  buttonPadding: PropTypes.number,
  loaderImage: PropTypes.string,
};

IconButtonWithLoader.defaultProps = {
  MarkedIcon: BookmarkIcon,
  UnMarkedIcon: BookmarkBorderIcon,
  iconColor: 'rgb(255, 152, 0)',
  markedTitle: 'Remove from Bookmark',
  unMarkedTitle: 'Add to Bookmark',
  buttonSize: 'default',
  buttonPadding: 12,
};
