import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import makeStyles from '@mui/styles/makeStyles';
import withStyles from '@mui/styles/withStyles';

import {
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Collapse,
  Tooltip,
  Box,
} from '../thirdParty';
import { ExpandLess, ExpandMore } from '../icons';

const CustomListItem = withStyles(theme => ({
  root: {
    paddingRight: '20px',
    marginTop: '10px',
    marginBottom: '10px',
    paddingBottom: 0,
    fontSize: '11px',
    color: theme.color.secondary,
    textTransform: 'uppercase',
    borderBottom: `0.25px solid ${theme.color.greyBorder}`,
    '&$selected': {
      backgroundColor: theme.color.darkGrey1,
    },
    '&$selected .count': {
      color: 'inherit',
    },
    '&$selected:hover': {
      backgroundColor: theme.color.darkGrey1,
    },
    '&:hover': {
      backgroundColor: theme.color.darkGrey2,
    },
    '& .count': {
      fontWeight: '500',
      fontSize: '11px',
    },
  },
  selected: {},
}))(ListItem);

const useStyles = makeStyles(theme => ({
  root: {
    width: '100%',
    /* '& .Mui-disabled': {
      opacity: 1,
    } */
    color: theme.color.greyForSidebarText,
    padding: '0 10px',
  },
  nested: {
    paddingLeft: '24px',
    textTransform: 'unset',
    borderBottom: 'none',
    marginTop: 0,
    marginBottom: 0,
  },
  listItemText: {
    fontWeight: 500,
    fontSize: '11px',
  },
  listItemTextNested: {
    fontWeight: 500,
    fontSize: '12px',
  },
  listItemRoot: {
    marginBottom: 0,
  },
  inactive: {
    pointerEvents: 'none',
  },
  disabled: {
    opacity: '0.5',
    pointerEvents: 'none',
  },
}));

export const NestedList = ({
  listArr,
  expandAllByDefault,
  iconsRequired,
  expandable,
  dense,
  onListItemClick,
  selectedListItem,
}) => {
  const classes = useStyles();
  const [open1, setOpen1] = React.useState({});
  const { t } = useTranslation();

  const handleClick = param => {
    setOpen1(prevState => ({ ...prevState, [param]: !prevState[param] }));
    onListItemClick(param);
  };

  const renderListTree = (list, isChild, paddingLeft) =>
    list.map(nav => {
      if (nav.hidden) {
        return null;
      }
      if (nav.children.length === 0) {
        return (
          <div className={isChild ? classes.nestedContainer : ''} key={nav.id}>
            <Tooltip title={t(nav.toolTipMsg) || ''}>
              <div>
                <CustomListItem
                  dense={dense || false}
                  button
                  className={`${isChild ? classes.nested : ''} ${
                    nav.disabled ? classes.disabled : ''
                  } ${nav.inactive ? classes.inactive : ''}`}
                  onClick={() => handleClick(nav.id)}
                  selected={nav.id === selectedListItem}
                  style={{ 'padding-left': `${paddingLeft}px` }}
                  // disabled={nav.disabled}
                  /* classes={{
                  disabled: classes.disabled,
                }} */
                >
                  {iconsRequired && <ListItemIcon>{nav.icon}</ListItemIcon>}
                  <ListItemText
                    classes={{
                      root: isChild ? '' : classes.listItemRoot,
                      primary:
                        isChild && !nav.parentNodeStyle
                          ? classes.listItemTextNested
                          : classes.listItemText,
                    }}
                    primary={t(nav.label)}
                  />
                  <Box component="div" className="count">
                    {nav.count}
                  </Box>
                </CustomListItem>
              </div>
            </Tooltip>
          </div>
        );
      }
      return (
        <div className={isChild ? classes.nestedContainer : ''} key={nav.id}>
          <Tooltip title={t(nav.toolTipMsg) || ''}>
            <div>
              <CustomListItem
                button
                dense={dense || false}
                className={`${isChild ? classes.nested : ''} ${
                  nav.disabled ? classes.disabled : ''
                } ${nav.inactive ? classes.inactive : ''}`}
                onClick={() => handleClick(nav.id)}
                // disabled={nav.disabled}
                style={{ 'padding-left': `${paddingLeft}px` }}
              >
                {iconsRequired && <ListItemIcon>{nav.icon}</ListItemIcon>}
                <ListItemText
                  classes={{
                    root: isChild ? '' : classes.listItemRoot,
                    primary: classes.listItemText,
                  }}
                  primary={t(nav.label)}
                />
                <Box component="div" className="count">
                  {nav.count}
                </Box>
                {expandAllByDefault || open1[nav.id]
                  ? expandable && <ExpandLess />
                  : expandable && <ExpandMore />}
              </CustomListItem>
            </div>
          </Tooltip>
          <Collapse
            in={expandAllByDefault || open1[nav.id]}
            timeout="auto"
            unmountOnExit
          >
            {!nav.hidden &&
              renderListTree(nav.children, true, paddingLeft + 24)}
          </Collapse>
        </div>
      );
    });

  return (
    <List
      dense={dense || false}
      component="nav"
      className={classes.root}
      disablePadding
    >
      {listArr && listArr.length > 0 && renderListTree(listArr, false, 0)}
    </List>
  );
};

NestedList.propTypes = {
  /**
   * expand all list items
   */
  expandAllByDefault: PropTypes.bool,
  /**
   * List array
   * Object should be a flat list of nodes with at least label, id, children fields
   */
  listArr: PropTypes.array,
  /**
   * Should icon be showed next to the list ?
   */
  iconsRequired: PropTypes.bool,
  /**
   * Should items can be expandable and collapsable ?
   */
  expandable: PropTypes.bool,
  /**
   * Callback function handler on click of a list node
   */
  onListItemClick: PropTypes.func,
  /**
   * Should the list be densly populated ?
   */
  dense: PropTypes.bool,
  /**
   * Selected list item
   */
  selectedListItem: PropTypes.string,
};
