import React from 'react';
import { SvgIcon } from '@mui/material';

function MyUsers(props) {
  return (
    <SvgIcon {...props}>
      <g>
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          fill="#152C73"
          d="M2.746,7.998c0-1.48,1.047-2.68,2.338-2.68s2.338,1.2,2.338,2.68
		s-1.047,2.681-2.338,2.681S2.746,9.478,2.746,7.998L2.746,7.998z"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          fill="#152C73"
          d="M16.602,7.998c0-1.48,1.025-2.68,2.289-2.68
		c1.265,0,2.289,1.2,2.289,2.68s-1.024,2.681-2.289,2.681C17.627,10.678,16.602,9.478,16.602,7.998L16.602,7.998z"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          fill="#152C73"
          d="M2.146,12.147L2.146,12.147c-0.916,0-1.604,0.795-1.604,1.769
		c0,4.865,0,4.865,0,4.865c4.504,0,4.504,0,4.504,0c0-4.6,0-4.6,0-4.6v-0.09c-0.992-1.945-0.992-1.945-0.992-1.945H2.146
		L2.146,12.147L2.146,12.147z"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          fill="#152C73"
          d="M21.862,12.147L21.862,12.147c-1.898,0-1.898,0-1.898,0
		c-0.987,1.945-0.987,1.945-0.987,1.945v0.09c0,4.6,0,4.6,0,4.6c4.48,0,4.48,0,4.48,0c0-4.865,0-4.865,0-4.865
		C23.457,12.942,22.697,12.147,21.862,12.147L21.862,12.147z"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          fill="#152C73"
          d="M9.209,6.162c0-1.778,1.232-3.219,2.754-3.219
		s2.755,1.441,2.755,3.219s-1.233,3.219-2.755,3.219S9.209,7.939,9.209,6.162L9.209,6.162z"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          fill="#152C73"
          d="M15.997,11.755L15.997,11.755c-2.581,0-2.581,0-2.581,0
		c-1.441,2.922-1.441,2.922-1.441,2.922c-1.443-2.922-1.443-2.922-1.443-2.922c-2.505,0-2.505,0-2.505,0l0,0
		c-1.138,0-2.049,1.063-2.049,2.391c0,6.91,0,6.91,0,6.91c12.068,0,12.068,0,12.068,0c0-6.91,0-6.91,0-6.91
		C18.046,12.818,17.136,11.755,15.997,11.755L15.997,11.755z"
        />
      </g>
    </SvgIcon>
  );
}
MyUsers.displayName = 'MyUsers';
MyUsers.muiName = 'SvgIcon';

export default MyUsers;
