/**
 *
 * History Chip Lists
 *
 */
// useState
import React from 'react';
import makeStyles from '@mui/styles/makeStyles';
import PropTypes from 'prop-types';
import { get, countBy, filter } from 'lodash';
import { useTranslation } from 'react-i18next';
import { Grid, Box } from '../thirdParty';
import { HistoryChip } from '../HistoryChip';
import { workSts } from '../constants';

const useStyles = makeStyles(() => ({
  root: {
    marginTop: 20,
    marginBottom: 20,
    flexDirection: 'column',
    alignItems: 'flex-start',
  },
  historyChipWrapper: {
    margin: '2px 10px 2px 0px',
    width: '100%',
  },
}));

function HistoryChipLists({
  feedback,
  sectionNameListMapping,
  filterCheck,
  isEditable,
  handleEditOperation,
  feedbackWorkStatus,
}) {
  const classes = useStyles();
  const { t } = useTranslation();

  const source = get(feedback, sectionNameListMapping.sources, []) || [];
  const workStatus = get(feedback, sectionNameListMapping.workstatus, []) || [];
  const sourceCounts = countBy(source, 'id');
  const filteredSource = filter(
    source,
    x => sourceCounts[x.id] === 1 || (sourceCounts[x.id] > 1 && x.deleted),
  );

  return (
    <Grid container alignItems="center" className={classes.root}>
      {filteredSource.length > 0 &&
        filterCheck?.reason &&
        filteredSource.map(
          fbSrc =>
            get(fbSrc, 'src.dn') && (
              <Box>
                {((!filterCheck?.deleted && !fbSrc.deleted) ||
                  filterCheck?.deleted) && (
                  <Grid item className={classes.historyChipWrapper}>
                    <HistoryChip
                      type="source"
                      label={`${fbSrc.src.dn} ${
                        fbSrc.srcId ? `( ${fbSrc.srcId} )` : ''
                      } `}
                      showSeparator
                      deleted={fbSrc.deleted}
                    />
                  </Grid>
                )}
              </Box>
            ),
        )}
      {workStatus.length > 0 &&
        filterCheck?.workOrder &&
        workStatus.map(
          ws =>
            get(ws, 'sts.dn') && (
              <Box>
                {((!filterCheck?.deleted && !ws.deleted) ||
                  filterCheck?.deleted) && (
                  <Grid item className={classes.historyChipWrapper}>
                    <HistoryChip
                      type="workorder"
                      label={`${t('Work_Order')} ${
                        ws.woId ? `( ${ws.woId} )` : ''
                      } ${ws.sts.dn}`}
                      showSeparator
                      deleted={ws.deleted}
                      isEditable={
                        isEditable && // equipment is good
                        ws.sts.id === workSts.INPROGRESS &&
                        !ws.deleted &&
                        feedbackWorkStatus?.filter(x => x.id === ws?.id)
                          .length !== 0
                      }
                      handleEditOperation={handleEditOperation}
                      workOrderDetails={ws}
                    />
                  </Grid>
                )}
              </Box>
            ),
        )}
    </Grid>
  );
}

HistoryChipLists.propTypes = {
  /**
   * The filter chips object
   */
  feedback: PropTypes.object,

  sectionNameListMapping: PropTypes.object,
  filterCheck: PropTypes.object,
  isEditable: PropTypes.bool,
  handleEditOperation: PropTypes.func,
  feedbackWorkStatus: PropTypes.array,
};

HistoryChipLists.defaultPropTypes = {
  isEditable: false,
  handleEditOperation: () => {},
  feedbackWorkStatus: [],
};

export { HistoryChipLists };
