import React from 'react';
import { SvgIcon } from '@mui/material';

function ReduceConsumable(props) {
  return (
    <SvgIcon
      {...props}
      width="58"
      height="58"
      viewBox="10 17 35 25"
      fill="none"
    >
      <rect width="58" height="58" fill="white" />
      <path
        d="M18.8158 30.3525H15.9462C15.4235 30.3525 15 30.776 15 31.2987V40.1188C15 40.6415 15.4235 41.065 15.9462 41.065H18.8158V30.3525Z"
        fill="#162C73"
      />
      <path
        d="M42.0525 30.3525H33.7044L31.5986 33.806L32.4409 34.9541L30.6039 36.9434V35.0303L29.0301 33.7299L30.9212 30.3525H23.7627V41.065H42.0537C42.5764 41.065 42.9999 40.6415 42.9999 40.1188V31.2987C42.9999 30.776 42.5764 30.3525 42.0537 30.3525H42.0525Z"
        fill="#162C73"
      />
      <path
        d="M22.8137 43H19.7617C18.9206 43 18.2363 42.3146 18.2363 41.4734V29.9844C18.2363 29.1433 18.9206 28.4579 19.7617 28.4579H22.8137C23.6549 28.4579 24.3403 29.1433 24.3403 29.9844V41.4734C24.3403 42.3146 23.6549 43 22.8137 43ZM19.7629 29.6175C19.5598 29.6175 19.3971 29.7814 19.3971 29.9844V41.4734C19.3971 41.6765 19.561 41.8404 19.7629 41.8404H22.8148C23.0179 41.8404 23.1818 41.6765 23.1818 41.4734V29.9844C23.1818 29.7814 23.0168 29.6175 22.8148 29.6175H19.7629Z"
        fill="#162C73"
      />
      <path
        d="M37.8847 20.6278C37.8847 19.2155 36.9166 18.0189 35.5724 17.6935C34.8755 16.0562 33.192 15.0281 31.3643 15.2612C30.5993 14.4558 29.555 14 28.445 14C27.1734 14 26.0057 14.585 25.2396 15.5866C25.0584 15.56 24.883 15.5473 24.7123 15.5473C22.4761 15.5473 20.6553 17.367 20.6553 19.6043C20.6553 21.6259 22.1368 23.307 24.1019 23.6036C24.658 24.829 25.7784 25.6863 27.0834 25.9159C27.6096 27.0363 28.7162 27.7679 29.9554 27.7679H33.5693C33.8012 27.7679 34.0331 27.739 34.2535 27.6871C36.3213 27.2983 37.8224 25.4521 37.8224 23.2978C37.8224 22.8201 37.7313 22.3574 37.5882 21.9086C37.774 21.5186 37.8836 21.087 37.8836 20.6289L37.8847 20.6278ZM25.1692 22.797L25.0284 22.3782L24.5876 22.3586C23.1061 22.2905 21.9453 21.0801 21.9453 19.6055C21.9453 18.1308 23.1868 16.8373 24.7134 16.8373C24.9165 16.8373 25.1276 16.8662 25.3769 16.9285L25.8384 17.0427L26.0842 16.6354C26.5942 15.7931 27.4769 15.29 28.4461 15.29C29.2885 15.29 30.0789 15.6789 30.6108 16.3585L30.8623 16.6781L31.2581 16.5893C32.7754 16.2523 34.0574 17.1131 34.4832 18.4597L34.6101 18.8624L35.0301 18.9062C35.9232 18.9997 36.5959 19.7405 36.5959 20.6301C36.5947 21.7101 35.6278 22.5859 34.4808 22.3332L33.9535 22.2178L33.747 22.7151C33.1631 24.1217 31.4854 24.8509 30.0039 24.1424L29.6646 23.9809L29.3577 24.1955C28.8892 24.5221 28.3435 24.6951 27.7792 24.6951C26.5965 24.6951 25.5465 23.9324 25.168 22.7982L25.1692 22.797ZM33.987 26.4259C33.837 26.4617 33.702 26.479 33.5704 26.479H29.9566C29.435 26.479 28.9654 26.2425 28.6146 25.8732C29.0231 25.7867 29.4212 25.6575 29.7869 25.4475C31.6712 26.1582 33.7747 25.3252 34.7185 23.6578C35.3797 23.689 36.0028 23.4917 36.5209 23.1444C36.5232 23.1951 36.5347 23.2447 36.5347 23.2967C36.5347 24.8313 35.4755 26.1455 33.9858 26.4259H33.987Z"
        fill="#162C73"
      />
    </SvgIcon>
  );
}
ReduceConsumable.displayName = 'ReduceConsumable';
ReduceConsumable.muiName = 'SvgIcon';

export default ReduceConsumable;
