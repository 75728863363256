/* eslint-disable no-unused-vars */
/**
 *
 * Feedback No Issue Exists
 *
 */

import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import makeStyles from '@mui/styles/makeStyles';
import Checkbox from '@mui/material/Checkbox';
import { isEmpty } from 'lodash';
import { Box } from '../thirdParty';
import { ProgressButton } from '../ProgressButton';
import { FeedbackSnackBar } from '../FeedbackSnackBar';
import theme from '../theme';

const useStyles = makeStyles({
  root: {
    display: 'flex',
    alignItems: 'center',
    marginBottom: 26,
  },
  noIssueLabel: {
    fontSize: 12,
    marginRight: 26,
    fontWeight: 500,
  },
  btn: {
    marginRight: 8,
  },
  disabled: {
    color: theme.color.lightBlack1,
  },
});

export const FeedbackNoIssue = ({
  noIssueHeading,
  noIssueExists,
  saveFeedback,
  paiUpdateFeedbackNoIssueExistsLoading,
  paiUpdateFeedbackNoIssueExistsLoaded,
  paiUpdateFeedbackNoIssueExistsError,
  resetUpdatePaiFeedbackSectionStatus,
  sectionNameListMapping,
  setFeedbacks,
  disabled,
  loaderImage,
}) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const [noIssue, setNoIssue] = React.useState(noIssueExists || false);

  const setNoIssueFound = event => {
    setNoIssue(event.target.checked);
  };

  const createNoIssueData = () => {
    const reqObj = {};
    reqObj.noIssueExists = noIssue;
    return reqObj;
  };

  const updateNoIssue = () => {
    saveFeedback('noIssueExists', createNoIssueData());
  };

  const feedbacks = () => {
    setFeedbacks(createNoIssueData());
  };

  useEffect(() => {
    feedbacks();
  }, [noIssue]);

  useEffect(() => {
    setNoIssue(noIssueExists);
  }, [noIssueExists]);

  return (
    <Box className={classes.root}>
      <Box component="span">
        <Box
          component="span"
          className={`${classes.noIssueLabel} ${
            disabled ? classes.disabled : ''
          }`}
        >
          {noIssueHeading}
        </Box>
        <Checkbox
          checked={noIssue}
          color="primary"
          onChange={setNoIssueFound}
          inputProps={{ 'aria-label': 'controlled' }}
          disabled={disabled}
        />
      </Box>
      <Box component="span">
        <ProgressButton
          inProgress={paiUpdateFeedbackNoIssueExistsLoading}
          onClick={updateNoIssue}
          buttonText={t('Save')}
          disabled={noIssueExists === noIssue}
          loaderImage={loaderImage}
        />
      </Box>

      <FeedbackSnackBar
        open={!isEmpty(paiUpdateFeedbackNoIssueExistsError)}
        message={t('Error_updating_no_issue_found')}
        handleClose={() => resetUpdatePaiFeedbackSectionStatus('noIssueExists')}
        severity="error"
      />

      <FeedbackSnackBar
        open={paiUpdateFeedbackNoIssueExistsLoaded}
        message={t('Successfully_updated_no_issue_found')}
        handleClose={() => resetUpdatePaiFeedbackSectionStatus('noIssueExists')}
        severity="success"
      />
    </Box>
  );
};

FeedbackNoIssue.propTypes = {
  /**
   * No issue exists label
   */
  noIssueHeading: PropTypes.string,
  /**
   * true if no issue exists
   */
  noIssueExists: PropTypes.bool,
  /**
   * Callback function to update feedback
   */
  saveFeedback: PropTypes.func,
  /**
   * Mapping of section name with section key
   */
  sectionNameListMapping: PropTypes.object,
  /**
   * true while adding/updating no issue exists
   */
  paiUpdateFeedbackNoIssueExistsLoading: PropTypes.bool,
  /**
   * true when successfully added/updated no issue exists
   */
  paiUpdateFeedbackNoIssueExistsLoaded: PropTypes.bool,
  /**
   * Error object while adding/updating no issue exists
   */
  paiUpdateFeedbackNoIssueExistsError: PropTypes.object,
  /**
   * Callback function to reset success/error status
   */
  resetUpdatePaiFeedbackSectionStatus: PropTypes.bool,
  setFeedbacks: PropTypes.func,
  disabled: PropTypes.bool,
  loaderImage: PropTypes.string,
};
