/**
 *
 * ContainerWithHeader
 *
 */

import React from 'react';
import PropTypes from 'prop-types';

import makeStyles from '@mui/styles/makeStyles';
import { Paper } from '@mui/material';
import { ResolutionTypeTotalCountInDialog } from '../ResolutionTypeTotalCountInDialog';

const useStyles = makeStyles(theme => ({
  headerText: {
    fontWeight: 600,
    marginBottom: theme.spacing(2),
    display: 'flex',
  },
  root: {
    /* maxHeight: '406px',
    overflowY: 'auto',
    padding: '24px 50px 24px 24px',
    boxShadow: '0 0 3px 2px #0000001a',
    width: ({ widthFromProps }) => widthFromProps || 'auto',
    border: '1px solid #8c8c8c', */
    boxShadow: 'none',
  },
  resolutionTypeCountAlignment: {
    marginLeft: '5px',
  },
}));

export const ContainerWithHeader = ({
  children,
  width,
  headerText,
  resolutionTypeCount,
}) => {
  const classes = useStyles({ width });

  return (
    <div>
      <Paper className={classes.root}>
        <div className={classes.headerText}>
          {`${headerText} ${resolutionTypeCount.length === 0 ? '' : '-'}`}
          <div className={classes.resolutionTypeCountAlignment}>
            <ResolutionTypeTotalCountInDialog
              resolutionTypesCountInDialog={resolutionTypeCount}
            />
          </div>
        </div>
        {children}
      </Paper>
    </div>
  );
};

ContainerWithHeader.propTypes = {
  /**
   * Component/Content that goes inside container. Expects a React component
   */
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]).isRequired,
  /**
   * Width of the container in px or %
   */
  width: PropTypes.string,
  /**
   * Header text for the container
   */
  headerText: PropTypes.string.isRequired,
  /**
   * Resolution type name and its count as array of Object
   */
  resolutionTypeCount: PropTypes.arrayOf(PropTypes.Object),
};

ContainerWithHeader.defaultProps = {
  width: '100%',
};
