import React, { useState } from 'react';
import PropTypes from 'prop-types';
import makeStyles from '@mui/styles/makeStyles';
import { isEmpty } from 'lodash';
import { useTranslation } from 'react-i18next';
import { SimpleDropdown } from '../SimpleDropdown';
import { FeedbackList } from '../FeedbackList';
import { ProgressButton } from '../ProgressButton';
import { Box } from '../thirdParty';

const useStyles = makeStyles(theme => ({
  root: {
    fontSize: '12px',
  },
  row: {
    border: `1px solid ${theme.color.lightBlack1}`,
    padding: '17px',
    margin: '10px',
    fontSize: '13px',
  },
  label: {
    marginRight: '10px',
  },
  select: {
    margin: '10px',
    fontWeight: '500',
  },
  addbtn: {
    color: `${theme.color.lightblue1}`,
    fontSize: '14px',
    fontWeight: '500',
    cursor: 'pointer',
  },
}));

/**
 * Primary UI component for user interaction
 */
export const CreateFeedback = props => {
  const {
    categories,
    categoriesLoading,
    categoriesDisabled,
    issues,
    issuesLoading,
    feedback,
    saveUserFeedback,
    onCategoryChange,
    loading,
  } = props;
  const classes = useStyles(props);
  const [selectedCategory, setSelectedCategory] = useState('');
  const [selectedIssue, setselectedIssue] = useState('');
  const { t } = useTranslation();

  const handleCategoryChange = value => {
    setSelectedCategory(value);
    setselectedIssue('');
    onCategoryChange(value);
  };

  const handleIssuesChange = value => {
    setselectedIssue(value);
  };

  const handleClick = () => {
    if (!isEmpty(selectedCategory) && !isEmpty(selectedIssue))
      saveUserFeedback(selectedCategory, selectedIssue);
  };

  const feedbackData =
    !isEmpty(feedback) &&
    feedback.map(feed => {
      if (!isEmpty(feed.Category) && !isEmpty(feed.Issue)) {
        const feedbackDt = {};
        feedbackDt[t('Category')] = feed.Category;
        feedbackDt[t('Issue')] = feed.Issue;
        return feedbackDt;
      }
      if (feed.Description) return { Description: feed.Description };
      return '';
    });

  return (
    <Box className={classes.root}>
      <Box display="flex" alignItems="center">
        <Box display="flex" alignItems="center" className={classes.select}>
          <Box className={classes.label}>{t('Category')}</Box>
          <Box>
            <SimpleDropdown
              data={categories}
              label={t('Select_one')}
              labelName="recommCategoryDisplay"
              selectedValue={selectedCategory}
              valueName="recommCategoryCode"
              handleChange={handleCategoryChange}
              loading={categoriesLoading}
              disabled={loading || categoriesDisabled}
            />
          </Box>
        </Box>

        <Box display="flex" alignItems="center" className={classes.select}>
          <Box className={classes.label}>{t('Issue')}</Box>
          <Box>
            <SimpleDropdown
              data={selectedCategory ? issues : []}
              label={t('Select_one')}
              labelName="recommDesc"
              selectedValue={selectedIssue}
              valueName="recommCode"
              handleChange={handleIssuesChange}
              loading={issuesLoading}
              disabled={loading || isEmpty(selectedCategory)}
            />
          </Box>
        </Box>
        <ProgressButton
          inProgress={loading}
          onClick={handleClick}
          buttonText={t('ADD')}
          disabled={isEmpty(selectedCategory) || isEmpty(selectedIssue)}
        />
      </Box>
      <Box>
        <FeedbackList feedback={feedbackData || []} />
      </Box>
    </Box>
  );
};

CreateFeedback.propTypes = {
  /**
   * list of categories
   */
  categories: PropTypes.array,
  /**
   * show loader if true
   */
  categoriesLoading: PropTypes.bool,
  /**
   * disable categories dropdown if true
   */
  categoriesDisabled: PropTypes.bool,
  /**
   * list of issues
   */
  issues: PropTypes.array,
  /**
   * show loader if true
   */
  issuesLoading: PropTypes.bool,
  /**
   * list of feedback
   */
  feedback: PropTypes.array,
  /**
   * true while creating feedback
   */
  loading: PropTypes.bool,
  /**
   * category change handler
   */
  onCategoryChange: PropTypes.func,
  /**
   * save handler
   */
  saveUserFeedback: PropTypes.func,
};
