import React from 'react';
import PropTypes from 'prop-types';
import makeStyles from '@mui/styles/makeStyles';
import { Paper, Tabs, Box } from '../thirdParty';

const useStyles = makeStyles(theme => ({
  rootpapper: {
    minWidth: props =>
      props?.styles?.minWidthPaper ? props?.styles?.minWidthPaper : '850px',
    display: 'flex',
    padding: '10px 5px',
  },
  labelContainer: {
    height: '20px',
    marginRight: theme.spacing(2.5),
    display: 'flex',
    alignItems: 'center',
    // maxWidth: '180px',
    minHeight: '25px',
  },
  icon: {
    marginRight: '5px',
    height: '20px',
  },
  label: {
    marginRight: '5px',
    color: theme.color.black60,
    fontSize: '11px',
    fontWeight: 500,
  },
  count: {
    fontSize: '24px',
    fontWeight: 'bold',
  },
  borderRight: {
    paddingRight: theme.spacing(2.5),
    borderRight: '1px solid #c5c5c5',
  },
  tabs: {
    minHeight: 20,
  },
  scrollButtons: {
    width: 30,
  },
  rowLabel: {
    fontSize: '12px',
    fontStyle: 'normal',
    fontWeight: 900,
    fontFeatureSettings: `"liga" 0`,
    color: theme.color.lightGrey,
    display: 'flex',
    alignItems: 'center',
  },
}));

/**
 * Primary UI component for user interaction
 */
export const LabelWithCountAndIconRow = ({ label, labelArr, styles }) => {
  const classes = useStyles({ styles });

  return (
    <Paper elevation={0} classes={{ root: classes.rootpapper }}>
      <Box component="span" className={classes.rowLabel}>
        {label}:
      </Box>
      <Tabs
        variant="scrollable"
        scrollButtons
        indicatorColor="primary"
        textColor="primary"
        aria-label="scrollable site points"
        classes={{ root: classes.tabs, scrollButtons: classes.scrollButtons }}
        allowScrollButtonsMobile
      >
        {labelArr.map((labelObj, index) => (
          <div
            className={
              index !== labelArr.length - 1
                ? `${classes.labelContainer} ${classes.borderRight}`
                : classes.labelContainer
            }
          >
            <div className={classes.icon}>{labelObj.icon}</div>
            <div className={classes.label}>{labelObj.label}</div>
            <div className={classes.count}>{labelObj.count}</div>
          </div>
        ))}
      </Tabs>
    </Paper>
  );
};

LabelWithCountAndIconRow.propTypes = {
  /**
   * Label array (Array of objects)
   */
  labelArr: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string,
      count: PropTypes.number,
      icon: PropTypes.instanceOf(Element),
    }),
  ),
  /**
   * Label for the row
   */
  label: PropTypes.string,
  styles: PropTypes.object,
};
