/* eslint-disable no-unused-vars */
/* eslint-disable arrow-body-style */
/**
 *
 * Feedback comments
 *
 */

import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { isEmpty, cloneDeep, trim } from 'lodash';
import { makeStyles, Box, TextareaAutosize } from '../thirdParty';
import { ListItem } from '../listItem';
import { getCurrentTimestamp } from '../dateUtils';
import { ProgressButton } from '../ProgressButton';
import { FeedbackSnackBar } from '../FeedbackSnackBar';
import { ContainerLoader } from '../ContainerLoader';
import { CustomDialog } from '../CustomDialog';
import { FeedbackCommentsTable } from '../FeedbackCommentsTable';

const useStyles = makeStyles(theme => ({
  root: {
    marginBottom: 35,
  },
  iconButton: {
    padding: 5,
  },
  commentWrapper: {
    margin: '25px 10px',
    fontSize: 11,
  },
  comment: {
    width: '70%',
    height: '83px !important',
    borderColor: theme.color.darkGrey3,
    backgroundColor: theme.color.darkGrey1,
    marginLeft: 10,
  },
  commentHeading: {
    fontSize: 12,
    fontWeight: 500,
  },
}));

export const FeedbackComments = ({
  loaderImage,
  feedbackComments,
  saveFeedback,
  paiUpdateFeedbackCommentsLoading,
  paiUpdateFeedbackCommentsLoaded,
  paiUpdateFeedbackCommentsError,
  resetUpdatePaiFeedbackSectionStatus,
  sectionNameListMapping,
  setFeedbacks,
  resetFields,
  timezone,
  deletePaiFeedbackSectionEntry,
}) => {
  const { t } = useTranslation();
  const classes = useStyles();
  const [comment, setComment] = React.useState('');
  const [deleteId, setDeleteId] = React.useState(-1);

  const setFeedbackComment = event => {
    setComment(event.target.value);
  };

  const createCommentsData = () => {
    /* const reqObj = {};
    reqObj[sectionNameListMapping.comments || 'comments'] = [
      ...feedbackComments,
      { ts: getCurrentTimestamp(), comment: trim(comment) },
    ];
    return reqObj; */
    return [{ comment: trim(comment) }];
  };

  const addComment = () => {
    saveFeedback('comments', createCommentsData());
  };

  const deleteComments = () => {
    /* const reqObj = {};
    const comments = cloneDeep(feedbackComments);
    comments.splice(deleteId, 1);
    reqObj[sectionNameListMapping.comments || 'comments'] = comments;
    setDeleteId(-1); */
    deletePaiFeedbackSectionEntry('comments', deleteId);
    setDeleteId(-1);
    // saveFeedback('comments', reqObj);
  };

  const feedbacks = () => {
    if (!isEmpty(trim(comment))) setFeedbacks(createCommentsData());
    else {
      const reqObj = {};
      reqObj[sectionNameListMapping.comments || 'comments'] = '';
      setFeedbacks(reqObj);
    }
  };

  useEffect(() => {
    feedbacks();
  }, [comment]);

  const clearComment = () => {
    setComment('');
  };

  useEffect(() => {
    if (paiUpdateFeedbackCommentsLoaded || resetFields) clearComment();
  }, [paiUpdateFeedbackCommentsLoaded, resetFields]);

  return (
    <Box className={classes.root}>
      <ListItem>
        <Box component="span" className={classes.commentHeading}>
          {feedbackComments.length}{' '}
          {t('Comment', { count: feedbackComments.length })}
        </Box>
      </ListItem>
      <ContainerLoader
        open={paiUpdateFeedbackCommentsLoading}
        loaderImage={loaderImage}
      >
        <Box component="div" className={classes.commentWrapper}>
          <FeedbackCommentsTable
            feedbackComments={feedbackComments}
            setDeleteId={setDeleteId}
            timezone={timezone}
          />
        </Box>
        <Box component="div" className={classes.addCommentWrapper}>
          <TextareaAutosize
            className={classes.comment}
            value={comment}
            placeholder={t('Write_a_comment')}
            onChange={setFeedbackComment}
          />
        </Box>
        <Box component="div">
          <ProgressButton
            onClick={addComment}
            buttonText={t('ADD_COMMENT')}
            disabled={isEmpty(trim(comment))}
          />
        </Box>
      </ContainerLoader>
      <FeedbackSnackBar
        open={!isEmpty(paiUpdateFeedbackCommentsError)}
        message={t('Error_updating_comments')}
        handleClose={() => resetUpdatePaiFeedbackSectionStatus('comments')}
        severity="error"
      />

      <FeedbackSnackBar
        open={paiUpdateFeedbackCommentsLoaded}
        message={t('Successfully_updated_comments')}
        handleClose={() => resetUpdatePaiFeedbackSectionStatus('comments')}
        severity="success"
      />

      <CustomDialog
        open={deleteId >= 0}
        cancelBtnLabel={t('Cancel')}
        dialogDescriptionText={t('Are_you_sure_you_want_to_remove_comment_?')}
        dialogTitleText={t('Comments')}
        handleAgreeCallback={deleteComments}
        handleCloseCallback={() => setDeleteId(-1)}
        okBtnLabel={t('Ok')}
      />
    </Box>
  );
};

FeedbackComments.propTypes = {
  /**
   * List of added comments
   */
  feedbackComments: PropTypes.array,
  /**
   * Callback function to update feedback
   */
  saveFeedback: PropTypes.func,
  /**
   * Mapping of section name with section key
   */
  sectionNameListMapping: PropTypes.object,
  /**
   * true while adding/updating comments
   */
  paiUpdateFeedbackCommentsLoading: PropTypes.bool,
  /**
   * true when successfully added/updated comments
   */
  paiUpdateFeedbackCommentsLoaded: PropTypes.bool,
  /**
   * Error object while adding/updating comments
   */
  paiUpdateFeedbackCommentsError: PropTypes.object,
  /**
   * Callback function to reset success/error status
   */
  resetUpdatePaiFeedbackSectionStatus: PropTypes.func,
  /**
   * Callback function to set feedbacks data
   */
  setFeedbacks: PropTypes.func,
  /**
   * reset comment text box if true
   */
  resetFields: PropTypes.bool,
  /**
   * show date with tinezone provided
   */
  timezone: PropTypes.string,
  loaderImage: PropTypes.string,

  deletePaiFeedbackSectionEntry: PropTypes.func,
};
