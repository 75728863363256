import React from 'react';
import makeStyles from '@mui/styles/makeStyles';
import Popover from '@mui/material/Popover';
import Button from '@mui/material/Button';
import PersonIcon from '@mui/icons-material/Person';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import PowerSettingsNewIcon from '@mui/icons-material/PowerSettingsNew';
import { TextButton } from '../TextButton';
import MyUsers from '../SvgIcons/MyUsers';

const useStyles = makeStyles(theme => ({
  typography: {
    padding: theme.spacing(2),
  },
  iconText: {
    display: 'flex',
    alignItems: 'center',
  },
  title: {
    paddingTop: 2,
    paddingLeft: 3,
    color: theme.palette.primary.main,
  },
  buttonText: {
    textTransform: 'none',
  },
  buttonRoot: { height: '80%', alignSelf: 'center', color: 'inherit' },
  popOverContent: {
    padding: theme.spacing(3),
    height: 200,
    minWidth: 200,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
  },
  userNameText: {
    fontSize: '1.25rem',
    fontWeight: '700',
    color: theme.palette.primary.main,
  },
  timeText: {
    color: 'rgba(0, 0, 0, 0.6)',
    fontSize: '0.8125rem',
    display: 'block',
  },
  logoutButton: {
    marginRight: theme.spacing(1),
    alignSelf: 'flex-end',
  },
  iconWithText: {
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column',
  },
  termsAndPrivacy: {
    display: 'flex',
    justifyContent: 'space-between',
    marginTop: '7px',
    marginBottom: '10px',
  },
}));

export function LogoutPopover({
  onLogOutClick,
  userName,
  time,
  logoutText,
  lastLoginTimeText,
  eulaButtonTitle,
  onEulaClick,
  myUserUrl,
  myUserText,
  onDeleteAccountClick,
  deleteAccLink,
  privacyNoticeTitle,
  privacyNoticeUrl,
}) {
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = React.useState(null);
  const { t } = useTranslation();

  const handleClick = event => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? 'logout-popover' : undefined;

  return (
    // eslint-disable-next-line react/jsx-fragments
    <React.Fragment>
      <Button
        aria-describedby={id}
        variant="text"
        onClick={handleClick}
        classes={{ root: classes.buttonRoot, text: classes.buttonText }}
      >
        <div className={classes.iconText}>
          <PersonIcon />
          <span className={classes.title}>{userName}</span>
        </div>
      </Button>
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
      >
        <div className={classes.popOverContent}>
          <div>
            <span className={classes.userNameText}>{userName}</span>
            <span className={classes.timeText}>{`${
              lastLoginTimeText || t('Last_login_time')
            } : ${time}`}</span>
          </div>
          <div className={classes.termsAndPrivacy}>
            <TextButton onClick={onEulaClick}>{eulaButtonTitle}</TextButton>
            <TextButton
              onClick={() => {
                window.open(privacyNoticeUrl);
              }}
            >
              {privacyNoticeTitle}
            </TextButton>
          </div>
          <div style={{ display: 'flex', justifyContent: 'space-between' }}>
            {myUserUrl ? (
              <Button
                variant="text"
                onClick={() => {
                  window.open(myUserUrl);
                }}
                color="primary"
                classes={{ root: classes.logoutButton }}
              >
                <div className={classes.iconWithText}>
                  <MyUsers />
                  {myUserText}
                </div>
              </Button>
            ) : (
              <div />
            )}

            <Button
              variant="text"
              onClick={onLogOutClick}
              color="primary"
              classes={{ root: classes.logoutButton }}
            >
              <div className={classes.iconWithText}>
                <PowerSettingsNewIcon />
                {logoutText || t('Log_out')}
              </div>
            </Button>
          </div>
          <div>
            <TextButton onClick={onDeleteAccountClick}>
              {deleteAccLink}
            </TextButton>
          </div>
        </div>
      </Popover>
    </React.Fragment>
  );
}

LogoutPopover.propTypes = {
  userName: PropTypes.string,
  time: PropTypes.string,
  logoutText: PropTypes.string,
  lastLoginTimeText: PropTypes.string,
  eulaButtonTitle: PropTypes.string,
  onLogOutClick: PropTypes.func,
  onEulaClick: PropTypes.func,
  myUserUrl: PropTypes.string,
  myUserText: PropTypes.string,
  onDeleteAccountClick: PropTypes.func,
  deleteAccLink: PropTypes.string,
  privacyNoticeTitle: PropTypes.string,
  privacyNoticeUrl: PropTypes.string,
};
