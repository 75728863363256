import React from 'react';
import { SvgIcon } from '@mui/material';

function EnergyEfficiency(props) {
  return (
    <SvgIcon
      {...props}
      width="58"
      height="58"
      viewBox="10 17 35 25"
      fill="none"
    >
      <rect width="58" height="58" fill="white" />
      <path
        d="M25.4072 31.0469C24.7519 31.0469 24.0976 31.0426 23.4423 31.0491C23.1986 31.0512 22.9993 30.9895 22.8834 30.7597C22.7664 30.5277 22.8465 30.3315 22.9906 30.1386C25.4289 26.8826 27.8661 23.6255 30.3034 20.3673C30.3857 20.2578 30.4583 20.1191 30.6326 20.2004C30.8168 20.286 30.7605 20.4432 30.7247 20.583C30.1766 22.7887 29.6263 24.9934 29.0782 27.1991C28.9623 27.6641 29.1757 27.9394 29.6502 27.9405C30.9511 27.9426 32.2531 27.9437 33.5541 27.9383C33.814 27.9383 34.035 27.9827 34.1574 28.2396C34.2766 28.4868 34.1715 28.6829 34.0231 28.8802C31.6086 32.1005 29.1974 35.3229 26.7851 38.5453C26.7645 38.5724 26.7428 38.5995 26.7223 38.6277C26.6399 38.7393 26.5435 38.8921 26.3984 38.7718C26.3215 38.7079 26.2911 38.529 26.3139 38.4174C26.4016 37.9871 26.5197 37.5644 26.6259 37.1384C27.0711 35.3511 27.5163 33.5626 27.9604 31.7753C28.0709 31.3287 27.8553 31.0502 27.3971 31.048C26.7331 31.0458 26.0702 31.048 25.4062 31.048L25.4072 31.0469Z"
        fill="#162C73"
      />
      <path
        d="M43.0514 29.7538C42.9268 36.1932 38.49 41.9119 32.1781 43.4998C27.0123 44.7994 22.4292 43.5496 18.4939 39.9479C18.4365 39.8958 18.3823 39.8395 18.326 39.7864C18.0325 39.5143 17.7844 39.523 17.4876 39.7929C17.3815 39.8893 17.245 39.9522 17.1226 40.0302C17.0727 39.8839 16.998 39.7408 16.9785 39.5902C16.9059 38.9994 16.8518 38.4076 16.7889 37.8158C16.7586 37.5286 16.7857 37.4993 17.0792 37.5297C17.7227 37.5947 18.3661 37.6554 19.0074 37.7291C19.1059 37.741 19.1969 37.8169 19.2922 37.8624C19.2489 37.9481 19.2143 38.0402 19.1612 38.1182C19.0799 38.2375 18.9575 38.3372 18.9023 38.4662C18.807 38.6884 18.8936 38.8824 19.0626 39.0482C20.9236 40.8746 23.1192 42.0994 25.6713 42.637C31.5737 43.8791 37.3472 41.2659 40.2914 36.0046C41.692 33.5019 42.2043 30.8009 41.8815 27.9459C41.7678 26.9389 41.5349 25.9624 41.2013 25.0064C41.1016 24.7191 41.1579 24.5045 41.3551 24.3571C41.6519 24.1349 42.0548 24.2509 42.1599 24.6335C42.4069 25.5288 42.669 26.4241 42.8304 27.3367C42.9712 28.1301 42.9832 28.9474 43.0514 29.7538Z"
        fill="#162C73"
      />
      <path
        d="M14 29.2466C14.1083 22.6261 18.8463 16.7525 25.3185 15.3706C30.376 14.291 34.8161 15.5808 38.6041 19.1024C39.0352 19.5034 39.2204 19.5067 39.6472 19.0959C39.7144 19.0309 39.814 18.9994 39.8985 18.9528C39.9494 19.0525 40.035 19.149 40.0469 19.2541C40.1227 19.8958 40.1823 20.5396 40.2484 21.1835C40.2765 21.4545 40.2451 21.4881 39.9722 21.461C39.3125 21.3959 38.6517 21.332 37.9931 21.255C37.9043 21.2442 37.7949 21.1802 37.7527 21.1076C37.7288 21.0664 37.8068 20.9418 37.8653 20.88C38.2856 20.4356 38.2856 20.2318 37.835 19.8069C35.0338 17.1655 31.7029 15.893 27.8629 16.0545C21.8175 16.3092 16.6896 20.6535 15.3822 26.578C14.8319 29.071 14.9846 31.5271 15.823 33.9409C15.9779 34.3864 15.7656 34.7398 15.3583 34.7322C15.0984 34.7278 14.9489 34.5793 14.8666 34.3463C14.5124 33.3524 14.2589 32.3324 14.1451 31.2843C14.0715 30.6079 14.0477 29.9262 14.0011 29.2477L14 29.2466Z"
        fill="#162C73"
      />
    </SvgIcon>
  );
}
EnergyEfficiency.displayName = 'EnergyEfficiency';
EnergyEfficiency.muiName = 'SvgIcon';

export default EnergyEfficiency;
