/**
 *
 * Feedback comments
 *
 */

import React from 'react';
import PropTypes from 'prop-types';
import { isEmpty } from 'lodash';
import { makeStyles, Box } from '../thirdParty';
import { convertToDtMnthYr } from '../dateUtils';
import { DeleteIconButton } from '../DeleteIconButton';
import { NoDataText } from '../NoDataText';

const useStyles = makeStyles(theme => ({
  commentItem: {
    marginBottom: 20,
    fontWeight: 400,
  },
  date: {
    marginBottom: 5,
    color: theme.color.secondary,
    display: 'flex',
    alignItems: 'center',
  },
  commentText: {
    wordBreak: 'break-all',
  },
  deleted: {
    textDecoration: `line-through ${theme.color.brightOrange}`,
  },
}));

export const FeedbackCommentsTable = ({
  feedbackComments,
  setDeleteId,
  noDataText,
  timezone,
  filterCheck,
}) => {
  const classes = useStyles();

  return !isEmpty(feedbackComments) ? (
    <Box component="div">
      {feedbackComments.map(feedbackComment => (
        <Box>
          {((!filterCheck?.deleted && !feedbackComment.deleted) ||
            filterCheck?.deleted) && (
            <Box component="div" className={classes.commentItem}>
              <Box component="div" className={classes.date}>
                <Box component="div">
                  {convertToDtMnthYr(feedbackComment.ts, timezone)}
                </Box>
                {setDeleteId && (
                  <Box component="div">
                    <DeleteIconButton
                      onClickHandler={() => setDeleteId(feedbackComment.id)}
                    />
                  </Box>
                )}
              </Box>
              <Box
                component="div"
                className={`${classes.commentText} ${
                  feedbackComment.deleted ? classes.deleted : ''
                }`}
              >
                {feedbackComment.comment}
              </Box>
            </Box>
          )}
        </Box>
      ))}
    </Box>
  ) : (
    <NoDataText text={noDataText}></NoDataText>
  );
};

FeedbackCommentsTable.propTypes = {
  /**
   * List of added comments
   */
  feedbackComments: PropTypes.array,
  setDeleteId: () => {},
  noDataText: PropTypes.string,
  /**
   * show date with tinezone provided
   */
  timezone: PropTypes.string,
  filterCheck: PropTypes.object,
};
