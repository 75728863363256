import React from 'react';
import PropTypes from 'prop-types';
import OutlinedInput from '@mui/material/OutlinedInput';
import InputAdornment from '@mui/material/InputAdornment';
import FormControl from '@mui/material/FormControl';
import { ThemeProvider, StyledEngineProvider } from '@mui/material/styles';
import makeStyles from '@mui/styles/makeStyles';
import IconButton from '@mui/material/IconButton';

import { blueTheme } from '../theme';
import { SearchIcon } from '../icons';

const useStyles = makeStyles(theme => ({
  textField: {
    width: '25ch',
  },
  // input: {
  //   padding: theme.spacing(0.5),
  //   paddingLeft: theme.spacing(1.5),
  //   width: '250px',
  // },
  inputRoot: {
    borderRadius: '9px',
    '& .MuiOutlinedInput-input': {
      padding: theme.spacing(0.5),
      paddingLeft: theme.spacing(1.5),
      width: '250px',
    },
  },
  icon: {
    color: 'rgba(18, 18, 18, 0.5)',
  },
}));

export const PaiAssetSearch = ({ searchKey, setSearchKey, placeholder }) => {
  const classes = useStyles();
  const [expanded, setExpanded] = React.useState(true);

  const handleChange = event => {
    setSearchKey(event.target.value);
  };

  return (
    <StyledEngineProvider injectFirst>
      <ThemeProvider theme={blueTheme}>
        {expanded ? (
          <FormControl className={classes.textField} variant="outlined">
            <OutlinedInput
              id="outlined-adornment-password"
              type="text"
              value={searchKey}
              classes={{
                root: classes.inputRoot,
                // inputMarginDense: classes.input,
              }}
              onChange={handleChange}
              margin="dense"
              endAdornment={
                <InputAdornment position="end">
                  <SearchIcon classes={{ root: classes.icon }} />
                </InputAdornment>
              }
              labelWidth={0}
              placeholder={placeholder}
            />
          </FormControl>
        ) : (
          <IconButton
            aria-label="search"
            onClick={() => setExpanded(true)}
            size="small"
          >
            <SearchIcon classes={{ root: classes.icon }} />
          </IconButton>
        )}
      </ThemeProvider>
    </StyledEngineProvider>
  );
};

PaiAssetSearch.propTypes = {
  searchKey: PropTypes.string,
  setSearchKey: PropTypes.func,
  placeholder: PropTypes.string,
};

PaiAssetSearch.defaultProps = {
  placeholder: 'Search',
};
