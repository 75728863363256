import React from 'react';
import { SvgIcon } from '@mui/material';

function RefrigerationFoodSafety(props) {
  return (
    <SvgIcon
      {...props}
      width="58"
      height="58"
      viewBox="10 17 35 25"
      fill="none"
    >
      <rect width="58" height="58" fill="white" />
      <path
        d="M33.6794 22.4582H33.3765V21.5526H32.176V22.7591H31.8672V23.059V23.1394V24.8672H33.9763V23.059H33.6794V22.4582Z"
        fill="#162C73"
      />
      <path
        d="M37.8876 14H21V40.8949H21.9096V43H26.1278V40.8949H32.7787V43H36.9969V40.8949H37.8886V37.9656H37.9085V14.001H37.8886L37.8876 14ZM32.7777 39.6854H31.5564V38.4641H32.7777V39.6854ZM33.3765 31.8329V30.9273H32.176V31.8329H31.8672V34.8526H30.06V32.1189H29.7591V31.8419H29.4612V30.9273H28.5447V31.8419H28.2468V32.1189H27.9459V34.8526H26.4266V32.1189H26.1258V31.823H25.8279V30.9273H24.9114V31.821H24.3126V34.8536H22.7983V30.3206H34.583V29.3932H33.9743V27.2841H33.6784V26.9842H33.3755V26.0786H32.175V26.9842H31.8662V29.3932H30.059V27.2613H29.7581V26.9842H29.4602V26.0697H28.5437V26.9842H28.2458V27.2613H27.9449V29.3932H26.4256V27.2613H26.1248V26.9842H25.8269V26.0697H24.9104V26.9842H24.6125V27.2613H24.3116V29.3932H22.7973V25.8125H34.582V24.8851H30.058V22.7422H29.7571V22.4652H29.4592V21.5506H28.5427V22.4652H28.2448V22.7422H27.9439V24.8851H26.4247V22.7422H26.1238V22.4652H25.8259V21.5506H24.9094V22.4652H24.6115V22.7422H24.3106V24.8851H22.7963V21.2627H34.581V20.3352H33.9723V18.2261H33.6764V17.9262H33.3735V17.0206H32.173V17.9262H31.8642V20.3352H30.057V18.2033H29.7561V17.9262H29.4582V17.0117H28.5417V17.9262H28.2438V18.2033H27.9429V20.3352H26.4237V18.2033H26.1228V17.9262H25.8249V17.0117H24.9084V17.9262H24.6105V18.2033H24.3096V20.3352H22.7953V15.8003H36.1022V21.2468H35.4886V22.1554H36.1022V34.8546H33.9713V32.1338H33.6754V31.8339H33.3725L33.3765 31.8329ZM34.587 39.6854H33.6853V38.4641H34.587V39.6854ZM36.4011 39.6854H35.5045V38.4641H36.4011V39.6854Z"
        fill="#162C73"
      />
    </SvgIcon>
  );
}
RefrigerationFoodSafety.displayName = 'RefrigerationFoodSafety';
RefrigerationFoodSafety.muiName = 'SvgIcon';

export default RefrigerationFoodSafety;
