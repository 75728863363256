/**
 *
 * ContainerWithHeader
 *
 */

import React from 'react';
import PropTypes from 'prop-types';

import makeStyles from '@mui/styles/makeStyles';
import { StatusChip } from '../StatusChip';
import { ResolutionTypeChip } from '../ResolutionTypeChip';
import { StageChip } from '../StageChip';

const useStyles = makeStyles(theme => ({
  headerText: {
    fontSize: '12px',
    fontWeight: '600',
    // marginBottom: theme.spacing(1),
    display: 'flex',
    alignItems: 'center',
  },
  descriptionText: {
    color: theme.color.originalBlack,
  },
  root: {
    fontSize: '12px',
    width: ({ widthFromProps }) => widthFromProps || 'auto',
    color: '#000000b3',
    fontWeight: 'normal',
  },
  mr12: {
    marginRight: '12px',
  },
  ml1: {
    marginLeft: theme.spacing(1),
  },
  subHeaderText: {
    display: 'flex',
    marginBottom: theme.spacing(1),
    alignItems: 'center',
    fontSize: '11px',
    color: theme.color.black60,
  },
}));

export const HeaderAndDescription = ({
  width,
  headerText,
  descriptionText,
  resolutionTypes,
  showChip,
  chipType,
  currentStage,
  numStages,
  subHeaderText,
  status,
}) => {
  const classes = useStyles({ width });

  return (
    <div className={classes.root}>
      <div className={classes.headerText}>{headerText}</div>
      <div className={classes.subHeaderText}>
        <span>{subHeaderText.text1}</span>
        <span className={classes.ml1}>{subHeaderText.text2}</span>
        {numStages && currentStage && status && (
          <span className={classes.ml1}>
            <StageChip
              numStages={numStages}
              currentStage={currentStage}
              status={status}
            />
          </span>
        )}
        {showChip && <StatusChip type={chipType} size="small" />}
        <ResolutionTypeChip
          resolutionTypes={resolutionTypes}
          showChip={showChip}
          chipType={chipType}
        />
      </div>
      <div className={classes.descriptionText}>{descriptionText}</div>
    </div>
  );
};

HeaderAndDescription.propTypes = {
  /**
   * Width of the container in px or %
   */
  width: PropTypes.string,
  /**
   * Header text
   */
  headerText: PropTypes.string.isRequired,
  /**
   * Description text
   */
  descriptionText: PropTypes.string.isRequired,
  /**
   * Flag to show chip next to header
   */
  showChip: PropTypes.bool,
  /**
   * Type of chip to be displayed
   */
  chipType: PropTypes.string,
  /**
   *Resolution type
   */
  resolutionTypes: PropTypes.array,
  /**
   * currentStage is number equal or less than numStages
   */
  currentStage: PropTypes.number,
  /**
   * numStages is number greater or equal to currentStage
   */
  numStages: PropTypes.number,
  /**
   * Sub header below the header text
   */
  subHeaderText: PropTypes.object,
  status: PropTypes.string,
};

HeaderAndDescription.defaultProps = {
  width: '100%',
};
