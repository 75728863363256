import React from 'react';
import PropTypes from 'prop-types';
import { Box } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { isEmpty } from 'lodash';

const useStyles = makeStyles(theme => ({
  root: {
    fontSize: '12px',
  },
  row: {
    border: `1px solid ${theme.color.lightBlack1}`,
    padding: '17px',
    margin: '10px',
    fontSize: '13px',
    fontWeight: 500,
  },
  label: {
    opacity: 0.7,
    marginRight: '10px',
  },
  feedback: {
    '&:not(:first-child)': {
      marginLeft: '58px',
    },
  },
}));

/**
 * Primary UI component for user interaction
 */
export const FeedbackList = props => {
  const { feedback } = props;
  const classes = useStyles(props);
  return (
    <Box component="div" className="root">
      {feedback.map(data => {
        const keys = Object.keys(data);
        const feed = [];
        if (keys.length === 1) {
          feed.push(
            <Box component="span" className={classes.value}>
              {data[keys[0]]}
            </Box>,
          );
        } else {
          keys.forEach(key => {
            feed.push(
              <Box component="span" className={classes.feedback}>
                <Box component="span" className={classes.label}>
                  {key}
                </Box>
                <Box component="span" className={classes.value}>
                  {data[key]}
                </Box>
              </Box>,
            );
          });
        }
        return !isEmpty(feed) ? (
          <Box component="div" className={classes.row}>
            {feed}
          </Box>
        ) : null;
      })}
    </Box>
  );
};

FeedbackList.propTypes = {
  /**
   * list of feedback
   */
  feedback: PropTypes.array,
};
