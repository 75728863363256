import React from 'react';
import { SvgIcon } from '@mui/material';

function RemoteFix(props) {
  return (
    <SvgIcon {...props}>
      <g>
        <g>
          <path
            d="M12.015,7.472c1.662,0,3.172,0.67,4.274,1.754l1.022-1.039c-1.365-1.344-3.236-2.175-5.297-2.175
					c-2.034,0-3.883,0.81-5.242,2.121l1.022,1.04C8.891,8.121,10.378,7.472,12.015,7.472z"
          />
          <path
            d="M12.015,4.545c2.46,0,4.696,0.992,6.324,2.596l1.024-1.039c-1.893-1.863-4.487-3.016-7.349-3.016
					c-2.833,0-5.405,1.131-7.295,2.961l1.023,1.04C7.369,5.515,9.579,4.545,12.015,4.545z"
          />
          <path
            d="M12.015,1.582c3.269,0,6.237,1.317,8.403,3.447l1.022-1.04c-2.429-2.389-5.758-3.867-9.426-3.867
					c-3.643,0-6.947,1.456-9.371,3.813l1.023,1.041C5.827,2.878,8.771,1.582,12.015,1.582z"
          />
        </g>
        <g>
          <path
            d="M22.808,22.104c0,0.976-0.798,1.773-1.773,1.773H2.966c-0.975,0-1.773-0.798-1.773-1.773v-9.881
					c0-0.975,0.798-1.773,1.773-1.773h18.068c0.976,0,1.773,0.799,1.773,1.773V22.104z"
          />
        </g>
        <g>
          <path
            fill="#FFFFFF"
            d="M15.779,14.473h-2.822c-0.346,0-0.625,0.281-0.625,0.627c0,0.344,0.279,0.625,0.625,0.625h0.761v3.493
					c0,0.347,0.279,0.626,0.625,0.626c0.347,0,0.626-0.279,0.626-0.626v-3.493h0.811c0.346,0,0.625-0.281,0.625-0.625
					C16.404,14.754,16.125,14.473,15.779,14.473z"
          />
          <path
            fill="#FFFFFF"
            d="M11.996,15.086c0-0.347-0.281-0.626-0.626-0.626c-0.346,0-0.626,0.279-0.626,0.626v2.595
					c0,0.017,0.002,0.038,0.003,0.054c0.01,0.125-0.009,0.497-0.204,0.706c-0.045,0.051-0.186,0.201-0.608,0.201
					c-0.612,0-0.751-0.335-0.76-0.608v-2.973c0-0.345-0.28-0.625-0.626-0.625c-0.347,0-0.626,0.28-0.626,0.625v2.973
					c0,0.747,0.535,1.86,2.012,1.86c0.81,0,1.284-0.334,1.538-0.613c0.553-0.607,0.536-1.425,0.523-1.623V15.086z"
          />
          <path
            fill="#FFFFFF"
            d="M5.177,14.31c-0.811,0-1.284,0.334-1.538,0.614c-0.553,0.605-0.537,1.424-0.524,1.623v2.57
					c0,0.345,0.28,0.626,0.627,0.626c0.346,0,0.625-0.281,0.625-0.626v-0.735h1.57v0.76c0,0.346,0.28,0.626,0.626,0.626
					c0.347,0,0.626-0.28,0.626-0.626v-2.973C7.188,15.421,6.653,14.31,5.177,14.31z M4.366,17.132v-0.61
					c0-0.015-0.001-0.036-0.002-0.054c-0.01-0.125,0.008-0.495,0.203-0.707c0.046-0.05,0.187-0.2,0.609-0.2
					c0.611,0,0.75,0.336,0.76,0.608v0.963H4.366z"
          />
          <path
            fill="#FFFFFF"
            d="M20.741,16.176c0.003-0.077-0.007-0.784-0.536-1.318c-0.248-0.25-0.701-0.548-1.458-0.548
					c-0.731,0-1.163,0.296-1.396,0.544c-0.48,0.509-0.49,1.176-0.485,1.33v1.98c-0.005,0.155,0.002,0.805,0.478,1.312
					c0.241,0.257,0.69,0.563,1.462,0.563c0.759,0,1.203-0.301,1.444-0.555c0.482-0.509,0.495-1.166,0.492-1.312V16.176z
					 M19.489,18.213c0.002,0.002,0,0.256-0.147,0.408c-0.103,0.111-0.284,0.166-0.537,0.166c-0.256,0-0.439-0.055-0.543-0.163
					c-0.147-0.151-0.147-0.405-0.147-0.405c0.003-0.015,0.003-0.027,0.003-0.04v-2.007c0-0.014,0-0.026,0-0.038
					c0-0.003-0.003-0.269,0.143-0.424c0.094-0.1,0.258-0.149,0.487-0.149c0.603,0,0.737,0.334,0.744,0.608l-0.002,2.013
					C19.489,18.192,19.489,18.203,19.489,18.213z"
          />
        </g>
      </g>
    </SvgIcon>
  );
}
RemoteFix.displayName = 'RemoteFix';
RemoteFix.muiName = 'SvgIcon';

export default RemoteFix;
