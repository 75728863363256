/**
 *
 * SearchBar
 *
 */

import React, { memo } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import makeStyles from '@mui/styles/makeStyles';

import _ from 'lodash';

// import messages from './messages';
import { Autocomplete, TextField, InputAdornment, Grid } from '../thirdParty';
import { SearchIcon, HistoryIcon } from '../icons';

const useStyles = makeStyles(theme => ({
  listbox: props => ({
    boxShadow: `0 1px 3px ${theme.color.lightBlack}, 0 1px 2px ${theme.color.black}`,
    fontSize: props.size === 'x-small' && '12px',
  }),
  inputRoot: props => ({
    '& .MuiOutlinedInput-root': {
      '& fieldset': {
        borderRadius: 20,
        boxShadow: `0 0 3px ${theme.color.lightBlack}`,
      },
      '& .MuiOutlinedInput-input': {
        fontSize: props.size === 'x-small' && '12px',
      },
      /* To change the searchbar border colors
      '&:hover fieldset': {
        borderColor: 'green',
      },
      '&.Mui-focused fieldset': {
        borderColor: 'green',
      }, */
    },
    cursor: 'pointer',
  }),
  searchIcon: {
    color: theme.palette.text.secondary,
    marginRight: theme.spacing(1),
  },
  historyIcon: {
    color: theme.palette.text.secondary,
    marginRight: theme.spacing(1),
  },
  listColor: {
    color: theme.color.lightblue,
  },
  listWrapper: {
    cursor: 'pointer',
    width: '100%',
  },
}));

function SearchBar({
  onSearchCallback,
  defaultSeperator,
  searchOptions,
  setSearchInputValue,
  searchKey,
  onClickCallOnceCallback,
  disabled,
  focus,
  onBlurCallback,
  defaultLabel,
  defaultFor,
  size,
}) {
  // TODO: rename onClickCallOnceCallback
  // const [searchBarClickedOnce, setSearchBarClickedOnce] = useState(false);
  // const intl = useIntl();

  // Call only once if both events are executed
  const onSearch = _.debounce(onSearchCallback, 100);

  const onClickOnce = () => {
    onClickCallOnceCallback();
  };
  const { t } = useTranslation();
  const getSearchListFormatMessage = labelType => {
    let displayLabel = '';
    switch (labelType) {
      case 'SiteCode':
        displayLabel = t('Site_Code');
        break;
      case 'SiteName':
        displayLabel = t('Site_Name');
        break;
      case 'City':
        displayLabel = t('City');
        break;
      case 'State':
        displayLabel = t('State');
        break;
      case 'Country':
        displayLabel = t('Country');
        break;
      case 'Zip':
        displayLabel = t('Zip');
        break;
      case 'OrganizationCode':
        displayLabel = t('Organization_Code');
        break;
      default:
        displayLabel = '';
    }
    return displayLabel;
  };

  const onKeyPress = event => {
    if (event.key === 'Enter') {
      onSearch({
        value: event.target.value,
        label: defaultLabel,
        seperator: defaultSeperator,
        for: defaultFor,
      });
      event.preventDefault();
    }
  };
  const onChange = (event, newValue) => {
    onSearch(newValue);
  };
  const classes = useStyles({ size });
  return (
    <Autocomplete
      id="main-search-bar"
      disableOpenOnFocus
      classes={{ listbox: classes.listbox }}
      getOptionLabel={option => option.value}
      filterOptions={x => x}
      freeSolo
      includeInputInList
      filterSelectedOptions
      size="small"
      options={searchOptions}
      onKeyPress={onKeyPress}
      onChange={onChange}
      value={searchKey}
      disabled={disabled}
      onBlur={onBlurCallback}
      onInputChange={(event, newInputValue) => {
        setSearchInputValue(newInputValue);
      }}
      renderInput={params => (
        <TextField
          {...params}
          autoFocus={focus}
          placeholder={t('Search_Site_Code,_Site_Name,_City,_State,_Zip')}
          classes={{ root: classes.inputRoot }}
          margin="normal"
          variant="outlined"
          fullWidth
          onClick={onClickOnce}
          InputProps={{
            ...params.InputProps,
            type: 'search',
            startAdornment: (
              <InputAdornment position="end">
                <SearchIcon classes={{ root: classes.searchIcon }} />
              </InputAdornment>
            ),
          }}
        />
      )}
      renderOption={(props, option) => {
        const displayLabel = getSearchListFormatMessage(option.label);
        const displaySeperator = t('in');
        const optionList = (
          <Grid item xs>
            {option.value} &nbsp;
            <span
              // variant="body2"
              color="textSecondary"
              className={classes.listColor}
            >
              {`${displaySeperator} ${displayLabel}`}
            </span>
          </Grid>
        );
        if (option.for === 'searchOption') {
          if (searchOptions[6].label === option.label) {
            return (
              <div
                style={{ marginBottom: '7px' }}
                className={classes.listWrapper}
              >
                <Grid
                  container
                  alignItems="center"
                  title={t('Search_Options')}
                  {...props}
                >
                  <Grid item>
                    <SearchIcon classes={{ root: classes.searchIcon }} />
                  </Grid>
                  {optionList}
                </Grid>
              </div>
            );
          }
          return (
            <div className={classes.listWrapper}>
              <Grid
                container
                alignItems="center"
                title={t('Search_Options')}
                {...props}
              >
                <Grid item>
                  <SearchIcon classes={{ root: classes.searchIcon }} />
                </Grid>
                {optionList}
              </Grid>
            </div>
          );
        }
        return (
          <div className={classes.listWrapper}>
            <Grid
              container
              alignItems="center"
              title={t('Recent_Searches')}
              {...props}
            >
              <Grid item>
                <HistoryIcon classes={{ root: classes.historyIcon }} />
              </Grid>
              {optionList}
            </Grid>
          </div>
        );
      }}
    />
  );
}

SearchBar.propTypes = {
  /**
   * Search Key
   */
  searchKey: PropTypes.string,
  /**
   * Array of searchOptions default 'label' attribute value on onKeyPress
   */
  defaultLabel: PropTypes.string,
  /**
   * Array of searchOptions default 'seperator' attribute value on onKeyPress
   */
  defaultSeperator: PropTypes.string,
  /**
   * Array of searchOptions default 'for' attribute value on onKeyPress
   */
  defaultFor: PropTypes.string,
  /**
   * set the onchange dropdown values to search input.
   */
  setSearchInputValue: PropTypes.string,
  /**
   * Array of searchOptions
   */
  searchOptions: PropTypes.arrayOf(PropTypes.string),
  /**
   * called when user enters a search key
   */
  onSearchCallback: PropTypes.func,
  /**
   * Will be called only once on click
   */
  onClickCallOnceCallback: PropTypes.func,
  /**
   * Input will disabled if true
   */
  disabled: PropTypes.bool,
  /**
   * If the search bar be focused
   */
  focus: PropTypes.bool,
  /**
   * OnBlur callback
   */
  onBlurCallback: PropTypes.func,
  size: PropTypes.oneOf(['large', 'small', 'x-small']),
};

SearchBar.defaultProps = {
  disabled: false,
  focus: false,
  size: 'large',
};

SearchBar.whyDidYouRender = true;

export default memo(SearchBar);
