import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '../thirdParty';

import { FilterTextFields } from '../filterTextField';
import { FilterDropdown } from '../filterDropdown';

const useStyles = makeStyles({
  containerStyle: {
    border: '1px solid #C8C8C8',
    height: '492px',
    width: '172px',
    padding: '30px',
    margin: '10px 0px 0px 67px',
  },
  headingStyle: {
    fontWeight: 550,
    fontSize: '1.09rem',
  },
  contentStyle: {
    paddingLeft: '2px',
    marginTop: '16px',
  },
});
export const SearchFilter = ({
  itemInSelectOrg,
  itmInSelectContract,
  siteNames,
  siteCodes,
  changeSiteCode,
  changeSiteName,
  handleChangeOrg,
  handleChangeContractCode,
  selectedOrganizationValue,
  selectedContractCodeValue,
}) => {
  const classes = useStyles();
  return (
    <div className={classes.containerStyle}>
      <div className={classes.headingStyle}>Filter</div>
      <div className={classes.contentStyle}>
        <FilterTextFields
          changeSiteCodeText={changeSiteCode}
          changeSiteNameText={changeSiteName}
          siteNames={siteNames}
          siteCodes={siteCodes}
        />
        <FilterDropdown
          itemInSelectOrg={itemInSelectOrg}
          itmInSelectContract={itmInSelectContract}
          handleOrganizationChange={handleChangeOrg}
          selectedOrgValue={selectedOrganizationValue}
          selectedCCValue={selectedContractCodeValue}
          handlContractCodeChange={handleChangeContractCode}
          itemsInSelect={itmInSelectContract}
        />
      </div>
    </div>
  );
};

SearchFilter.propTypes = {
  /**
   * The items in organization
   */
  itemInSelectOrg: PropTypes.array.isRequired,
  /**
   * The items in contact code
   */
  itmInSelectContract: PropTypes.array.isRequired,
  /**
   * The array of object from which auto correct will be populated
   */
  siteNames: PropTypes.array.isRequired,
  /**
   * The array of object from which auto correct will be populated
   */
  siteCodes: PropTypes.array.isRequired,
  /**
   * Call back method for site code change
   */
  changeSiteCode: PropTypes.func,
  /**
   * Call back method for site name change
   */
  changeSiteName: PropTypes.func,
  /**
   * Call back method for organization change
   */
  handleChangeOrg: PropTypes.func,
  /**
   * Call back method for contract code change
   */
  handleChangeContractCode: PropTypes.func,
  /**
   *  Selected value for organization
   */
  selectedOrganizationValue: PropTypes.string,
  /**
   * Selected value for contract code
   */
  selectedContractCodeValue: PropTypes.string,
};
