/**
 *
 * DeleteIconButton
 *
 */

import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { DeleteIcon } from '../thirdParty';
import { IconButtonWithLoader } from '../IconButtonWithLoader';

export const DeleteIconButton = ({ onClickHandler }) => {
  const { t } = useTranslation();
  return (
    <IconButtonWithLoader
      UnMarkedIcon={DeleteIcon}
      buttonPadding={6}
      buttonSize="18px"
      iconColor="rgba(33, 33, 33, 0.8)"
      onClickHandler={onClickHandler}
      unMarkedTitle={t('Delete')}
    />
  );
};

DeleteIconButton.propTypes = {
  onClickHandler: PropTypes.func,
};
