import React from 'react';
import PropTypes from 'prop-types';
import makeStyles from '@mui/styles/makeStyles';

const useStyles = makeStyles(theme => ({
  linkStyle: prop => ({
    color: theme.palette.primary.main,
    border: 'none',
    backgroundColor: 'inherit',
    cursor: 'pointer',
    padding: 0,
    textDecoration: prop.alwaysUnderline ? 'underline' : 'none',
    '&:hover': {
      textDecoration: 'underline',
    },
    fontSize: 'inherit',
  }),
}));

export const TextButton = props => {
  const { children, disabled, onClick, alwaysUnderline } = props;
  const classes = useStyles({ alwaysUnderline });

  return (
    <button
      onClick={onClick}
      className={classes.linkStyle}
      disabled={disabled}
      type="button"
    >
      {children}
    </button>
  );
};

TextButton.propTypes = {
  /**
   * Show text in normal style when disabled
   */
  disabled: PropTypes.bool,
  /**
   * onclick callback handler
   */
  onClick: PropTypes.func,
  children: PropTypes.node.isRequired,
  /**
   * button will be always underlined
   */
  alwaysUnderline: PropTypes.bool,
};

TextButton.defaultProps = {
  disabled: false,
  alwaysUnderline: false,
};
