import React from 'react';
import PropTypes from 'prop-types';
import { Box, Grid } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import Paper from '@mui/material/Paper';
import clsx from 'clsx';
import { useTranslation } from 'react-i18next';
import { StatusChip } from '../StatusChip';
import { RecommendationsLabels } from '../RecommendationsLabels';
import { RecommendationsDialog } from '../RecommendationsDialog';
import { ActionableDate } from '../ActionableDateV2';
import { Computing } from '../Computing';
import { CustomLink } from '../CustomLink';
import { ResolutionTypeMultiple } from '../ResolutionTypeMultipleIcon';
import { BenefitBadges } from '../BenefitBadges';
import { Tooltip } from '../thirdParty';

const useStyles = makeStyles(theme => ({
  rootpapper: {
    minWidth: 850,
    borderWidth: '0px 0px 1px 5px',
    border: 'rgba(0, 0, 0, 0.3) solid',
    borderRadius: 9,
    boxShadow: '0px 0px 1px 1px rgba(0,0,0,0.2)',
    paddingBottom: theme.spacing(1),
    maxHeight: 102.67,
  },
  headerText: {
    display: 'flex',
    alignItems: 'center',
    fontSize: '0.875rem',
    paddingBottom: theme.spacing(0.25),
    fontWeight: 700,
    whiteSpace: 'nowrap',
    width: '100%',
  },
  title: {
    fontSize: '0.75rem',
    flexGrow: 0,
    maxWidth: 230,
    minWidth: 230,
    flexBasis: '25%',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
  },
  titleTooltipTop: {
    fontSize: '0.75rem',
    marginBottom: '6px',
  },
  titleTooltipBottom: {
    fontSize: '0.75rem',
  },
  toolTipContainer: {
    // maxWidth: 'fit-content !important',
    maxWidth: '500px',
  },
  titleContainer: {
    marginTop: '7px',
  },
  titleAge: {
    fontSize: '0.6875rem',
    marginLeft: '10px',
    fontWeight: 500,
    flexGrow: 0,
    maxWidth: 170,
    flexBasis: '9%',
  },
  titleDates: {
    fontSize: '0.6875rem',
    fontWeight: 500,
    flexGrow: 0,
    maxWidth: 170,
    flexBasis: '15%',
  },
  root: {
    flexGrow: 1,
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
    fontSize: '0.75rem',
    color: '#000',
  },
  actionItem: {
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column',
    '& > *': {
      marginBottom: theme.spacing(1),
    },
    fontSize: '0.75rem',
    fontWeight: 700,
  },
  stepper: {
    display: 'flex',
    flexDirection: 'column',
    fontSize: '0.6875rem',
    height: '100%',
    width: '100%',
    padding: 0,
    margin: 0,
  },
  value: {
    marginTop: theme.spacing(0.25),
    textAlign: 'center',
  },
  condition: {
    fontSize: '0.6875rem',
    fontWeight: 700,
  },
  days: {
    fontSize: '0.875rem',
  },
  age: {
    fontSize: '0.6875rem',
    fontWeight: 400,
  },
  black60: {
    color: theme.color.black60,
    fontWeight: 500,
    fontSize: '0.875rem',
  },
  detailsContainer: {
    display: 'flex',
    width: '100%',
    marginTop: '-5px',
  },
  urgencyResCondContainer: {
    display: 'flex',
    alignItems: 'center',
    width: 225,
    marginTop: '-5px',
    justifyContent: 'space-around',
    '& > *': {
      marginLeft: 16,
      textAlign: 'center',
    },
    '& > :first-child': {
      marginLeft: 0,
      '& > :first-child': {
        marginLeft: 8,
      },
      width: 65,
    },
    '& > :nth-child(2)': {
      width: 64,
    },
    '& > :nth-child(3)': {
      width: 64,
    },
  },
  verticalLine: {
    paddingRight: theme.spacing(1),
    marginRight: theme.spacing(1),
    borderRight: '1px solid rgba(102, 102, 102, 0.5)',
  },
  icon: {
    fontSize: 18,
    marginLeft: 5,
  },
  recommendationNone: {
    marginLeft: theme.spacing(2),
  },
  computing: {
    height: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  recommendationAndDateContainer: {
    display: 'flex',
    width: '100%',
    height: '100%',
    position: 'relative',
  },
  benefits: {
    width: 105,
    minWidth: 105,
    height: 60,
  },
  viewDetails: {
    position: 'absolute',
    bottom: 8,
    right: 8,
  },
  bookmark: {
    flexGrow: 0,
    maxWidth: '5%',
    flexBasis: '5%',
  },
  URGENT: {
    borderLeftColor: theme.color.urgentYellow,
  },
  PLAN: {
    borderLeftColor: theme.color.planYellow,
  },
  HOLD: {
    borderLeftColor: theme.color.holdGrey,
  },
  NONE: {
    borderLeftColor: theme.color.resolvedGreen,
  },
}));

const urgencyValues = Object.freeze({
  URGENT: 'URGENT',
  PLAN: 'PLAN',
  NONE: 'NONE',
  HOLD: 'HOLD',
});

/**
 * Primary UI component for user interaction
 */
export const ActionableListItem = ({
  title,
  // onClickIcon,
  steps,
  openDate,
  urgency,
  condition,
  lastChangedDate,
  ageInDays,
  siteuuid,
  assetuuid,
  paiId,
  Bookmark,
  workInProgress,
  redirectURL,
  resolutionType,
  chipType,
  resolutionTypesCountInDialog,
  textColor,
  benefitsWithCount,
  encloseSerialModelNum,
  locale,
}) => {
  const classes = useStyles();
  const { t } = useTranslation();

  // TODO: move to container
  const getActionableText = actionText => {
    if (actionText) {
      return actionText;
    }
    return (
      <div className={classes.computing}>
        <Computing />
      </div>
    );
  };

  const showTitle = () => (
    <Tooltip
      classes={{ tooltip: classes.toolTipContainer }}
      title={showTitleToolTipContent()}
    >
      {showTitleContent()}
    </Tooltip>
  );

  const showTitleContent = () => (
    <div className={classes.titleContainer}>
      <div className={classes.title}>{title[0]}</div>
      <div className={classes.title}>{title[1]}</div>
    </div>
  );

  const showTitleToolTipContent = () => (
    <div>
      <div className={classes.titleTooltipTop}>{title[0]}</div>
      <div className={classes.titleTooltipBottom}>{title[1]}</div>
    </div>
  );
  return (
    <Paper
      classes={{ root: clsx(classes.rootpapper, classes[urgency]) }}
      variant="outlined"
    >
      <div className={classes.root}>
        <Grid container rowSpacing={0}>
          {/* Header Text */}
          <Grid item xs={12}>
            <Box component="div" className={classes.headerText}>
              {/* <AcUnit /> */}
              <Box component="div">
                {/* Site | Actionable | Summary */}
                {redirectURL ? (
                  <CustomLink to={redirectURL} showVisited>
                    {showTitle()}
                  </CustomLink>
                ) : (
                  showTitle()
                )}
              </Box>
              {/* ----Dates---- */}
              {urgency !== urgencyValues.NONE && (
                <div className={classes.titleAge}>
                  <span className={classes.age}>{t('Age')}</span>{' '}
                  <span className={classes.days}>{ageInDays}</span>{' '}
                  {t('Day', { count: ageInDays })}
                </div>
              )}
              {urgency !== urgencyValues.NONE && (
                <div className={classes.titleDates}>
                  <ActionableDate
                    text={t('Opened_on')}
                    date={openDate}
                    locale={locale}
                  />
                </div>
              )}
              <div className={classes.titleDates}>
                <ActionableDate
                  text={t('Last_change')}
                  date={lastChangedDate}
                  locale={locale}
                />
              </div>

              {urgency === urgencyValues.NONE && (
                <div className={classes.titleDates}>
                  <ActionableDate
                    text={t('Closed_on')}
                    date={lastChangedDate}
                    locale={locale}
                  />
                </div>
              )}

              {/* HOLD -->  N/A date for 'on closed' */}

              {urgency === urgencyValues.HOLD && (
                <div className={classes.titleDates}>
                  <ActionableDate text={t('Closed_on')} />
                </div>
              )}
              <div className={classes.bookmark}>
                <Bookmark
                  siteuuid={siteuuid}
                  assetuuid={assetuuid}
                  paiId={paiId}
                  workInProgress={workInProgress}
                  buttonPadding={2}
                />
              </div>
            </Box>
          </Grid>
          <div className={classes.detailsContainer}>
            {/* urgency */}
            <div>
              <Box mt={0.5} mb={0.75} fontSize={10} fontWeight={500}>
                {encloseSerialModelNum}
              </Box>
              <div className={classes.urgencyResCondContainer}>
                <div>
                  {urgency && <StatusChip type={urgency.toLowerCase()} />}
                </div>
                <span title={t(resolutionType)}>
                  <ResolutionTypeMultiple type={resolutionType} />
                </span>
                <div className={classes.condition}>
                  {getActionableText(condition)}
                </div>
              </div>
            </div>
            {/* impacting and condition */}
            <div className={classes.verticalLine}></div>
            <div className={clsx(classes.verticalLine, classes.benefits)}>
              {benefitsWithCount.length > 0 ? (
                <BenefitBadges benefitsWithCount={benefitsWithCount} />
              ) : (
                getActionableText()
              )}
            </div>
            <div className={classes.recommendationAndDateContainer}>
              {/* Date removed */}
              {/* Recommendations */}
              {steps.length === 0 && urgency !== urgencyValues.NONE && (
                <div className={classes.recommendationNone}>
                  {getActionableText()}
                </div>
              )}
              {urgency !== urgencyValues.NONE && (
                <div className={clsx(classes.stepper)}>
                  {steps.length > 0 && <RecommendationsLabels steps={steps} />}
                  {steps.length > 0 && (
                    <div className={classes.viewDetails}>
                      <RecommendationsDialog
                        steps={steps}
                        chipType={chipType}
                        resolutionTypesCountInDialog={
                          resolutionTypesCountInDialog
                        }
                        textColor={textColor}
                      />
                    </div>
                  )}
                </div>
              )}
            </div>
          </div>
        </Grid>
      </div>
      {/* eslint-enable */}
    </Paper>
  );
};

ActionableListItem.propTypes = {
  /**
   * Header title in an array
   */
  title: PropTypes.array.isRequired,
  /**
   * Optional click handler
   */
  // onClickIcon: PropTypes.func,
  steps: PropTypes.object.isRequired,
  openDate: PropTypes.string.isRequired,
  lastChangedDate: PropTypes.string,
  urgency: PropTypes.string.isRequired,
  condition: PropTypes.string.isRequired,
  ageInDays: PropTypes.string.isRequired,
  siteuuid: PropTypes.string,
  assetuuid: PropTypes.string,
  paiId: PropTypes.string,
  Bookmark: PropTypes.node,
  locale: PropTypes.string,
  /**
   * OnClick of title redirect to this URL
   */
  redirectURL: PropTypes.string,
  /* progress: PropTypes.oneOf(['inprogress', 'closed', 'open']).isRequired, */
  workInProgress: PropTypes.bool,
  /**
   * type is resolutionType either Field Visit or/and Remote Fix
   */
  resolutionType: PropTypes.array,
  /**
   * Type of chip to be displayed
   */
  chipType: PropTypes.string,
  /**
   * resolution type count for recommendation details dialog
   */
  resolutionTypesCountInDialog: PropTypes.arrayOf(Object),
  /**
   * color of the text for resolutionType summary
   */
  textColor: PropTypes.string,
  /**
   * Benifits with count
   */
  benefitsWithCount: PropTypes.arrayOf(Object),
  encloseSerialModelNum: PropTypes.string,
};

Date.propTypes = {
  date: PropTypes.string,
};

ActionableListItem.defaultProps = {
  benefitsWithCount: [],
};
