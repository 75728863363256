/* eslint-disable no-unused-vars */
/**
 *
 * Feedback Work Status
 *
 */

import React, { useEffect, useCallback, useRef } from 'react';
import PropTypes from 'prop-types';
import TextField from '@mui/material/TextField';
import moment from 'moment';
import DatePicker from 'react-multi-date-picker';
import InputIcon from 'react-multi-date-picker/components/input_icon';
import { isEmpty, cloneDeep, trim, set } from 'lodash';
import { useTranslation } from 'react-i18next';
import { makeStyles, Box } from '../thirdParty';
import { SimpleDropdown } from '../SimpleDropdown';
import { ProgressButton } from '../ProgressButton';
import { FeedbackSnackBar } from '../FeedbackSnackBar';
import { getCurrentTimestamp } from '../dateUtils';
import { CustomDialog } from '../CustomDialog';
import { ContainerLoader } from '../ContainerLoader';
import { FeedbackWorkStatusTable } from '../FeedbackWorkStatusTable';
import { localeLanguage, useOnClickOutside } from '../../../utils/common';

const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
    // alignItems: 'center',
  },
  heading: {
    textDecoration: `none solid ${theme.color.primary}`,
  },
  content: {
    padding: '10px 28px',
  },
  iconButton: {
    padding: 5,
  },
  workId: {
    '& .MuiInputBase-root::after, & input': {
      borderBottomWidth: `3px`,
      borderBottomStyle: `solid`,
    },
    '& :not(.Mui-error).MuiInputBase-root::after, & :not(.Mui-error) input': {
      borderBottomColor: theme?.color?.blue,
    },
    '& .Mui-error': {
      maxWidth: 180,
    },
    '& .MuiFormHelperText-root': {
      minHeight: 38,
    },
  },
  workIDLabel: {
    marginRight: 12,
    marginTop: 6,
  },
  statusLabel: {
    margin: '6px 12px 0px 21px',
  },
}));

export const FeedbackWorkStatus = ({
  loaderImage,
  workStatusHeading,
  workStatusList,
  feedbackWorkStatus,
  saveFeedback,
  sectionNameListMapping,
  feedbackWorkStatusLoading,
  feedbackWorkStatusLoaded,
  feedbackWorkStatusError,
  resetFeedbackWorkStatusError,
  resetUpdatePaiFeedbackSectionStatus,
  paiUpdateFeedbackWorkStatusLoading,
  paiUpdateFeedbackWorkStatusLoaded,
  paiUpdateFeedbackWorkStatusError,
  setServiceNotes,
  setServiceNotesError,
  resetFields,
  timezone,
  deletePaiFeedbackSectionEntry,
  locale,
}) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const dateRef = useRef();
  const [status, setStatus] = React.useState('');
  const [Id, setId] = React.useState('');
  const [deleteId, setDeleteId] = React.useState(-1);
  const [editId, setEditId] = React.useState(-1);
  const [editStatus, setEditStatus] = React.useState('');
  const [error, setError] = React.useState(false);
  const [date, setDate] = React.useState(getCurrentTimestamp());
  const [workOrderChangeDate, setWorkOrderChangeDate] = React.useState();

  const setWorkStatus = value => {
    setStatus(value);
  };

  const setEditWorkStatus = value => {
    setEditStatus(value);
  };

  const editWorkOrder = id => {
    setEditId(id);
    setEditStatus('');
  };

  const checkIfIdExists = woId =>
    !isEmpty(feedbackWorkStatus.find(ws => ws.woId === trim(woId)));

  const setWorkId = event => {
    setId(event.target.value);
    /* if (checkIfIdExists(event.target.value)) {
      setError(true);
    } else {
      setError(false);
    } */
  };

  const onDatePicked = pickedDate => {
    setDate(new Date(pickedDate).getTime());
  };

  const createWorkStatusData = () => {
    /* const reqObj = {};
    const workSts = workStatusList.find(workStatus => workStatus.id === status);
    reqObj[sectionNameListMapping.workstatus || 'workstatus'] = [
      ...feedbackWorkStatus,
      { woId: trim(Id), sts: workSts, woCreateDate: date },
    ];
    return reqObj; */
    const workSts = workStatusList.find(workStatus => workStatus.id === status);
    return [{ woId: trim(Id), sts: workSts, woCreateDate: date }];
  };

  const handleClick = () => {
    saveFeedback('workstatus', createWorkStatusData());
  };

  const setUpdatedDate = pickedDate => {
    setWorkOrderChangeDate(new Date(pickedDate).getTime());
  };

  const editWorkStatus = () => {
    /* const reqObj = {};
    const workStatus = cloneDeep(feedbackWorkStatus);
    const workSts = workStatusList.find(ws => ws.id === editStatus);
    set(workStatus, `[${editId}].sts`, workSts);
    set(workStatus, `[${editId}].woCreateDate`, getCurrentTimestamp());
    reqObj[sectionNameListMapping.workstatus || 'workstatus'] = workStatus;
    setEditId(-1);
    setEditStatus('');
    saveFeedback('workstatus', reqObj); */
    const workSts = workStatusList.find(ws => ws.id === editStatus);
    const editWS = feedbackWorkStatus.find(ws => ws.id === editId);
    saveFeedback(
      'workstatus',
      {
        woId: editWS.woId,
        woCreateDate: !workOrderChangeDate
          ? editWS.woCreateDate
          : workOrderChangeDate,
        sts: { id: workSts.id },
      },
      editWS.id,
    );
    // return [{ ...feedbackWorkStatus[editId], sts: workSts }];
  };

  const deleteWorkStatus = () => {
    /* const reqObj = {};
    const workStatus = cloneDeep(feedbackWorkStatus);
    workStatus.splice(deleteId, 1);
    reqObj[sectionNameListMapping.workstatus || 'workstatus'] = workStatus;
    setDeleteId(-1);
    saveFeedback('workstatus', reqObj); */

    deletePaiFeedbackSectionEntry('workstatus', deleteId);
    setDeleteId(-1);
  };

  const serviceNotes = () => {
    if (!isEmpty(trim(Id)) && !isEmpty(status)) {
      setServiceNotes(createWorkStatusData());
    } else {
      const reqObj = {};
      reqObj[sectionNameListMapping.workstatus || 'workstatus'] = [];
      setServiceNotes(reqObj);
    }
  };

  useEffect(() => {
    if (error) {
      setServiceNotesError(true);
    } else {
      setServiceNotesError(false);
      serviceNotes();
    }
  }, [status, Id, error]);

  const clearWorkStatus = () => {
    setId('');
    setStatus('');
  };

  const handleDatePickerClose = useCallback(
    () => dateRef?.current?.closeCalendar(),
    [dateRef],
  );

  useOnClickOutside(dateRef, handleDatePickerClose);

  useEffect(() => {
    if (paiUpdateFeedbackWorkStatusLoaded || resetFields) clearWorkStatus();
  }, [paiUpdateFeedbackWorkStatusLoaded, resetFields]);

  return (
    <Box component="div">
      <Box component="div" className={classes.heading}>
        {workStatusHeading}
      </Box>
      <ContainerLoader
        open={paiUpdateFeedbackWorkStatusLoading}
        loaderImage={loaderImage}
      >
        <Box component="div" className={classes.content}>
          <Box component="div" className={classes.addWorkStatus}>
            {!isEmpty(workStatusList) && (
              <Box component="div" className={classes.root}>
                <Box component="span" className={classes.workIDLabel}>
                  {t('ID')}
                </Box>
                <Box component="span">
                  <TextField
                    variant="standard"
                    onChange={setWorkId}
                    className={classes.workId}
                    value={Id}
                    error={error}
                    /* eslint-disable */
                    helperText={
                      error
                        ? t(
                            'Work_order_Id_already_exists,_please_use_a_different_Id',
                          )
                        : ' '
                    }
                    /* eslint-enable */
                  />
                </Box>
                <Box component="span" className={classes.statusLabel}>
                  {t('Status')}
                </Box>
                <Box component="span">
                  <SimpleDropdown
                    data={(feedbackWorkStatusLoaded && workStatusList) || []}
                    label={t('Select_one')}
                    labelName="dn"
                    valueName="id"
                    selectedValue={status}
                    loading={feedbackWorkStatusLoading}
                    handleChange={setWorkStatus}
                  />
                </Box>
                <Box component="span" className={classes.statusLabel}>
                  {t('Date')}
                </Box>
                <Box className="custom-calendar">
                  <DatePicker
                    locale={localeLanguage(t)}
                    placeholder=""
                    render={<InputIcon />}
                    minDate={
                      new Date(
                        moment(moment().subtract(90, 'days')).format(
                          'YYYY-MM-DD',
                        ),
                      )
                    }
                    maxDate={new Date(moment())}
                    format="DD MMM, YYYY"
                    shouldCloseOnSelect={false}
                    onChange={e => onDatePicked(e)}
                    value={new Date(date)}
                    ref={dateRef}
                  />
                </Box>
                <Box component="span">
                  <ProgressButton
                    onClick={handleClick}
                    buttonText={t('ADD')}
                    disabled={isEmpty(status) || error}
                  />
                </Box>
              </Box>
            )}
          </Box>
          <FeedbackWorkStatusTable
            workStatusList={workStatusList}
            feedbackWorkStatus={feedbackWorkStatus}
            feedbackWorkStatusLoading={feedbackWorkStatusLoading}
            feedbackWorkStatusLoaded={feedbackWorkStatusLoaded}
            editId={editId}
            editWorkOrder={editWorkOrder}
            setDeleteId={setDeleteId}
            setEditWorkStatus={setEditWorkStatus}
            editWorkStatus={editWorkStatus}
            editStatus={editStatus}
            timezone={timezone}
            sendUpdatedDate={setUpdatedDate}
            locale={locale}
          />
          <FeedbackSnackBar
            open={!isEmpty(paiUpdateFeedbackWorkStatusError)}
            message={t('Error_updating_work_status')}
            handleClose={() =>
              resetUpdatePaiFeedbackSectionStatus('workstatus')
            }
            severity="error"
          />

          <FeedbackSnackBar
            open={paiUpdateFeedbackWorkStatusLoaded}
            message={t('Successfully_updated_work_status')}
            handleClose={() =>
              resetUpdatePaiFeedbackSectionStatus('workstatus')
            }
            severity="success"
          />

          <FeedbackSnackBar
            open={!isEmpty(feedbackWorkStatusError)}
            message={t('Error_loading_list_of_work_status')}
            handleClose={resetFeedbackWorkStatusError}
            severity="error"
          />

          <CustomDialog
            open={deleteId >= 0}
            cancelBtnLabel={t('Cancel')}
            dialogDescriptionText={t(
              'Are_you_sure_you_want_to_remove_work_status_?',
            )}
            dialogTitleText={t('Work_status')}
            handleAgreeCallback={deleteWorkStatus}
            handleCloseCallback={() => setDeleteId(-1)}
            okBtnLabel={t('Ok')}
          />
        </Box>
      </ContainerLoader>
    </Box>
  );
};

FeedbackWorkStatus.propTypes = {
  /**
   * List of available workStatus
   */
  workStatusList: PropTypes.array,
  /**
   * User's added feedback source
   */
  feedbackWorkStatus: PropTypes.array,
  /**
   * Work status heading label
   */
  workStatusHeading: PropTypes.string,
  /**
   * Callback function to update feedback
   */
  saveFeedback: PropTypes.func,
  /**
   * Mapping of section name with section key
   */
  sectionNameListMapping: PropTypes.object,
  /**
   * Error object while loading list of work status
   */
  feedbackWorkStatusError: PropTypes.object,
  /**
   * Callback function to reset feedback work status error
   */
  resetFeedbackWorkStatusError: PropTypes.func,
  /**
   * true when list work status loading
   */
  feedbackWorkStatusLoading: PropTypes.bool,
  /**
   * true when list work status loaded
   */
  feedbackWorkStatusLoaded: PropTypes.bool,
  /**
   * true while updating/adding sources
   */
  paiUpdateFeedbackWorkStatusLoading: PropTypes.bool,
  /**
   * true when successfully added/updated sources
   */
  paiUpdateFeedbackWorkStatusLoaded: PropTypes.bool,
  /**
   * Error object while adding/updating work status
   */
  paiUpdateFeedbackWorkStatusError: PropTypes.object,
  /**
   * Callback function to reset success/error status
   */
  resetUpdatePaiFeedbackSectionStatus: PropTypes.func,
  /**
   * Callback function to set service notes data
   */
  setServiceNotes: PropTypes.func,
  /**
   * Callback function to ser error if any
   */
  setServiceNotesError: PropTypes.func,
  /**
   * reset woId and status fields if true
   */
  resetFields: PropTypes.bool,
  /**
   * show date with tinezone provided
   */
  timezone: PropTypes.string,

  deletePaiFeedbackSectionEntry: PropTypes.func,
  /**
   * locale to be used in date localization
   */
  locale: PropTypes.string,
  loaderImage: PropTypes.string,
};

FeedbackWorkStatus.defaultProps = {};
