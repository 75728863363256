/**
 *
 * History Chip
 *
 */

import React, { Fragment } from 'react';
import makeStyles from '@mui/styles/makeStyles';
import { Chip } from '@mui/material';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { EditIcon, Grid } from '../thirdParty';

const useStyles = makeStyles(theme => ({
  root: {
    height: '20px',
    borderRadius: 5,
    border: `1px solid ${theme.color.originalBlack}`,
    minWidth: 80,
  },
  label: {
    fontSize: '0.75rem',
    fontWeight: 400,
    padding: `${theme.spacing(1)} ${theme.spacing(1.5)}`,
    height: '100%',
    wordBreak: 'break-word',
    whiteSpace: 'break-spaces',
  },
  workorder: {
    color: theme.color.white,
    backgroundColor: theme.color.palePurple,
  },
  separator: {
    border: `1px solid ${theme.color.grey}`,
    borderWidth: '1px 0',
    height: '2px',
    width: '10px',
  },
  source: {
    backgroundColor: theme.color.paleNavy,
  },
  additionalFixes: {
    backgroundColor: theme.color.paleCyan,
  },
  comments: {
    color: theme.color.originalBlack,
    backgroundColor: theme.color.green,
  },
  chipWrapper: {
    width: 'calc(100% - 10px)',
  },
  chip: {
    width: 'calc(100% + 10px)',
    height: '100%',
    whiteSpace: 'break-spaces',
    color: theme.color.originalBlack,
    justifyContent: 'left',
  },
  deleted: {
    textDecoration: `line-through ${theme.color.brightOrange}`,
  },
}));

function HistoryChip({
  label,
  type,
  showSeparator,
  deleted,
  isEditable,
  handleEditOperation,
  workOrderDetails,
}) {
  const classes = useStyles();
  const { t } = useTranslation();

  const handleEdit = () => {
    handleEditOperation(workOrderDetails);
  };

  return (
    <Grid container alignItems="center">
      {showSeparator && <Grid item className={classes.separator}></Grid>}
      <Grid item className={classes.chipWrapper}>
        <Chip
          variant="outlined"
          color="primary"
          label={t(`${label}`)}
          classes={{
            root: classes.root,
            label: classes.label,
          }}
          className={`${classes.chip} ${classes[type]} ${
            deleted ? classes.deleted : ''
          }`}
          onDelete={handleEdit}
          deleteIcon={
            isEditable ? (
              <EditIcon style={{ color: 'rgba(0, 0, 0, 0.87)' }} />
            ) : (
              <></>
            )
          }
        />
      </Grid>
    </Grid>
  );
}

HistoryChip.propTypes = {
  /**
   * The filter chips object
   */
  label: PropTypes.object,
  /**
   * Select chips truth array
   */
  type: PropTypes.oneOf(['workorder', 'source', 'additionalFixes', 'comments']),
  /**
   * show chip separator line if true
   */
  showSeparator: PropTypes.bool,

  deleted: PropTypes.bool,
  isEditable: PropTypes.bool,
  handleEditOperation: PropTypes.func,
  workOrderDetails: PropTypes.object,
};

HistoryChip.defaultProps = {
  isEditable: false,
  handleEditOperation: () => {},
  workOrderDetails: {},
};

export { HistoryChip };
