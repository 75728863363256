import React from 'react';
import makeStyles from '@mui/styles/makeStyles';
import PropTypes from 'prop-types';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import Divider from '@mui/material/Divider';
import ListItemText from '@mui/material/ListItemText';

import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import { useTranslation } from 'react-i18next';
import { StageChip } from '../StageChip';
// import { getConfirmedStatusChip } from '../Common';
import { ResolutionTypeChip } from '../ResolutionTypeChip';
import { ResolutionTypeTotalCountInDialog } from '../ResolutionTypeTotalCountInDialog';
import { StatusChip } from '../StatusChip';
import { TextButton } from '../TextButton';

const useStyles = makeStyles(theme => ({
  root: {
    alignItems: 'center',
    display: 'flex',
    fontWeight: 700,
  },
  viewDetails: {
    fontSize: 11,
    fontWeight: 700,
    textTransform: 'uppercase',
    marginLeft: 10,
    marginTop: 3,
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
  secondary: {
    marginTop: '10px',
  },
  chipStyleCondition: {
    display: 'flex',
  },
  dialogPaper: {
    minWidth: '350px',
  },
}));

function RecommendationsDialog({
  steps,
  chipType,
  resolutionTypesCountInDialog,
  textColor,
}) {
  const classes = useStyles();
  const { t } = useTranslation();
  const [open, setOpen] = React.useState(false);

  const handleClose = e => {
    e.stopPropagation();
    e.preventDefault();
    setOpen(false);
  };

  const onViewDetails = e => {
    e.stopPropagation();
    e.preventDefault();
    setOpen(true);
  };

  // stopPropagation for backdrop click
  const onClick = e => {
    e.stopPropagation();
    e.preventDefault();
  };

  return (
    <div className={classes.root}>
      <TextButton onClick={onViewDetails}>
        <div className={classes.viewDetails}>{t('View_details')}</div>
      </TextButton>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="form-dialog-title"
        classes={{ paper: classes.dialogPaper }}
        onClick={onClick}
      >
        <DialogTitle id="form-dialog-title">
          {`${t('Service_Recommendations')} (${steps.length})`}
          <IconButton
            aria-label="close"
            className={classes.closeButton}
            onClick={handleClose}
            size="large"
          >
            <CloseIcon />
          </IconButton>
          <div>
            <ResolutionTypeTotalCountInDialog
              resolutionTypesCountInDialog={resolutionTypesCountInDialog}
              textColor={textColor}
            />
          </div>
        </DialogTitle>
        <DialogContent>
          <div>
            {steps.map((step, index) => (
              <List>
                <ListItem alignItems="flex-start">
                  <ListItemText
                    primary={
                      <div>
                        {step.label}
                        <div className={classes.chipStyleCondition}>
                          <span>
                            <StageChip
                              numStages={step.numStages}
                              currentStage={step.currentStage}
                              status={step.urgency}
                            />{' '}
                          </span>
                          {/* {getConfirmedStatusChip(step.feedback)} */}
                          <span>
                            {step.showChip && (
                              <StatusChip type={chipType} size="small" />
                            )}
                          </span>
                          {resolutionTypesCountInDialog && (
                            <ResolutionTypeChip
                              resolutionTypes={step.recommendatinTypes}
                            />
                          )}
                        </div>
                      </div>
                    }
                    secondary={
                      <div className={classes.secondary}>
                        {step.description}
                      </div>
                    }
                  />
                </ListItem>
                {steps.length - 1 > index && <Divider component="li" />}
              </List>
            ))}
          </div>
        </DialogContent>
      </Dialog>
    </div>
  );
}

RecommendationsDialog.propTypes = {
  /**
   * steps
   */
  steps: PropTypes.array,
  /**
   * chip type in string
   */
  chipType: PropTypes.string,
  /**
   * Resolution type name and its count as array of Object
   */
  resolutionTypesCountInDialog: PropTypes.arrayOf(PropTypes.Object),
  /**
   * Text color for resolution type count
   */
  textColor: PropTypes.string,
};

RecommendationsDialog.defaultProps = {};

export { RecommendationsDialog };
