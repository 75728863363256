import React from 'react';
import PropTypes from 'prop-types';

import { makeStyles } from '../thirdParty';

import { FavouriteItems } from '../favouriteItems';

export const Favourites = ({
  itemsInFavourites,
  favouriteLabel,
  textColor,
  fontSize,
}) => {
  const useStyles = makeStyles(theme => ({
    paper: {
      marginRight: theme.spacing(2),
    },
    headingStyle: {
      fontSize,
      fontWeight: 'bold',
      color: textColor,
    },
    parentDivStyle: {
      margin: '20px 0px 0px 42px',
    },
  }));
  const classes = useStyles();
  return (
    <div className={classes.parentDivStyle}>
      <div className={classes.headingStyle}>
        {favouriteLabel || 'Favourite'}
      </div>
      <FavouriteItems itemsInFavourites={itemsInFavourites} />
    </div>
  );
};

Favourites.propTypes = {
  /**
   * Items available in Favourites
   */
  itemsInFavourites: PropTypes.array.isRequired,
  /**
   * Label of the font
   */
  favouriteLabel: PropTypes.string,
  /**
   * Color of the font
   */
  textColor: PropTypes.string,
  /**
   * Size of the font
   */
  fontSize: PropTypes.string,
};

Favourites.defaultProps = {
  favouriteLabel: 'Favourite',
  textColor: '#282828',
  fontSize: '20px',
};
