import React from 'react';
import PropTypes from 'prop-types';
import makeStyles from '@mui/styles/makeStyles';
import TreeItem from '@mui/lab/TreeItem';
import Typography from '@mui/material/Typography';

const useStyles = makeStyles(theme => ({
  root: {
    color: theme.palette.text.primary,
    '&:hover > $content': {
      backgroundColor: theme.palette.action.hover,
    },
    '&:focus > $content, &$selected > $content': {
      backgroundColor: `var(--tree-view-bg-color, ${theme.palette.grey[400]})`,
      color: 'var(--tree-view-color)',
    },
    '&:focus > $content $label, &:hover > $content $label, &$selected > $content $label':
      {
        backgroundColor: 'transparent',
      },
  },
  content: {
    color: theme.palette.text.secondary,
    borderTopRightRadius: theme.spacing(2),
    borderBottomRightRadius: theme.spacing(2),
    paddingRight: theme.spacing(1),
    fontWeight: theme.typography.fontWeightMedium,
    '$expanded > &': {
      fontWeight: theme.typography.fontWeightRegular,
    },
  },
  group: {
    marginLeft: 0,
    '& $content': {
      paddingLeft: theme.spacing(2),
    },
  },
  expanded: {},
  selected: {
    color: 'black',
  },
  label: {
    fontWeight: 'inherit',
    color: 'inherit',
  },
  labelRoot: props => ({
    display: 'flex',
    alignItems: 'center',
    padding: theme.spacing(0.5, 0),
    cursor: props.clickable ? 'pointer' : 'default',
  }),
  labelIcon: {
    marginRight: theme.spacing(1),
  },
  labelText: props => ({
    fontWeight: props.isDate ? 700 : 'inherit',
    flexGrow: 1,
  }),
  dot: {
    height: 10,
    width: 10,
    backgroundColor: theme.color.treeItemBlue,
    borderRadius: '50%',
    display: 'inline-block',
    marginRight: 4,
  },
}));

function StyledTreeItem(props) {
  const {
    labelText,
    labelInfo,
    /* color,
    bgColor, */
    title = '',
    clickable,
    treeItemRef,
    ...other
  } = props;
  const classes = useStyles({ clickable, isDate: false });

  return (
    <TreeItem
      ref={treeItemRef}
      label={
        <div title={title} className={classes.labelRoot}>
          {/* <LabelIcon color="inherit" className={classes.labelIcon} /> */}
          {clickable && <div className={classes.dot} />}
          <Typography variant="body2" className={classes.labelText}>
            {labelText}
          </Typography>
          <Typography variant="caption" color="inherit">
            {labelInfo}
          </Typography>
        </div>
      }
      /* style={{
        '--tree-view-color': color,
        '--tree-view-bg-color': bgColor,
        pointerEvents: !groupId ? 'none' : 'auto',
        cursor: !groupId ? 'default' : 'auto',
        color: !groupId ? '#ddd' : '',
        // textDecoration:  "none",
      }} */
      classes={{
        root: classes.root,
        // content: classes.content,
        expanded: classes.expanded,
        selected: classes.selected,
        // group: classes.group,
        label: classes.label,
      }}
      {...other}
    />
  );
}

StyledTreeItem.propTypes = {
  /**
   * Appears on the right side, can be used to show a number
   */
  labelInfo: PropTypes.string,
  /**
   * The label
   */
  labelText: PropTypes.string.isRequired,
  /**
   * Tooltip
   */
  title: PropTypes.string,
  /**
   * whether the item is clickable or not
   */
  clickable: PropTypes.bool,
  /**
   * Ref
   */
  treeItemRef: PropTypes.shape({ current: PropTypes.instanceOf(Element) }),
};

export { StyledTreeItem as TreeItem };
