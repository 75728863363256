/**
 *
 * LabelWithIcon
 *
 */

import React from 'react';
import PropTypes from 'prop-types';
import { Grid } from '@mui/material';

export const LabelWithIcon = ({ label, icon }) => (
  <Grid
    style={{ width: 'auto' }}
    wrap="nowrap"
    container
    direction="row"
    alignItems="center"
    spacing={1}
  >
    <Grid item>{icon}</Grid>
    <Grid item>
      <span style={{ textAlign: 'center' }}>{label}</span>
    </Grid>
  </Grid>
);

LabelWithIcon.propTypes = {
  /**
   * The label to be displayed
   */
  label: PropTypes.string.isRequired,
  /**
   * Callback function called when the close button is clicked
   */
  icon: PropTypes.element.isRequired,
};
