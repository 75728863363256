/**
 *
 * PaiChipArray
 *
 */

import React from 'react';
import { ThemeProvider, StyledEngineProvider } from '@mui/material/styles';
import makeStyles from '@mui/styles/makeStyles';
import CloseIcon from '@mui/icons-material/Close';
import { Chip } from '@mui/material';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { has } from 'lodash';

import { blueTheme } from '../theme';

const useStyles = makeStyles(theme => ({
  root: {
    height: '25px',
    paddingLeft: 4,
    borderRadius: 12,
    borderWidth: 2,
    marginLeft: theme.spacing(1),
  },
  label: {
    fontSize: '0.75rem',
    fontWeight: 400,
    minWidth: 50,
    padding: theme.spacing(1.5),
  },
}));

function PaiChipArray({ list, onChipClick, isHidden, selected }) {
  const classes = useStyles();
  const { t } = useTranslation();
  const omitedWithoutValue = list?.filter(
    attribute => has(attribute, 'value') && !isHidden(attribute),
  );

  return (
    <StyledEngineProvider injectFirst>
      <ThemeProvider theme={blueTheme}>
        {omitedWithoutValue.map((attribute, idx) => {
          const label = attribute?.count
            ? `${t(attribute?.value)} (${attribute.count})`
            : `${t(attribute?.value)}`;

          return (
            <Chip
              clickable
              variant={selected[idx] ? 'default' : 'outlined'}
              color="primary"
              key={attribute.value}
              deleteIcon={<CloseIcon fontSize="small" />}
              label={label}
              classes={{
                root: classes.root,
                label: classes.label,
              }}
              onClick={() => {
                onChipClick(attribute, selected[idx]);
              }}
            />
          );
        })}
      </ThemeProvider>
    </StyledEngineProvider>
  );
}

PaiChipArray.propTypes = {
  /**
   * The filter chips object
   */
  list: PropTypes.object,
  /**
   * Select chips truth array
   */
  selected: PropTypes.object,
  /**
   * To set the filter object
   */
  onChipClick: PropTypes.func,
  /**
   * A function that will return bool, if the value need to be hidden or not
   */
  isHidden: PropTypes.func,
};

export { PaiChipArray };
