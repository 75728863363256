/* eslint-disable indent */
/**
 *
 * StatusHeader Component
 *
 */

import React from 'react';
import PropTypes from 'prop-types';
import makeStyles from '@mui/styles/makeStyles';
import {
  //   StatusLabel,
  StatusChip,
  //   ResolutionTypeSummary,
} from '../StatusChip';

// import Bookmark from '../../containers/Bookmark';

import { Paper } from '../thirdParty';
// import {
//   EQUIPMENT_DETAILS_PAGE,
//   EQUIPMENT_HISTORY_PAGE,
//   resolutionTypeTexts,
// } from '../../../constants';

// eslint-disable-next-line no-unused-vars
const useStyles = makeStyles(theme => ({
  statusLabelContainer: {
    display: 'flex',
    alignItems: 'center',
    minHeight: '30px',
    marginBottom: '1px',
  },
  statusLabel: {
    marginRight: theme.spacing(2),
    marginBottom: theme.spacing(1),
    display: 'flex',
    alignItems: 'center',
    maxHeight: theme.spacing(3),
    paddingLeft: '12px',
  },
  alignItemsCenter: {
    display: 'flex',
    alignItems: 'center',
  },
  value: {
    marginRight: '5px',
    paddingLeft: '15px',
    fontSize: '12px',
    fontWeight: 900,
  },
  label: {
    fontSize: '12px',
    color: theme.color.lightGrey,
    fontWeight: 600,
  },
  mr5: {
    marginRight: theme.spacing(4),
  },
  statusDataRoot: {
    display: 'flex',
    padding: '0px 12px',
    borderLeft: `1px solid ${theme.color.lightGrey}`,
    alignItems: 'center',
  },
  statusDataRootWithoutBorder: {
    display: 'flex',
    padding: '0px 12px',
    alignItems: 'center',
  },
  statusDataValue: {
    fontSize: '16px',
    fontVariant: 'small-caps',
    fontWeight: 900,
    marginLeft: '5px',
    textTransform: 'capitalize',
  },
  averageAge: {
    marginLeft: '10px',
    display: 'flex',
    alignItems: 'center',
  },
  days: {
    fontSize: '16px',
    fontWeight: 900,
    marginLeft: '5px',
    fontVariant: 'small-caps',
  },
  bookmark: {
    marginTop: '-11.5px',
  },
  resolutionSummary: {
    // marginTop: '-8px',
  },
}));

export function EquipmentDetailsStatusHeader(props) {
  const { chipData, statusData, applyCss } = props;
  const classes = useStyles(props);

  const getStatusData = stat => (
    <div className={classes.statusLabel}>
      <span className={classes.label}>{stat?.label}</span>
      <span className={classes.value}>{stat?.value?.toUpperCase()}</span>
    </div>
  );

  return (
    <Paper width={1} elevation={0} className={classes.statusLabelContainer}>
      <div>
        {chipData && (
          <div className={classes.statusLabel}>
            <span className={classes.label}>{chipData.label}</span>
            <StatusChip
              size="small"
              type={chipData.value.toLowerCase()}
              applyCss={applyCss}
            />
          </div>
        )}
        {statusData &&
          statusData.length > 0 &&
          statusData.map((stat, index) =>
            index < 2 ? getStatusData(stat) : null,
          )}
      </div>
      <div>
        {statusData &&
          statusData.length > 0 &&
          statusData.map((stat, index) =>
            index < 5 && index > 1 ? getStatusData(stat) : null,
          )}
      </div>
      <div>
        {statusData &&
          statusData.length > 0 &&
          statusData.map((stat, index) =>
            index < statusData.length && index > 4 ? getStatusData(stat) : null,
          )}
      </div>
    </Paper>
  );
}

EquipmentDetailsStatusHeader.propTypes = {
  /**
   * Data for status chip
   */
  chipData: PropTypes.shape({
    label: PropTypes.string,
    value: PropTypes.string,
  }),

  /**
   * Specify Data with label in an array
   */
  statusData: PropTypes.array,
  applyCss: PropTypes.object,
};

EquipmentDetailsStatusHeader.defaultProps = {
  applyCss: {},
};
