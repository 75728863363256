/* eslint-disable indent */
/* eslint-disable react/prop-types */
/**
 *
 * SearchResult
 *
 */

import React, { memo, useRef, useEffect } from 'react';
import PropTypes from 'prop-types';
import { VariableSizeList as List } from 'react-window';
import { useTranslation } from 'react-i18next';
import { NavLink } from 'react-router-dom';

import { ListItem, ListItemText, makeStyles } from '../thirdParty';
import ScrollToTopButton from '../ScrollToTopButton';
import { tempConsump, liveConnect } from '../constants';
const BOTTOM_NAV_HEIGHT = 56;

const useStyles = makeStyles(theme => ({
  listItem: {
    height: 90,
    borderRadius: 4,
    boxShadow: `0 0 3px ${theme.color.darkBlack}`,
  },
  listItemCurrent: {
    height: 90,
    borderRadius: 4,
    boxShadow: `0 0 3px ${theme.color.darkblue}`,
  },
  listItemContainer: {
    display: 'flex',
    alignItems: 'flex-end',
    [theme.breakpoints.up('sm')]: {
      marginLeft: theme.spacing(5),
      marginRight: theme.spacing(5),
      maxWidth: 600,
    },
    [theme.breakpoints.down('md')]: {
      width: '100%',
    },
    padding: theme.spacing(1),
  },
  listPrimaryText: {
    color: '#1976d2',
  },
  listPrimaryTextVisited: {
    color: '#9c27b0',
  },
}));

const AdapterLink = React.forwardRef((props, ref) => (
  <NavLink innerRef={ref} {...props} />
));

function SearchResult({
  resultData,
  onResultClickCallback,
  listHeight,
  scrollIndex,
  onScrollCallback,
  showScrollToTop,
  visitedSiteIds,
  //  selectedTemplate,
  listOffset,
  startIn,
}) {
  const classes = useStyles();
  const listRef = useRef(null);
  const { t } = useTranslation();

  const handleClickScrollUp = () => {
    listRef.current.scrollToItem(0, 'start');
  };

  useEffect(() => {
    // Scrolls Item out of view, if the item is at the edges
    let itemIndex = scrollIndex;
    if (scrollIndex <= 2) {
      itemIndex = scrollIndex - 1;
    }
    if (scrollIndex >= resultData.length - 3) {
      itemIndex = scrollIndex + 1;
    }
    listRef.current.scrollToItem(itemIndex, 'center');
  }, []);

  const getItemSize = index => {
    // Blank space at top and bottom to offset other elts
    if (index === 0) {
      return listOffset + 100;
    }
    if (index === resultData.length) {
      return BOTTOM_NAV_HEIGHT;
    }
    return 100;
  };

  const getUrlForStartIn = site => {
    let pageUrl;
    if (startIn === tempConsump)
      pageUrl = `/${encodeURIComponent(site.id)}/${encodeURIComponent(
        site.subscriptionCode,
      )}/temperature-and-consumption`;
    else if (startIn === liveConnect)
      pageUrl = `/${encodeURIComponent(site.id)}/${encodeURIComponent(
        site.subscriptionCode,
      )}/live-connect`;
    else
      pageUrl = `/${encodeURIComponent(site.id)}/${encodeURIComponent(
        site.subscriptionCode,
      )}/site-details/${startIn}/active`;

    return encodeURI(pageUrl);
  };

  // eslint-disable-next-line react/prop-types
  // disbled for entire file for  resultData is missing for props validation.
  const Row = ({ index, style }) => {
    // Inserting a blank item to the end
    if (index === resultData.length) {
      return <div></div>;
    }
    const site = resultData[index];
    const isNullOrEmptyString = str => str === null || !str?.trim().length;
    const isNullOrEmptyOrZero = val =>
      val === null || !val?.trim().length || val?.toString() === '0';
    let titleText = `${t('Site_Code')}               :  ${site.siteCode}
    \n${t('Site_Name')}              :  ${site.siteName}
    \n${t('Organization')}          :  ${site.org}
    \n${t('Contract_Code')}        :  ${site.subscriptionCode}
    \n${t('Contract_Duration')}  :  ${site.subscriptionStartDate} ${t('to')} ${
      site.subscriptionEndDate
    }
    \n${t('City')}                         :  ${site.city}`;
    const stateText = `\n\n${t('State')}                       :  ${
      site.state
    }`;
    const zipText = `\n\n${t('Zip')}                          :  ${
      site.zipCode
    }`;
    titleText = isNullOrEmptyString(site.state)
      ? titleText
      : `${titleText}${stateText}`;
    titleText = isNullOrEmptyOrZero(site.zipCode)
      ? titleText
      : `${titleText}${zipText}`;

    let secondaryText = site.city;
    secondaryText = isNullOrEmptyString(site.state)
      ? secondaryText
      : `${secondaryText}, ${site.state}`;
    secondaryText = isNullOrEmptyOrZero(site.zipCode)
      ? secondaryText
      : `${secondaryText}, ${site.zipCode}`;
    return (
      <div
        title={titleText}
        style={style}
        className={classes.listItemContainer}
      >
        <ListItem
          key={site.id}
          button
          className={
            scrollIndex === index ? classes.listItemCurrent : classes.listItem
          }
          onClick={() => onResultClickCallback(index)}
          component={AdapterLink}
          to={getUrlForStartIn(site)}
        >
          <ListItemText
            classes={{
              primary: visitedSiteIds.includes(site.id)
                ? classes.listPrimaryTextVisited
                : classes.listPrimaryText,
            }}
            // Site code, Name, Org in search result item
            primary={`${site.siteCode}, ${site.siteName}, ${site.subscriptionCode}, ${site.org}`}
            secondary={secondaryText}
          />
        </ListItem>
      </div>
    );
  };
  return (
    // eslint-disable-next-line react/jsx-fragments
    <React.Fragment>
      <List
        ref={listRef}
        height={listHeight}
        itemCount={resultData.length + 1} // Blank space at end
        itemSize={getItemSize}
        onScroll={onScrollCallback}
      >
        {Row}
      </List>
      <ScrollToTopButton
        open={showScrollToTop}
        handleOnClick={handleClickScrollUp}
      />
    </React.Fragment>
  );
}

SearchResult.propTypes = {
  /**
   * Array of results
   */
  resultData: PropTypes.arrayOf(PropTypes.object),
  /**
   * Callback on click
   */
  onResultClickCallback: PropTypes.func.isRequired,
  /**
   * Height of list
   */
  listHeight: PropTypes.number.isRequired,
  /**
   * Scroll Position
   */
  scrollIndex: PropTypes.number,
  /**
   * Callback on scroll
   */
  onScrollCallback: PropTypes.func,
  /**
   * Show the floating action button
   */
  showScrollToTop: PropTypes.bool.isRequired,
  /**
   * Array of visited site ids
   */
  visitedSiteIds: PropTypes.arrayOf(PropTypes.string),
  // selectedTemplate: PropTypes.string,
  /**
   * Height to be appended for firstItem
   */
  listOffset: PropTypes.number,
  startIn: PropTypes.string,
};
SearchResult.defaultProps = {
  resultData: [],
  visitedSiteIds: [],
};

SearchResult.whyDidYouRender = true;

export default memo(SearchResult);
