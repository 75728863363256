import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { DEFAULT_FAVORITE_FOLDER } from '../constants';

import { makeStyles, MenuItem, FormControl, Select } from '../thirdParty';

const useStyles = makeStyles({
  formControl: {
    width: '100%',
    borderRadius: 6,
    marginTop: '3px',
  },
  select: {
    padding: '8px',
    fontSize: '0.875rem',
  },
  fontStyle: {
    fontSize: '0.875rem',
  },
});

export const FavoriteDropdown = ({
  itemsInDropDown,
  selectedValue,
  handleChange,
}) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const renderFavoriteDropDownLabel = itemInSelect => {
    const dropDownLabel =
      itemInSelect && itemInSelect.label ? itemInSelect.label : itemInSelect;
    if (dropDownLabel === DEFAULT_FAVORITE_FOLDER.value) {
      return t(DEFAULT_FAVORITE_FOLDER.label);
    }
    return dropDownLabel;
  };
  return (
    <div>
      <FormControl variant="outlined" className={classes.formControl}>
        <Select
          classes={{
            root: classes.select,
          }}
          value={selectedValue}
          onChange={handleChange}
        >
          {itemsInDropDown.map(itemInSelect => (
            <MenuItem
              key={
                itemInSelect && itemInSelect.code
                  ? itemInSelect.code
                  : itemInSelect
              }
              value={
                itemInSelect && itemInSelect.label
                  ? itemInSelect.label
                  : itemInSelect
              }
              className={classes.fontStyle}
            >
              {renderFavoriteDropDownLabel(itemInSelect)}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </div>
  );
};

FavoriteDropdown.propTypes = {
  /**
   * The option values for the dropdown
   */
  itemsInDropDown: PropTypes.array,
  /**
   * The language selected by user
   */
  selectedValue: PropTypes.string,
  /**
   * The function that handles language change
   */
  handleChange: PropTypes.func,
};
