import React from 'react';
import { SvgIcon } from '@mui/material';

function PreventCostlyPartsFailure(props) {
  return (
    <SvgIcon
      {...props}
      width="58"
      height="58"
      viewBox="10 17 35 25"
      fill="none"
    >
      <rect width="58" height="58" fill="white" />
      <path
        d="M43.0241 28.8825L42.8697 26.6831C42.7807 25.4109 41.6745 24.451 40.3995 24.5407L39.5151 24.6019C39.3249 24.1184 39.102 23.6511 38.8494 23.2033L39.4312 22.5586C40.287 21.6108 40.2103 20.15 39.2594 19.2971L37.6174 17.822C36.6675 16.9681 35.2034 17.0446 34.3486 17.9934L33.8211 18.5779C33.2741 18.3259 32.7025 18.1168 32.1115 17.9556L32.0717 17.1885C32.0052 15.9143 30.9153 14.9359 29.6393 15.0033L27.4318 15.1186C26.1548 15.1849 25.1743 16.2724 25.2417 17.5455L25.2796 18.2596C24.6917 18.479 24.1273 18.7483 23.5915 19.0594L23.0609 18.583C22.111 17.7291 20.6469 17.8056 19.7921 18.7544L18.3136 20.3928C17.4579 21.3405 17.5345 22.8014 18.4854 23.6542L19.0212 24.1357C18.7697 24.6978 18.5621 25.2824 18.4067 25.8894L17.6787 25.9271C16.4017 25.9934 15.4212 27.0809 15.4886 28.3541L15.6042 30.5566C15.6706 31.8308 16.7606 32.8091 18.0366 32.7418L18.83 32.6999C19.102 33.3896 19.4445 34.0435 19.8432 34.6576L22.8421 31.6655C22.4259 30.7678 22.1877 29.7721 22.1877 28.7172C22.1877 24.8284 25.347 21.6761 29.2446 21.6761C33.1422 21.6761 36.3015 24.8284 36.3015 28.7172C36.3015 32.6061 33.1422 35.7584 29.2446 35.7584C28.5391 35.7584 27.8592 35.6523 27.2161 35.4595L24.1436 38.525C24.797 38.8566 25.4851 39.13 26.2049 39.3279L26.2366 40.2358C26.2816 41.511 27.3531 42.5077 28.6301 42.4628L30.8386 42.3863C32.1156 42.3414 33.1156 41.2723 33.0706 39.9981L33.0368 39.0412C33.5654 38.8454 34.0736 38.6097 34.5613 38.3404L35.2688 38.9994C36.2034 39.8696 37.6685 39.8186 38.5407 38.8852L40.0477 37.2723C40.9199 36.3399 40.8688 34.878 39.9332 34.0078L39.2308 33.3539C39.5222 32.7356 39.7594 32.0868 39.9332 31.4125L40.878 31.3462C42.153 31.2574 43.1151 30.1536 43.0251 28.8815L43.0241 28.8825Z"
        fill="#162C73"
      />
      <path
        d="M33.8099 27.2564L31.4317 29.6293C30.8458 30.2138 29.896 30.2138 29.3101 29.6293L28.6333 28.9539C28.0474 28.3694 28.0474 27.4216 28.6333 26.8371L30.9747 24.5009C30.5258 24.352 30.0463 24.2693 29.5473 24.2693C27.0413 24.2693 25.0087 26.2964 25.0087 28.7978C25.0087 29.2957 25.0925 29.7741 25.2408 30.222L19.602 35.8481C19.4374 35.8277 19.2707 35.8145 19.101 35.8145C16.8363 35.8145 15 37.6467 15 39.9063C15 42.166 16.8363 43.9982 19.101 43.9982C21.3657 43.9982 23.202 42.166 23.202 39.9063C23.202 39.4829 23.1376 39.0749 23.018 38.6903L28.5167 33.2039C28.848 33.2804 29.1915 33.3253 29.5463 33.3253C32.0523 33.3253 34.0849 31.2982 34.0849 28.7968C34.0849 28.2551 33.9847 27.7369 33.8089 27.2553L33.8099 27.2564ZM19.7278 42.169L17.4508 41.5784L16.8251 39.3156L18.4753 37.6436L20.7512 38.2343L21.377 40.497L19.7268 42.169H19.7278Z"
        fill="#162C73"
      />
    </SvgIcon>
  );
}
PreventCostlyPartsFailure.displayName = 'PreventCostlyPartsFailure';
PreventCostlyPartsFailure.muiName = 'SvgIcon';

export default PreventCostlyPartsFailure;
