/**
 *
 * Computing
 *
 */

import React from 'react';
import makeStyles from '@mui/styles/makeStyles';
import { useTranslation } from 'react-i18next';

const useStyles = makeStyles(theme => ({
  computing: {
    fontSize: '10px',
    fontWeight: 300,
    fontStyle: 'italic',
    textShadow: `0px 1px 2px ${theme.lightBlack1}`,
  },
}));

export const Computing = () => {
  const classes = useStyles();
  const { t } = useTranslation();
  return <div className={classes.computing}>{t('None')}</div>;
};

Computing.propTypes = {};
