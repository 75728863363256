/**
 *
 * PaiFilterChips
 *
 */

import React from 'react';
import { ThemeProvider, StyledEngineProvider } from '@mui/material/styles';
import makeStyles from '@mui/styles/makeStyles';
import CloseIcon from '@mui/icons-material/Close';
import { Chip } from '@mui/material';
import PropTypes from 'prop-types';
import _ from 'lodash';
import { useTranslation } from 'react-i18next';

import { blueTheme } from '../theme';

const useStyles = makeStyles(theme => ({
  root: {
    height: '25px',
    paddingLeft: 4,
    borderRadius: 12,
  },
  label: {
    color: 'white',
    fontSize: '0.75rem',
    fontWeight: 400,
    minWidth: 50,
    padding: theme.spacing(1.5),
  },
  deleteIcon: {
    color: '#fff',
    '&:hover': {
      color: '#f7f7f7',
    },
    fontSize: '.75rem',
  },
}));

function PaiFilterChips({ filter, setFilter, isHidden }) {
  const classes = useStyles();
  const { t } = useTranslation();

  const handleChipDelete = key => {
    const newFilter = _.omit(filter, key);
    setFilter(newFilter);
  };

  const omitedKeysWithoutValue = _.omitBy(
    filter,
    attribute => !attribute.value || isHidden(attribute),
  );

  return (
    <StyledEngineProvider injectFirst>
      <ThemeProvider theme={blueTheme}>
        {_.map(omitedKeysWithoutValue, (attribute, key) => {
          const label = attribute.count
            ? `${t(key)} : ${t(attribute.value)} (${attribute.count})`
            : `${t(key)} : ${t(attribute.value)}`;
          return (
            <Chip
              clickable
              color="primary"
              key={key}
              deleteIcon={<CloseIcon fontSize="small" />}
              label={label}
              classes={{
                root: classes.root,
                label: classes.label,
                deleteIcon: classes.deleteIcon,
              }}
              onDelete={() => {
                handleChipDelete(key);
              }}
            />
          );
        })}
      </ThemeProvider>
    </StyledEngineProvider>
  );
}

PaiFilterChips.propTypes = {
  /**
   * The filter chips object
   */
  filter: PropTypes.object,
  /**
   * To set the filter object
   */
  setFilter: PropTypes.func,
  /**
   * A function that will return bool, if the value need to be hidden or not
   */
  isHidden: PropTypes.func,
};

export { PaiFilterChips };
