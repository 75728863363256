import React from 'react';
import PropTypes from 'prop-types';
import withStyles from '@mui/styles/withStyles';
import makeStyles from '@mui/styles/makeStyles';
import LoadingIndicator from '../LoadingIndicator';
import { Grid, Button } from '../thirdParty';

const MuiButton = withStyles(theme => ({
  root: {
    borderColor: theme.color.primary,
    fontSize: '0.75rem',
    minWidth: 42,
  },
  label: {
    textTransform: 'initial',
    color: theme.color.primary,
    alignItems: 'initial',
  },
  startIcon: {
    color: theme.color.originalBlack,
    opacity: 0.7,
  },
  outlined: {
    padding: '4px 8px 4px 12px',
  },
}))(Button);

const useStyles = makeStyles(() => ({
  loader: {
    marginLeft: '5px',
  },
  iconButton: {
    '& .MuiButton-startIcon': {
      marginRight: props => (props.children ? 8 : 0),
    },
  },
}));

/**
 * Primary UI component for user interaction
 */
export const CustomButton = props => {
  const { loading, onClickHandler, loaderImage } = props;
  const classes = useStyles(props);

  return (
    <Grid container alignItems="center">
      <MuiButton
        {...props}
        onClick={onClickHandler || ''}
        className={classes.iconButton}
      ></MuiButton>
      {loading && (
        <LoadingIndicator
          size={13}
          className={classes.loader}
          loaderImage={loaderImage}
        />
      )}
    </Grid>
  );
};

CustomButton.propTypes = {
  /**
   * Show Loader and disable Button when true
   */
  loading: PropTypes.bool,
  /**
   * OnClick Callback Handler
   */
  onClickHandler: PropTypes.func,
  loaderImage: PropTypes.string,
};
