/**
 *
 * Feedback Service Notes
 *
 */

import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { isEmpty, pickBy } from 'lodash';
import { makeStyles, Box } from '../thirdParty';
import { FeedbackSources } from '../FeedbackSources';
import { FeedbackWorkStatus } from '../FeedbackWorkStatus';
import { FeedbackSnackBar } from '../FeedbackSnackBar';
import { ProgressButton } from '../ProgressButton';
import { ContainerLoader } from '../ContainerLoader';

const useStyles = makeStyles(theme => ({
  root: {
    borderRadius: 4,
    backgroundColor: theme.color.paleBlue,
    backgroundSize: 'cover',
    fontWeight: '500',
    boxShadow: `0px 0px 2px 1px ${theme.color.lightBlack2}`,
    paddingBottom: '6px',
  },
  heading: {
    textDecoration: `none solid ${theme.color.primary}`,
    padding: 20,
  },
  container: {
    borderRadius: 10,
    backgroundColor: theme.color.white,
    backgroundSize: 'cover',
    boxShadow: `0px 1px 2px ${theme.color.lightBlack2}`,
    padding: 20,
    margin: '0px 28px 28px 28px',
    fontSize: 12,
  },
  addBtn: {
    marginTop: 50,
  },
}));

export const FeedbackServiceNotes = ({
  loaderImage,
  sectionHeading,
  sourcesHeading,
  sourcesList,
  feedbackSourcesError,
  resetFeedbackSourcesError,
  feedbackSources,
  workStatusHeading,
  workStatusListLoading,
  resetFeedbackWorkStatusError,
  workStatusList,
  feedbackWorkStatus,
  saveFeedback,
  sectionNameListMapping,
  feedbackWorkStatusError,
  resetUpdatePaiFeedbackSectionStatus,
  feedbackSourcesLoading,
  feedbackSourcesLoaded,
  feedbackWorkStatusLoading,
  feedbackWorkStatusLoaded,
  paiUpdateFeedbackSourcesLoading,
  paiUpdateFeedbackSourcesLoaded,
  paiUpdateFeedbackSourcesError,
  paiUpdateFeedbackWorkStatusLoading,
  paiUpdateFeedbackWorkStatusLoaded,
  paiUpdateFeedbackWorkStatusError,
  paiUpdateFeedbackServiceNotesLoading,
  paiUpdateFeedbackServiceNotesLoaded,
  paiUpdateFeedbackServiceNotesError,
  showUpdateButton,
  timezone,
  deletePaiFeedbackSectionEntry,
  locale,
}) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const [serviceNotes, setServiceNotes] = useState({});
  const [error, setError] = React.useState(false);

  const filterData = () => pickBy(serviceNotes, value => !isEmpty(value));

  const saveServiceNotes = () => {
    saveFeedback('servicenotes', filterData());
  };

  const setServiceNotesData = data => {
    setServiceNotes({ ...serviceNotes, ...data });
  };

  return (
    <Box component="div" className={classes.root}>
      <Box component="div" className={classes.heading}>
        {sectionHeading}
      </Box>
      <ContainerLoader
        open={paiUpdateFeedbackServiceNotesLoading}
        loaderImage={loaderImage}
      >
        <Box component="div" className={classes.container}>
          <FeedbackSources
            loaderImage={loaderImage}
            sourcesList={sourcesList}
            feedbackSources={feedbackSources}
            sourcesHeading={sourcesHeading}
            saveFeedback={saveFeedback}
            sectionNameListMapping={sectionNameListMapping}
            feedbackSourcesError={feedbackSourcesError}
            resetFeedbackSourcesError={resetFeedbackSourcesError}
            resetUpdatePaiFeedbackSectionStatus={
              resetUpdatePaiFeedbackSectionStatus
            }
            feedbackSourcesLoading={feedbackSourcesLoading}
            feedbackSourcesLoaded={feedbackSourcesLoaded}
            paiUpdateFeedbackSourcesLoading={paiUpdateFeedbackSourcesLoading}
            paiUpdateFeedbackSourcesLoaded={paiUpdateFeedbackSourcesLoaded}
            paiUpdateFeedbackSourcesError={paiUpdateFeedbackSourcesError}
            setServiceNotes={setServiceNotesData}
            resetFields={paiUpdateFeedbackServiceNotesLoaded}
            timezone={timezone}
            deletePaiFeedbackSectionEntry={deletePaiFeedbackSectionEntry}
          />
          <FeedbackWorkStatus
            loaderImage={loaderImage}
            workStatusListLoading={workStatusListLoading}
            workStatusList={workStatusList}
            feedbackWorkStatus={feedbackWorkStatus}
            workStatusHeading={workStatusHeading}
            saveFeedback={saveFeedback}
            sectionNameListMapping={sectionNameListMapping}
            feedbackWorkStatusError={feedbackWorkStatusError}
            resetFeedbackWorkStatusError={resetFeedbackWorkStatusError}
            resetUpdatePaiFeedbackSectionStatus={
              resetUpdatePaiFeedbackSectionStatus
            }
            feedbackWorkStatusLoading={feedbackWorkStatusLoading}
            feedbackWorkStatusLoaded={feedbackWorkStatusLoaded}
            paiUpdateFeedbackWorkStatusLoading={
              paiUpdateFeedbackWorkStatusLoading
            }
            paiUpdateFeedbackWorkStatusLoaded={
              paiUpdateFeedbackWorkStatusLoaded
            }
            paiUpdateFeedbackWorkStatusError={paiUpdateFeedbackWorkStatusError}
            setServiceNotes={setServiceNotesData}
            setServiceNotesError={setError}
            resetFields={paiUpdateFeedbackServiceNotesLoaded}
            timezone={timezone}
            deletePaiFeedbackSectionEntry={deletePaiFeedbackSectionEntry}
            locale={locale}
          />
          {showUpdateButton && (
            <Box component="div" className={classes.addBtn}>
              <ProgressButton
                onClick={saveServiceNotes}
                buttonText={t('UPDATE')}
                disabled={isEmpty(filterData()) || error}
                loaderImage={loaderImage}
              />
            </Box>
          )}
        </Box>
      </ContainerLoader>

      <FeedbackSnackBar
        open={!isEmpty(paiUpdateFeedbackServiceNotesError)}
        message={t('Error_updating_service_notes')}
        handleClose={() => resetUpdatePaiFeedbackSectionStatus('servicenotes')}
        severity="error"
      />

      <FeedbackSnackBar
        open={paiUpdateFeedbackServiceNotesLoaded}
        message={t('Successfully_updated_service_notes')}
        handleClose={() => resetUpdatePaiFeedbackSectionStatus('servicenotes')}
        severity="success"
      />
    </Box>
  );
};

FeedbackServiceNotes.propTypes = {
  /**
   * Heading label of service notes section
   */
  sectionHeading: PropTypes.string,
  /**
   * List of available sources
   */
  sourcesList: PropTypes.array,
  /**
   * List of User's added feedback sources
   */
  feedbackSources: PropTypes.array,
  /**
   * Sources heading label
   */
  sourcesHeading: PropTypes.string,
  /**
   * true when sources list loading
   */
  feedbackSourcesLoading: PropTypes.bool,
  /**
   * true when sources list loaded
   */
  feedbackSourcesLoaded: PropTypes.bool,
  /**
   * Error object while loading list of feedback sources
   */
  feedbackSourcesError: PropTypes.object,
  /**
   * Callback function to reset feedback sources error
   */
  resetFeedbackSourcesError: PropTypes.func,
  /**
   * Mapping of section name with section key
   */
  sectionNameListMapping: PropTypes.object,
  /**
   * Callback function to update feedback
   */
  saveFeedback: PropTypes.func,
  /**
   * true while updating/adding sources
   */
  paiUpdateFeedbackSourcesLoading: PropTypes.bool,
  /**
   * true when successfully added/updated sources
   */
  paiUpdateFeedbackSourcesLoaded: PropTypes.bool,
  /**
   * Error object while adding/updating sources
   */
  paiUpdateFeedbackSourcesError: PropTypes.object,
  /**
   * List of available workStatus
   */
  workStatusList: PropTypes.array,
  /**
   * User's added feedback source
   */
  feedbackWorkStatus: PropTypes.array,
  /**
   * Work status heading label
   */
  workStatusHeading: PropTypes.string,
  /**
   * true when work status list loading
   */
  workStatusListLoading: PropTypes.bool,
  /**
   * Error object while loading list of work status
   */
  feedbackWorkStatusError: PropTypes.object,
  /**
   * Callback function to reset feedback work status error
   */
  resetFeedbackWorkStatusError: PropTypes.func,
  /**
   * true when list work status loading
   */
  feedbackWorkStatusLoading: PropTypes.bool,
  /**
   * true when list work status loaded
   */
  feedbackWorkStatusLoaded: PropTypes.bool,
  /**
   * true while updating/adding sources
   */
  paiUpdateFeedbackWorkStatusLoading: PropTypes.bool,
  /**
   * true when successfully added/updated sources
   */
  paiUpdateFeedbackWorkStatusLoaded: PropTypes.bool,
  /**
   * Error object while adding/updating work status
   */
  paiUpdateFeedbackWorkStatusError: PropTypes.object,
  /**
   * Callback function to reset success/error status
   */
  resetUpdatePaiFeedbackSectionStatus: PropTypes.func,
  /**
   * true while adding service notes
   */
  paiUpdateFeedbackServiceNotesLoading: PropTypes.bool,
  /**
   * true when service notes saved successfully
   */
  paiUpdateFeedbackServiceNotesLoaded: PropTypes.bool,
  /**
   * Error while adding service notes
   */
  paiUpdateFeedbackServiceNotesError: PropTypes.object,
  /**
   * show update button on section if true
   */
  showUpdateButton: PropTypes.bool,
  /**
   * show date with tinezone provided
   */
  timezone: PropTypes.string,

  deletePaiFeedbackSectionEntry: PropTypes.func,
  /**
   * locale to be used in date localization
   */
  locale: PropTypes.string,
  loaderImage: PropTypes.string,
};
