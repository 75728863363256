import React from 'react';
import PropTypes from 'prop-types';
import { isEmpty } from 'lodash';
import makeStyles from '@mui/styles/makeStyles';
import { useTranslation } from 'react-i18next';
import { StatusChip } from '../StatusChip';

const useStyles = makeStyles({
  spacing: {
    marginRight: '5px',
    marginLeft: '5px',
  },
  spacingLeft: {
    marginLeft: '5px',
  },
});

export const ResolutionTypeChipWithCount = ({ resolutionTypesCount }) => {
  const classes = useStyles();
  const { t } = useTranslation();
  if (isEmpty(resolutionTypesCount)) {
    return null;
  }
  return (
    <StatusChip
      label={resolutionTypesCount.map((resolutionTypesCountItem, index) => (
        <span>
          <span>{t(resolutionTypesCountItem.resolutionType)}</span>
          <span className={classes.spacingLeft}>
            {resolutionTypesCountItem.count}
          </span>
          {index < resolutionTypesCount.length - 1 && (
            <span className={classes.spacing}>|</span>
          )}
        </span>
      ))}
      size="small"
      type="resolution"
    />
  );
};

ResolutionTypeChipWithCount.propTypes = {
  /**
   * Resolution type name and its count as array of Object
   */
  resolutionTypesCount: PropTypes.arrayOf(PropTypes.Object),
};

ResolutionTypeChipWithCount.defaultProps = {
  resolutionTypesCount: [],
};
