import React from 'react';
import PropTypes from 'prop-types';
import { isEmpty } from 'lodash';
import { useTranslation } from 'react-i18next';
import { StatusChip } from '../StatusChip';

export const ResolutionTypeChip = ({ resolutionTypes }) => {
  const { t } = useTranslation();
  return (
    <div>
      {!isEmpty(resolutionTypes) &&
        resolutionTypes.map(resolutionType => (
          <StatusChip
            size="small"
            label={t(resolutionType)}
            type="resolution"
          />
        ))}
    </div>
  );
};

ResolutionTypeChip.propTypes = {
  /**
   * Resolution type Field Visit OR/AND Remote Fix
   */
  resolutionTypes: PropTypes.array,
};
