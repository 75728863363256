/**
 *
 * RecommendationListItem
 *
 */

import React from 'react';
import PropTypes from 'prop-types';

import makeStyles from '@mui/styles/makeStyles';

const useStyles = makeStyles(theme => ({
  boxContainer: {
    marginBottom: theme.spacing(2),
    display: 'flex',
    width: ({ widthFromProps }) => widthFromProps || 'auto',
  },
  headerText: {
    fontWeight: 600,
    marginBottom: theme.spacing(1),
    fontSize: '14px',
  },
  firstChild: {
    marginRight: theme.spacing(2.5),
  },
  secondChild: {
    display: 'flex',
    // paddingBottom: props => (props.borderBottom ? theme.spacing(2) : 0),
    borderBottom: props =>
      props.borderBottom ? '1px solid #0000004d' : 'none',
    width: '100%',
    flexDirection: 'column',
  },
  centerAligned: {
    marginRight: theme.spacing(2),
    display: 'flex',
    alignItems: 'center',
  },
  width65: {
    width: '65%',
  },
  mt1: {
    display: 'flex',
    marginTop: theme.spacing(1),
    marginLeft: theme.spacing(5),
  },
  leftBottomText: {
    marginTop: theme.spacing(1),
    color: theme.color.lightGrey,
    fontStyle: 'italic',
    fontSize: '11px',
    float: 'right',
    marginRight: '20px',
  },
  content: {
    width: '100%',
    display: 'flex',
    justifyContent: 'space-between',
  },
}));

export const RecommendationListItem = ({
  child1,
  child2,
  child3,
  width,
  borderBottom,
  leftBottomText,
}) => {
  const classes = useStyles({ width, borderBottom });

  return (
    <div>
      <div className={classes.boxContainer}>
        <div className={`${classes.centerAligned} ${classes.firstChild}`}>
          {child1}
        </div>
        <div className={classes.secondChild}>
          <div className={classes.content}>
            <div className={classes.width65}>
              <div>{child2}</div>
              {/* <div>
              <div className={classes.leftBottomText}>{leftBottomText}</div>
            </div> */}
            </div>
            <div className={classes.mt1}>{child3}</div>
          </div>
          <div>
            <div className={classes.leftBottomText}>{leftBottomText}</div>
          </div>
        </div>
      </div>
    </div>
  );
};

RecommendationListItem.propTypes = {
  /**
   * Component/Content that goes inside flex box as first item. Expects a React component
   */
  child1: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]).isRequired,
  /**
   * Component/Content that goes inside flex box as second item. Expects a React component
   */
  child2: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]).isRequired,
  /**
   * Component/Content that goes inside flex box as third item. Expects a React component
   */
  child3: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]),
  /**
   * Width of the container in px or %
   */
  width: PropTypes.string,
  /**
   * Separator / border to separate list item
   */
  borderBottom: PropTypes.bool,
  /**
   * A small text to show recommendation code or some id
   */
  leftBottomText: PropTypes.string,
};

RecommendationListItem.defaultProps = {
  width: '100%',
};
