import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import { StyledEngineProvider } from '@mui/material/styles';
import makeStyles from '@mui/styles/makeStyles';
import { Grid } from '@mui/material';
import { useTranslation } from 'react-i18next';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import _ from 'lodash';
// import { blueTheme } from '../theme';
import { SimpleDropdown } from '../SimpleDropdown';
import { MultipleSelect } from '../MultipleDropdown';

const useStyles = makeStyles(theme => ({
  root: {
    width: 500,
    fontSize: '0.875rem',
    fontWeight: 500,
  },
  daysInput: {
    width: 50,
  },
  dialogPaper: {
    padding: theme.spacing(2.5),
  },
  actions: {
    paddingTop: theme.spacing(1),
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
}));

const getSelectedValue = (filter, key, uniqueValuesForSelect) => {
  const selectedValue = _.get(filter, key, []);
  // when only one option, show that in the disabled selection
  if (selectedValue.length === 0 && uniqueValuesForSelect.length === 1) {
    return [uniqueValuesForSelect[0].value];
  }
  return selectedValue;
};

const curryMakeArgs = (filter, t) => (uniqueValuesForSelect, key) => {
  // If uniqueValuesForSelect is empty show N/A
  if (uniqueValuesForSelect.length === 0) {
    return {
      data: [{ label: 'None', value: { value: 'None' } }],
      labelName: 'label',
      valueName: 'value.value',
      selectedValue: ['None'],
      label: 'None',
      disabled: true,
      selectAll: false,
    };
  }
  const data = uniqueValuesForSelect.reduce((acc, item) => {
    if (item.value !== 'All') {
      acc.push({
        value: item,
        label: item.count ? `${t(item.value)} (${item.count})` : t(item.value),
      });
    }
    return acc;
  }, []);

  return {
    data,
    labelName: 'label',
    valueName: 'value.value',
    selectedValue: getSelectedValue(filter, key, uniqueValuesForSelect),
    label: data[0].label,
    // If the length is one then disable it as there no need for select
    disabled: uniqueValuesForSelect.length <= 1,
    selectAll: uniqueValuesForSelect.some(e => e.value === 'All'),
    allCount: uniqueValuesForSelect.some(e => e.value === 'All')
      ? uniqueValuesForSelect.find(e => e.value === 'All').count
      : null,
  };
};

const curryMakeArgsDays = (filter, t) => (uniqueValuesForSelect, key) => {
  // If uniqueValuesForSelect is empty show N/A
  if (uniqueValuesForSelect.length === 0) {
    return {
      data: [{ label: 'None', value: { value: 'None' } }],
      labelName: 'label',
      valueName: 'value.value',
      selectedValue: 'None',
      label: 'None',
      disabled: true,
    };
  }

  const ageArgsLabel = {
    equals: 'Equals',
    lessThan: 'Less_than',
    lessThanOrEqual: 'Less_than_or_equal',
    greaterThan: 'Greater_than',
    greaterThanOrEqual: 'Greater_than_or_equal',
  };

  const data = uniqueValuesForSelect.map(item => {
    const displayLabel = ageArgsLabel[item.value]
      ? t(ageArgsLabel[item.value])
      : t(item.value);
    return {
      value: item,
      label: item.count ? `${displayLabel} (${item.count})` : displayLabel,
    };
  });

  // Code before standard translation keys
  // const data = uniqueValuesForSelect.map(item => ({
  //   value: item,
  //   label: item.count ? `${t(item.value)} (${item.count})` : t(item.value),
  // }));

  return {
    data,
    labelName: 'label',
    valueName: 'value.value',
    // If key not in filter, show 0th elt
    selectedValue: filter[key]?.value || uniqueValuesForSelect[0].value,
    label: uniqueValuesForSelect[0].label,
    // If the length is one then disable it as there no need for select
    disabled: uniqueValuesForSelect.length <= 1,
  };
};

export function PaiAssetFilterDialogMultipleSelect({
  handleApplyCallback,
  applyCascade,
  uniqueValues,
  initialFilter,
  open,
  setOpen,
}) {
  const [filter, setFilter] = React.useState(initialFilter);
  const classes = useStyles();
  const { t } = useTranslation();
  const makeArgs = curryMakeArgs(filter, t);
  const makeArgsDays = curryMakeArgsDays(filter, t);

  // Cascading filter
  useEffect(() => {
    applyCascade(filter);
  }, [filter]);

  // If selectedValue not in dropdown values
  // useEffect(() => {
  //   const modifiedFilters = _.reduce(
  //     filter,
  //     (acc, cur, key) => {
  //       if (
  //         cur.value &&
  //         uniqueValues[key].find(o => o.value === cur.value) === undefined
  //       ) {
  //         if (uniqueValues[key][0]) {
  //           acc[key] = { ...uniqueValues[key][0] };
  //         }
  //       }
  //       return acc;
  //     },
  //     {},
  //   );
  //   if (!_.isEmpty(modifiedFilters)) {
  //     setFilter({ ...filter, ...modifiedFilters });
  //   }
  // }, [uniqueValues]);

  const handleApply = () => {
    handleApplyCallback(filter);
    setOpen(false);
  };

  const handleUndo = () => {
    setFilter(initialFilter);
  };

  const handleSelectDays = (key, value) => {
    // For getting the count too, TODO: Maybe change the logic
    const foundFromUniqueValuesForSelect = _.find(uniqueValues[key], { value });
    setFilter({
      ...filter,
      [key]: { ...filter[key], ...foundFromUniqueValuesForSelect },
    });
  };

  const handleSelect = (key, value) => {
    // For getting the count too, TODO: Maybe change the logic
    // const foundFromUniqueValuesForSelect = _.filter(uniqueValues[key], );
    setFilter({
      ...filter,
      [key]: value,
    });
  };

  // In case of days both the scalerFilter and days should be available
  const handleDaysChange = days => {
    // If no scalerFilter type is there, then take 0th as default
    if (!filter.ageInDays?.value) {
      setFilter({
        ...filter,
        ageInDays: {
          value: uniqueValues.ageInDays[0].value,
          days,
        },
      });
    } else {
      setFilter({
        ...filter,
        ageInDays: { ...filter.ageInDays, days },
      });
    }
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <div>
      <StyledEngineProvider injectFirst>
        <Dialog
          open={open}
          onClose={handleClose}
          aria-labelledby="form-dialog-title"
          classes={{ paper: classes.dialogPaper }}
        >
          <DialogTitle id="form-dialog-title">
            {t('Filter')}
            <IconButton
              aria-label="close"
              className={classes.closeButton}
              onClick={handleClose}
              size="large"
            >
              <CloseIcon />
            </IconButton>
          </DialogTitle>
          <DialogContent>
            <div className={classes.root}>
              <Grid>
                {/* -------------- Age ---------- */}
                <Grid alignItems="center" container spacing={2}>
                  <Grid item xs={4}>
                    <div>{t('Age')}</div>
                  </Grid>
                  <Grid item xs={4}>
                    <SimpleDropdown
                      handleChange={value =>
                        handleSelectDays('ageInDays', value)
                      }
                      {...makeArgsDays(uniqueValues.ageInDays, 'ageInDays')}
                    />
                  </Grid>
                  <Grid item xs={2}>
                    <TextField
                      variant="standard"
                      value={filter.ageInDays.days}
                      classes={{ root: classes.daysInput }}
                      onChange={event => {
                        handleDaysChange(event.target.value);
                      }}
                      type="number"
                    />
                  </Grid>
                  <Grid item xs={2}>
                    <span>{t('Day')}</span>
                  </Grid>
                  {/* -------------- Equipment Category ---------- */}
                  <Grid item xs={4}>
                    <div>{t('Equipment_Category')}</div>
                  </Grid>
                  <Grid item xs={4}>
                    <MultipleSelect
                      handleChange={value =>
                        handleSelect('assetCategory', value)
                      }
                      {...makeArgs(uniqueValues.assetCategory, 'assetCategory')}
                    />
                  </Grid>
                  <Grid item xs={4}></Grid>
                  {/* -------------- Have Recommendation ---------- */}
                  <Grid item xs={4}>
                    <div>{t('Recommendation')}</div>
                  </Grid>
                  <Grid item xs={4}>
                    <MultipleSelect
                      handleChange={value =>
                        handleSelect('haveRecommendations', value)
                      }
                      {...makeArgs(
                        uniqueValues.haveRecommendations,
                        'haveRecommendations',
                      )}
                    />
                  </Grid>
                  <Grid item xs={4}></Grid>
                  {/* -------------- ResolutionType ---------- */}
                  <Grid item xs={4}>
                    <div>{t('Resolution_type')}</div>
                  </Grid>
                  <Grid item xs={4}>
                    <MultipleSelect
                      handleChange={value =>
                        handleSelect('resolutionType', value)
                      }
                      {...makeArgs(
                        uniqueValues.resolutionType,
                        'resolutionType',
                      )}
                    />
                  </Grid>
                  <Grid item xs={4}></Grid>
                  {/* -------------- workInProgress ---------- */}
                  <Grid item xs={4}>
                    <div>{t('Bookmarked')}</div>
                  </Grid>
                  <Grid item xs={4}>
                    <MultipleSelect
                      handleChange={value =>
                        handleSelect('workInProgress', value)
                      }
                      {...makeArgs(
                        uniqueValues.workInProgress,
                        'workInProgress',
                      )}
                    />
                  </Grid>
                  <Grid item xs={4}></Grid>
                  {/* -------------- Urgency ---------- */}
                  <Grid item xs={4}>
                    <div>{t('Urgency')}</div>
                  </Grid>
                  <Grid item xs={4}>
                    <MultipleSelect
                      handleChange={value => handleSelect('urgency', value)}
                      {...makeArgs(uniqueValues.urgency, 'urgency')}
                    />
                  </Grid>
                  <Grid item xs={4}></Grid>
                  {/* -------------- Affecting Process ---------- */}
                  <Grid item xs={4}>
                    <div>{t('Benefits')}</div>
                  </Grid>
                  <Grid item xs={4}>
                    <MultipleSelect
                      handleChange={value => handleSelect('benefits', value)}
                      {...makeArgs(uniqueValues.benefits, 'benefits')}
                    />
                  </Grid>
                  <Grid item xs={4}></Grid>
                  {/* -------------- Condition ---------- */}
                  <Grid item xs={4}>
                    <div>{t('Condition')}</div>
                  </Grid>
                  <Grid item xs={4}>
                    <MultipleSelect
                      handleChange={value => handleSelect('condition', value)}
                      {...makeArgs(uniqueValues.condition, 'condition')}
                    />
                  </Grid>
                  <Grid item xs={4}></Grid>
                </Grid>
                <Grid item xs={4}>
                  <SimpleDropdown
                    handleChange={value => handleSelectDays('ageInDays', value)}
                    {...makeArgsDays(uniqueValues.ageInDays, 'ageInDays')}
                  />
                </Grid>
                <Grid item xs={2}>
                  <TextField
                    value={filter.ageInDays.days}
                    classes={{ root: classes.daysInput }}
                    onChange={event => {
                      handleDaysChange(event.target.value);
                    }}
                    type="number"
                  />
                </Grid>
                <Grid item xs={2}>
                  <span>{t('Day')}</span>
                </Grid>
                {/* -------------- Equipment Category ---------- */}
                <Grid item xs={4}>
                  <div>{t('Equipment_Category')}</div>
                </Grid>
                <Grid item xs={4}>
                  <MultipleSelect
                    handleChange={value => handleSelect('assetCategory', value)}
                    {...makeArgs(uniqueValues.assetCategory, 'assetCategory')}
                  />
                </Grid>
                <Grid item xs={4}></Grid>
                {/* -------------- Have Recommendation ---------- */}
                <Grid item xs={4}>
                  <div>{t('Recommendation')}</div>
                </Grid>
                <Grid item xs={4}>
                  <MultipleSelect
                    handleChange={value =>
                      handleSelect('haveRecommendations', value)
                    }
                    {...makeArgs(
                      uniqueValues.haveRecommendations,
                      'haveRecommendations',
                    )}
                  />
                </Grid>
                <Grid item xs={4}></Grid>
                {/* -------------- ResolutionType ---------- */}
                <Grid item xs={4}>
                  <div>{t('Resolution_type')}</div>
                </Grid>
                <Grid item xs={4}>
                  <MultipleSelect
                    handleChange={value =>
                      handleSelect('resolutionType', value)
                    }
                    {...makeArgs(uniqueValues.resolutionType, 'resolutionType')}
                  />
                </Grid>
                <Grid item xs={4}></Grid>
                {/* -------------- workInProgress ---------- */}
                <Grid item xs={4}>
                  <div>{t('Bookmarked')}</div>
                </Grid>
                <Grid item xs={4}>
                  <MultipleSelect
                    handleChange={value =>
                      handleSelect('workInProgress', value)
                    }
                    {...makeArgs(uniqueValues.workInProgress, 'workInProgress')}
                  />
                </Grid>
                <Grid item xs={4}></Grid>
                {/* -------------- Urgency ---------- */}
                <Grid item xs={4}>
                  <div>{t('Urgency')}</div>
                </Grid>
                <Grid item xs={4}>
                  <MultipleSelect
                    handleChange={value => handleSelect('urgency', value)}
                    {...makeArgs(uniqueValues.urgency, 'urgency')}
                  />
                </Grid>
                <Grid item xs={4}></Grid>
                {/* -------------- Affecting Process ---------- */}
                <Grid item xs={4}>
                  <div>{t('Benefits')}</div>
                </Grid>
                <Grid item xs={4}>
                  <MultipleSelect
                    handleChange={value => handleSelect('benefits', value)}
                    {...makeArgs(uniqueValues.benefits, 'benefits')}
                  />
                </Grid>
                <Grid item xs={4}></Grid>
                {/* -------------- Condition ---------- */}
                <Grid item xs={4}>
                  <div>{t('Condition')}</div>
                </Grid>
                <Grid item xs={4}>
                  <MultipleSelect
                    handleChange={value => handleSelect('condition', value)}
                    {...makeArgs(uniqueValues.condition, 'condition')}
                  />
                </Grid>
                <Grid item xs={4}></Grid>
                {/* -------------- Activities ---------- */}
                <Grid item xs={4}>
                  <div>{t('Activities')}</div>
                </Grid>
                <Grid item xs={4}>
                  <MultipleSelect
                    handleChange={value => handleSelect('activities', value)}
                    {...makeArgs(uniqueValues.activities, 'activities')}
                  />
                </Grid>
                <Grid item xs={4}></Grid>

                {/* -------------- Work Order ---------- */}
                <Grid item xs={4}>
                  <div>{t('Work_order')}</div>
                </Grid>
                <Grid item xs={4}>
                  <MultipleSelect
                    handleChange={value => handleSelect('workOrder', value)}
                    {...makeArgs(uniqueValues.workOrder, 'workOrder')}
                  />
                </Grid>
                <Grid item xs={4}></Grid>
              </Grid>
            </div>
          </DialogContent>
          {/* ------- Actions ------- */}
          <DialogActions classes={{ root: classes.actions }}>
            <Button
              onClick={handleUndo}
              disabled={filter === initialFilter}
              color="primary"
            >
              {t('Reset')}
            </Button>
            <Button onClick={handleClose} color="primary">
              {t('Cancel')}
            </Button>
            <Button onClick={handleApply} color="primary">
              {t('Apply')}
            </Button>
          </DialogActions>
        </Dialog>
      </StyledEngineProvider>
    </div>
  );
}

PaiAssetFilterDialogMultipleSelect.propTypes = {
  handleApplyCallback: PropTypes.func.isRequired,
  applyCascade: PropTypes.func.isRequired,
  uniqueValues: PropTypes.object.isRequired,
  initialFilter: PropTypes.object,
  open: PropTypes.bool.isRequired,
  setOpen: PropTypes.func.isRequired,
  selectedValue: PropTypes.array,
};

PaiAssetFilterDialogMultipleSelect.defaultProps = {
  initialFilter: {},
};
