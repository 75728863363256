/* eslint-disable no-nested-ternary */
import React from 'react';
import PropTypes from 'prop-types';
import { Box } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';

const useStyles = makeStyles(theme => ({
  status: {
    borderLeft: props =>
      props.border === 'left' || !props.border
        ? props.borderWidth
          ? `${props.borderWidth}px solid`
          : '3px solid'
        : 'none',

    borderRight: props =>
      props.border === 'right'
        ? props.borderWidth
          ? `${props.borderWidth}px solid`
          : '3px solid'
        : 'none',
    paddingLeft: theme.spacing(0.625),
    paddingRight: '5px',
    width: 'fit-content',
    lineHeight: '18px',
  },
  alignItemsCenter: {
    display: 'flex',
    alignItems: 'center',
  },
  primary: {
    borderLeftColor: props =>
      props.border === 'left' || !props.border ? theme.color.holdGrey : '',
    borderRightColor: props =>
      props.border === 'right' ? theme.color.holdGrey : '',
  },
  danger: {
    borderLeftColor: props =>
      props.border === 'left' || !props.border ? theme.color.urgentYellow : '',
    borderRightColor: props =>
      props.border === 'right' ? theme.color.urgentYellow : '',
  },
  success: {
    borderLeftColor: props =>
      props.border === 'left' || !props.border ? theme.color.resolvedGreen : '',
    borderRightColor: props =>
      props.border === 'right' ? theme.color.resolvedGreen : '',
  },
  warning: {
    borderLeftColor: props =>
      props.border === 'left' || !props.border ? theme.color.planYellow : '',
    borderRightColor: props =>
      props.border === 'right' ? theme.color.planYellow : '',
  },
  value: {
    marginLeft: '5px',
    fontSize: props => props.fontSize || '16px',
    fontWeight: 900,
  },
  label: {
    fontSize: '12px',
    fontStyle: 'normal',
    fontFeatureSettings: `"liga" 0`,
    fontWeight: 500,
    color: theme.color.lightGrey,
  },
}));

/**
 * Primary UI component for user interaction
 */
export const StatusLabel = props => {
  // eslint-disable-next-line no-unused-vars
  const { type, label, value, border, fontSize, borderWidth } = props;
  const classes = useStyles(props);
  return (
    <Box
      component="div"
      className={
        classes[type]
          ? `${classes.status} ${classes.alignItemsCenter} ${classes[type]}`
          : `${classes.alignItemsCenter}`
      }
    >
      <Box component="span" className={classes.label}>
        {label}
      </Box>
      <Box component="span" className={classes.value}>
        {value}
      </Box>
    </Box>
  );
};

StatusLabel.propTypes = {
  /**
   * Specify type of label
   */
  type: PropTypes.oneOf(['default', 'primary', 'success', 'danger']),
  /**
   * Font size of label value
   */
  // eslint-disable-next-line react/no-unused-prop-types
  fontSize: PropTypes.string,
  /**
   * Label text to be displayed
   */
  label: PropTypes.string,
  /**
   * Label value to be displayed
   */
  value: PropTypes.string,
  /**
   * Border left or right
   */
  border: PropTypes.string,
  /**
   * Border left or right
   */
  borderWidth: PropTypes.number,
};
