/**
 *
 * ScrollToTopButton
 *
 */

import React, { memo } from 'react';
import PropTypes from 'prop-types';

import { KeyboardArrowUpIcon } from '../icons';
import { makeStyles, Zoom, Fab } from '../thirdParty';

const useStyles = makeStyles(theme => ({
  floatingAction: {
    position: 'fixed',
    bottom: theme.spacing(5),
    right: theme.spacing(5),
    zIndex: 3,
    [theme.breakpoints.down('sm')]: {
      bottom: theme.spacing(8),
    },
  },
}));

function ScrollToTopButton({ open, handleOnClick }) {
  const classes = useStyles();
  return (
    <Zoom in={open}>
      <div
        onClick={handleOnClick}
        role="presentation"
        className={classes.floatingAction}
      >
        <Fab color="primary" size="medium" aria-label="scroll back to top">
          <KeyboardArrowUpIcon htmlColor="#fff" />
        </Fab>
      </div>
    </Zoom>
  );
}

ScrollToTopButton.propTypes = {
  /**
   * Show the button
   */
  open: PropTypes.bool.isRequired,
  /**
   * On click callback
   */
  handleOnClick: PropTypes.func.isRequired,
};

ScrollToTopButton.whyDidYouRender = true;

export default memo(ScrollToTopButton);
