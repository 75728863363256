import React, { useRef } from 'react';
import PropTypes from 'prop-types';
import { debounce } from 'lodash';
import { useTranslation } from 'react-i18next';
import { makeStyles, InputBase } from '../thirdParty';

import { SearchIcon, CloseIcon } from '../icons';
const useStyles = makeStyles(theme => ({
  table: {
    width: 600,
    border: 'none',
  },
  search: {
    position: 'relative',
    borderRadius: '10px',
    // backgroundColor: fade(theme.palette.common.white, 0.15),
    background: theme.palette.background.default,
    border: `1px solid ${theme.palette.primary.main}`,
    marginRight: theme.spacing(2),
    marginTop: '10px',
    marginLeft: 0,
    width: '100%',
    [theme.breakpoints.up('sm')]: {
      marginLeft: theme.spacing(1.5),
      margin: props => (props.smallSearch ? '4px 0px 0px 12px' : '10px 0px'),
    },
  },
  searchIcon: {
    padding: props =>
      props.smallSearch ? theme.spacing(0, 1.25) : theme.spacing(0, 2),
    color: props => props.iconColor,
    height: '100%',
    position: 'absolute',
    pointerEvents: 'none',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  inputRoot: {
    color: 'inherit',
    width: '100%',
  },
  inputInput: {
    padding: theme.spacing(0.7, 0.7, 0.7, 0),
    paddingLeft: `calc(1em + ${theme.spacing(4)})`,
    transition: theme.transitions.create('width'),
    width: '100%',
    [theme.breakpoints.up('md')]: {
      width: props => (props.smallSearch ? '45%' : '20ch'),
      fontSize: props => props.smallSearch && 12,
    },
  },
  closeIcon: {
    color: props => props.closeIconColor,
    height: '100%',
    position: 'absolute',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    right: '0px',
    top: '0px',
    padding: props =>
      props.smallSearch ? theme.spacing(0, 1.25) : theme.spacing(0, 2),
    cursor: 'pointer',
  },
}));
export const Search = ({
  isSearchBox,
  isIconEnabled,
  iconColor,
  closeIconColor,
  onChange,
  isCloseIconEnabled = false,
  onCloseClick,
  searchText,
  smallSearch,
  placeHolderText,
}) => {
  const classes = useStyles({ smallSearch, iconColor, closeIconColor });
  const debounced = debounce(onChange, 50);
  const { t } = useTranslation();
  const inputRef = useRef();
  return (
    <div>
      {isSearchBox && (
        <div className={classes.search}>
          <div className={classes.searchIcon}>
            {isIconEnabled && (
              <SearchIcon style={{ fontSize: smallSearch && 18 }} />
            )}
          </div>
          <InputBase
            placeholder={
              placeHolderText ? `${t(placeHolderText)}` : `${t('Search')}…`
            }
            classes={{
              root: classes.inputRoot,
              input: classes.inputInput,
            }}
            inputProps={{ 'aria-label': 'search' }}
            onChange={e => {
              debounced(e.target.value);
            }}
            value={searchText && searchText}
            inputRef={inputRef}
          />
          <div className={classes.closeIcon}>
            {isCloseIconEnabled && (
              <CloseIcon
                onClick={debounce(() => {
                  inputRef.current.value = '';
                  onCloseClick('');
                }, 200)}
                style={{ fontSize: smallSearch && 18 }}
              />
            )}
          </div>
        </div>
      )}
    </div>
  );
};

Search.propTypes = {
  /**
   * Search bar enabled or disabled
   */
  isSearchBox: PropTypes.bool,
  /**
   * Icon in searchbar is enabled or not
   */
  isIconEnabled: PropTypes.bool,
  /**
   * Icon in searchbar is enabled or not
   */
  iconColor: PropTypes.string,
  /**
   * Callback function on change of searchtext
   */
  onChange: PropTypes.func,
  /**
   * Close Icon in searchbar is enabled or not
   */
  isCloseIconEnabled: PropTypes.bool,
  /**
   * Callback function onclick to clear searchtext
   */
  onCloseClick: PropTypes.func,
  closeIconColor: PropTypes.string,
  searchText: PropTypes.string,
  smallSearch: PropTypes.bool,
  placeHolderText: PropTypes.string,
};

Search.defaultProps = {
  isSearchBox: true,
  isIconEnabled: true,
  iconColor: '#8cc63f',
  closeIconColor: '#8cc63f',
  smallSearch: false,
  placeHolderText: 'Search',
};
