/**
 *
 * PaiAssetSort
 *
 */

import React from 'react';
import makeStyles from '@mui/styles/makeStyles';
import PropTypes from 'prop-types';
import _ from 'lodash';
import { useTranslation } from 'react-i18next';
import IconButton from '@mui/material/IconButton';
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';
import ArrowUpward from '@mui/icons-material/ArrowUpward';

import { SimpleDropdown } from '../SimpleDropdown';

const useStyles = makeStyles({
  root: {
    display: 'flex',
    alignItems: 'center',
    position: 'relative',
  },
  iconButton: {
    // simpledropdown have some space at left
    marginLeft: -12,
  },
});

function PaiAssetSort({ assetkeys, sort, setSort }) {
  const classes = useStyles();
  const { t } = useTranslation();
  const labelTranslationMap = {
    ageInDays: 'Age',
    workInProgress: 'Bookmarked',
    condition: 'Condition',
    assetCategory: 'Equipment_Category',
    benefits: 'Benefits',
    haveRecommendations: 'Recommendation',
    resolutionType: 'Resolution_type',
    activities: 'Activities',
    workOrder: 'Work_order',
  };
  const sortArgs = {
    data: _.map(assetkeys, (value, key) => ({
      // label: t(key),
      label: t(labelTranslationMap[key]) || t(key),
      value,
    })),
    labelName: 'label',
    valueName: 'value',
    selectedValue: sort.key,
  };

  const handleSortSelect = key => {
    setSort({ key, order: 'asc' });
  };

  const changeSortOrder = () => {
    setSort({ ...sort, order: sort.order === 'asc' ? 'desc' : 'asc' });
  };

  return (
    <div className={classes.root}>
      <SimpleDropdown {...sortArgs} handleChange={handleSortSelect} />
      <IconButton
        classes={{ root: classes.iconButton }}
        aria-label="sort"
        onClick={changeSortOrder}
        size="small"
      >
        {sort.order === 'asc' ? <ArrowUpward /> : <ArrowDownwardIcon />}
      </IconButton>
    </div>
  );
}

PaiAssetSort.propTypes = {
  /**
   * The filter chips object
   */
  assetkeys: PropTypes.object,
  /**
   * Sort object
   */
  sort: PropTypes.object,
  /**
   * To set the filter object
   */
  setSort: PropTypes.func,
};

export { PaiAssetSort };
