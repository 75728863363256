const roundPrecision = 2;
export const UNIT_FAMILY = Object.freeze({
  TEMPERATURE: 'TEMPERATURE',
  DIFFERENTIAL_TEMPERATURE: 'DIFFERENTIAL TEMPERATURE',
  FLOW_LIQUID: 'FLOW LIQUID',
  PRESSURE: 'PRESSURE',
});
const getUomSymbol = (unitFamily, unitGroup) => {
  const uom = {
    unit1: {
      TEMPERATURE: 'DegC',
      'DIFFERENTIAL TEMPERATURE': 'DegC',
      HUMIDITY: '%',
      PRESSURE: 'kPa',
      PERCENTAGE: '%',
      'FLOW LIQUID': 'LPS',
      'FLOW AIR': 'CFM',
      CONCENTRATION: 'PPM',
      'ELECTRIC VOLTAGE': 'V',
      'ELECTRIC CURRENT': 'A',
      'ELECTRIC ENERGY': 'kWh',
      'ELECTRIC POWER': 'kW',
      'HEAT ENERGY': 'BTU',
      'APPARENT POWER': 'kVA',
      'REACTIVE POWER': 'kVAR',
      'REACTIVE ENERGY': 'kVARh',
      VOLUME: 'I',
      AREA: 'Sqm',
      'LUMINOUS FLUX': 'lm',
      ILLUMINANCE: 'ftcd',
      'ILLUMINANCE LUX': 'lux',
      REFRIGERATION: 'TR',
      LENGTH: 'm',
      MASS: 'kg',
      DENSITY: 'kg/m3',
      FREQUENCY: 'Hz',
      'ROTATIONAL SPEED': 'RPM',
      TIME: 'Hr(s)',
      DIGITAL_INFORMATION_UNIT: 'MB',
      DIGITAL_INFORMATION_UNIT_KB: 'KB',
      DIGITAL_INFORMATION_UNIT_GB: 'GB',
      PRECIPITATION: 'mm',
      'ATMOSPHERIC PRESSURE': 'hPa',
      DISTANCE: 'm',
      SPEED: 'm/s',
      CALENDER_DAYS: 'Days',
      BINARY_NOUNITS: '',
      ANALOG_NOUNITS: '',
      STRING_NOUNITS: '',
      SAMPLECOUNT_HR: 'Smpl/Hr',
      SAMPLECOUNT_DAY: 'Smpl/Day',
      'AIR PRESSURE': 'Pa',
    },
    unit2: {
      TEMPERATURE: 'DegF',
      'DIFFERENTIAL TEMPERATURE': 'DegF',
      HUMIDITY: '%',
      PRESSURE: 'PSIG',
      PERCENTAGE: '%',
      'FLOW LIQUID': 'USGPM',
      'FLOW AIR': 'CFM',
      CONCENTRATION: 'PPM',
      'ELECTRIC VOLTAGE': 'V',
      'ELECTRIC CURRENT': 'A',
      'ELECTRIC ENERGY': 'kWh',
      'ELECTRIC POWER': 'kW',
      'HEAT ENERGY': 'BTU',
      'APPARENT POWER': 'kVA',
      'REACTIVE POWER': 'kVAR',
      'REACTIVE ENERGY': 'kVARh',
      VOLUME: 'I',
      AREA: 'Sqm',
      'LUMINOUS FLUX': 'lm',
      ILLUMINANCE: 'ftcd',
      'ILLUMINANCE LUX': 'lux',
      REFRIGERATION: 'TR',
      LENGTH: 'm',
      MASS: 'kg',
      DENSITY: 'kg/m3',
      FREQUENCY: 'Hz',
      'ROTATIONAL SPEED': 'RPM',
      TIME: 'Hr(s)',
      DIGITAL_INFORMATION_UNIT: 'MB',
      DIGITAL_INFORMATION_UNIT_KB: 'KB',
      DIGITAL_INFORMATION_UNIT_GB: 'GB',
      PRECIPITATION: 'mm',
      'ATMOSPHERIC PRESSURE': 'hPa',
      DISTANCE: 'm',
      SPEED: 'm/s',
      CALENDER_DAYS: 'Days',
      BINARY_NOUNITS: '',
      ANALOG_NOUNITS: '',
      STRING_NOUNITS: '',
      SAMPLECOUNT_HR: 'Smpl/Hr',
      SAMPLECOUNT_DAY: 'Smpl/Day',
      'AIR PRESSURE': 'Pa',
    },
  };
  return uom[unitGroup][unitFamily];
};

export default getUomSymbol;

const conversionConstants = {
  fahrenheitAndCentigrade: {
    conversionFactor: 1.8,
    offSet: 32,
  },
  lpsToUsgpm: {
    conversionFactor: 15.8503742,
  },
  usgpmToLps: {
    conversionFactor: 0.06309,
  },
};

export const countDecimals = value => {
  let val = -1;
  if (Math.floor(value) === value) {
    val = 0;
  } else if (Number.isNaN(value)) {
    val = value;
  } else if (value && value.toString().split('.')[1]) {
    val = value.toString().split('.')[1].length;
  } else {
    val = 0;
  }
  return val;
};

const countUomDecimals = value => {
  if (Math.floor(value) === value) return 0;
  return Number.isNaN(value)
    ? value
    : value.toString().split('.')[1].length || 0;
};

const truncateToTwoDecimalPlaces = originalValue => {
  if (Math.floor(originalValue) === originalValue) return 0;
  const splitedValue = originalValue.toString().split('.');
  return splitedValue[1].length > roundPrecision
    ? `${splitedValue[0]}.${splitedValue[1].substring(0, roundPrecision)}`
    : originalValue || 0;
};

export const unitConversion = {
  convertCentigradeToFahrenheit: centigrade => {
    const convertedFahrenheit =
      centigrade *
        conversionConstants.fahrenheitAndCentigrade.conversionFactor +
      conversionConstants.fahrenheitAndCentigrade.offSet;
    return convertedFahrenheit % 1 !== 0 &&
      countUomDecimals(convertedFahrenheit) > 2
      ? Number(truncateToTwoDecimalPlaces(convertedFahrenheit))
      : Number(convertedFahrenheit);
  },
  convertFahrenheitToCentrigrade: fahrenheit => {
    const convertedCentigrade =
      (fahrenheit - conversionConstants.fahrenheitAndCentigrade.offSet) /
      conversionConstants.fahrenheitAndCentigrade.conversionFactor;
    return convertedCentigrade % 1 !== 0 &&
      countUomDecimals(convertedCentigrade) > 2
      ? Number(truncateToTwoDecimalPlaces(convertedCentigrade))
      : Number(convertedCentigrade);
  },
  convertDiffFahrenToCenti: fahrenheit => {
    const convertedCentigrade = fahrenheit / 1.8;
    return convertedCentigrade % 1 !== 0 &&
      countUomDecimals(convertedCentigrade) > 2
      ? Number(truncateToTwoDecimalPlaces(convertedCentigrade))
      : Number(convertedCentigrade);
  },
  convertDiffCentiToFahren: centigrade => {
    const convertedFahrenheit = centigrade * 1.8;
    return convertedFahrenheit % 1 !== 0 &&
      countUomDecimals(convertedFahrenheit) > 2
      ? Number(truncateToTwoDecimalPlaces(convertedFahrenheit))
      : Number(convertedFahrenheit);
  },
  convertLpsToUsgpm: lps => {
    const convertedUsgpm =
      lps * conversionConstants.lpsToUsgpm.conversionFactor;
    return convertedUsgpm % 1 !== 0 && countUomDecimals(convertedUsgpm) > 2
      ? Number(truncateToTwoDecimalPlaces(convertedUsgpm))
      : Number(convertedUsgpm);
  },
  convertUsgpmToLps: usGpm => {
    const convertedLps =
      usGpm * conversionConstants.usgpmToLps.conversionFactor;
    return convertedLps % 1 !== 0 && countUomDecimals(convertedLps) > 2
      ? Number(truncateToTwoDecimalPlaces(convertedLps))
      : Number(convertedLps);
  },
  convertKpaToPsig: kPa => {
    const convertedPsig = kPa / 6.89476;
    return convertedPsig % 1 !== 0 && countUomDecimals(convertedPsig) > 2
      ? Number(truncateToTwoDecimalPlaces(convertedPsig))
      : Number(convertedPsig);
  },
  convertPsigToKpa: psig => {
    const convertedKpa = psig * 6.89476;
    return convertedKpa % 1 !== 0 && countUomDecimals(convertedKpa) > 2
      ? Number(truncateToTwoDecimalPlaces(convertedKpa))
      : Number(convertedKpa);
  },
};

export const uomValueStringMapping = {
  'Unit Category 01 (DegC,LPS)': 'unit1',
  'Unit Category 02 (DegF,USGPM)': 'unit2',
  unit1: 'unit1',
  unit2: 'unit2',
};

export const uomLabelStringMapping = {
  unit1: 'Unit Category 01 (DegC,LPS,kPa)',
  unit2: 'Unit Category 02 (DegF,USGPM,PSIG)',
};
export const convertUnitBasedOnSourceAndTargetUnitFamily = (
  unitFamily,
  targetUnit,
  value,
  sourceUnit = uomValueStringMapping.unit1,
) => {
  if (targetUnit === sourceUnit) {
    return value;
  }
  // Unit Category 01 (DegC,LPS,kPa) -> Unit Category 02 (DegF,USGPM,PSIG)
  if (targetUnit === uomValueStringMapping.unit2) {
    switch (unitFamily?.toUpperCase()) {
      case UNIT_FAMILY.TEMPERATURE: {
        return unitConversion.convertCentigradeToFahrenheit(value);
      }
      case UNIT_FAMILY.DIFFERENTIAL_TEMPERATURE: {
        return unitConversion.convertDiffCentiToFahren(value);
      }
      case UNIT_FAMILY.FLOW_LIQUID: {
        return unitConversion.convertUsgpmToLps(value);
      }
      case UNIT_FAMILY.PRESSURE: {
        return unitConversion.convertKpaToPsig(value);
      }
      default:
        return value;
    }
  }
  // Unit Category 02 (DegF,USGPM,PSIG) -> Unit Category 01 (DegC,LPS,kPa)
  if (targetUnit === uomValueStringMapping.unit1) {
    switch (unitFamily?.toUpperCase()) {
      case UNIT_FAMILY.TEMPERATURE: {
        return unitConversion.convertFahrenheitToCentrigrade(value);
      }
      case UNIT_FAMILY.DIFFERENTIAL_TEMPERATURE: {
        return unitConversion.convertDiffFahrenToCenti(value);
      }
      case UNIT_FAMILY.FLOW_LIQUID: {
        return unitConversion.convertLpsToUsgpm(value);
      }
      case UNIT_FAMILY.PRESSURE: {
        return unitConversion.convertPsigToKpa(value);
      }
      default:
        return value;
    }
  }
  return value;
};
