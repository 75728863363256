import moment from 'moment';
import 'moment-timezone';
const traslationFormats = {
  en: {
    sameDay: '[Today]',
    nextDay: '[Tomorrow]',
    nextWeek: 'dddd',
    lastDay: '[Yesterday]',
    lastWeek: 'Do MMMM',
    sameElse: 'DD/MM/YYYY',
  },
  es: {
    sameDay: '[Hoy]',
    nextDay: '[mañana]',
    nextWeek: 'dddd',
    lastDay: '[Ayer]',
    lastWeek: 'Do MMMM',
    sameElse: 'DD/MM/YYYY',
  },
  ar: {
    sameDay: '[اليوم]',
    nextDay: '[غداً]',
    nextWeek: 'dddd',
    lastDay: '[أمس]',
    lastWeek: 'Do MMMM',
    sameElse: 'DD/MM/YYYY',
  },
  de: {
    sameDay: '[Heute]',
    nextDay: '[Morgen]',
    nextWeek: 'dddd',
    lastDay: '[Gestern]',
    lastWeek: 'Do MMMM',
    sameElse: 'DD/MM/YYYY',
  },
  fr: {
    sameDay: "[Aujourd'hui]",
    nextDay: '[Demain]',
    nextWeek: 'dddd',
    lastDay: '[Hier]',
    lastWeek: 'Do MMMM',
    sameElse: 'DD/MM/YYYY',
  },
  it: {
    sameDay: '[Oggi]',
    nextDay: '[Domani]',
    nextWeek: 'dddd',
    lastDay: '[Ieri]',
    lastWeek: 'Do MMMM',
    sameElse: 'DD/MM/YYYY',
  },
  nl: {
    sameDay: '[Vandaag]',
    nextDay: '[Morgen]',
    nextWeek: 'dddd',
    lastDay: '[Gisteren]',
    lastWeek: 'Do MMMM',
    sameElse: 'DD/MM/YYYY',
  },
  tr: {
    sameDay: '[Bugün]',
    nextDay: '[Yarın]',
    nextWeek: 'dddd',
    lastDay: '[Dün]',
    lastWeek: 'Do MMMM',
    sameElse: 'DD/MM/YYYY',
  },
  'zh-cn': {
    sameDay: '[今天]',
    nextDay: '[明天]',
    nextWeek: 'dddd',
    lastDay: '[昨天]',
    lastWeek: 'Do MMMM',
    sameElse: 'DD/MM/YYYY',
  },
  zh: {
    sameDay: '[今天]',
    nextDay: '[明天]',
    nextWeek: 'dddd',
    lastDay: '[昨天]',
    lastWeek: 'Do MMMM',
    sameElse: 'DD/MM/YYYY',
  },
  ja: {
    sameDay: '同じ日',
    nextDay: '次の日',
    nextWeek: 'dddd',
    lastDay: '最終日',
    lastWeek: 'Do MMMM',
    sameElse: 'DD/MM/YYYY',
  },
};

moment.updateLocale('es', {
  calendar: traslationFormats.es,
});
moment.updateLocale('zh', {
  calendar: traslationFormats.zh,
});
moment.updateLocale('zh-cn', {
  calendar: traslationFormats['zh-cn'],
});
moment.updateLocale('en', {
  calendar: traslationFormats.en,
});
moment.updateLocale('ar', {
  calendar: traslationFormats.ar,
});
moment.updateLocale('de', {
  calendar: traslationFormats.de,
});
moment.updateLocale('fr', {
  calendar: traslationFormats.fr,
});
moment.updateLocale('it', {
  calendar: traslationFormats.it,
});
moment.updateLocale('nl', {
  calendar: traslationFormats.nl,
});
moment.updateLocale('ja', {
  calendar: traslationFormats.ja,
});
moment.updateLocale('tr', {
  calendar: traslationFormats.tr,
});

export const relativeDate = (time, locale) => {
  const now = moment();
  return moment(time).locale(locale).calendar(now, traslationFormats[locale]);
};

export const daysFromNow = date =>
  Math.round((Date.now() - Date.parse(date)) / (1000 * 60 * 60 * 24));

export const monthYearFormatter = (locale = 'en') =>
  new Intl.DateTimeFormat(locale, {
    year: 'numeric',
    month: 'short',
  });

export const dayFormatter = (locale = 'en') =>
  new Intl.DateTimeFormat(locale, { day: 'numeric' });

export const getCurrentTimestamp = () => new Date().getTime();

export const convertToDtMnthYrUTC = epochMS => {
  if (!epochMS) {
    return '-';
  }
  return moment.utc(moment(epochMS).valueOf()).format('DD MMM, YYYY, z');
};

export const convertToDtMnthYr = (epochMS, timezone) => {
  if (!epochMS) {
    return '-';
  }
  return !timezone
    ? convertToDtMnthYrUTC(epochMS)
    : moment(epochMS).tz(timezone).format('DD MMM, YYYY, z');
};
