/**
 *
 * BackAndForwardButton
 *
 */

import React from 'react';
import PropTypes from 'prop-types';
import makeStyles from '@mui/styles/makeStyles';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import {
  ArrowBackIcon,
  ArrowForwardIcon,
  IconButton,
  Box,
} from '../thirdParty';
import { CustomLink } from '../CustomLink';

const useStyles = makeStyles({
  root: {
    display: 'flex',
    alignItems: 'center',
  },
  iconButton: {
    padding: 5,
  },
  buttonAndLinkContainer: {
    display: 'flex',
    alignItems: 'center',
  },
});

export const BackAndForwardButton = ({ hideBackButton, hideForwardButton }) => {
  const classes = useStyles();
  const history = useNavigate() || '';
  const { t } = useTranslation();

  const goBack = event => {
    history(-1);
    event.preventDefault();
  };

  const goForward = event => {
    history(1);
    event.preventDefault();
  };

  return (
    <Box component="div" className={classes.root}>
      {!hideBackButton && (
        <Box className={classes.buttonAndLinkContainer} component="div">
          <IconButton
            className={classes.iconButton}
            disabled={history.length <= 1}
            onClick={event => goBack(event)}
            title={t('Back')}
            size="large"
          >
            <ArrowBackIcon fontSize="small" />
          </IconButton>
          <CustomLink
            to="#"
            disabled={history.length <= 1}
            onClick={history.length <= 1 ? '' : goBack}
          >
            {t('Back')}
          </CustomLink>
        </Box>
      )}
      {!hideForwardButton && (
        <Box className={classes.buttonAndLinkContainer} component="div">
          <CustomLink
            to="#"
            disabled={history.action === 'PUSH' || history.length <= 1}
            onClick={
              history.action === 'PUSH' || history.length <= 1 ? '' : goForward
            }
          >
            {t('Forward')}
          </CustomLink>
          <IconButton
            className={classes.iconButton}
            onClick={() => history(1)}
            disabled={history.action === 'PUSH' || history.length <= 1}
            title={t('Forward')}
            size="large"
          >
            <ArrowForwardIcon fontSize="small" />
          </IconButton>
        </Box>
      )}
    </Box>
  );
};

BackAndForwardButton.propTypes = {
  /**
   * Dont show back button if true
   */
  hideBackButton: PropTypes.bool,
  /**
   * Dont show forward button if true
   */
  hideForwardButton: PropTypes.bool,
};
