import React from 'react';
import makeStyles from '@mui/styles/makeStyles';
import Popover from '@mui/material/Popover';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { TextButton } from '../TextButton';

const useStyles = makeStyles(theme => ({
  popOverContent: {
    padding: theme.spacing(2),
    minHeight: 40,
    minWidth: 200,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    fontSize: 12,
  },
}));

export function MoreDetailsPopover({ detailsArray }) {
  const classes = useStyles();
  const { t } = useTranslation();
  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleClick = event => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? 'logout-popover' : undefined;

  return (
    // eslint-disable-next-line react/jsx-fragments
    <React.Fragment>
      <span style={{ marginLeft: 8 }}>
        <TextButton onClick={handleClick} alwaysUnderline>
          {t('More')}
        </TextButton>
      </span>
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
      >
        <div className={classes.popOverContent}>
          <table>
            {detailsArray.map(da => (
              <tr>
                <td>{da.label}</td>
                <td> : </td>
                <td>{da.value}</td>
              </tr>
            ))}
          </table>
        </div>
      </Popover>
    </React.Fragment>
  );
}

MoreDetailsPopover.propTypes = {
  detailsArray: PropTypes.array,
};
