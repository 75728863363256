import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import makeStyles from '@mui/styles/makeStyles';
import { divElement } from '../constants';
import { Paper } from '../thirdParty';

const useStyles = makeStyles(() => ({
  rootpapper: {
    minWidth: '850px',
    borderWidth: '1px',
    borderColor: 'rgb(140, 140, 140)',
    borderStyle: 'solid',
    boxShadow: 'rgb(0 0 0 / 20%) 0px 1px 2px 0px',
    borderRadius: '4px',
    minHeight: '50px',
    padding: '0 20px',
    // margin: '0 20px 10px',
  },
  horizontalLine: {
    backgroundColor: '#c5c5c5',
    height: '1px',
    border: 0,
    margin: 0,
  },
  divElement: {
    minHeight: props => props?.styles?.minHeightRow,
    padding: props => props?.styles?.paddingRow,
  },
}));

/**
 * Primary UI component for user interaction
 */
export const RowsContainer = ({ children, styles }) => {
  const classes = useStyles({ styles });

  return styles?.element !== divElement ? (
    <Paper classes={{ root: classes.rootpapper }}>
      {children?.map((child, index) => (
        <>
          <div>{child}</div>
          {index !== children.length - 1 && children[index + 1] ? (
            <hr className={classes.horizontalLine} />
          ) : null}
        </>
      ))}
    </Paper>
  ) : (
    <div>
      {children?.map((child, index) => (
        <>
          <div>{child}</div>
          {index !== children.length - 1 && children[index + 1] ? (
            <hr className={classes.horizontalLine} />
          ) : null}
        </>
      ))}
    </div>
  );
};

RowsContainer.propTypes = {
  /**
   * Children elements (rows)
   */
  children: PropTypes.element,
  styles: PropTypes.object,
};
