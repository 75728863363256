import React from 'react';
import { SvgIcon } from '@mui/material';

function ControlsPerformance(props) {
  return (
    <SvgIcon
      {...props}
      width="58"
      height="58"
      viewBox="10 17 35 25"
      fill="none"
    >
      <rect width="58" height="58" fill="white" />
      <path
        d="M19.9192 18.0012H17.9019C17.4399 18.0012 17.0654 18.3757 17.0654 18.8377V39.3392C17.0654 39.8012 17.4399 40.1757 17.9019 40.1757H19.9192C20.3811 40.1757 20.7556 39.8012 20.7556 39.3392V18.8377C20.7556 18.3757 20.3811 18.0012 19.9192 18.0012Z"
        fill="#152C73"
      />
      <path
        d="M20.9621 24.0986H17.2793C16.0205 24.0986 15 25.1191 15 26.378V26.3792C15 27.638 16.0205 28.6585 17.2793 28.6585H20.9621C22.221 28.6585 23.2415 27.638 23.2415 26.3792V26.378C23.2415 25.1191 22.221 24.0986 20.9621 24.0986Z"
        fill="#152C73"
      />
      <path
        d="M39.6799 18.0012H37.6626C37.2007 18.0012 36.8262 18.3757 36.8262 18.8377V39.3392C36.8262 39.8012 37.2007 40.1757 37.6626 40.1757H39.6799C40.1419 40.1757 40.5164 39.8012 40.5164 39.3392V18.8377C40.5164 18.3757 40.1419 18.0012 39.6799 18.0012Z"
        fill="#152C73"
      />
      <path
        d="M40.7219 26.8085H37.0391C35.7803 26.8085 34.7598 27.829 34.7598 29.0879V29.0891C34.7598 30.3479 35.7803 31.3684 37.0391 31.3684H40.7219C41.9807 31.3684 43.0012 30.3479 43.0012 29.0891V29.0879C43.0012 27.829 41.9807 26.8085 40.7219 26.8085Z"
        fill="#152C73"
      />
      <path
        d="M27.7917 40.1745H29.8091C30.271 40.1745 30.6455 39.8 30.6455 39.3381L30.6455 18.8365C30.6455 18.3745 30.271 18 29.8091 18H27.7917C27.3298 18 26.9553 18.3745 26.9553 18.8365L26.9553 39.3381C26.9553 39.8 27.3298 40.1745 27.7917 40.1745Z"
        fill="#152C73"
      />
      <path
        d="M26.7488 36.1238H30.4316C31.6905 36.1238 32.7109 35.1033 32.7109 33.8445V33.8432C32.7109 32.5844 31.6905 31.5639 30.4316 31.5639H26.7488C25.49 31.5639 24.4695 32.5844 24.4695 33.8432V33.8445C24.4695 35.1033 25.49 36.1238 26.7488 36.1238Z"
        fill="#152C73"
      />
    </SvgIcon>
  );
}
ControlsPerformance.displayName = 'ControlsPerformance';
ControlsPerformance.muiName = 'SvgIcon';

export default ControlsPerformance;
