import React from 'react';
import PropTypes from 'prop-types';
import makeStyles from '@mui/styles/makeStyles';
import { ChevronRightIcon, LocalShippingIcon } from '../icons';
import RemoteFixIcon from '../SvgIcons/RemoteFix';
const useStyles = makeStyles(() => ({
  remoteAndFieldVisitIcon: {
    display: 'flex',
  },
  remoteFixicon: {
    fontSize: '1.2rem',
  },
  fieldVisitIcon: {
    fontSize: '1.5rem',
  },
  iconContainerHeight: {
    height: 24,
  },
  remoteFixIconSize: {
    fontSize: '1.5rem',
    height: 12,
    width: 12,
  },
}));

const resolutionTypeTexts = Object.freeze({
  none: 'None',
  fieldVisit: 'Field Visit',
  remoteFix: 'Remote Fix',
  both: 'Both Remote & Field visit',
});

const getResolutionType = resolutionTypes => {
  if (resolutionTypes?.length === 0) {
    return resolutionTypeTexts.none;
  }
  if (resolutionTypes?.length > 1) {
    return resolutionTypeTexts.both;
  }
  return resolutionTypes?.[0];
};

export const ResolutionTypeMultiple = ({
  multipleResolutionType,
  type,
  parentComponent,
}) => {
  const resolutionType = type || getResolutionType(multipleResolutionType);
  const classes = useStyles();
  if (resolutionType === resolutionTypeTexts.remoteFix)
    return (
      <div className={classes.iconContainerHeight}>
        <RemoteFixIcon
          classes={{
            root:
              parentComponent === 'SiteSummary'
                ? classes.remoteFixIconSize
                : classes.remoteFixicon,
          }}
        />
      </div>
    );
  if (resolutionType === resolutionTypeTexts.fieldVisit)
    return (
      <LocalShippingIcon
        className={
          parentComponent === 'SiteSummary' && classes.remoteFixIconSize
        }
      />
    );
  if (resolutionType === resolutionTypeTexts.both) {
    return (
      <div className={classes.remoteAndFieldVisitIcon}>
        <RemoteFixIcon
          classes={{
            root:
              parentComponent === 'SiteSummary'
                ? classes.remoteFixIconSize
                : classes.remoteFixicon,
          }}
        />
        <ChevronRightIcon
          fontSize="small"
          className={
            parentComponent === 'SiteSummary' && classes.remoteFixIconSize
          }
        />
        <LocalShippingIcon
          className={
            parentComponent === 'SiteSummary' && classes.remoteFixIconSize
          }
        />
      </div>
    );
  }
  return <span>-</span>;
};

ResolutionTypeMultiple.propTypes = {
  /**
   * type is resolutionType Field, Remote or both
   */
  type: PropTypes.oneOf([
    'Field Visit',
    'Remote Fix',
    'Both Remote & Field visit',
    'None',
  ]),
  multipleResolutionType: PropTypes.array,
  parentComponent: PropTypes.string,
};

ResolutionTypeMultiple.defaultProps = {
  parentComponent: '',
};
