import React from 'react';
import PropTypes from 'prop-types';
import makeStyles from '@mui/styles/makeStyles';
import { NavLink } from 'react-router-dom';

const useStyles = makeStyles(theme => ({
  linkStyle: {
    color: theme.palette.primary.main,
    textDecoration: props => (props.alwaysUnderline ? 'underline' : 'none'),
    '&:hover': {
      textDecoration: 'underline',
    },
    '&:visited': {
      color: props =>
        props.showVisited ? theme.color.purple : theme.palette.primary.main,
    },
  },
}));

/**
 * Primary UI component for user interaction
 */
export const CustomLink = props => {
  // eslint-disable-next-line react/prop-types
  const { to, children, disabled, onClick } = props;
  const classes = useStyles(props);

  return (
    <span>
      {disabled ? (
        children
      ) : (
        <NavLink
          to={to}
          className={classes.linkStyle}
          onClick={onClick ? event => onClick(event) : ''}
        >
          {children}
        </NavLink>
      )}
    </span>
  );
};

CustomLink.propTypes = {
  /**
   * link URL
   */
  to: PropTypes.string,
  /**
   * Show text in normal style when disabled
   */
  disabled: PropTypes.bool,
  /**
   * onclick callback handler
   */
  onClick: PropTypes.func,
  /**
   * Change color when visited if true
   */
  // eslint-disable-next-line react/no-unused-prop-types
  showVisited: PropTypes.bool,
  /**
   * link will be always underlined
   */
  // eslint-disable-next-line react/no-unused-prop-types
  alwaysUnderline: PropTypes.bool,
};

CustomLink.defaultProps = {
  alwaysUnderline: false,
};
