/**
 *
 * Feedback Sources
 *
 */

import React from 'react';
import PropTypes from 'prop-types';
import makeStyles from '@mui/styles/makeStyles';
import { isEmpty } from 'lodash';
import { Box, Grid } from '../thirdParty';
import { ListItem } from '../listItem';
import { convertToDtMnthYr } from '../dateUtils';
import { DeleteIconButton } from '../DeleteIconButton';
import { NoDataText } from '../NoDataText';

const useStyles = makeStyles(() => ({
  listItemWrapper: {
    minWidth: 400,
    maxWidth: 600,
  },
  listItem: {
    alignItems: 'center',
  },
  gridItem: {
    flexGrow: 1,
    flexBasis: 0,
  },
}));

export const FeedbackSourcesTable = ({
  feedbackSources,
  setDeleteId,
  noDataText,
  timezone,
}) => {
  const classes = useStyles();

  return !isEmpty(feedbackSources) ? (
    <Box component="div">
      {feedbackSources.map(feedbackSource => (
        <Box component="div" className={classes.listItemWrapper}>
          <ListItem>
            <Grid container className={classes.listItem}>
              <Grid item className={classes.gridItem}>
                {convertToDtMnthYr(feedbackSource.ts, timezone)}
              </Grid>
              <Grid item className={classes.gridItem}>
                {feedbackSource.src.dn}
              </Grid>
              <Grid item className={classes.gridItem}>
                {feedbackSource.srcId}
              </Grid>
              {setDeleteId && (
                <Grid item className={classes.gridItem}>
                  <DeleteIconButton
                    onClickHandler={() => setDeleteId(feedbackSource.id)}
                  />
                </Grid>
              )}
            </Grid>
          </ListItem>
        </Box>
      ))}
    </Box>
  ) : (
    <NoDataText text={noDataText}></NoDataText>
  );
};

FeedbackSourcesTable.propTypes = {
  /**
   * List of User's added feedback sources
   */
  feedbackSources: PropTypes.array,
  setDeleteId: PropTypes.func,
  noDataText: PropTypes.string,
  /**
   * show date with tinezone provided
   */
  timezone: PropTypes.string,
};
