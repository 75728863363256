import React from 'react';
import { SvgIcon } from '@mui/material';

function Connectivity(props) {
  return (
    <SvgIcon
      {...props}
      width="58"
      height="58"
      viewBox="10 17 35 25"
      fill="none"
    >
      <rect width="58" height="58" fill="white" />
      <path
        d="M42.9998 43H35.6643C35.6606 42.9008 35.6582 42.8017 35.6582 42.7013C35.6582 40.37 36.6385 38.1729 38.4269 36.9395C39.5347 36.1753 41.0695 35.8646 42.5181 35.8646C42.6807 35.8646 42.8409 35.8706 42.9998 35.8815V43Z"
        fill="#162C73"
      />
      <path
        d="M41.5503 24.4111C32.0163 24.4111 24.2598 32.1923 24.2598 41.7557C24.2598 42.1741 24.2743 42.5889 24.3047 43H25.8856C25.8528 42.5901 25.837 42.1741 25.837 41.7557C25.837 33.0593 32.8862 25.9831 41.5503 25.9831C42.0393 25.9831 42.5222 26.0061 43.0002 26.0496V24.4716C42.5222 24.4317 42.0381 24.4111 41.5503 24.4111Z"
        fill="#162C73"
      />
      <path
        d="M41.6435 28.5949C34.3686 28.5949 28.4502 34.5429 28.4502 41.8525C28.4502 42.2394 28.4672 42.6215 28.4999 43H30.0845C30.0457 42.6227 30.0275 42.2394 30.0275 41.8525C30.0275 35.4087 35.2385 30.1669 41.6435 30.1669C42.1021 30.1669 42.5547 30.1935 42.9999 30.2467V28.665C42.5547 28.6191 42.1009 28.5949 41.6435 28.5949Z"
        fill="#162C73"
      />
      <path
        d="M41.6431 32.6336C36.7098 32.6336 32.6963 36.7255 32.6963 41.7557C32.6963 42.1777 32.7242 42.5937 32.78 43H34.3743C34.3075 42.5949 34.2736 42.1789 34.2736 41.7557C34.2736 37.5925 37.5798 34.2056 41.6431 34.2056C42.1065 34.2056 42.5603 34.2491 42.9995 34.3349V32.7376C42.5567 32.6699 42.1041 32.6336 41.6431 32.6336Z"
        fill="#162C73"
      />
      <path
        d="M14 14V21.3108C14.0995 21.3144 14.199 21.3168 14.2997 21.3168C16.6389 21.3168 18.8434 20.3398 20.081 18.5574C20.8478 17.4534 21.1596 15.9238 21.1596 14.48C21.1596 14.318 21.1535 14.1584 21.1426 14H14Z"
        fill="#162C73"
      />
      <path
        d="M32.5912 14H31.0079C31.0515 14.4764 31.0746 14.9577 31.0746 15.445C31.0746 24.0798 23.9744 31.1052 15.2485 31.1052C14.8287 31.1052 14.4113 31.0895 14 31.0568V32.6324C14.4125 32.6626 14.8287 32.6771 15.2485 32.6771C24.8444 32.6771 32.6519 24.9468 32.6519 15.445C32.6519 14.9589 32.6312 14.4764 32.5912 14Z"
        fill="#162C73"
      />
      <path
        d="M28.3835 14H26.7965C26.8499 14.4438 26.8766 14.8948 26.8766 15.3519C26.8766 21.7352 21.617 26.9287 15.1514 26.9287C14.7632 26.9287 14.3785 26.9105 14 26.8718V28.451C14.3798 28.4837 14.7632 28.5006 15.1514 28.5006C22.4857 28.5006 28.4539 22.6022 28.4539 15.3519C28.4539 14.896 28.4296 14.4438 28.3835 14Z"
        fill="#162C73"
      />
      <path
        d="M24.2972 14H22.6944C22.7806 14.4377 22.8242 14.89 22.8242 15.3519C22.8242 19.4014 19.4258 22.6965 15.2485 22.6965C14.8238 22.6965 14.4065 22.6626 14 22.5961V24.185C14.4077 24.2406 14.825 24.2684 15.2485 24.2684C20.2957 24.2684 24.4015 20.2684 24.4015 15.3519C24.4015 14.8924 24.3651 14.4414 24.2972 14Z"
        fill="#162C73"
      />
    </SvgIcon>
  );
}
Connectivity.displayName = 'Connectivity';
Connectivity.muiName = 'SvgIcon';

export default Connectivity;
