import React from 'react';
import makeStyles from '@mui/styles/makeStyles';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { VerifiedIcon } from '../verifiedIcon';
import { recommendationFeedbackStatus } from '../Common';

const useStyles = makeStyles(theme => ({
  root: {
    alignItems: 'center',
    display: 'flex',
    fontWeight: 700,
  },
  swipeableContainer: {
    minWidth: 200,
    flexGrow: 1,
    marginRight: theme.spacing(2.5),
    marginLeft: theme.spacing(2.5),
    display: 'flex',
  },
  desc: {
    textOverflow: 'ellipsis',
    wordWrap: 'break-word',
    overflow: 'hidden',
    maxHeight: '4.5em',
    lineHeight: '1.5em',
  },
  iconAlignment: {
    marginLeft: '2px',
  },
  splitter: {
    margin: '0px 8px',
  },
  recommWorkSumDialog: {
    display: 'flex',
    flexGrow: 1,
    minWidth: 200,
    marginLeft: 0,
    marginRight: 0,
    fontWeight: 400,
    color: 'rgba(0, 0, 0, 0.87)',
    fontSize: 12,
  },
}));

const getVerifiedIconOnRecoConfirm = (feedback, title) => {
  let verifiedIcon = '';
  if (feedback && feedback === recommendationFeedbackStatus.confirm) {
    verifiedIcon = (
      <VerifiedIcon title={title} label="verified" fontSize={15} />
    );
  }
  return verifiedIcon;
};
function RecommendationsLabels({ steps, styleWorkOrderSummaryDialog }) {
  const classes = useStyles();
  const { t } = useTranslation();
  const maxSteps = steps.length;
  return (
    <div className={classes.root} title>
      <div
        className={
          styleWorkOrderSummaryDialog
            ? classes.recommWorkSumDialog
            : classes.swipeableContainer
        }
      >
        <div className={classes.desc}>
          {steps.map(
            (step, idx) =>
              step.label && (
                <span key={step.label}>
                  <span>{`${step.label}`}</span>
                  {getVerifiedIconOnRecoConfirm(step.feedback, t('Confirmed'))}
                  {maxSteps - 1 === idx ? (
                    ''
                  ) : (
                    <span className={classes.splitter}>|</span>
                  )}
                </span>
              ),
          )}
        </div>
      </div>
    </div>
  );
}

RecommendationsLabels.propTypes = {
  /**
   * steps
   */
  steps: PropTypes.array,
  styleWorkOrderSummaryDialog: PropTypes.bool,
};

RecommendationsLabels.defaultProps = {
  styleWorkOrderSummaryDialog: false,
};

export { RecommendationsLabels };
