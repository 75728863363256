/* eslint-disable arrow-body-style */
import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import makeStyles from '@mui/styles/makeStyles';
import { isEmpty } from 'lodash';
import { useTranslation } from 'react-i18next';
// import { SimpleDropdown } from '../SimpleDropdown';
// import { ProgressButton } from '../ProgressButton';
import { Box } from '../thirdParty';
// import { getCurrentTimestamp } from '../dateUtils';
import { ContainerLoader } from '../ContainerLoader';
import { FeedbackSnackBar } from '../FeedbackSnackBar';
import { CustomDialog } from '../CustomDialog';
import { FeedbackRecommendationsTable } from '../FeedbackRecommendationsTable';
import FeedbackModal from '../FeedbackModal';

const useStyles = makeStyles(theme => ({
  root: {
    fontSize: '12px',
    marginBottom: 36,
  },
  heading: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
  },
  row: {
    border: `1px solid ${theme.color.lightBlack1}`,
    padding: '17px',
    margin: '10px',
    fontSize: '13px',
  },
  label: {
    marginRight: '10px',
  },
  select: {
    margin: '10px',
    fontWeight: '500',
  },
  notFound: {
    margin: '10px',
    fontWeight: 'normal',
  },
  addRecommendation: {
    // marginBottom: 30,
    marginTop: 10,
  },
}));

/**
 * Primary UI component for user interaction
 */
export const FeedbackRecommendations = props => {
  const {
    recommendationsHeading,
    recommendations,
    sectionNameListMapping,
    recommendationCategories,
    additionalRecommendations,
    // recommendationCategoriesLoading,
    recommendationCategoriesError,
    resetRecommendationCategoriesError,
    // recommendationTechTypes,
    // recommendationTechTypesLoading,
    recommendationTechTypesError,
    resetRecommendationTechTypesError,
    // recommendationCodes,
    // recommendationCodesLoading,
    recommendationCodesError,
    resetRecommendationCodesError,
    paiUpdateFeedbackRecommendationsLoading,
    paiUpdateFeedbackRecommendationsLoaded,
    paiUpdateFeedbackRecommendationsError,
    resetUpdatePaiFeedbackSectionStatus,
    saveFeedback,
    setFeedbacks,
    // onCategoryChange,
    // onTechTypeChange,
    setFeedbacksError,
    resetFields,
    deletePaiFeedbackSectionEntry,
    // disabled,
    timezone,
    loaderImage,
  } = props;
  const classes = useStyles(props);
  const [selectedCategory, setSelectedCategory] = useState('');
  const [selectedTechType, setSelectedTechType] = useState('');
  const [selectedCode, setSelectedCode] = useState('');
  const [deleteId, setDeleteId] = React.useState(-1);
  const [error, setError] = React.useState(false);

  const { t } = useTranslation();

  // const handleCategoryChange = value => {
  //   setSelectedCategory(value);
  //   setSelectedTechType('');
  //   onCategoryChange(value);
  // };

  // const handleTechTypeChange = value => {
  //   setSelectedTechType(value);
  //   setSelectedCode('');
  //   onTechTypeChange(value);
  // };

  // const handleCodesChange = value => {
  //   setSelectedCode(value);
  // };

  const checkIfCategoryTechTypeCodeExists = () =>
    recommendations.find(
      recomm =>
        recomm?.recommCategory?.id === selectedCategory &&
        recomm?.recommCode?.id === selectedCode &&
        recomm?.recommTechType?.id === selectedTechType,
    );

  const createRecommendationsData = () => {
    /* const reqObj = {};
    const category = recommendationCategories.find(
      cat => cat.recommCategoryCode === selectedCategory,
    );
    const code = recommendationCodes.find(cd => cd.recommCode === selectedTechType);

    reqObj[sectionNameListMapping.recommendations || 'recommendations'] = [
      ...recommendations,
      {
        ts: getCurrentTimestamp(),
        recommCategory: {
          id: category.recommCategoryCode,
          dn: category.recommCategoryDisplay,
        },
        recommCode: { id: code.recommCode, dn: code.recommDesc },
        recommTechType: {},
      },
    ];
    reqObj[sectionNameListMapping.noIssueExists || 'noIssueExists'] = false;
    return reqObj; */

    return [{ recommCode: { id: selectedCode } }];
  };

  const addRecommendation = () => {
    saveFeedback('recommendations', createRecommendationsData());
  };

  const deleteRecommendation = () => {
    /* const reqObj = {};
    const recomm = cloneDeep(recommendations);
    recomm.splice(deleteId, 1);
    reqObj[
      sectionNameListMapping.recommendations || 'recommendations'
    ] = recomm;
    setDeleteId(-1);
    saveFeedback('recommendations', reqObj); */

    deletePaiFeedbackSectionEntry('recommendations', deleteId);
    setDeleteId(-1);
  };

  const feedbacks = () => {
    if (
      !isEmpty(selectedCategory) &&
      !isEmpty(selectedTechType) &&
      !isEmpty(selectedCode)
    ) {
      setFeedbacks(createRecommendationsData());
    } else {
      const reqObj = {};
      reqObj[sectionNameListMapping.recommendations || 'recommendations'] = '';
      setFeedbacks(reqObj);
    }
  };

  useEffect(() => {
    if (!isEmpty(checkIfCategoryTechTypeCodeExists())) {
      setError(true);
      setFeedbacksError(true);
    } else {
      setError(false);
      setFeedbacksError(false);
      feedbacks();
    }
  }, [selectedCategory, selectedTechType, selectedCode, error]);

  const resetRecommendationFields = () => {
    setSelectedCode('');
  };

  useEffect(() => {
    if (resetFields || paiUpdateFeedbackRecommendationsLoaded)
      resetRecommendationFields();
  }, [paiUpdateFeedbackRecommendationsLoaded, resetFields]);

  return (
    <Box className={classes.root}>
      <Box component="div" className={classes.heading}>
        {recommendationsHeading}
        <FeedbackModal
          additionalRecommendations={additionalRecommendations}
          recommendationCategories={recommendationCategories}
          addRecommendation={addRecommendation}
          setSelectedCategory={setSelectedCategory}
          setSelectedTechType={setSelectedTechType}
          setSelectedCode={setSelectedCode}
          error={error}
        />
      </Box>
      <ContainerLoader
        open={paiUpdateFeedbackRecommendationsLoading}
        loaderImage={loaderImage}
      >
        <Box
          display="flex"
          alignItems="center"
          className={classes.addRecommendation}
        ></Box>

        <Box>
          {recommendations.length > 0 ? (
            <Box component="div">
              <FeedbackRecommendationsTable
                recommendations={recommendations}
                setDeleteId={setDeleteId}
                timezone={timezone}
              />
            </Box>
          ) : (
            <Box component="div" className={classes.notFound}>
              {t('No_Additional_repairs_Found')}
            </Box>
          )}
        </Box>
      </ContainerLoader>
      <FeedbackSnackBar
        open={!isEmpty(paiUpdateFeedbackRecommendationsError)}
        message={t('Error_updating_recommendations')}
        handleClose={() =>
          resetUpdatePaiFeedbackSectionStatus('recommendations')
        }
        severity="error"
      />
      <FeedbackSnackBar
        open={paiUpdateFeedbackRecommendationsLoaded}
        message={t('Successfully_updated_recommendations')}
        handleClose={() =>
          resetUpdatePaiFeedbackSectionStatus('recommendations')
        }
        severity="success"
      />
      <FeedbackSnackBar
        open={!isEmpty(recommendationCategoriesError)}
        message={t('Error_occurred_while_loading_categories')}
        handleClose={() => resetRecommendationCategoriesError()}
        severity="error"
      />
      <FeedbackSnackBar
        open={!isEmpty(recommendationCodesError)}
        message={t('Error_occurred_while_loading_codes')}
        handleClose={() => resetRecommendationCodesError()}
        severity="error"
      />
      <FeedbackSnackBar
        open={!isEmpty(recommendationTechTypesError)}
        message={t('Error_occurred_while_loading_tech_types')}
        handleClose={() => resetRecommendationTechTypesError()}
        severity="error"
      />
      <CustomDialog
        open={deleteId >= 0}
        cancelBtnLabel={t('Cancel')}
        dialogDescriptionText={t(
          'Are_you_sure_you_want_to_remove_recommendation_?',
        )}
        dialogTitleText={t('Recommendation')}
        handleAgreeCallback={deleteRecommendation}
        handleCloseCallback={() => setDeleteId(-1)}
        okBtnLabel={t('Ok')}
      />
    </Box>
  );
};

FeedbackRecommendations.propTypes = {
  /**
   * recommendations label
   */
  recommendationsHeading: PropTypes.string,
  /**
   * Mapping of section name with section key
   */
  sectionNameListMapping: PropTypes.object,
  /**
   * list ofrecommendationCategories
   */
  recommendationCategories: PropTypes.array,
  /**
   * list of additionalRecommendations
   */
  additionalRecommendations: PropTypes.array,
  /**
   * show loader if true
   */
  // recommendationCategoriesLoading: PropTypes.bool,
  /**
   * Show error snackbar if error is not empty
   */
  recommendationCategoriesError: PropTypes.object,
  /**
   * Callback handler to reset recommnendation categories error
   */
  resetRecommendationCategoriesError: PropTypes.func,
  /**
   * list of recommendation codes
   */
  // recommendationCodes: PropTypes.array,
  /**
   * show loader if true
   */
  // recommendationCodesLoading: PropTypes.bool,
  /**
   * Show error snackbar if error is not empty
   */
  recommendationCodesError: PropTypes.object,
  /**
   * Callback handler to reset recommnendation codes error
   */
  resetRecommendationCodesError: PropTypes.func,
  /**
   * list of recommendation codes
   */
  // recommendationTechTypes: PropTypes.array,
  /**
   * show loader if true
   */
  // recommendationTechTypesLoading: PropTypes.bool,
  /**
   * Show error snackbar if error is not empty
   */
  recommendationTechTypesError: PropTypes.object,
  /**
   * Callback handler to reset recommnendation codes error
   */
  resetRecommendationTechTypesError: PropTypes.func,
  /**
   * list of added recommendations
   */
  recommendations: PropTypes.array,
  /**
   * category change handler
   */
  // onCategoryChange: PropTypes.func,
  /**
   * techtype change handler
   */
  // onTechTypeChange: PropTypes.func,
  /**
   * Callback function to update feedback
   */
  saveFeedback: PropTypes.func,
  /**
   * true while adding/updating recommendation
   */
  paiUpdateFeedbackRecommendationsLoading: PropTypes.bool,
  /**
   * true when successfully added/updated recommendation
   */
  paiUpdateFeedbackRecommendationsLoaded: PropTypes.bool,
  /**
   * Error object while adding/updating recommendation
   */
  paiUpdateFeedbackRecommendationsError: PropTypes.object,
  /**
   * Callback function to reset success/error status
   */
  resetUpdatePaiFeedbackSectionStatus: PropTypes.func,

  setFeedbacks: PropTypes.func,
  setFeedbacksError: PropTypes.func,
  /**
   * reset code dropdown if true
   */
  resetFields: PropTypes.bool,

  deletePaiFeedbackSectionEntry: PropTypes.func,

  // disabled: PropTypes.bool,

  timezone: PropTypes.string,
  loaderImage: PropTypes.string,
};
