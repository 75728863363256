import React from 'react';
import PropTypes from 'prop-types';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { map, get } from 'lodash';
import {
  makeStyles,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TableSortLabel,
} from '../thirdParty';

import { relativeDate } from '../dateUtils';

const useStyles = makeStyles(theme => ({
  table: {
    border: 'none',
  },
  tableContainerWrapper: {
    maxHeight: props =>
      props?.hideVerticleScroll ? '' : 'calc(100vh - 420px)',
    minHeight: '150px',
  },
  tableHead: props => ({
    fontSize: props.size === 'x-small' ? '10px' : '0.75rem',
    fontWeight: 600,
    lineHeight: props.size === 'x-small' && '1rem',
  }),
  tableFont: props => ({
    wordBreak: 'break-word',
    padding: theme.spacing(0.75, 1.25),
    '&:first-child': {
      color: theme?.color?.blue,
    },
    fontSize: props.size === 'x-small' ? '10px' : '0.75rem',
    fontWeight: 500,
  }),
  row: {
    cursor: 'pointer',
  },
}));

const descendingComparator = (a, b, orderBy) => {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
};

const getComparator = (order, orderBy) =>
  order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);

const stableSort = (array, comparator) => {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  return stabilizedThis.map(el => el[0]);
};

const getCellData = (data, type, locale) => {
  if (!data) {
    return '-';
  }
  return type === 'date' ? relativeDate(data, locale) : data;
};

export const RecentlyViewedTable = ({
  primary,
  dataAlignment,
  tableHeaderData,
  tableBodyData,
  filterText,
  size,
  hideVerticleScroll,
  locale,
}) => {
  const [order, setOrder] = React.useState('desc');
  const [orderBy, setOrderBy] = React.useState('lastViewed');
  const { t } = useTranslation();
  const classes = useStyles({ size, hideVerticleScroll });
  const history = useNavigate();

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const createSortHandler = property => event => {
    handleRequestSort(event, property);
  };

  const dataKey = map(tableHeaderData, 'id');

  const filteredData =
    (filterText &&
      tableBodyData.filter(data =>
        dataKey.some(key => {
          const formattedData =
            key === 'lastViewed' ? relativeDate(data[key], locale) : data[key];
          return (
            formattedData &&
            formattedData
              .toString()
              .toLowerCase()
              .includes(filterText.toLowerCase())
          );
        }),
      )) ||
    tableBodyData;

  return (
    <div>
      <TableContainer
        className={classes.tableContainerWrapper}
        component={Paper}
        elevation={0}
      >
        <Table
          className={classes.table}
          stickyHeader
          size="small"
          aria-label="a dense table"
        >
          <TableHead>
            <TableRow>
              {tableHeaderData &&
                tableHeaderData.length > 0 &&
                tableHeaderData.map(headerData => (
                  <TableCell
                    align={dataAlignment && primary ? dataAlignment : 'left'}
                    key={headerData.id}
                    className={classes.tableHead}
                  >
                    <TableSortLabel
                      active={orderBy === headerData.id}
                      direction={orderBy === headerData.id ? order : 'asc'}
                      onClick={createSortHandler(headerData.id)}
                    >
                      {headerData.label}
                      {orderBy === headerData ? (
                        <span className={classes.visuallyHidden}>
                          {order === 'desc'
                            ? 'sorted descending'
                            : 'sorted ascending'}
                        </span>
                      ) : null}
                    </TableSortLabel>
                  </TableCell>
                ))}
            </TableRow>
          </TableHead>
          <TableBody style={{ wordBreak: 'break-word' }}>
            {filteredData &&
              stableSort(filteredData, getComparator(order, orderBy)).map(
                (tableBodyDataRow, index) => (
                  <TableRow
                    hover
                    key={
                      tableBodyDataRow && tableBodyDataRow.name
                        ? tableBodyDataRow.name + index
                        : index
                    }
                    className={classes.row}
                    onClick={() => history(encodeURI(tableBodyDataRow.url))}
                  >
                    {tableHeaderData.map(headerData => (
                      <TableCell
                        component="td"
                        scope="row"
                        align={
                          dataAlignment && primary ? dataAlignment : 'left'
                        }
                        className={classes.tableFont}
                      >
                        {getCellData(
                          headerData.translate && headerData.type !== 'date'
                            ? t(get(tableBodyDataRow, `[${headerData.id}]`))
                            : get(tableBodyDataRow, `[${headerData.id}]`),
                          headerData.type,
                          locale,
                        )}
                      </TableCell>
                    ))}
                  </TableRow>
                ),
              )}
          </TableBody>
        </Table>
      </TableContainer>
    </div>
  );
};

RecentlyViewedTable.propTypes = {
  /**
   * The type of variant is selected
   */
  primary: PropTypes.bool,
  /**
   * The text alignment within table cell i.e
   */
  dataAlignment: PropTypes.string,
  /**
   * The table header strings in array format
   */
  tableHeaderData: PropTypes.array.isRequired,
  /**
   * The tbale contents in array of object format
   */
  tableBodyData: PropTypes.array.isRequired,
  /**
   * Text to be used to filter data in table
   */
  filterText: PropTypes.string,
  size: PropTypes.oneOf(['large', 'small', 'x-small']),
  hideVerticleScroll: PropTypes.bool,
  locale: PropTypes.string,
};

RecentlyViewedTable.defaultProps = {
  size: 'large',
  hideVerticleScroll: false,
};
