import React from 'react';
import PropTypes from 'prop-types';
import { Box, Grid } from '@mui/material';
import FlareIcon from '@mui/icons-material/Flare';
import WarningIcon from '@mui/icons-material/Warning';
import makeStyles from '@mui/styles/makeStyles';
import Paper from '@mui/material/Paper';
// import StarBorderIcon from '@mui/icons-material/StarBorder';
import BuildIcon from '@mui/icons-material/Build';
import clsx from 'clsx';
import { useTranslation } from 'react-i18next';
import { StatusChip } from '../StatusChip';
import { LabelWithIcon } from '../LabelWithIcon';
import { RecommendationsLabels } from '../RecommendationsLabels';
import { RecommendationsDialog } from '../RecommendationsDialog';
import { ActionableDate } from '../ActionableDate';
import { daysFromNow } from '../dateUtils';
import { Computing } from '../Computing';
import { CustomLink } from '../CustomLink';
import { ResolutionTypeChipWithCount } from '../ResoltutionTypeChipWithCount';
import { ResolutionTypeMultiple } from '../ResolutionTypeMultipleIcon';

const useStyles = makeStyles(theme => ({
  rootpapper: {
    minWidth: 850,
    borderWidth: '0px 0px 1px',
    borderColor: 'rgba(0, 0, 0, 0.3)',
    borderStyle: 'solid',
    boxShadow: '0px 0px 1px 1px rgba(0,0,0,0.2)',
    borderRadius: 9,
  },
  headerText: {
    display: 'flex',
    alignItems: 'center',
    fontSize: '0.875rem',
    paddingBottom: theme.spacing(1),
    fontWeight: 700,
    whiteSpace: 'nowrap',
  },
  bookmarkIcon: {
    // Will move to themes
    color: 'rgb(255, 152, 0)',
  },
  title: {
    fontSize: '0.875rem',
    marginRight: '20px',
  },
  titleAge: {
    fontSize: '0.75rem',
    marginRight: '20px',
  },
  description: {
    color: theme.color.secondary,
    fontSize: '14px',
  },
  disableIcon: {
    cursor: 'default',
    pointerEvents: 'none',
  },
  root: {
    flexGrow: 1,
    padding: theme.spacing(1),
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
    fontSize: '0.75rem',
    color: '#000',
  },
  actionItem: {
    display: 'flex',
    minHeight: 100,
    alignItems: 'center',
    flexDirection: 'column',
    '& > *': {
      marginBottom: theme.spacing(1),
    },
    fontSize: '0.75rem',
    fontWeight: 700,
  },
  actionItemDate: {
    display: 'flex',
    minHeight: 100,
    alignItems: 'flex-end',
    flexDirection: 'column',
    justifyContent: 'space-around',
  },
  stepper: {
    display: 'flex',
    // Stepper was taking full width on init
    maxWidth: 'calc(100% - 176px)',
    flexDirection: 'column',
    fontSize: '0.75rem',
    height: '100%',
    padding: 0,
    margin: 0,
  },
  stepperLabel: {
    display: 'flex',
    margin: '0px 0px 8px 0px',
    alignItems: 'center',
    justifyContent: 'flex-start',
  },
  value: {
    marginTop: theme.spacing(0.25),
    textAlign: 'center',
  },
  days: {
    fontSize: '1rem',
    fontWeight: 500,
  },
  black60: {
    color: theme.color.black60,
    fontWeight: 500,
    fontSize: '0.875rem',
  },
  date: {
    display: 'flex',
    width: 176,
    height: '100%',
    justifyContent: 'center',
  },
  star: {
    padding: 0,
  },
  detailsContainer: {
    display: 'flex',
    width: '100%',
  },
  urgency: {
    height: '19%',
    display: 'flex',
    marginTop: '32px',
    marginBottom: 8,
  },
  urgencyContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    flexBasis: 90,
    maxWidth: 150,
  },
  status: {
    marginLeft: theme.spacing(2.5),
    marginRight: theme.spacing(2.5),
  },
  verticalLine: {
    paddingRight: theme.spacing(1),
    marginRight: theme.spacing(1),
    borderRight: '1px solid rgba(0,0,0,0.3)',
  },
  verticalLineNoMargin: {
    paddingRight: theme.spacing(1),
    borderRight: '1px solid rgba(0,0,0,0.3)',
  },
  width50p: {
    width: '50%',
  },
  impactingConditioning: {
    display: 'flex',
    flexDirection: 'row',
    flexBasis: '50%',
    maxWidth: 300,
    paddingTop: 4,
  },
  icon: {
    fontSize: 18,
    marginLeft: 5,
  },
  recommendationText: {
    marginTop: theme.spacing(0.25),
  },
  computing: {
    textAlign: 'center',
  },
  recommendationAndDateContainer: {
    display: 'flex',
    width: '100%',
    height: '100%',
  },
  resolutionTypeIcon: {
    marginLeft: '14px',
  },
}));

const urgencyValues = Object.freeze({
  URGENT: 'URGENT',
  PLAN: 'PLAN',
  NONE: 'NONE',
  HOLD: 'HOLD',
});

/**
 * Primary UI component for user interaction
 */
export const ActionableListItem = ({
  title,
  // onClickIcon,
  steps,
  openDate,
  urgency,
  condition,
  impacting,
  lastChangedDate,
  ageInDays,
  /* progress, */
  siteuuid,
  assetuuid,
  paiId,
  Bookmark,
  workInProgress,
  redirectURL,
  resolutionTypesCount,
  multipleResolutionType,
  resolutionTypes,
  chipType,
  resolutionTypesCountInDialog,
  textColor,
}) => {
  const classes = useStyles();
  const { t } = useTranslation();

  // const [activeStep, setActiveStep] = React.useState(0);

  /* const getActiveStepFromStepper = step => {
    setActiveStep(step);
  }; */

  // TODO: move to container
  const getActionableText = actionText => {
    if (actionText) {
      return actionText;
    }
    if (!actionText && urgency !== urgencyValues.NONE) {
      return (
        <div className={classes.computing}>
          <Computing />
        </div>
      );
    }
    return 'None';
  };

  const lastChangedDaysAgo = daysFromNow(lastChangedDate);

  return (
    <Paper classes={{ root: classes.rootpapper }} variant="outlined">
      {/* eslint-disable */}
      <div
        className={classes.root}
        /* title={`${
          urgency.toLowerCase() === 'urgent'
            ? t('Needs_immediate_attention')
            : ''
        }
      \n${t('Equipment_Category')}               :  ${title[1]}
      \n${t('Actionable_Urgency_Status')}      :  ${urgency}
      \n${t(
        'Age',
      )}                                          :  ${ageInDays} ${t(
          'Day',
          { count: ageInDays },
        )}
      \n${t(
        'Last_change',
      )}                             :  ${lastChangedDaysAgo} ${t('Day', {
          count: lastChangedDaysAgo,
        })} ${t('Ago')}
      \n\n${t('Impacting_Process')}                   :  ${
          impacting ? impacting : t('None')
        }
      \n${t('Condition')}                                :  ${condition}
      \n${t('Total_recommendation')}            :  ${steps.length}`} */
      >
        <Grid container spacing={1}>
          {/* Header Text */}
          <Grid item xs={12}>
            <Box component="div" className={classes.headerText}>
              {/* <AcUnit /> */}
              <Box component="span" className={classes.title}>
                {/* Site | Actionable | Summary */}
                {redirectURL ? (
                  <CustomLink to={redirectURL} showVisited>
                    {title.join(' | ')}
                  </CustomLink>
                ) : (
                  title.join(' | ')
                )}
              </Box>
              {/* <div className={classes.status}>
                <StatusChip type={progress} />
                </div> */}
              {urgency !== urgencyValues.NONE && (
                <div className={classes.titleAge}>
                  {t('Age')} <span className={classes.days}>{ageInDays}</span>{' '}
                  {t('Day', { count: ageInDays })}
                </div>
              )}
              <div className={classes.titleAge}>
                {t('Last_change')}{' '}
                <span className={classes.days}>{lastChangedDaysAgo}</span>{' '}
                {t('Day', { count: lastChangedDaysAgo })} {t('Ago')}
              </div>
              {/* <IconButton
                onClick={(e) => onClickBookmark(e, assetUUID, piID, progress==='open' ? true : false)}
                classes={{ root: classes.star }}
                title={progress==='open' ? t("Add_to_Bookmark") : t("Remove_from_Bookmark")}
              >
               { progress==='open' ? <BookmarkBorderIcon  Icon className={classes.bookmarkIcon} /> : <BookmarkIcon className={classes.bookmarkIcon} /> }
              </IconButton> */}

              {/*  <IconButton
                onClick={onClickIcon}
                classes={{ root: classes.star }}
              >
                <StarBorderIcon className={classes.bookmarkIcon} />
              </IconButton> */}
              <Bookmark
                siteuuid={siteuuid}
                assetuuid={assetuuid}
                paiId={paiId}
                workInProgress={workInProgress}
              />
            </Box>
          </Grid>
          <div className={classes.detailsContainer}>
            {/* urgency */}
            <div className={classes.urgencyContainer}>
              {urgency && (
                <div className={classes.urgency}>
                  <StatusChip type={urgency.toLowerCase()} />
                </div>
              )}
              <div className={classes.resolutionTypeIcon}>
                <ResolutionTypeMultiple
                  multipleResolutionType={resolutionTypes}
                />
              </div>
            </div>
            {/* impacting and condition */}
            <div className={classes.impactingConditioning}>
              <div
                className={clsx(
                  classes.actionItem,
                  classes.verticalLine,
                  classes.width50p,
                )}
              >
                <div className={classes.black60}>
                  <LabelWithIcon
                    label={t('Impacting')}
                    icon={<WarningIcon classes={{ root: classes.icon }} />}
                  />
                </div>
                <span className={classes.value}>
                  {getActionableText(impacting)}
                </span>
              </div>
              <div
                className={clsx(
                  classes.actionItem,
                  classes.verticalLine,
                  classes.width50p,
                )}
              >
                <div className={classes.black60}>
                  <LabelWithIcon
                    label={t('Condition')}
                    icon={<FlareIcon classes={{ root: classes.icon }} />}
                  />
                </div>
                <div className={classes.value}>
                  {getActionableText(condition)}
                </div>
              </div>
            </div>
            <div className={classes.recommendationAndDateContainer}>
              {/* HOLD -->  N/A date for 'on closed' */}
              {/* Date */}
              <div className={clsx(classes.date, classes.verticalLine)}>
                <table /* style={{ borderSpacing: 0, borderCollapse: 'collapse' }} */
                >
                  <ActionableDate text={t('Opened_on')} date={openDate} />
                  <ActionableDate
                    text={t('Last_change')}
                    date={lastChangedDate}
                  />
                  {urgency === urgencyValues.NONE && (
                    <ActionableDate
                      text={t('Closed_on')}
                      date={lastChangedDate}
                    />
                  )}
                  {urgency === urgencyValues.HOLD && (
                    <ActionableDate text={t('Closed_on')} />
                  )}
                </table>
              </div>
              {/* Recommendations */}
              {urgency !== urgencyValues.NONE && (
                <div className={clsx(classes.stepper)}>
                  <div className={clsx(classes.black60, classes.stepperLabel)}>
                    <LabelWithIcon
                      label={`${t('Service_Recommendation')} (${steps.length})`}
                      icon={<BuildIcon classes={{ root: classes.icon }} />}
                    />
                    <ResolutionTypeChipWithCount
                      resolutionTypesCount={resolutionTypesCount}
                    />
                    {steps.length > 0 && (
                      <RecommendationsDialog
                        steps={steps}
                        resolutionTypes={resolutionTypes}
                        chipType={chipType}
                        resolutionTypesCountInDialog={
                          resolutionTypesCountInDialog
                        }
                        textColor={textColor}
                      />
                    )}
                  </div>
                  {steps.length > 0 && (
                    <RecommendationsLabels
                      steps={steps}
                      multipleResolutionType={multipleResolutionType}
                    />
                  )}
                  {steps.length === 0 && (
                    <div className={classes.recommendationText}>
                      {getActionableText()}
                    </div>
                  )}
                </div>
              )}
            </div>
          </div>
        </Grid>
      </div>
      {/* eslint-enable */}
    </Paper>
  );
};

ActionableListItem.propTypes = {
  /**
   * Header title in an array
   */
  title: PropTypes.array.isRequired,
  /**
   * Optional click handler
   */
  // onClickIcon: PropTypes.func,
  steps: PropTypes.object.isRequired,
  openDate: PropTypes.string.isRequired,
  lastChangedDate: PropTypes.string,
  urgency: PropTypes.string.isRequired,
  condition: PropTypes.string.isRequired,
  impacting: PropTypes.string.isRequired,
  ageInDays: PropTypes.string.isRequired,
  siteuuid: PropTypes.string,
  assetuuid: PropTypes.string,
  paiId: PropTypes.string,
  Bookmark: PropTypes.node,
  /**
   * OnClick of title redirect to this URL
   */
  redirectURL: PropTypes.string,
  /* progress: PropTypes.oneOf(['inprogress', 'closed', 'open']).isRequired, */
  workInProgress: PropTypes.bool,
  /**
   * type is resolutionType either Field Visit or/and Remote Fix
   */
  multipleResolutionType: PropTypes.array,
  /**
   * Resolution type name and its count as array of Object
   */
  resolutionTypesCount: PropTypes.arrayOf(PropTypes.Object),
  /**
   * type is resolutionType either Field Visit or/and Remote Fix
   */
  resolutionTypes: PropTypes.array,
  /**
   * Type of chip to be displayed
   */
  chipType: PropTypes.string,
  /**
   * resolution type count for recommendation details dialog
   */
  resolutionTypesCountInDialog: PropTypes.arrayOf(Object),
  /**
   * color of the text for resolutionType summary
   */
  textColor: PropTypes.string,
};

Date.propTypes = {
  date: PropTypes.string,
};

ActionableListItem.defaultProps = {};
