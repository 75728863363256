import React from 'react';
import PropTypes from 'prop-types';
import {
  makeStyles,
  Card,
  CardContent,
  Typography,
  Box,
  Tooltip,
} from '../thirdParty';

const colorVariations = {
  Bavg: '#f4811d',
  Avg: '#ffe551',
  Good: '#8fc740',
  Null: '#a9a9a9',
  NullN: '#a9a9a9',
};

const useStyles = makeStyles(theme => ({
  root: {
    width: '123px',
    height: '139px',
    marginTop: theme.spacing(2.5),
    marginRight: theme.spacing(1.15),
    marginBottom: theme.spacing(1.15),
    marginLeft: theme.spacing(1.15),
    border: `1px solid ${theme.color.grey1}`,
    borderRadius: 6,
  },
  cardHeader: {
    height: theme.spacing(5.5),
    backgroundColor: ({ headerBackGroundColor }) =>
      colorVariations[headerBackGroundColor] || theme.color.orange,
    display: 'flex',
    alignItems: 'baseline',
    boxShadow: `0 1px 1px 0px ${theme.color.darkGrey}`,
  },
  headerValueStyle: {
    marginTop: theme.spacing(1.125),
    fontSize: '1.25rem',
    fontWeight: 600,
    marginLeft: theme.spacing(1.25),
    alignItems: 'center',
  },
  headerLabelStyle: {
    marginTop: theme.spacing(1.125),
    marginLeft: theme.spacing(1),
    fontSize: '0.75rem',
    fontWeight: 600,
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
    overflow: 'hidden',
  },
  cardContentStyle: {
    paddingTop: theme.spacing(1.375),
    paddingRight: theme.spacing(1.125),
    paddingBottom: theme.spacing(1.375),
    paddingLeft: theme.spacing(1.125),
  },
  description1: {
    fontSize: '.75rem',
    color: theme.color.originalBlack,
    lineHeight: '1em',
    height: '35px',
  },
  description2: {
    paddingTop: theme.spacing(1.75),
    paddingRight: theme.spacing(1.125),
    paddingBottom: theme.spacing(0),
    paddingLeft: theme.spacing(1.125),
  },
  description2Label: {
    fontSize: '.6875rem',
    lineHeight: '1em',
  },
  description2Value: {
    fontSize: '1.25rem',
    fontWeight: 600,
    marginLeft: theme.spacing(0.125),
  },
  description2ValueUom: {
    fontSize: '0.75rem',
    fontWeight: 600,
    marginLeft: theme.spacing(0.125),
  },
  lightTooltip: {
    backgroundColor: theme.palette.common.white,
    color: 'rgba(0, 0, 0, 0.87)',
    boxShadow: theme.shadows[1],
    fontSize: 11,
    marginTop: 0,
  },
  ellipsisDescription: {
    display: '-webkit-box',
    WebkitBoxOrient: 'vertical',
    fontSize: '.6875rem',
    lineHeight: '1em',
    WebkitLineClamp: 3,
    overflow: 'hidden',
  },
}));

export const ProactiveIndices = ({
  headerLabel,
  headerValue,
  headerBackGroundColor,
  description1,
  description2Label,
  description2Value,
  description2ValueUom,
}) => {
  const classes = useStyles({ headerBackGroundColor });
  const totalLenghtOfHeaderValueAndLabel =
    headerValue.length + headerLabel.length;
  return (
    <Card className={classes.root} borderRadius={20}>
      {headerValue.length > 5 && totalLenghtOfHeaderValueAndLabel > 10 ? (
        <Tooltip
          classes={{ tooltip: classes.lightTooltip }}
          title={`${headerValue}  ${headerLabel}`}
          interactive
        >
          <Box className={classes.cardHeader}>
            <Box component="span" className={classes.headerValueStyle}>
              {`${headerValue.substring(0, 4)}..`}
            </Box>
            <Box component="span" className={classes.headerLabelStyle}>
              {headerLabel}
            </Box>
          </Box>
        </Tooltip>
      ) : (
        <Box className={classes.cardHeader}>
          <Box component="span" className={classes.headerValueStyle}>
            {headerValue}
          </Box>
          <Box component="span" className={classes.headerLabelStyle}>
            {headerLabel}
          </Box>
        </Box>
      )}
      <CardContent className={classes.cardContentStyle}>
        {description1.length > 52 ? (
          <Tooltip
            classes={{ tooltip: classes.lightTooltip }}
            title={description1}
            interactive
          >
            <Typography className={classes.ellipsisDescription} component="p">
              {description1}
            </Typography>
          </Tooltip>
        ) : (
          <Typography className={classes.description1} component="p">
            {description1}
          </Typography>
        )}
      </CardContent>
      <Box className={classes.description2}>
        <Typography className={classes.description2Label} component="p">
          {description2Label}
          <Box component="span" className={classes.description2Value}>
            {description2Value}
          </Box>
          <Box component="span" className={classes.description2ValueUom}>
            {description2ValueUom}
          </Box>
        </Typography>
      </Box>
    </Card>
  );
};

ProactiveIndices.propTypes = {
  /**
   * Unit label in header
   */
  headerLabel: PropTypes.string,
  /**
   * Value in header
   */
  headerValue: PropTypes.string,
  /**
   * Backgroud color for header
   */
  headerBackGroundColor: PropTypes.string,
  /**
   * First text in PI card body
   */
  description1: PropTypes.string,
  /**
   * Label in PI card footer description
   */
  description2Label: PropTypes.string,
  /**
   * Value in PI card footer description
   */
  description2Value: PropTypes.string,
  /**
   * Time span in PI card description
   */
  description2ValueUom: PropTypes.string,
};

ProactiveIndices.defaultProps = {};
