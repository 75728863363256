/**
 *
 * FeedbackSnackBar
 *
 */

import React from 'react';
import PropTypes from 'prop-types';

import { Snackbar, Alert, makeStyles } from '../thirdParty';

const BOTTOM_NAV_HEIGHT = 50;

const useStyles = makeStyles(theme => ({
  snackbar: {
    [theme.breakpoints.down('sm')]: {
      bottom: BOTTOM_NAV_HEIGHT + 5,
    },
    '& .MuiSvgIcon-root': {
      color: `${theme.palette.secondary.main} !important`,
    },
    '& .MuiAlert-filledSuccess': {
      background: theme.palette.primary.main,
    },
  },
}));

export const FeedbackSnackBar = ({ open, message, handleClose, severity }) => {
  const classes = useStyles();
  return (
    <Snackbar
      open={open}
      autoHideDuration={10000}
      onClose={handleClose}
      anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
      className={classes.snackbar}
    >
      <Alert variant="filled" onClose={handleClose} severity={severity}>
        {message}
      </Alert>
    </Snackbar>
  );
};

FeedbackSnackBar.propTypes = {
  /**
   * If the error snackbar be open
   */
  open: PropTypes.bool.isRequired,
  /**
   * The error message to be displayed
   */
  message: PropTypes.string,
  /**
   * Callback function called when the close button is clicked
   */
  handleClose: PropTypes.func,
  /**
   * severity determines the color of alert i.e green for success or red for error
   */
  severity: PropTypes.oneOf(['success', 'info', 'warning', 'error']),
};

FeedbackSnackBar.defaultProps = {
  severity: 'success',
};
