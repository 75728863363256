import React from 'react';
import PropTypes from 'prop-types';
import makeStyles from '@mui/styles/makeStyles';

const useStyles = makeStyles(theme => ({
  verifiedIcon: props => ({
    color: theme.palette.primary.main,
    fontSize: props.fontSize,
    marginLeft: 5,
    cursor: 'default',
    verticalAlign: '-4px',
  }),
}));

const VerifiedIcon = ({ title, label, fontSize }) => {
  const classes = useStyles({ fontSize });
  return (
    <span className={`material-icons ${classes.verifiedIcon}`} title={title}>
      {label}
    </span>
  );
};

VerifiedIcon.propTypes = {
  title: PropTypes.string,
  label: PropTypes.string,
  fontSize: PropTypes.number,
};

VerifiedIcon.defaultProps = {
  fontSize: 18,
};

export { VerifiedIcon };
