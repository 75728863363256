import React from 'react';
import PropTypes from 'prop-types';
import {
  makeStyles,
  Card,
  CardActions,
  CardContent,
  Typography,
} from '../thirdParty';
import { SearchIcon, EmojiObjectsOutlinedIcon } from '../icons';

const useStyles = makeStyles(theme => ({
  root: {
    height: '100%',
    '&:hover': {
      boxShadow: `0 3px 10px 2px ${theme.color.darkGrey}`,
      cursor: 'pointer',
    },
    padding: '5px',
  },
  title: {
    fontSize: 14,
  },
  spacing: {
    display: 'flex',
    justifyContent: 'center',
    flexWrap: 'wrap',
    textDecoration: 'none',
    padding: '0px',
  },
  icon: {
    opacity: 1,
    height: '65px',
    width: '65px',
    paddingBottom: '10px',
    color: theme.palette.primary.main,
  },
  cardContentRoot: {
    display: 'flex',
    justifyContent: 'center',
    padding: '0px',
  },
}));

export const NavigationPaper = ({ type, label }) => {
  const classes = useStyles();
  return (
    <Card className={classes.root} variant="outlined" square key={label}>
      <CardContent className={classes.cardContentRoot} key={label}>
        {type === 'summary' ? (
          <EmojiObjectsOutlinedIcon className={classes.icon} />
        ) : (
          <SearchIcon className={classes.icon} />
        )}
      </CardContent>
      <CardActions className={classes.spacing}>
        <Typography noWrap>{label}</Typography>
      </CardActions>
    </Card>
  );
};

NavigationPaper.propTypes = {
  /**
   * type of navigation card
   */
  type: PropTypes.oneOf(['search', 'summary']),

  /**
   * label to be displayed in navigation card
   */
  label: PropTypes.string,
};
