import { initializeApp } from 'firebase/app';
import { getAnalytics, setUserProperties, logEvent } from 'firebase/analytics';
import { firebaseAnalyticsPageIds, getLoggedUserInfo } from './common';
import envConfig from './getEnv';

// Your Firebase config from Firebase Console
let firebaseConfig = '';
if (envConfig.remoteConfigEnv === 'dev') {
  firebaseConfig = {
    apiKey: 'AIzaSyBaSS09_kBE8QP-Ryqt8wh_UaAI2i56aXg',
    authDomain: 'cortix-int.firebaseapp.com',
    projectId: 'cortix-int',
    storageBucket: 'cortix-int.firebasestorage.app',
    messagingSenderId: '598965684061',
    appId: '1:598965684061:web:48de566640ed9bf88ecab4',
    measurementId: 'G-YN7NW0KK2V',
  };
} else {
  firebaseConfig = {
    apiKey: 'AIzaSyBpylIxAdAgkKIYf9XlYd7IBw7nXqQkcMo',
    authDomain: 'cortixandroid.firebaseapp.com',
    databaseURL: 'https://cortixandroid.firebaseio.com',
    projectId: 'cortixandroid',
    storageBucket: 'cortixandroid.firebasestorage.app',
    messagingSenderId: '507162114320',
    appId: '1:507162114320:web:cfe1f28bee0a9631fd4d95',
    measurementId: 'G-SBN9Q7QZXV',
  };
}

// Initialize Firebase
const app = initializeApp(firebaseConfig);

// Initialize Analytics and export it
const analytics = getAnalytics(app);
setUserProperties(analytics, {
  ALLOW_AD_PERSONALIZATION_SIGNALS: 'false',
});

const accessAnalyticsCode = (pathname = '') => {
  const userInfo = getLoggedUserInfo();
  if (pathname) {
    const analyticsPage = Object.keys(firebaseAnalyticsPageIds)?.find(o =>
      pathname?.includes(o),
    );
    const analyticsPageAttributes = analyticsPage
      ? firebaseAnalyticsPageIds[analyticsPage]
      : '';
    if (analyticsPageAttributes?.pageId) {
      logEvent(analytics, analyticsPageAttributes?.pageId, {
        domain: userInfo?.domainName,
        userId: userInfo?.userId,
      });
    }
  } else {
    logEvent(analytics, 'LOGIN_SUCCESS', {
      domain: userInfo?.domainName,
      userId: userInfo?.userId,
    });
  }
};

export { analytics, accessAnalyticsCode };
