import React from 'react';
import PropTypes from 'prop-types';
import makeStyles from '@mui/styles/makeStyles';
// import Badge from '@mui/material/Badge';
import { BenefitIcons } from '../BenefitIcons';
import { benefitTypes } from '../constants';

const useStyles = makeStyles(theme => ({
  root: {
    maxHeight: 60,
    '& > *': {
      margin: '2px 6px 2px 6px',
    },
  },
  badge: {
    backgroundColor: 'black',
    color: theme.color.white,
  },
}));

export const BenefitBadges = ({ benefitsWithCount }) => {
  const classes = useStyles();
  return (
    <div className={classes.root}>
      {benefitsWithCount
        .filter(benefit => benefitTypes[benefit.type.id])
        .map(benefit => (
          /*  <Badge classes={{ badge: classes.badge }} badgeContent={benefit.count}> */
          <span title={benefit.type.dn}>
            <BenefitIcons fontSize={20} benefit={benefit.type.id} />
          </span>
          /* </Badge> */
        ))}
    </div>
  );
};

BenefitBadges.propTypes = {
  /**
   * benefitsWithCount
   */
  benefitsWithCount: PropTypes.array,
};
