export const benefitTypes = Object.freeze({
  CMFRT: 'CMFRT',
  'H&S': 'H&S',
  RFS: 'RFS',
  MAINTC: 'MAINTC',
  MAINTP: 'MAINTP',
  EE: 'EE',
  CONN: 'CONN',
  DCONN: 'DCONN',
  CNTRL_PERF: 'CNTRL_PERF',
});
export const REC_BY_AI = 'AI';

export const workSts = {
  INPROGRESS: 'INPRO',
  COMPLETED: 'COMPLETED',
};

export const DEFAULT_FAVORITE_FOLDER = {
  label: 'My_Favorite',
  value: 'My Favorite',
};

export const parentComp = ['SiteDetails', 'ReportsDialog', 'EquipmentsReport'];

export const divElement = 'divElement';
export const tempConsump = 'tempConsump';
export const liveConnect = 'liveConnect';

export const trendsFavSplitter = '$$';

export const favoriteTypeLabel = {
  ASSET: 'assets',
  SITE: 'sites',
  TREND: 'trends',
  SITE_SUMMARY: 'SITE_SUMMARY',
  TODO_LIST: 'TODO_LIST',
  EQUIPMENT: 'EQUIPMENT',
  PORTFOLIO_TREND: 'PORTFOLIO_TREND',
  PORTFOLIO_TREND_REPORT: 'PORTFOLIO_TREND_REPORT',
  Temperature_and_consumption_view: 'Temperature_and_consumption_view',
  live_connect: 'LIVE_CONNECT',
};
