import React from 'react';
import PropTypes from 'prop-types';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Box from '@mui/material/Box';
import { useTranslation } from 'react-i18next';
import { capitalize } from 'lodash';
import LoadingIndicator from '../LoadingIndicator';
import { trendsFavSplitter, favoriteTypeLabel as type } from '../constants';

function ConfirmDialog({
  open,
  setOpen,
  rowToDelete,
  deleteFavorites,
  favoriteLoading,
  favoriteTransObj,
  loaderImage,
}) {
  const renderNames = row => {
    if (row.folderName) {
      return `${row.folderName} (${t('Folder')})`;
    }
    return formLableforPortfolioTrends(row, favoriteTransObj);
  };
  const formLableforPortfolioTrends = rowData => {
    let lableValue = '';
    switch (rowData?.favorite_page_id) {
      case type.PORTFOLIO_TREND:
        lableValue = `${rowData?.group_id?.split(trendsFavSplitter)[1]} ${
          rowData?.bundle_name
        } ${favoriteTransObj.portFolioTrendReport} (${capitalize(
          rowData?.trend_link?.split(trendsFavSplitter)[2],
        )})`;

        break;
      case type.PORTFOLIO_TREND_REPORT:
        lableValue = `${rowData?.group_id?.split(trendsFavSplitter)[1]} ${
          rowData?.bundle_name
        }
        ${rowData?.combo_code} (${capitalize(
          rowData?.trend_link?.split(trendsFavSplitter)[2],
        )})`;
        break;
      default:
        lableValue = rowData?.name;
    }
    return lableValue;
  };

  const makeSentence = arr => {
    const arrLength = arr?.length;
    return arr?.map((item, idx) => {
      let postText = ', ';
      if (arrLength === idx + 2) {
        postText = ` ${t('and')} `;
      }
      if (arrLength === idx + 1) {
        postText = '?';
      }
      return ` ${renderNames(item)}${postText}`;
    });
  };

  const handleClose = () => {
    setOpen(false);
  };

  const onConfirm = () => {
    if (deleteFavorites) {
      if (rowToDelete?.length > 0) {
        deleteFavorites(rowToDelete);
      }
    }
    setOpen(false);
  };

  const { t } = useTranslation();
  const sentence = makeSentence(rowToDelete);
  return (
    <div>
      {favoriteLoading && (
        <Box>
          <LoadingIndicator loaderImage={loaderImage} />
        </Box>
      )}
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="confirm-dialog-title"
      >
        <DialogTitle style={{ cursor: 'move' }} id="confirm-dialog-title">
          {t('Delete_Favorite')}
        </DialogTitle>
        <DialogContent>
          <DialogContentText>
            {t('Are_you_sure_you_want_to_delete')}
            {sentence}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button autoFocus onClick={handleClose} color="primary">
            {t('Cancel')}
          </Button>
          <Button onClick={onConfirm} color="primary">
            {t('Delete')}
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}

ConfirmDialog.propTypes = {
  open: PropTypes.bool.isRequired,
  setOpen: PropTypes.func.isRequired,
  deleteFavorites: PropTypes.func,
  rowToDelete: PropTypes.array,
  favoriteLoading: PropTypes.bool,
  loaderImage: PropTypes.string,
  favoriteTransObj: PropTypes.object,
};

export default React.memo(ConfirmDialog);
