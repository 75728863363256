/**
 *
 * PaiChipArray
 *
 */

import React from 'react';
import { ThemeProvider, StyledEngineProvider } from '@mui/material/styles';
import makeStyles from '@mui/styles/makeStyles';
import { Chip } from '@mui/material';
import PropTypes from 'prop-types';
import { blueTheme } from '../theme';

const useStyles = makeStyles(theme => ({
  root: {
    height: '25px',
    borderRadius: 12,
    borderWidth: 2,
    marginLeft: theme.spacing(1),
  },
  label: {
    fontSize: '0.75rem',
    fontWeight: 400,
    minWidth: 50,
    padding: theme.spacing(1.5),
  },
}));

function FilterChip({ label, selected, onFilterChipClick, id }) {
  const classes = useStyles();

  return (
    <StyledEngineProvider injectFirst>
      <ThemeProvider theme={blueTheme}>
        <Chip
          clickable
          variant={selected ? 'default' : 'outlined'}
          color="primary"
          key={id}
          label={label}
          classes={{
            root: classes.root,
            label: classes.label,
          }}
          onClick={() => {
            onFilterChipClick(id);
          }}
        />
      </ThemeProvider>
    </StyledEngineProvider>
  );
}

FilterChip.propTypes = {
  /**
   * The filter chips object
   */
  label: PropTypes.object,
  /**
   * Select chips truth array
   */
  selected: PropTypes.object,
  /**
   * To set the filter object
   */
  onFilterChipClick: PropTypes.func,
  /**
   * Id of the type
   */
  id: PropTypes.func,
};

export { FilterChip };
