import React from 'react';
import PropTypes from 'prop-types';
import { NavigationPaper } from '../navigationPaper';
import { RecentlyViewed } from '../recentlyViewed';
import { Favourites } from '../favourites';

export const HomepageContainer = ({
  primary,
  dataAlignment,
  tableHeaderData,
  tableBodyData,
  cardsData,
  isSearchBox,
  isIconEnabled,
  itemsInFavourites,
  iconColor,
  labelColor,
  labelSize,
  labelWeight,
  isNavigationCardIconEnabled,
}) => (
  <div>
    <NavigationPaper
      cardsData={cardsData}
      iconColor={iconColor}
      labelColor={labelColor}
      labelSize={labelSize}
      labelWeight={labelWeight}
      isIconEnabled={isNavigationCardIconEnabled}
    />
    <RecentlyViewed
      primary={primary}
      dataAlignment={dataAlignment}
      tableHeaderData={tableHeaderData}
      tableBodyData={tableBodyData}
      isSearchBox={isSearchBox}
      isIconEnabled={isIconEnabled}
      iconColor={iconColor}
    />
    <Favourites itemsInFavourites={itemsInFavourites} />
  </div>
);

HomepageContainer.propTypes = {
  /**
   * The type of variant is selected
   */
  primary: PropTypes.bool,
  /**
   * The text alignment within table cell
   */
  dataAlignment: PropTypes.string.isRequired,
  /**
   * The table header strings in array format
   */
  tableHeaderData: PropTypes.array.isRequired,
  /**
   * The tbale contents in array of object format
   */
  tableBodyData: PropTypes.array.isRequired,
  /**
   * Cards content for navigation paper
   */
  cardsData: PropTypes.arrayOf(PropTypes.object).isRequired,
  /**
   * Search box for table enable flag
   */
  isSearchBox: PropTypes.bool,
  /**
   * Search icon enable flag
   */
  isIconEnabled: PropTypes.bool,
  /**
   * Items available in Favourites
   */
  itemsInFavourites: PropTypes.array.isRequired,
  /**
   * Navigation card icon enable flag
   */
  isNavigationCardIconEnabled: PropTypes.bool,
  /**
   * Color of the icon
   */
  iconColor: PropTypes.string,
  /**
   * Color of the label
   */
  labelColor: PropTypes.string,
  /**
   * Font size of the label
   */
  labelSize: PropTypes.string,
  /**
   * Font weight of the label
   */
  labelWeight: PropTypes.number,
};

HomepageContainer.defaultProps = {
  primary: true,
  isIconEnabled: true,
  isNavigationCardIconEnabled: true,
  iconColor: '#8FC740',
  labelColor: '#RGBA(0, 0, 0, 0.87)',
  labelSize: '1rem',
  labelWeight: 400,
};
