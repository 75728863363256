import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import makeStyles from '@mui/styles/makeStyles';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import { isEmpty, trim, get } from 'lodash';
import { useTranslation } from 'react-i18next';
import { ProgressButton } from '../ProgressButton';
import { Box, Grid, TextField, TextareaAutosize } from '../thirdParty';
import { ContainerLoader } from '../ContainerLoader';
import { FeedbackSnackBar } from '../FeedbackSnackBar';
import { CustomDialog } from '../CustomDialog';
import { FeedbackPartsTable } from '../FeedbackPartsTable';
import FeedbackPartsModal from '../FeedbackPartsModal';

const useStyles = makeStyles(theme => ({
  root: {
    fontSize: '12px',
    marginBottom: 36,
  },
  row: {
    border: `1px solid ${theme.color.lightBlack1}`,
    padding: '17px',
    margin: '10px',
    fontSize: '13px',
  },
  label: {
    marginRight: '10px',
  },
  select: {
    margin: '10px',
    fontWeight: '500',
  },
  notFound: {
    margin: '10px',
    fontWeight: 'normal',
  },
  addPart: {
    marginBottom: 30,
    marginTop: 10,
  },
  inputText: {
    minWidth: 182,
    '& .MuiInputBase-root::after, & input': {
      borderBottomWidth: `3px`,
      borderBottomStyle: `solid`,
    },
    '& :not(.Mui-error).MuiInputBase-root::after, & :not(.Mui-error) input': {
      borderBottomColor: theme?.color?.blue,
    },
    '& .Mui-error': {
      maxWidth: 180,
    },
    '& .MuiFormHelperText-root': {
      minHeight: 30,
    },
    '& .MuiInput-input.Mui-disabled': {
      borderBottomColor: theme.color.grey,
    },
  },
  partsName: {
    minWidth: 335,
  },
  formControlLabel: {
    '&:last-child': {
      marginRight: '0px',
    },
    '& .MuiTypography-body1': {
      fontSize: '12px',
      fontWeight: '500',
    },
  },
  partsNameLabel: {
    marginRight: 16,
    marginTop: 8,
    whiteSpace: 'nowrap',
  },
  selectMargin: {
    marginRight: '10vw',
  },
  selectSection: {
    margin: '10px',
    fontWeight: '500',
    width: '70%',
  },
  alignCenter: {
    justifyContent: 'center',
  },
  slash: {
    marginLeft: '10px',
    fontWeight: 'normal',
    color: theme.color.red,
  },
  highlight: {
    color: theme.color.red,
  },
  normalFont: {
    fontWeight: 'normal',
  },
  commentWrapper: {
    flexDirection: 'column',
    alignItems: 'start',
    marginTop: theme.spacing(3),
  },
  comment: {
    width: '100%',
    height: '83px !important',
    borderColor: theme.color.darkGrey3,
    backgroundColor: theme.color.darkGrey1,
    marginTop: theme.spacing(1.5),
  },
}));

/**
 * Primary UI component for user interaction
 */
export const FeedbackParts = props => {
  const {
    partsHeading,
    /* sectionNameListMapping, */
    recommendationCategories,
    // recommendationCategoriesLoading,
    recommendationCategoriesError,
    resetRecommendationCategoriesError,
    feedbackParts,
    parts,
    // partsLoading,
    partsError,
    resetPartsError,
    paiUpdateFeedbackPartsLoading,
    paiUpdateFeedbackPartsLoaded,
    paiUpdateFeedbackPartsError,
    resetUpdatePaiFeedbackSectionStatus,
    saveFeedback,
    /* setFeedbacks, */
    // onPartsCategoryChange,
    /* setFeedbacksError, */
    resetFields,
    deletePaiFeedbackSectionEntry,
    timezone,
    loaderImage,
  } = props;
  const classes = useStyles(props);
  const [selectedCategory, setSelectedCategory] = useState('');
  const [selectedPart, setSelectedPart] = useState('');
  const [partName, setPartName] = useState('');
  const [partNumber, setPartNumber] = useState('');
  const [serialNumber, setSerialNumber] = useState('');
  const [deleteId, setDeleteId] = React.useState(-1);
  const [actionValue, setActionValue] = React.useState('REPL');
  const [comment, setComment] = React.useState('');
  const [editId, setEditId] = React.useState(-1);

  const { t } = useTranslation();

  // const handleCategoryChange = value => {
  //   setSelectedCategory(value);
  //   setSelectedPart('');
  //   onPartsCategoryChange(value);
  // };

  // const handlePartsChange = value => {
  //   setSelectedPart(value);
  // };

  const createPartsData = () => {
    const partDetail = parts.find(part => part.id === selectedPart);
    const partObj = {
      ...(editId >= 0 && { id: editId }),
      recommCategory: { id: selectedCategory || 'Othrs' },
      partId: selectedPart || 0,
      partName: get(partDetail, 'partName') || trim(partName),
      partNo: trim(partNumber),
      serialNo: trim(serialNumber),
      comment,
      sts: { id: actionValue },
    };
    return editId >= 0 ? partObj : [partObj];
  };

  const addPart = () => {
    saveFeedback('parts', createPartsData(), editId >= 0 && editId);
  };

  const deletePart = () => {
    deletePaiFeedbackSectionEntry('parts', deleteId);
    setDeleteId(-1);
  };

  const resetPartsFields = () => {
    setSelectedPart('');
    setPartNumber('');
    setSerialNumber('');
    setPartName('');
    setComment('');
    setActionValue('REPL');
    setEditId(-1);
  };

  const editPartFields = obj => {
    setSelectedPart(obj.partId);
    setPartNumber(obj.partNo);
    setSerialNumber(obj.serialNo);
    setPartName(obj.partName);
    setComment(obj.comment || '');
    setActionValue(obj.sts.id || 'REPL');
    setSelectedCategory(obj.recommCategory.id);
    setEditId(obj.id);
    const divElement = document.getElementById('partsWrapper');
    divElement.scrollIntoView({ behavior: 'smooth' });
  };

  const setFeedbackComment = event => {
    setComment(event.target.value);
  };

  const handleActionChange = event => {
    setActionValue(event.target.value);
  };

  useEffect(() => {
    if (resetFields || paiUpdateFeedbackPartsLoaded) resetPartsFields();
  }, [paiUpdateFeedbackPartsLoaded, resetFields]);

  return (
    <Box className={classes.root}>
      <Box component="div" className={classes.heading}>
        {partsHeading}
      </Box>
      <ContainerLoader
        open={paiUpdateFeedbackPartsLoading}
        loaderImage={loaderImage}
      >
        <Box
          display="flex"
          alignItems="center"
          className={classes.addPart}
          id="partsWrapper"
        >
          <Grid container>
            <Grid item xs={12}>
              <Box display="flex">
                <Box
                  display="flex"
                  alignItems="center"
                  className={`${classes.select} ${classes.selectMargin}`}
                >
                  <Box className={classes.label}>{t('Action')}</Box>
                  <FormControl variant="standard" component="fieldset">
                    <RadioGroup
                      aria-label="action"
                      name="action"
                      value={actionValue}
                      onChange={handleActionChange}
                      row
                    >
                      <FormControlLabel
                        value="REPL"
                        control={<Radio color="primary" size="small" />}
                        label={t('Replaced')}
                        className={classes.formControlLabel}
                      />
                      <FormControlLabel
                        value="PLAN"
                        control={<Radio color="primary" size="small" />}
                        label={t('Planned')}
                        className={classes.formControlLabel}
                      />
                    </RadioGroup>
                  </FormControl>
                </Box>
                {/* <Box
                  display="flex"
                  alignItems="center"
                  className={classes.select}
                >
                  <Box className={classes.label}>{t('Category')}</Box>
                  <Box>
                    <SimpleDropdown
                      data={recommendationCategories || []}
                      label={t('Select_one')}
                      labelName="recommCategoryDisplay"
                      selectedValue={selectedCategory}
                      valueName="recommCategoryCode"
                      handleChange={handleCategoryChange}
                      loading={recommendationCategoriesLoading}
                      disabled={!isEmpty(recommendationCategoriesError)}
                    />
                  </Box>
                </Box> */}
                <Box
                  display="flex"
                  alignItems="center"
                  className={classes.select}
                >
                  <Box className={classes.label}>{t('Part_Name')}</Box>
                  <FeedbackPartsModal
                    parts={parts}
                    recommendationCategories={recommendationCategories}
                    setSelectedCategory={setSelectedCategory}
                    setSelectedPart={setSelectedPart}
                    setPartName={setPartName}
                    partName={partName}
                    editId={editId}
                  />
                </Box>
                {/* <Box
                  display="flex"
                  alignItems="center"
                  className={classes.select}
                >
                  <Box className={classes.label}>
                    {t('Parts_Category_Name')}
                  </Box>
                  <Box>
                    <SimpleDropdown
                      data={selectedCategory ? parts : []}
                      label={t('Select_one')}
                      labelName="partName"
                      selectedValue={selectedPart}
                      valueName="id"
                      handleChange={handlePartsChange}
                      loading={partsLoading}
                      disabled={
                        !isEmpty(partsError) ||
                        isEmpty(selectedCategory) ||
                        !isEmpty(trim(partName)) ||
                        isEmpty(parts)
                      }
                    />
                  </Box>
                </Box> */}
                {/* <Box
                  display="flex"
                  alignItems="center"
                  className={classes.select}
                >
                  <Box className={classes.partsNameLabel}>{t('OR')}</Box>
                  <Box component="span">
                    <TextField
                      onChange={event => setPartName(event.target.value)}
                      className={`${classes.inputText} ${classes.partsName}`}
                      disabled={selectedPart}
                      placeholder={t(
                        'Add_new_Parts_Category_name_if_not_in_the_list',
                      )}
                      value={partName}
                    />
                  </Box>
                </Box> */}
              </Box>
            </Grid>
            <Grid item xs={12}>
              <Box display="flex">
                <Box
                  display="flex"
                  alignItems="center"
                  className={`${classes.select} ${classes.selectMargin}`}
                >
                  <Box className={classes.partsNameLabel}>{t('P/N')}</Box>
                  <Box component="span">
                    <TextField
                      onChange={event => setPartNumber(event.target.value)}
                      className={classes.inputText}
                      value={partNumber}
                      variant="standard"
                    />
                  </Box>
                </Box>
                <Box
                  display="flex"
                  alignItems="center"
                  className={classes.select}
                >
                  <Box className={classes.partsNameLabel}>{t('S/N')}</Box>
                  <Box component="span">
                    <TextField
                      onChange={event => setSerialNumber(event.target.value)}
                      className={classes.inputText}
                      value={serialNumber}
                      variant="standard"
                    />
                  </Box>
                  {actionValue === 'PLAN' && serialNumber?.trim() && (
                    <Box
                      className={`${classes.partsNameLabel} ${classes.slash}`}
                    >
                      {t('S/N_is_being_added_in_a_planned_part._Please_check.')}
                    </Box>
                  )}
                </Box>
                {/* <Box
                    display="flex"
                    alignItems="center"
                    className={classes.select}
                  >
                    <Box className={classes.partsNameLabel}>{t('Comment')}</Box>
                    <Box component="span">
                      <TextField
                        onChange={event => setComment(event.target.value)}
                        className={classes.inputText}
                        value={comment}
                      />
                    </Box>
                  </Box> */}
              </Box>
            </Grid>
            <Grid item xs={12}>
              <Box
                display="flex"
                alignItems="center"
                className={`${classes.selectSection} ${classes.commentWrapper}`}
              >
                <Box className={classes.partsNameLabel}>{t('Comments')}</Box>
                <TextareaAutosize
                  className={classes.comment}
                  value={comment}
                  placeholder={t('Write_a_comment')}
                  onChange={setFeedbackComment}
                />
              </Box>
              <Box
                display="flex"
                alignItems="center"
                className={`${classes.selectSection} ${classes.alignCenter}`}
              >
                {editId >= 0 && (
                  <Box className={`${classes.normalFont} ${classes.highlight}`}>
                    {t('Editing_is_inprogress')}
                    <span className={classes.slash}>/</span>
                  </Box>
                )}
                {editId >= 0 && (
                  <ProgressButton
                    onClick={resetPartsFields}
                    buttonText={t('RESET')}
                    loaderImage={loaderImage}
                  />
                )}
                <ProgressButton
                  onClick={addPart}
                  buttonText={editId >= 0 ? t('SAVE') : t('ADD')}
                  disabled={!((selectedCategory && selectedPart) || partName)}
                  loaderImage={loaderImage}
                />
              </Box>
            </Grid>
          </Grid>
        </Box>

        <Box>
          {feedbackParts.length > 0 ? (
            <Box component="div">
              <FeedbackPartsTable
                parts={feedbackParts}
                setDeleteId={setDeleteId}
                timezone={timezone}
                editPartFields={editPartFields}
              />
            </Box>
          ) : (
            <Box component="div" className={classes.notFound}>
              {t('No_Parts_Found')}
            </Box>
          )}
        </Box>
      </ContainerLoader>
      <FeedbackSnackBar
        open={!isEmpty(paiUpdateFeedbackPartsError)}
        message={t('Error updating parts')}
        handleClose={() => resetUpdatePaiFeedbackSectionStatus('parts')}
        severity="error"
      />
      <FeedbackSnackBar
        open={paiUpdateFeedbackPartsLoaded}
        message={t('Successfully_updated_parts')}
        handleClose={() => resetUpdatePaiFeedbackSectionStatus('parts')}
        severity="success"
      />
      <FeedbackSnackBar
        open={!isEmpty(recommendationCategoriesError)}
        message={t('Error_occurred_while_loading_categories')}
        handleClose={() => resetRecommendationCategoriesError('parts')}
        severity="error"
      />
      <FeedbackSnackBar
        open={!isEmpty(partsError)}
        message={t(
          'Parts_category_not_available,_try_adding_parts_category_name_or_error_in_loading_or_adding_parts',
        )}
        handleClose={() => resetPartsError()}
        severity="error"
      />
      <CustomDialog
        open={deleteId >= 0}
        cancelBtnLabel={t('Cancel')}
        dialogDescriptionText={t('Are_you_sure_you_want_to_remove_part_?')}
        dialogTitleText={t('Part')}
        handleAgreeCallback={deletePart}
        handleCloseCallback={() => setDeleteId(-1)}
        okBtnLabel={t('Ok')}
      />
    </Box>
  );
};

FeedbackParts.propTypes = {
  /**
   * parts label
   */
  partsHeading: PropTypes.string,
  /**
   * Mapping of section name with section key
   */
  // sectionNameListMapping: PropTypes.object,
  /**
   * list ofrecommendationCategories
   */
  recommendationCategories: PropTypes.array,
  /**
   * show loader if true
   */
  // recommendationCategoriesLoading: PropTypes.bool,
  /**
   * Show error snackbar if error is not empty
   */
  recommendationCategoriesError: PropTypes.object,
  /**
   * Callback handler to reset recommnendation categories error
   */
  resetRecommendationCategoriesError: PropTypes.func,

  feedbackParts: PropTypes.array,
  /**
   * list of recommendation codes
   */
  parts: PropTypes.array,
  /**
   * show loader if true
   */
  // partsLoading: PropTypes.bool,
  /**
   * Show error snackbar if error is not empty
   */
  partsError: PropTypes.object,
  /**
   * Callback handler to reset recommnendation codes error
   */
  resetPartsError: PropTypes.func,

  // onPartsCategoryChange: PropTypes.func,
  /**
   * Callback function to update feedback
   */
  saveFeedback: PropTypes.func,
  /**
   * true while adding/updating recommendation
   */
  paiUpdateFeedbackPartsLoading: PropTypes.bool,
  /**
   * true when successfully added/updated recommendation
   */
  paiUpdateFeedbackPartsLoaded: PropTypes.bool,
  /**
   * Error object while adding/updating recommendation
   */
  paiUpdateFeedbackPartsError: PropTypes.object,
  /**
   * Callback function to reset success/error status
   */
  resetUpdatePaiFeedbackSectionStatus: PropTypes.func,
  /**
   * reset code dropdown if true
   */
  resetFields: PropTypes.bool,

  deletePaiFeedbackSectionEntry: PropTypes.func,

  timezone: PropTypes.string,
  loaderImage: PropTypes.string,
};
