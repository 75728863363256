/* eslint-disable jsx-a11y/label-has-associated-control */
import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import {
  makeStyles,
  TextField,
  Autocomplete,
  parse,
  match,
} from '../thirdParty';

const useStyles = makeStyles({
  root: {
    '& .MuiAutocomplete-inputRoot': {
      padding: '0px 4px',
    },
    '& .MuiAutocomplete-inputRoot[class*="MuiOutlinedInput-root"] .MuiAutocomplete-input:first-child':
      {
        padding: '3.5px 4px',
      },
    '& .MuiFormControl-marginNormal': {
      marginTop: '5px',
    },
    maxWidth: '150px',
  },
  fieldHeading: {
    fontWeight: 500,
    fontSize: '14px',
    fontFamily: 'G-Roboto-n4, Arial !important',
  },
  itemStyle: {
    marginTop: '10px',
  },
});

export const FilterTextFields = ({
  changeSiteCodeText,
  changeSiteNameText,
  siteNames,
  siteCodes,
}) => {
  const classes = useStyles();
  const { t } = useTranslation();
  return (
    <div className={classes.itemStyle}>
      <div className={classes.fieldHeading}>
        <label>{t('Site_Code')}</label>
      </div>
      <Autocomplete
        className={classes.root}
        options={siteCodes}
        getOptionLabel={option => option.title}
        onInputChange={event => changeSiteCodeText(event)}
        renderInput={params => (
          <TextField {...params} variant="outlined" margin="normal" />
        )}
        renderOption={(option, { inputValue }) => {
          const matches = match(option.title, inputValue);
          const parts = parse(option.title, matches);
          return (
            <div>
              {parts.map(part => (
                <span
                  key={part.highlight}
                  style={{ fontWeight: part.highlight ? 700 : 400 }}
                >
                  {part.text}
                </span>
              ))}
            </div>
          );
        }}
      />

      <div className={classes.fieldHeading}>
        <label>{t('Site_Name')}</label>
      </div>
      <Autocomplete
        className={classes.root}
        options={siteNames}
        getOptionLabel={option => option.title}
        onInputChange={event => changeSiteNameText(event)}
        renderInput={params => (
          <TextField {...params} variant="outlined" margin="normal" />
        )}
        renderOption={(option, { inputValue }) => {
          const matches = match(option.title, inputValue);
          const parts = parse(option.title, matches);
          return (
            <div>
              {parts.map(part => (
                <span
                  key={part.highlight}
                  style={{ fontWeight: part.highlight ? 700 : 400 }}
                >
                  {part.text}
                </span>
              ))}
            </div>
          );
        }}
      />
    </div>
  );
};

FilterTextFields.propTypes = {
  /**
   * Call back function for Site code change
   */
  changeSiteCodeText: PropTypes.func.isRequired,
  /**
   * Call back function for Site name change
   */
  changeSiteNameText: PropTypes.func.isRequired,
  /**
   * The array of object from which auto correct will be populated
   */
  siteNames: PropTypes.array.isRequired,
  /**
   * The array of object from which auto correct will be populated
   */
  siteCodes: PropTypes.array.isRequired,
};
