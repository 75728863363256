import React from 'react';
import PropTypes from 'prop-types';
import makeStyles from '@mui/styles/makeStyles';

const useStyles = makeStyles(theme => ({
  text: {
    fontWeight: 400,
    marginRight: theme.spacing(1),
    whiteSpace: 'nowrap',
    fontSize: '0.6875rem',
  },
  day: {
    fontSize: '0.875rem',
    fontWeight: 500,
  },
  monthYear: {
    fontWeight: 500,
    fontSize: '0.6875rem',
  },
  date: {
    whiteSpace: 'nowrap',
  },
}));

export const ActionableDate = ({ text, date, locale }) => {
  const classes = useStyles();
  const languageLocale = locale || 'en';
  const monthYearFormatter = new Intl.DateTimeFormat(languageLocale, {
    year: 'numeric',
    month: 'short',
  });
  const dayFormatter = new Intl.DateTimeFormat(languageLocale, {
    day: 'numeric',
  });
  return (
    <div>
      <span className={classes.text}>{text}</span>
      {date ? (
        <span>
          <span className={classes.day}>
            {dayFormatter.format(new Date(date))}{' '}
          </span>
          <span className={classes.monthYear}>
            {monthYearFormatter.format(new Date(date))}
          </span>
        </span>
      ) : (
        <div className={classes.date}>
          {/* making the height same */}
          <span className={classes.day}></span>
          <span className={classes.monthYear}>N/A</span>
        </div>
      )}
    </div>
  );
};

ActionableDate.propTypes = {
  text: PropTypes.string,
  date: PropTypes.string,
  locale: PropTypes.string,
};
