import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '../thirdParty';

export const FavouriteItems = ({
  itemsInFavourites,
  textColor,
  hoverBackGroundColor,
  fontSize,
}) => {
  const useStyles = makeStyles(() => ({
    root: {
      marginTop: '10px',
      maxWidth: '110px',
      // fontFamily: 'G-Roboto-n4, Arial'
    },
    favouriteStyle: {
      opacity: 1,
      mixBlendMode: 'normal',
      color: textColor,
      fontFeatureSettings: '"liga" 0',
      fontSize,
      fontStyle: 'normal',
      lineHeight: 'normal',
      padding: '7px',
      textTransform: 'none',
      '&:hover': {
        backgroundColor: hoverBackGroundColor,
        cursor: 'pointer',
        opacity: 1,
        transition: '0.9s',
      },
    },
  }));
  const classes = useStyles();
  return (
    <div className={classes.root}>
      {itemsInFavourites &&
        itemsInFavourites.map(itemInFavourite => (
          <div className={classes.favouriteStyle} key={itemInFavourite}>
            {itemInFavourite}
          </div>
        ))}
    </div>
  );
};

FavouriteItems.propTypes = {
  /**
   * Items available in Favourites
   */
  itemsInFavourites: PropTypes.array.isRequired,
  /**
   * The rgb color code or html color code
   */
  textColor: PropTypes.string,
  /**
   * The rgb color code or html color code
   */
  hoverBackGroundColor: PropTypes.string,
  /**
   * Font size in px
   */
  fontSize: PropTypes.string,
};

FavouriteItems.defaultProps = {
  textColor: 'rgb(140, 198, 63)',
  hoverBackGroundColor: 'rgba(140, 198, 63, 0.3)',
  fontSize: '16px',
};
