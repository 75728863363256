import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { IconButton, Tooltip } from '../thirdParty';

export const MiniSidebar = ({ listArr, onListItemClick }) => {
  const { t } = useTranslation();

  const handleClick = param => {
    onListItemClick(param);
  };

  const renderListTree = list =>
    list.map(nav => (
      <div key={nav.id}>
        {nav.icon && (
          <Tooltip title={t(nav.toolTipMsg) || ''}>
            <IconButton onClick={() => handleClick(nav.id)} size="large">
              {nav.icon}
            </IconButton>
          </Tooltip>
        )}
        {nav.children.length > 0 && renderListTree(nav.children, true)}
      </div>
    ));

  return (
    <div>{listArr && listArr.length > 0 && renderListTree(listArr, false)}</div>
  );
};

MiniSidebar.propTypes = {
  /**
   * List array
   * Object should be a flat list of nodes with at least label, id, children fields
   */
  listArr: PropTypes.array,

  /**
   * Callback function handler on click of a list node
   */
  onListItemClick: PropTypes.func,
};
