import React from 'react';
import { SvgIcon } from '@mui/material';

function DataConnectivity(props) {
  return (
    <SvgIcon
      {...props}
      width="58"
      height="58"
      viewBox="10 17 35 25"
      fill="none"
    >
      <rect width="58" height="58" fill="white" />
      <path
        d="M31.7829 27.7305H14.5554C14.2483 27.7305 14 27.979 14 28.2862V39.1314C14 39.4386 14.2483 39.6871 14.5554 39.6871H21.7901V40.9081H20.828C20.5495 40.9081 20.3243 41.1334 20.3243 41.4121V42.4959C20.3243 42.7747 20.5503 43 20.828 43H25.6224C25.901 43 26.1261 42.7747 26.1261 42.4959V41.4121C26.1261 41.1334 25.901 40.9081 25.6224 40.9081H24.8152V39.6871H31.7829C32.09 39.6871 32.3383 39.4386 32.3383 39.1314V28.2862C32.3383 27.979 32.09 27.7305 31.7829 27.7305ZM23.0076 40.9089V39.688H23.5977V40.9089H23.0076Z"
        fill="#162C73"
      />
      <path
        d="M40.3849 18.6692C39.9968 17.2149 38.6841 16.1382 37.1088 16.1382C36.9833 16.1382 36.8632 16.1623 36.7412 16.1756C35.9233 14.8727 34.4851 14 32.8342 14C30.2817 14 28.2116 16.0705 28.2116 18.6255C28.2116 18.63 28.2124 18.6336 28.2124 18.638C26.3257 18.7084 24.8145 20.2499 24.8145 22.1557C24.8145 24.0615 26.3942 25.6867 28.3433 25.6867H39.9417C41.8898 25.6867 43.4696 24.106 43.4696 22.1557C43.4696 20.3577 42.1212 18.89 40.3849 18.6701V18.6692Z"
        fill="#162C73"
      />
      <path
        d="M38.6496 31.1868C38.7074 31.1868 38.7644 31.1788 38.8196 31.1627C41.2875 30.4654 41.9532 28.0912 41.9604 26.9335C41.963 26.5862 41.6836 26.3039 41.3365 26.3012H41.3329C40.9885 26.3012 40.7081 26.58 40.7055 26.9246C40.7055 26.9486 40.6663 29.2748 38.5624 29.9293L37.0974 29.9097L38.4796 29.061C38.7751 28.8794 38.8668 28.4929 38.6852 28.1981C38.5054 27.9024 38.1174 27.8116 37.8228 27.9906L34.5921 29.973C34.5761 29.9828 34.569 30.0006 34.5538 30.0121C34.5022 30.0504 34.4586 30.095 34.4212 30.1475C34.4034 30.1716 34.3856 30.1929 34.3714 30.2196C34.3367 30.2855 34.3162 30.3586 34.3073 30.4361C34.3055 30.4512 34.2958 30.4628 34.2949 30.4779C34.2949 30.4806 34.2966 30.4832 34.2966 30.4868C34.2966 30.4913 34.294 30.4957 34.294 30.5002C34.2931 30.5937 34.3144 30.681 34.35 30.7611C34.3589 30.7807 34.3732 30.7949 34.3839 30.8136C34.4195 30.8769 34.4631 30.933 34.5182 30.9802C34.528 30.9882 34.5316 31.0016 34.5423 31.0096L38.0213 33.6456C38.1343 33.7311 38.2678 33.773 38.3995 33.773C38.5882 33.773 38.7769 33.6875 38.8997 33.5236C39.1088 33.2475 39.0536 32.8539 38.7786 32.6446L36.8224 31.1627L38.6398 31.1868H38.6487H38.6496Z"
        fill="#162C73"
      />
      <path
        d="M23.0115 21.5973C23.0133 21.5822 23.0231 21.5706 23.024 21.5555C23.024 21.5528 23.0222 21.5502 23.0222 21.5466C23.0222 21.5421 23.0249 21.5377 23.0249 21.5332C23.0258 21.4397 23.0044 21.3524 22.9688 21.2723C22.9599 21.2527 22.9457 21.2385 22.935 21.2198C22.8994 21.1565 22.8558 21.1004 22.8006 21.0532C22.7908 21.0452 22.7873 21.0318 22.7766 21.0238L19.2976 18.3887C19.0244 18.1803 18.6274 18.2319 18.4192 18.5098C18.21 18.7859 18.2652 19.1795 18.5402 19.3888L20.4964 20.8707L18.6791 20.8466C18.623 20.8493 18.558 20.8537 18.5002 20.8707C16.0322 21.5689 15.3665 23.9431 15.3594 25.1008C15.3567 25.4463 15.6353 25.7277 15.9806 25.7313H15.9868C16.3304 25.7313 16.6089 25.4552 16.6143 25.1115C16.6152 25.0144 16.673 22.7524 18.7574 22.1032L20.2223 22.1228L18.8393 22.9724C18.5438 23.154 18.4521 23.5405 18.6337 23.8362C18.7512 24.0295 18.9576 24.1354 19.1677 24.1354C19.2807 24.1354 19.3928 24.1051 19.4961 24.0428L22.7268 22.0595C22.7428 22.0498 22.7508 22.0319 22.765 22.0204C22.8166 21.9821 22.8602 21.9375 22.8976 21.885C22.9154 21.861 22.9332 21.8396 22.9475 21.8129C22.9822 21.747 23.0026 21.6739 23.0115 21.5965V21.5973Z"
        fill="#162C73"
      />
    </SvgIcon>
  );
}
DataConnectivity.displayName = 'DataConnectivity';
DataConnectivity.muiName = 'SvgIcon';

export default DataConnectivity;
