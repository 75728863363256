import React from 'react';
import PropTypes from 'prop-types';
import makeStyles from '@mui/styles/makeStyles';
import { Box } from '../thirdParty';

const useStyles = makeStyles(() => ({
  root: {
    textAlign: 'center',
    margin: '20px 0px',
    fontSize: '32px',
    fontFamily: 'Roboto, Helvetica, Arial, sans-serif',
  },
  logout: {
    textDecoration: 'underline',
    color: '#551a8b !important',
  },
}));

/**
 * Primary UI component for user interaction
 */
export const RestrictedAccess = ({ logout }) => {
  const classes = useStyles();

  return (
    <Box component="h1" className={classes.root}>
      <Box component="div">
        Sorry, you do not have access to this application.
      </Box>
      {/* eslint-disable */}
      { logout && <a href="#" className={classes.logout} onClick={logout}>
        Logout
      </a> }
      {/* eslint-enable */}
    </Box>
  );
};

RestrictedAccess.propTypes = {
  /**
   * Onclick handler
   */
  logout: PropTypes.func,
};
