import React from 'react';
import PropTypes from 'prop-types';

import { makeStyles } from '../thirdParty';

export const RecentlyViewedLabel = ({
  label,
  labelColor,
  labelFontWeight,
  labelfontFeatureSettings,
}) => {
  const useStyles = makeStyles(() => ({
    headingStyle: {
      fontSize: '16px',
      fontWeight: labelFontWeight,
      color: labelColor,
      fontFeatureSettings: labelfontFeatureSettings,
    },
  }));
  const classes = useStyles();
  const recentlyviewedLabel = 'Recently Viewed';

  return (
    <div className={classes.headingStyle}>{label || recentlyviewedLabel}</div>
  );
};

RecentlyViewedLabel.propTypes = {
  /**
   * string for the label
   */
  label: PropTypes.string.isRequired,
  /**
   * Color code for the label
   */
  labelColor: PropTypes.string,
  /**
   *  Font weight for the label
   */
  labelFontWeight: PropTypes.number,
  /**
   *  Font feature settings for the label
   */
  labelfontFeatureSettings: PropTypes.string,
};

RecentlyViewedLabel.defaultProps = {
  labelColor: '#282828',
  labelFontWeight: 400,
  labelfontFeatureSettings: '"liga" 0',
};
