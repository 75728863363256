import React from 'react';
import PropTypes from 'prop-types';
import { Box, Grid } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { PieChart, Pie, Cell } from 'recharts';

import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';

const useStyles = makeStyles({
  wrapper: {
    alignItems: 'center',
    justifyContent: 'center',
  },
  color: {
    width: '10px',
    height: '10px',
    borderRadius: '50%',
    marginRight: '5px',
  },
  labelText: {
    padding: '0px',
    margin: '0px',
  },
  labelValue: {
    padding: '0px',
    margin: '0px',
    marginLeft: '10px',
  },
  label: {
    display: 'flex',
    margin: '0 40px',
  },
});

/**
 * Primary UI component for user interaction
 */
export const CustomPieChart = props => {
  const { data, colors } = props;
  const classes = useStyles(props);
  return (
    <Grid container className={classes.wrapper} direction="row">
      <Grid item>
        <Box component="div">
          <PieChart width={160} height={160}>
            <Pie
              data={data}
              innerRadius={60}
              outerRadius={80}
              fill="#8884d8"
              dataKey="value"
            >
              {data.map((entry, index) => (
                <Cell
                  // eslint-disable-next-line react/no-array-index-key
                  key={`cell-${index}`}
                  fill={colors[index % colors.length]}
                />
              ))}
            </Pie>
          </PieChart>
        </Box>
      </Grid>
      <Grid item>
        <Box component="div" className={classes.label}>
          <List>
            {data.map((entry, index) => (
              <ListItem className={classes.labelText}>
                <Box
                  component="div"
                  className={classes.color}
                  style={{ backgroundColor: colors[index] }}
                />
                <ListItemText primary={`${entry.name}`} />
              </ListItem>
            ))}
          </List>
          <List>
            {data.map(entry => (
              <ListItem className={classes.labelValue}>
                <ListItemText primary={`${entry.value}`} />
              </ListItem>
            ))}
          </List>
        </Box>
      </Grid>
    </Grid>
  );
};

CustomPieChart.propTypes = {
  /**
   * Chart data
   */
  data: PropTypes.array,
  /**
   * Specify list of colors to be used in chart
   */
  colors: PropTypes.array,
};
