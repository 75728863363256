import log from 'loglevel';

export const TextFilter = Object.freeze({
  CONTAINS: 'contains',
  NOT_CONTAINS: 'notContains',
  STARTS_WITH: 'startsWith',
  ENDS_WITH: 'endsWith',
  EQUALS: 'equals',
  NOT_EQUAL: 'notEqual',
});

const lowerCaseFormatter = from => {
  if (from == null) {
    return null;
  }
  return from.toString().toLowerCase();
};

const textComparator = (filterType, value, filterText) => {
  switch (filterType) {
    case TextFilter.CONTAINS:
      return value.indexOf(filterText) >= 0;
    case TextFilter.NOT_CONTAINS:
      return value.indexOf(filterText) === -1;
    case TextFilter.EQUALS:
      return value === filterText;
    case TextFilter.NOT_EQUAL:
      return value !== filterText;
    case TextFilter.STARTS_WITH:
      return value.indexOf(filterText) === 0;
    case TextFilter.ENDS_WITH: {
      const index = value.lastIndexOf(filterText);
      return index >= 0 && index === value.length - filterText.length;
    }
    default:
      // should never happen
      log.warn(`invalid filter type ${filterType}`);
      return false;
  }
};

/**
 * Text Comparator that ignore case
 * @param {string} filterType - Eg: TextFilter.EQUALS
 * @param {string} value - The text to be compared on
 * @param {string} filterText - The filter value
 */
export const icTextComparator = (filterType, value, filterText) =>
  textComparator(
    filterType,
    lowerCaseFormatter(value),
    lowerCaseFormatter(filterText),
  );

/* console.log(ignoreCaseTextComparator(TextFilter.CONTAINS, 'ee', 'EE'));
console.log(textComparator(TextFilter.EQUALS, 'ee', 'dd'));
console.log(lowerCaseFormatter('FFd')); */
