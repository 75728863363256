import React from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import makeStyles from '@mui/styles/makeStyles';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import LoadingIndicator from '../LoadingIndicator';
import { Box } from '../thirdParty';

const useStyles = makeStyles(theme => ({
  wrapper: {
    display: 'flex',
    alignItems: 'center',
  },
  root: {
    fontSize: 'inherit',
    fontWeight: 'inherit',
    '&:before, &:after': {
      borderBottom: `3px solid ${theme?.color?.blue} !important`,
    },
    minWidth: '135px',
  },
  row: {
    border: `1px solid ${theme.color.lightBlack1}`,
    padding: '17px',
    margin: '10px',
    fontSize: '13px',
  },
  label: {
    color: theme.color.secondary,
    marginRight: '10px',
  },
  select: {
    paddingLeft: theme.spacing(1),
  },
  value: {
    fontWeight: 500,
  },
  feedback: {
    '&:not(:first-child)': {
      marginLeft: '58px',
    },
  },
  loader: {
    width: '15px',
  },
}));

/**
 * Primary UI component for user interaction
 */
export const SimpleDropdown = props => {
  const {
    data,
    label,
    selectedValue,
    labelName,
    valueName,
    loading,
    disabled,
    handleChange,
  } = props;
  const classes = useStyles(props);

  return (
    <Box component="div" className={classes.wrapper}>
      <Select
        variant="standard"
        labelId="demo-simple-select-label"
        id="demo-simple-select"
        value={selectedValue}
        displayEmpty
        onChange={e => handleChange(e.target.value)}
        className={classes.root}
        classes={{ select: classes.select }}
        disabled={disabled || loading}
      >
        {label && <MenuItem value="">{label}</MenuItem>}
        {data.map(entry => (
          <MenuItem
            value={_.get(entry, valueName)}
            key={_.get(entry, valueName)}
          >
            {_.get(entry, labelName)}
          </MenuItem>
        ))}
      </Select>
      <Box className={classes.loader}>
        {loading && <LoadingIndicator size={15} />}
      </Box>
    </Box>
  );
};

SimpleDropdown.propTypes = {
  /**
   * Data to be displayed in Dropdown
   */
  data: PropTypes.array,
  /**
   * Dropdown label
   */
  label: PropTypes.string,
  /**
   * selected Dropdown value
   */
  selectedValue: PropTypes.any,
  /**
   * key to be used to display label
   */
  labelName: PropTypes.string,
  /**
   * key to be used to display value
   */
  valueName: PropTypes.string,
  /**
   * show loader when loading
   */
  loading: PropTypes.bool,
  /**
   * disable dropdown if true
   */
  disabled: PropTypes.bool,
  /**
   * To be triggered onchange of dropdown value
   */
  handleChange: PropTypes.func,
};
