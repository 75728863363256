/**
 *
 * No Data Text
 *
 */

import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles, Box } from '../thirdParty';

const useStyles = makeStyles(() => ({
  root: {
    marginLeft: 10,
  },
}));

export const NoDataText = ({ text }) => {
  const classes = useStyles();

  return (
    <Box component="div" className={classes.root}>
      {text}
    </Box>
  );
};

NoDataText.propTypes = {
  text: PropTypes.string,
};
