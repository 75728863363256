import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import makeStyles from '@mui/styles/makeStyles';
import TreeView from '@mui/lab/TreeView';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import { TreeItem } from '../TreeItem';

const useStyles = makeStyles({
  root: {
    overflow: 'auto',
    flexGrow: 1,
    maxWidth: 400,
    height: '100%',
    '&::-webkit-scrollbar': {
      height: 5,
      width: 5,
    },
    '&::-webkit-scrollbar-thumb': {
      backgroundColor: 'darkgrey',
    },
    scrollbarWidth: 'thin',
    paddingRight: 2,
  },
});

const createTreeItemRefs = tree => {
  const tiRef = React.createRef();
  if (tree.children[0]) {
    return Object.assign(
      { [tree.level]: tiRef },
      ...tree.children.map(child => createTreeItemRefs(child)),
    );
  }
  return { [tree.level]: tiRef };
};

function RecursiveTreeView({
  navTree,
  onLinkClick,
  onIconClick,
  selected,
  expandedNodes,
}) {
  const classes = useStyles();
  const treeItemRefs = createTreeItemRefs(navTree[0]);

  useEffect(() => {
    if (treeItemRefs[selected]?.current) {
      treeItemRefs[selected].current.scrollIntoView();
    }
  }, [selected]);

  const renderTree = nodes => (
    <TreeItem
      treeItemRef={treeItemRefs[nodes.level]}
      labelText={nodes.label}
      /* labelIcon={nodes.clickable ? DescriptionIcon : Label} */
      labelInfo=""
      color="#999"
      bgColor="#fff"
      key={nodes.level}
      nodeId={nodes.level}
      onLabelClick={event => {
        event.preventDefault();
        if (nodes.clickable) {
          onLinkClick(nodes);
        }
      }}
      onIconClick={event => {
        event.preventDefault();
        onIconClick(nodes);
      }}
      clickable={nodes.clickable}
      title={nodes.link}
    >
      {Array.isArray(nodes.children)
        ? nodes.children.map(node => renderTree(node))
        : null}
    </TreeItem>
  );

  return (
    <TreeView
      className={classes.root}
      defaultCollapseIcon={<ExpandMoreIcon />}
      defaultExpandIcon={<ChevronRightIcon />}
      expanded={expandedNodes}
      selected={selected}
    >
      {navTree[0] && renderTree(navTree[0])}
    </TreeView>
  );
}

RecursiveTreeView.propTypes = {
  navTree: PropTypes.array,
  onLinkClick: PropTypes.func,
  onIconClick: PropTypes.func,
  selected: PropTypes.bool,
  expandedNodes: PropTypes.array,
};

export { RecursiveTreeView as TreeView };
