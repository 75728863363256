/**
 *
 * ProgressButton
 *
 */

import React from 'react';
import PropTypes from 'prop-types';
import makeStyles from '@mui/styles/makeStyles';
import { Button, Box } from '../thirdParty';
import LoadingIndicator from '../LoadingIndicator';

const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
    alignItems: 'center',
  },
  loading: {
    width: '15px',
  },
  btnText: {
    color: `${theme.color.lightblue1}`,
    // padding: '5px',
    minWidth: '40px',
  },
}));

export const ProgressButton = ({
  inProgress,
  disabled,
  buttonText,
  onClick,
  btnSize,
  loaderImage,
}) => {
  const classes = useStyles();

  return (
    <Box component="div" className={classes.root}>
      <Button
        className={classes.btnText}
        disabled={inProgress || disabled || false}
        onClick={onClick}
        size={btnSize || 'medium'}
      >
        {buttonText}
      </Button>
      <Box component="div" className={classes.loading}>
        {inProgress && <LoadingIndicator size={15} loaderImage={loaderImage} />}
      </Box>
    </Box>
  );
};

ProgressButton.propTypes = {
  /**
   * Show loader with button and disable click if true
   */
  inProgress: PropTypes.bool,
  /**
   * Disable buttton if true
   */
  disabled: PropTypes.bool,
  /**
   * Specify button text
   */
  buttonText: PropTypes.string,
  /**
   * Button Onclick handler
   */
  onClick: PropTypes.func,
  /**
   * Button Size prop. (small, medium, large)
   */
  btnSize: PropTypes.string,
  loaderImage: PropTypes.string,
};
