import React from 'react';
import PropTypes from 'prop-types';
import { Box, Grid } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { CustomLink } from '../CustomLink';
import { MoreDetailsPopover } from '../MoreDetailsPopover';
// import StarBorderIcon from '@mui/icons-material/StarBorder';

const useStyles = makeStyles(theme => ({
  wrapper: {
    flexWrap: 'nowrap',
  },
  iconButton: {
    border: '1px solid',
    transform: 'rotate(45deg)',
    width: '36px',
    height: '36px',
    cursor: 'default',
    pointerEvents: 'none',
  },
  headerText: {
    paddingLeft: '10px',
  },
  bookmarkIcon: {
    color: 'rgb(255, 152, 0)',
  },
  title: {
    fontSize: '16px',
    lineHeight: '1em',
    fontWeight: 500,
    color: theme.color.primary,
  },
  description: {
    color: theme.color.secondary,
    fontSize: '14px',
  },
  lastProcessedDate: {
    marginTop: 4,
    color: theme.color.black60,
    fontSize: 11,
  },
  disableIcon: {
    cursor: 'default',
    pointerEvents: 'none',
  },
  link: {
    fontSize: 11,
    fontWeight: 500,
  },
}));

/**
 * Primary UI component for user interaction
 */
export const PageHeader = ({
  title,
  description,
  descriptionURL,
  lastProcessedDate,
  detailsArray,
}) => {
  const classes = useStyles();
  return (
    <div>
      <Grid container direction="row" className={classes.wrapper}>
        {/* <IconButton className={classes.iconButton}>
          <NavigationIcon />
        </IconButton> */}
        <Box component="div" className={classes.headerText}>
          <Box component="div">
            <Box component="span" className={classes.title}>
              {/* Site | Actionable | Summary */}
              {title.map((titleVal, index) => (
                <Box component="span" key={titleVal}>
                  {' '}
                  {titleVal} {title[index + 1] ? ' > ' : ''}
                </Box>
              ))}
            </Box>
            {/* <IconButton
              onClick={onClickIcon}
              className={!onClickIcon ? classes.disableIcon : ''}
            >
              {' '}
              <StarBorderIcon className={classes.bookmarkIcon} />{' '}
            </IconButton> */}
          </Box>
          <Box component="div" className={classes.description}>
            {descriptionURL ? (
              <CustomLink alwaysUnderline to={descriptionURL}>
                <span className={classes.link}>{description}</span>
              </CustomLink>
            ) : (
              description
            )}
          </Box>
          {lastProcessedDate && (
            <Box component="div" className={classes.lastProcessedDate}>
              <span>{lastProcessedDate.title}</span>
              {': '}
              <span>{lastProcessedDate.value}</span>
              {detailsArray && (
                <MoreDetailsPopover detailsArray={detailsArray} />
              )}
            </Box>
          )}
        </Box>
      </Grid>
    </div>
  );
};

PageHeader.propTypes = {
  /**
   * Header title in an array
   */
  title: PropTypes.array,
  /**
   * Header description
   */
  description: PropTypes.string,
  /**
   * On click of description Link user will be redirected to this url
   */
  descriptionURL: PropTypes.string,
  /**
   * Optional click handler
   */
  // onClickIcon: PropTypes.func,
  /**
   * Last processed date info (Date object)
   */
  lastProcessedDate: PropTypes.instanceOf(Date),
  detailsArray: PropTypes.bool,
};
