import React from 'react';
import PropTypes from 'prop-types';
import makeStyles from '@mui/styles/makeStyles';
import { Link } from '../thirdParty';
import PortfolioSummary from '../SvgIcons/PortfolioSummary';

const useStyles = makeStyles({
  root: {
    display: 'flex',
    alignItems: 'center',
    '& > *': {
      margin: 5,
      // marginRight: 40,
    },
  },
  label: {
    fontSize: '7px',
    textTransform: 'none',
    fontWeight: 700,
  },
  iconWithText: {
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column',
  },
  icon: {
    marginBottom: '4px',
    fontSize: 30,
  },
});

export const ShortCuts = ({ portfolioSummaryUrl, portfolioSummaryText }) => {
  const classes = useStyles();
  return (
    <div className={classes.root}>
      {portfolioSummaryUrl && (
        <Link color="inherit" href={portfolioSummaryUrl} target="_blank">
          <div className={classes.iconWithText}>
            <PortfolioSummary color="primary" className={classes.icon} />
            <div className={classes.label}>{portfolioSummaryText}</div>
          </div>
        </Link>
      )}
    </div>
  );
};

ShortCuts.propTypes = {
  portfolioSummaryUrl: PropTypes.string,
  portfolioSummaryText: PropTypes.string,
};
