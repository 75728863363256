/**
 *
 * Loader container
 *
 */

import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles, Box } from '../thirdParty';
import LoadingIndicator from '../LoadingIndicator';

const useStyles = makeStyles(theme => ({
  root: {
    position: 'relative',
  },
  loader: {
    position: 'absolute',
    alignItems: 'center',
    justifyContent: 'center',
    width: '100%',
    height: '100%',
    backgroundColor: theme.color.lightGrey,
    zIndex: 2,
  },
  loaderContent: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    width: '100%',
    height: '100%',
  },
}));

export const ContainerLoader = ({ children, open, loaderImage }) => {
  const classes = useStyles();

  return (
    <Box component="div" className={classes.root}>
      {open && (
        <Box component="div" className={classes.loader}>
          <Box component="div" className={classes.loaderContent}>
            <LoadingIndicator loaderImage={loaderImage} />
          </Box>
        </Box>
      )}
      <Box component="div">{children}</Box>
    </Box>
  );
};

ContainerLoader.propTypes = {
  /**
   * show loader when true
   */
  open: PropTypes.bool,
  /**
   * show loader over this element
   */
  children: PropTypes.node,
  loaderImage: PropTypes.string,
};
