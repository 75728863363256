import React, { memo, useCallback } from 'react';
import PropTypes from 'prop-types';
import { Calendar } from 'react-multi-date-picker';
import { useTranslation } from 'react-i18next';
import moment from 'moment';
import {
  Dialog,
  DialogContent,
  DialogActions,
  Button,
  makeStyles,
} from '../../../ThirdParty';
import { localeLanguage } from '../../../utils/common';
const useStyles = makeStyles(theme => ({
  root: {
    padding: '0px !important',
  },
  error: {
    padding: '0px 10px',
    fontSize: 12,
    color: theme.color.red,
  },
  disabled: {
    opacity: 0.4,
    cursor: 'not-allowed',
  },
}));

function DatePicker({
  open,
  handleCancelCallback,
  handleOkCallback,
  name,
  fromDate,
  toDate,
  dateSelect,
}) {
  const classes = useStyles();
  const cancelBtn = React.useRef(null);
  const { t } = useTranslation();
  const [dateRange, setDateRange] = React.useState([
    new Date(dateSelect?.start),
    new Date(dateSelect?.end),
  ]);
  const handleCalendarChange = useCallback(dateObject => {
    const formattedDate = JSON.parse(JSON.stringify(dateObject));
    setDateRange([new Date(formattedDate?.[0]), new Date(dateSelect?.end)]);
  }, []);

  const handleOkChange = () => {
    const end = moment(dateRange[1]).isValid() ? dateRange[1] : dateRange[0];
    return handleOkCallback({
      startDate: dateRange[0],
      endDate: end,
    });
  };

  return (
    <Dialog
      open={open}
      onClose={handleCancelCallback}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      onEntering={() => cancelBtn.current.focus()}
      disableBackdropClick
    >
      <DialogContent classes={{ root: classes.root }}>
        <Calendar
          range
          numberOfMonths={1}
          rangeHover
          // onlyMonthPicker={viewBy === monthTrend}
          locale={localeLanguage(t)}
          value={dateRange}
          onChange={dateObject => {
            handleCalendarChange(dateObject);
          }}
          minDate={new Date(fromDate)}
          maxDate={new Date(toDate)}
        />
      </DialogContent>
      <DialogActions>
        <Button
          onClick={handleCancelCallback}
          color="primary"
          ref={cancelBtn}
          autoFocus
        >
          {t('Cancel')}
        </Button>
        <Button
          // onClick={() => !isValidRange && handleOkChange()}\
          onClick={() => handleOkChange()}
          color="primary"
          // className={isValidRange && classes.disabled}
          // className={classes.disabled}
        >
          {name}
        </Button>
      </DialogActions>
    </Dialog>
  );
}

DatePicker.propTypes = {
  open: PropTypes.bool,
  handleCancelCallback: PropTypes.func,
  handleOkCallback: PropTypes.func,
  name: PropTypes.object,
  fromDate: PropTypes.any,
  toDate: PropTypes.any,
  dateSelect: PropTypes.string,
};

export default memo(DatePicker);
