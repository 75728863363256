import React from 'react';
import PropTypes from 'prop-types';
import Chip from '@mui/material/Chip';
import { useTranslation } from 'react-i18next';
import makeStyles from '@mui/styles/makeStyles';

const useStyles = makeStyles(theme => ({
  inprogress: {
    backgroundColor: theme.color.inprogress,
    // border: '1px solid #8c8c8c',
    color: '#000',
    fontWeight: 'bold',
  },
  urgent: {
    backgroundColor: props =>
      props?.applyCss?.urgent
        ? props?.applyCss?.bgColor
        : theme.color.urgentYellow,
    // border: '1px solid #8c8c8c',
    color: theme.color.completeBlack,
    fontWeight: 'bold',
  },
  plan: {
    backgroundColor: theme.color.planYellow,
    // border: '1px solid #8c8c8c',
    color: theme.color.completeBlack,
    fontWeight: 'bold',
  },
  hold: {
    backgroundColor: theme.color.holdGrey,
    // border: '1px solid #8c8c8c',
    color: theme.color.completeBlack,
    fontWeight: 'bold',
  },
  none: {
    backgroundColor: theme.color.resolvedGreen,
    // border: '1px solid #8c8c8c',
    color: theme.color.completeBlack,
    fontWeight: 'bold',
  },
  open: {
    backgroundColor: theme.color.open,
    // border: '1px solid #8c8c8c',
    color: '#000',
    fontWeight: 'bold',
  },
  closed: {
    backgroundColor: theme.color.green,
    // border: `1px solid ${theme.color.green}`,
    color: '#000',
    fontWeight: 'bold',
  },
  confirmed: {
    backgroundColor: theme.color.lightBlueishGreen,
    border: `1px solid ${theme.color.blueishGreen}`,
    color: theme.color.blueishGreen,
    fontWeight: 'bold',
  },
  resolution: {
    color: '#000',
    fontWeight: 'bold',
  },
  root: {
    borderRadius: 2,
    height: 17,
    marginLeft: props =>
      props?.applyCss?.marginLeft ? props.applyCss.marginLeft : '14px',
  },
  label: {
    fontSize: '10px',
  },
}));

const textMapper = {
  inprogress: 'IN_PROGRESS',
  closed: 'CLOSED',
  urgent: 'URGENT',
  open: 'OPEN',
  confirmed: 'CONFIRMED',
  plan: 'PLAN',
  none: 'GOOD',
  hold: 'HOLD',
};

export const StatusChip = ({ size, type, label, applyCss }) => {
  const { t } = useTranslation();
  const classes = useStyles({ applyCss });
  return (
    <Chip
      classes={{ root: classes.root, label: classes.label }}
      className={`${classes[type] ? `${classes[type]}` : ''}`}
      size={size}
      label={label || t(textMapper[type])}
    />
  );
};

StatusChip.propTypes = {
  /**
   * The size of the chip
   */
  size: PropTypes.oneOf(['small', 'medium']),
  /**
   * Specify type of label
   */
  type: PropTypes.oneOf([
    'inprogress',
    'closed',
    'urgent',
    'open',
    'confirmed',
    'plan',
    'none',
    'hold',
  ]),
  label: PropTypes.node,
  applyCss: PropTypes.object,
};

StatusChip.defaultProps = {
  size: 'small',
  applyCss: {},
};
