import React from 'react';
import PropTypes from 'prop-types';

import makeStyles from '@mui/styles/makeStyles';

import Comfort from '../SvgIcons/Comfort';
import Connectivity from '../SvgIcons/Connectivity';
import DataConnectivity from '../SvgIcons/DataConnectivity';
import EnergyEfficiency from '../SvgIcons/EnergyEfficiency';
import ControlsPerformance from '../SvgIcons/ControlsPerformance';
import HealthAndSafety from '../SvgIcons/HealthAndSafety';
import ReduceConsumable from '../SvgIcons/ReduceConsumable';
import RefrigerationFoodSafety from '../SvgIcons/RefrigerationFoodSafety';
import PreventCostlyPartsFailure from '../SvgIcons/PreventCostlyPartsFailure';

import { benefitTypes } from '../constants';

const useStyles = makeStyles({
  root: {
    fontSize: props => props.fontSize,
    height: '0.8em',
  },
});

/**
 * These are just sample icons, will be changed
 */
export const BenefitIcons = ({ benefit, fontSize }) => {
  const classes = useStyles({ fontSize });
  switch (benefit) {
    case benefitTypes.CMFRT: {
      return <Comfort classes={{ root: classes.root }} />;
    }
    case benefitTypes['H&S']: {
      return <HealthAndSafety classes={{ root: classes.root }} />;
    }
    case benefitTypes.RFS: {
      return <RefrigerationFoodSafety classes={{ root: classes.root }} />;
    }
    case benefitTypes.CONN: {
      return <Connectivity classes={{ root: classes.root }} />;
    }
    case benefitTypes.EE: {
      return <EnergyEfficiency classes={{ root: classes.root }} />;
    }
    case benefitTypes.CNTRL_PERF: {
      return <ControlsPerformance classes={{ root: classes.root }} />;
    }
    case benefitTypes.DCONN: {
      return <DataConnectivity classes={{ root: classes.root }} />;
    }
    case benefitTypes.MAINTC: {
      return <ReduceConsumable classes={{ root: classes.root }} />;
    }
    case benefitTypes.MAINTP: {
      return <PreventCostlyPartsFailure classes={{ root: classes.root }} />;
    }
    default: {
      return null;
    }
  }
};

BenefitIcons.propTypes = {
  /**
   * benefit
   */
  benefit: PropTypes.string,
  /**
   * Icon fontsize
   */
  fontSize: PropTypes.number,
};
BenefitIcons.defaultProps = {
  fontSize: 24,
};
