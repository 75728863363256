import React from 'react';
import { SvgIcon } from '@mui/material';

function PortfolioSummary(props) {
  return (
    <SvgIcon {...props}>
      <g>
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          fill="#010101"
          d="M13.707,15.474L13.707,15.474"
        />
        <g>
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            fill="#8DC63F"
            d="M0.931,10.828c0,10.006,0,10.006,0,10.006
			c0,0.949,0.777,1.694,1.694,1.694c18.776,0,18.776,0,18.776,0c0.917,0,1.693-0.745,1.693-1.694c0-8.818,0-10.006,0-10.006H0.931z"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            fill="#8DC63F"
            d="M23.101,11.101c0.352-0.135,0.634-0.539,0.634-0.876
			c0-2.695,0-2.695,0-2.695c0-0.877-0.773-1.618-1.758-1.618l0,0c-19.955,0-19.955,0-19.955,0c-0.983,0-1.756,0.741-1.756,1.618
			c0,2.695,0,2.695,0,2.695c0,0.337,0.28,0.741,0.631,0.876H23.101z"
          />
          <g>
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              fill="#8DC63F"
              d="M16.41,5.024c-1.275,0-1.275,0-1.275,0
				c0-1.474-1.136-2.278-3.121-2.278c-1.986,0-3.121,0.805-3.121,2.278c-1.275,0-1.275,0-1.275,0c0-1.675,1.134-3.551,4.396-3.551
				C15.275,1.473,16.41,3.349,16.41,5.024L16.41,5.024z"
            />
            <polygon
              fillRule="evenodd"
              clipRule="evenodd"
              fill="#FFFFFF"
              points="17.848,10.934 19.021,10.934 16.347,7.077 
				13.673,10.934 14.846,10.934 14.846,20.068 13.715,20.068 13.715,15.225 10.32,15.225 10.32,20.068 9.237,20.068 9.237,17.391 
				6.172,17.391 6.172,20.068 5.65,20.068 5.65,21.169 18.369,21.169 18.369,20.068 17.848,20.068 17.848,10.934 17.848,10.934 			
				"
            />
          </g>
        </g>
      </g>
    </SvgIcon>
  );
}
PortfolioSummary.displayName = 'PortfolioSummary';
PortfolioSummary.muiName = 'SvgIcon';

export default PortfolioSummary;
