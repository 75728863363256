import React from 'react';
import PropTypes from 'prop-types';
import makeStyles from '@mui/styles/makeStyles';

const useStyles = makeStyles(theme => ({
  black60: {
    color: theme.color.black60,
    fontWeight: 500,
    marginRight: theme.spacing(1.5),
    whiteSpace: 'nowrap',
  },
  day: {
    fontSize: '1.25rem',
    fontWeight: 500,
  },
  monthYear: {
    fontWeight: 500,
  },
  date: {
    whiteSpace: 'nowrap',
  },
  tr: {
    height: 24,
  },
}));

export const ActionableDate = ({ text, date }) => {
  const classes = useStyles();
  const monthYearFormatter = new Intl.DateTimeFormat('en', {
    year: 'numeric',
    month: 'short',
  });
  const dayFormatter = new Intl.DateTimeFormat('en', { day: 'numeric' });
  return (
    <tr className={classes.tr}>
      <td>
        <span className={classes.black60}>{text}</span>
      </td>
      <td>
        {date ? (
          <div className={classes.date}>
            <span className={classes.day}>
              {dayFormatter.format(new Date(date))}{' '}
            </span>
            <span className={classes.monthYear}>
              {monthYearFormatter.format(new Date(date))}
            </span>
          </div>
        ) : (
          <div className={classes.date}>
            {/* making the height same */}
            <span className={classes.day}></span>
            <span className={classes.monthYear}>N/A</span>
          </div>
        )}
      </td>
    </tr>
  );
};

ActionableDate.propTypes = {
  text: PropTypes.string,
  date: PropTypes.string,
};
