import React, { useMemo } from 'react';
import makeStyles from '@mui/styles/makeStyles';
import PropTypes from 'prop-types';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import { isEmpty } from 'lodash';
import ListItemText from '@mui/material/ListItemText';
import ToggleButton from '@mui/material/ToggleButton';
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContentText from '@mui/material/DialogContentText';
import { useTranslation } from 'react-i18next';
import Checkbox from '@mui/material/Checkbox';
import { Card, CardContent, Box, Button } from '../thirdParty';
import { AddCircleOutlineOutlinedIcon } from '../icons';
import { Search } from '../Search';
import { ErrorContainer } from '../ErrorContainer';

const useStyles = makeStyles(theme => ({
  root: {
    alignItems: 'center',
    display: 'flex',
    fontWeight: 700,
  },
  titleContainer: {
    paddingBottom: '0px',
    padding: `${theme.spacing(2)} ${theme.spacing(2)} ${theme.spacing(
      0,
    )} ${theme.spacing(2)}`,
  },
  icon: {
    opacity: 1,
    fontSize: '35px',
    color: theme.palette.primary.main,
    marginLeft: '50px',
    cursor: 'pointer',
  },
  addButton: {
    position: 'absolute',
    right: theme.spacing(7),
    color: theme.color.white,
    textTransform: 'none',
  },
  searchContainer: {
    marginLeft: -theme.spacing(1.5),
    marginRight: theme.spacing(1.5),
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
  listTitle: {
    fontSize: '14px',
    fontWeight: 600,
  },
  listContent: {
    marginTop: '2px',
    fontSize: '12px',
  },
  categoryWrapper: {
    flexWrap: 'wrap',
    width: '90%',
  },
  selectedRow: {
    border: `2px solid ${theme.palette.primary.main} !important`,
    padding: `0px ${theme.spacing(1)}`,
    cursor: 'pointer',
  },
  category: {
    marginRight: theme.spacing(1),
    marginBottom: theme.spacing(1),
    background: theme.palette.primary.main,
    color: theme.color.white,
    fontSize: '12px',
    width: 'auto',
    cursor: 'pointer',
    padding: theme.spacing(0.5),
    borderRadius: `${theme.spacing(0.5)} !important`,
    '& .MuiToggleButton-label': {
      textTransform: 'none',
    },
    '&.MuiToggleButton-root:hover': {
      background: theme.palette.primary.main,
      color: theme.color.white,
    },
  },
  label: {
    whiteSpace: 'nowrap',
  },
  cardWrapper: {
    display: 'flex',
    '&.MuiCardContent-root:last-child': {
      paddingBottom: theme.spacing(1),
    },
  },
  contentList: {
    '&.MuiCardContent-root': {
      padding: theme.spacing(0),
    },
  },
  listWrapper: {
    padding: '2px 4px',
    width: '100%',
  },
  listItem: {
    cursor: 'pointer',
    padding: `0px ${theme.spacing(1)}`,
    borderBottom: '1px solid rgba(0, 0, 0, 0.12)',
    '&:last-child': {
      borderBottom: 'none',
    },
  },
  titleWrapper: {
    fontSize: '16px',
    marginBottom: theme.spacing(1),
  },
  checkboxWrapper: {
    display: 'flex',
    alignItems: 'flex-start',
    '& .MuiCheckbox-root': {
      paddingTop: '3px',
    },
  },
  dialogPaper: {
    minWidth: '350px',
    width: '50vw',
    height: '90vh',
  },
  notFound: {
    fontSize: '12px',
    textAlign: 'center',
    marginTop: theme.spacing(2),
    fontWeight: 'normal',
  },
  searchCount: {
    fontSize: '12px',
    fontWeight: 'normal',
    marginTop: theme.spacing(1),
  },
  filterSection: {
    padding: `${theme.spacing(0)} ${theme.spacing(2)}`,
  },
  contentWrapper: {
    padding: `${theme.spacing(1)} ${theme.spacing(2)}`,
  },
}));

function FeedbackModal({
  additionalRecommendations,
  recommendationCategories,
  iconColor,
  isSearchBox,
  isIconEnabled,
  closeIconColor,
  setSelectedCategory,
  setSelectedTechType,
  setSelectedCode,
  addRecommendation,
  error,
}) {
  const classes = useStyles();
  const { t } = useTranslation();
  const [open, setOpen] = React.useState(false);
  const [searchText, setSearchText] = React.useState('');
  const [formats, setFormats] = React.useState([]);
  const [filterRecommendations, setFilterRecommendations] = React.useState([]);
  const [checkAll, setCheckAll] = React.useState(true);
  const [selected, setSelected] = React.useState(0);
  const [errorState, setErrorState] = React.useState(false);

  const handleFormat = (event, newFormats) => {
    setSelected(0);
    setFormats(newFormats);
  };

  const handleSelectRow = obj => {
    if (selected === obj.codeId) setSelected(0);
    else {
      setSelected(obj.codeId);
      setSelectedCategory(obj.categoryCode);
      setSelectedTechType(obj.techTypeCode);
      setSelectedCode(obj.recommCode);
    }
  };

  const handleCheckboxChange = event => {
    setSelected(0);
    setCheckAll(event.target.checked);
    if (event.target.checked) setFormats([]);
    else setFormats(recommendationCategories.map(a => a.recommCategoryCode));
  };

  const handleClose = e => {
    e.stopPropagation();
    e.preventDefault();
    setSelected(0);
    setCheckAll(true);
    setFormats([]);
    setSearchText('');
    setFilterRecommendations([]);
    setOpen(false);
  };

  const handleSearch = e => {
    if (selected > 0) setSelected(0);
    setSearchText(e);
  };

  const onAddClick = e => {
    e.stopPropagation();
    e.preventDefault();
    setOpen(true);
  };

  const getFilteredRecommendations = additionalRecommendations?.filter(
    name =>
      name?.repairType?.toLowerCase().includes(searchText?.toLowerCase()) &&
      !formats.includes(name?.categoryCode),
  );

  const descriptionElementRef = React.useRef(null);
  React.useEffect(() => {
    if (open) {
      const { current: descriptionElement } = descriptionElementRef;
      if (descriptionElement !== null) {
        descriptionElement.focus();
      }
    }
  }, [open]);

  useMemo(() => {
    if (isEmpty(filterRecommendations) && !isEmpty(additionalRecommendations))
      setFilterRecommendations(additionalRecommendations);
  }, [additionalRecommendations]);

  useMemo(() => {
    setErrorState(error);
  }, [error]);

  useMemo(() => {
    setFilterRecommendations(getFilteredRecommendations);
    if (formats?.length === 0) setCheckAll(true);
    else setCheckAll(false);
  }, [formats]);

  useMemo(() => {
    setFilterRecommendations(getFilteredRecommendations);
  }, [searchText]);

  const NotFound = () =>
    recommendationCategories.length === formats.length ? (
      <Box className={classes.notFound}>{t('No_Category_selected')}</Box>
    ) : (
      <Box className={classes.notFound}>{t('No_recommendations_found')}</Box>
    );

  return (
    <Box className={classes.root}>
      <AddCircleOutlineOutlinedIcon
        className={classes.icon}
        onClick={onAddClick}
      />
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="form-dialog-title"
        aria-describedby="scroll-dialog-description"
        classes={{ paper: classes.dialogPaper }}
      >
        <DialogTitle id="form-dialog-title" className={classes.titleContainer}>
          <Box className={classes.titleWrapper}>
            {t('Select_Recommendations')}
            {selected > 0 && !errorState && (
              <Button
                onClick={e => {
                  handleClose(e);
                  addRecommendation();
                }}
                className={classes.addButton}
                size="small"
                variant="contained"
                color="primary"
              >
                {t('Add')}
              </Button>
            )}
            <IconButton
              aria-label="close"
              className={classes.closeButton}
              onClick={handleClose}
              size="large"
            >
              <CloseIcon />
            </IconButton>
          </Box>
        </DialogTitle>
        <Box class={classes.filterSection}>
          {selected > 0 && errorState && (
            <ErrorContainer
              error={errorState}
              errorMessage="Selected_Recommendation_has_been_already_added,_please_select_a_different_recommendation"
              marginLeft="10px"
            ></ErrorContainer>
          )}
          <Box className={classes.searchContainer}>
            <Search
              isSearchBox={isSearchBox}
              isIconEnabled={isIconEnabled}
              iconColor={iconColor}
              closeIconColor={closeIconColor}
              onChange={e => handleSearch(e)}
              isCloseIconEnabled={searchText.length > 0}
              onCloseClick={e => handleSearch(e)}
            />
          </Box>
          {recommendationCategories.length > 0 && (
            <Card variant="outlined">
              <CardContent className={classes.cardWrapper}>
                <ToggleButtonGroup
                  value={formats}
                  onChange={handleFormat}
                  aria-label=""
                  className={classes.categoryWrapper}
                >
                  {recommendationCategories?.map(obj => (
                    <ToggleButton
                      className={classes.category}
                      value={obj.recommCategoryCode}
                      aria-label=""
                    >
                      {obj.recommCategoryDisplay}
                    </ToggleButton>
                  ))}
                </ToggleButtonGroup>
                <Box className={classes.checkboxWrapper}>
                  <Checkbox
                    checked={checkAll}
                    color="primary"
                    onChange={handleCheckboxChange}
                    inputProps={{ 'aria-label': 'controlled' }}
                  />
                  <ListItemText
                    className={classes.label}
                    primary={`${t('All')}`}
                  />
                </Box>
              </CardContent>
            </Card>
          )}

          {!isEmpty(filterRecommendations) ? (
            <Box className={classes.searchCount}>{`${t('Search_from')} ${
              filterRecommendations.length
            } ${t('Recommendations')}`}</Box>
          ) : (
            <NotFound />
          )}
        </Box>
        {!isEmpty(filterRecommendations) && (
          <DialogContent className={classes.contentWrapper}>
            <DialogContentText
              id="scroll-dialog-description"
              ref={descriptionElementRef}
              tabIndex={-1}
            >
              <Card variant="outlined">
                <CardContent
                  className={`${classes.cardWrapper} ${classes.contentList}`}
                >
                  <List className={classes.listWrapper}>
                    {filterRecommendations?.map(obj => (
                      <ListItem
                        className={
                          selected === obj.codeId
                            ? classes.selectedRow
                            : classes.listItem
                        }
                        alignItems="flex-start"
                        key={obj.codeId}
                        onClick={() => handleSelectRow(obj)}
                      >
                        <ListItemText
                          primary={
                            <Box className={classes.listTitle}>
                              {obj.repairType}
                            </Box>
                          }
                          secondary={
                            <Box>
                              <Box className={classes.listContent}>
                                {obj.techType}
                              </Box>
                              <Box className={classes.listContent}>
                                {obj.category}
                              </Box>
                            </Box>
                          }
                        />
                      </ListItem>
                    ))}
                  </List>
                </CardContent>
              </Card>
            </DialogContentText>
          </DialogContent>
        )}
      </Dialog>
    </Box>
  );
}

FeedbackModal.propTypes = {
  /**
   * list of additionalRecommendations
   */
  additionalRecommendations: PropTypes.array,
  /**
   * list of additionalRecommendations
   */
  recommendationCategories: PropTypes.array,
  /**
   * Color of the icon
   */
  iconColor: PropTypes.string,
  /**
   * Color of the close icon
   */
  closeIconColor: PropTypes.string,
  /**
   * Search box for table enable flag
   */
  isSearchBox: PropTypes.bool,
  /**
   * Search icon enable flag
   */
  isIconEnabled: PropTypes.bool,
  /**
   * Callback handler to set selected category
   */
  setSelectedCategory: PropTypes.func,
  /**
   * Callback handler to set selected techType
   */
  setSelectedTechType: PropTypes.func,
  /**
   * Callback handler to set selected Code
   */
  setSelectedCode: PropTypes.func,
  /**
   * Callback handler to save recommendation
   */
  addRecommendation: PropTypes.func,
  /**
   * error handling for existing recommendation
   */
  error: PropTypes.bool,
};

FeedbackModal.defaultProps = {
  iconColor: '#8FC740',
  closeIconColor: '#9e9e9e',
};

export default React.memo(FeedbackModal);
