/**
 *
 * listItem
 *
 */

import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '../thirdParty';

const useStyles = makeStyles(theme => ({
  item: {
    fontSize: '11px',
    borderBottom: `1px solid ${theme.color.almostBlack22}`,
    padding: '10px 8px',
  },
}));

export const ListItem = ({ children }) => {
  const classes = useStyles();
  return <div className={classes.item}>{children}</div>;
};

ListItem.propTypes = {
  /**
   * Add styles to this element/text
   */
  children: PropTypes.any,
};
