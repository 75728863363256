import React from 'react';
import PropTypes from 'prop-types';
import Chip from '@mui/material/Chip';
import makeStyles from '@mui/styles/makeStyles';
import { useTranslation } from 'react-i18next';

const useStyles = makeStyles(theme => ({
  root: {
    backgroundColor: theme.color.white,
    color: theme.color.completeblack,
    borderRadius: 4,
    // border: `1px solid`,
    height: 20,
  },
  label: {
    fontSize: '10px',
    fontWeight: 'bold',
  },
}));

export const StageChip = ({ currentStage, numStages, status }) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const getStageBackground = () => {
    switch (status) {
      case 'URGENT':
        return '#FFC000';
      case 'PLAN':
        return '#FCFC01';
      default:
        return '#ececec';
    }
  };
  return (
    <Chip
      classes={{ root: classes.root, label: classes.label }}
      size="small"
      label={`${t('Stage')} ${currentStage} ${t('of')} ${numStages}`}
      style={{
        backgroundColor: getStageBackground(),
      }}
    />
  );
};

StageChip.propTypes = {
  currentStage: PropTypes.string,
  numStages: PropTypes.string,
  status: PropTypes.string,
};
