import React from 'react';
import _ from 'lodash';
import { useTranslation } from 'react-i18next';
import makeStyles from '@mui/styles/makeStyles';
import Input from '@mui/material/Input';
import MenuItem from '@mui/material/MenuItem';
import ListItemText from '@mui/material/ListItemText';
import Select from '@mui/material/Select';
import Checkbox from '@mui/material/Checkbox';
import PropTypes from 'prop-types';
import { Box } from '../thirdParty';
import LoadingIndicator from '../LoadingIndicator';

const useStyles = makeStyles(theme => ({
  wrapper: {
    display: 'flex',
    alignItems: 'center',
  },
  root: {
    fontSize: 'inherit',
    fontWeight: 'inherit',
    '&:before, &:after': {
      borderBottom: `3px solid ${theme?.color?.blue} !important`,
    },
    minWidth: '135px',
  },
  row: {
    border: `1px solid ${theme.color.lightBlack1}`,
    padding: '17px',
    margin: '10px',
    fontSize: '13px',
  },
  label: {
    color: theme.color.secondary,
    marginRight: '10px',
  },
  select: {
    paddingLeft: theme.spacing(1),
    width: 135,
  },
  value: {
    fontWeight: 500,
  },
  feedback: {
    '&:not(:first-child)': {
      marginLeft: '58px',
    },
  },
  loader: {
    width: '15px',
  },
}));

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      minWidth: 200,
    },
  },
};

export function MultipleSelect({
  data,
  loading,
  selectedValue,
  labelName,
  valueName,
  disabled,
  handleChange,
  selectAll,
  allCount,
}) {
  const classes = useStyles();
  const [dataValue, setDataValue] = React.useState(selectedValue);
  const { t } = useTranslation();
  const handleSelectChange = event => {
    if (event.target.value.includes('All') && checkIfAllItemsAreSelected()) {
      setDataValue([]);
      handleChange([]);
    } else if (
      event.target.value.includes('All') &&
      !checkIfAllItemsAreSelected()
    ) {
      const AllItems = data.map(entry => _.get(entry, valueName));
      setDataValue(AllItems);
      handleChange(AllItems);
    } else {
      setDataValue(event.target.value);
      handleChange(event.target.value);
    }
  };

  React.useEffect(() => {
    setDataValue(selectedValue);
  }, [selectedValue]);

  const checkIfAllItemsAreSelected = () =>
    data.every(entry => dataValue.includes(_.get(entry, valueName)));

  return (
    <div>
      <Box component="div" className={classes.wrapper}>
        <Select
          variant="standard"
          multiple
          value={dataValue}
          displayEmpty
          onChange={handleSelectChange}
          input={<Input />}
          renderValue={selected => selected.map(o => t(o)).join(', ')}
          MenuProps={MenuProps}
          classes={{ select: classes.select }}
          className={classes.root}
          disabled={disabled || loading}
        >
          {selectAll && (
            <MenuItem key="All" value="All">
              <Checkbox
                color="primary"
                checked={
                  checkIfAllItemsAreSelected() || selectedValue.includes('All')
                }
              />
              <ListItemText primary={`${t('All')} (${allCount || ''})`} />
            </MenuItem>
          )}
          {data &&
            data.length > 0 &&
            data.map(entry => (
              <MenuItem
                key={_.get(entry, valueName)}
                value={_.get(entry, valueName)}
              >
                <Checkbox
                  color="primary"
                  checked={
                    dataValue.includes(_.get(entry, valueName)) ||
                    selectedValue.includes('All')
                  }
                />
                <ListItemText primary={`${_.get(entry, labelName)}`} />
              </MenuItem>
            ))}
        </Select>
        <Box className={classes.loader}>
          {loading && <LoadingIndicator size={15} />}
        </Box>
      </Box>
    </div>
  );
}

MultipleSelect.propTypes = {
  /**
   * Option value in the multiple dropdown
   */
  data: PropTypes.oneOfType([PropTypes.array, PropTypes.object]),
  /**
   * show loader when loading
   */
  loading: PropTypes.bool,
  /**
   * disable dropdown if true
   */
  disabled: PropTypes.bool,
  /**
   * Default selected value
   */
  selectedValue: PropTypes.array,
  /**
   * key to be used to display label
   */
  labelName: PropTypes.string,
  /**
   * key to be used to display value
   */
  valueName: PropTypes.string,
  /**
   * To be triggered onchange of dropdown value
   */
  handleChange: PropTypes.func,
  /**
   * Do select all required ?
   */
  selectAll: PropTypes.bool,
  /**
   * Total count of all items
   */
  allCount: PropTypes.number,
};
