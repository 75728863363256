/**
 * LoadingIndicator Component
 */

import React from 'react';
import PropTypes from 'prop-types';

import makeStyles from '@mui/styles/makeStyles';

const useStyles = makeStyles({
  root: {
    display: 'flex',
    '& > * + *': {
      marginLeft: 3,
    },
    justifyContent: 'center',
  },
});

export default function LoadingIndicator({ loaderImage }) {
  const classes = useStyles();
  return (
    <div className={classes.root}>
      {/* eslint-disable-next-line react/jsx-props-no-spreading */}
      <img src={loaderImage} alt="loader" width="30" height="30" />
    </div>
  );
}

LoadingIndicator.propTypes = {
  loaderImage: PropTypes.string,
};
