/* eslint-disable no-unused-vars */
/**
 *
 * Feedback Sources
 *
 */

import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import makeStyles from '@mui/styles/makeStyles';
import withStyles from '@mui/styles/withStyles';
import { isEmpty, cloneDeep, trim } from 'lodash';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import { useTranslation } from 'react-i18next';
import { Box, TextField } from '../thirdParty';
import LoadingIndicator from '../LoadingIndicator';
import { ProgressButton } from '../ProgressButton';
import { FeedbackSnackBar } from '../FeedbackSnackBar';
import { CustomDialog } from '../CustomDialog';
import { getCurrentTimestamp } from '../dateUtils';
import { ContainerLoader } from '../ContainerLoader';
import { FeedbackSourcesTable } from '../FeedbackSourcesTable';

const CustomFormControlLabel = withStyles(theme => ({
  label: {
    fontSize: 'inherit',
    fontWeight: 'inherit',
    textDecoration: `none solid ${theme.color.primary}`,
  },
}))(FormControlLabel);

const CustomRadio = withStyles(theme => ({
  root: {
    size: '10px',
    '& svg': {},
  },
  colorPrimary: {
    color: 'black',
    '&.Mui-checked': {
      color: theme?.color?.blue,
    },
    '& svg': {
      width: '20px',
      height: '20px',
    },
  },
}))(Radio);

const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
    // alignItems: 'center',
  },
  iconButton: {
    padding: 5,
  },
  heading: {
    textDecoration: `none solid ${theme.color.primary}`,
  },
  content: {
    padding: '10px 28px 34px 28px',
  },
  controlLabel: {
    fontSize: '12',
    marginRight: 50,
  },
  addSources: {
    marginBottom: 30,
  },
  sourceId: {
    minWidth: 182,
    '& .MuiInputBase-root::after, & input': {
      borderBottomWidth: `3px`,
      borderBottomStyle: `solid`,
    },
    '& :not(.Mui-error).MuiInputBase-root::after, & :not(.Mui-error) input': {
      borderBottomColor: theme?.color?.blue,
    },
    '& .Mui-error': {
      maxWidth: 180,
    },
    '& .MuiFormHelperText-root': {
      minHeight: 38,
    },
  },
  sourcesIDLabel: {
    marginRight: 12,
    marginTop: 8,
  },
  sourceLabel: {
    marginTop: 10,
    marginRight: 10,
    marginLeft: 20,
  },
}));

export const FeedbackSources = ({
  sourcesHeading,
  sourcesList,
  feedbackSourcesLoading,
  feedbackSourcesLoaded,
  feedbackSourcesError,
  resetFeedbackSourcesError,
  feedbackSources,
  sectionNameListMapping,
  saveFeedback,
  paiUpdateFeedbackSourcesLoading,
  paiUpdateFeedbackSourcesLoaded,
  paiUpdateFeedbackSourcesError,
  resetUpdatePaiFeedbackSectionStatus,
  setServiceNotes,
  resetFields,
  timezone,
  deletePaiFeedbackSectionEntry,
  loaderImage,
}) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const [Id, setId] = React.useState('');
  const [value, setValue] = React.useState('');
  const [deleteId, setDeleteId] = React.useState(-1);
  const [error, setError] = React.useState(false);

  const handleChange = event => {
    setValue(event.target.value);
  };

  const createSourcesData = () => {
    /* const reqObj = {};
    const src = sourcesList.find(source => source.id === value);
    reqObj[sectionNameListMapping.sources || 'sources'] = [
      ...feedbackSources,
      { ts: getCurrentTimestamp(), src, srcId: Id },
    ];
    return reqObj; */
    const src = sourcesList.find(source => source.id === value);
    return [{ src, srcId: Id }];
  };

  const addSource = () => {
    saveFeedback('sources', createSourcesData());
  };

  const deleteSources = () => {
    /* const reqObj = {};
    const sources = cloneDeep(feedbackSources);
    sources.splice(DeleteId, 1);
    reqObj[sectionNameListMapping.sources || 'sources'] = sources;
    setDeleteId(-1);
    saveFeedback('sources', reqObj); */
    deletePaiFeedbackSectionEntry('sources', deleteId);
    setDeleteId(-1);
  };

  const serviceNotes = () => {
    if (!isEmpty(value)) {
      setServiceNotes(createSourcesData());
    } else {
      const reqObj = {};
      reqObj[sectionNameListMapping.sources || 'sources'] = [];
      setServiceNotes(reqObj);
    }
  };

  React.useEffect(() => {
    serviceNotes();
  }, [value]);

  const clearSource = () => {
    setValue('');
    setId('');
  };

  /* const checkIfIdExists = sourceId =>
    !isEmpty(
      feedbackSources.find(
        source => source.srcId && trim(source.srcId) === trim(sourceId),
      ),
    ); */

  const setSourceId = event => {
    setId(event.target.value);
    /* if (checkIfIdExists(event.target.value)) {
      setError(true);
    } else {
      setError(false);
    } */
  };

  useEffect(() => {
    if (paiUpdateFeedbackSourcesLoaded || resetFields) clearSource();
  }, [paiUpdateFeedbackSourcesLoaded, resetFields]);

  return (
    <Box component="div">
      <Box component="div" className={classes.heading}>
        {sourcesHeading}
      </Box>
      <ContainerLoader
        open={paiUpdateFeedbackSourcesLoading}
        loaderImage={loaderImage}
      >
        <Box component="div" className={classes.content}>
          <Box component="div" className={classes.addSources}>
            {feedbackSourcesLoading && (
              <LoadingIndicator loaderImage={loaderImage} />
            )}
            {feedbackSourcesLoaded && !isEmpty(sourcesList) && (
              <Box component="div" className={classes.root}>
                <Box component="span" className={classes.sourcesIDLabel}>
                  {t('ID')}
                </Box>
                <Box component="span">
                  <TextField
                    onChange={setSourceId}
                    className={classes.sourceId}
                    value={Id}
                    error={error}
                    /* eslint-disable */
                    helperText={
                      error
                        ? t(
                            'Source Id already exists, please use a different Id',
                          )
                        : ' '
                    }
                    variant="standard"
                    /* eslint-enable */
                  />
                </Box>
                <Box component="span" className={classes.sourceLabel}>
                  {t('Source')}
                </Box>
                <RadioGroup
                  row
                  aria-label="sources"
                  name="sources"
                  value={value}
                  onChange={handleChange}
                >
                  {sourcesList.map(source => (
                    <CustomFormControlLabel
                      value={source.id}
                      control={<CustomRadio color="primary" />}
                      label={source.dn}
                      className={classes.controlLabel}
                    />
                  ))}
                </RadioGroup>
                <Box component="span">
                  <ProgressButton
                    // inProgress={paiUpdateFeedbackSourcesLoading}
                    onClick={addSource}
                    buttonText={t('ADD')}
                    disabled={isEmpty(value)}
                  />
                </Box>
              </Box>
            )}
          </Box>
          <Box component="div" className={classes.sources}>
            <FeedbackSourcesTable
              feedbackSources={feedbackSources}
              setDeleteId={setDeleteId}
              timezone={timezone}
            />
          </Box>
        </Box>
      </ContainerLoader>
      <FeedbackSnackBar
        open={!isEmpty(paiUpdateFeedbackSourcesError)}
        message={t('Error_updating_reason_for_use')}
        handleClose={() => resetUpdatePaiFeedbackSectionStatus('sources')}
        severity="error"
      />

      <FeedbackSnackBar
        open={paiUpdateFeedbackSourcesLoaded}
        message={t('Successfully_updated_reason_for_use')}
        handleClose={() => resetUpdatePaiFeedbackSectionStatus('sources')}
        severity="success"
      />

      <FeedbackSnackBar
        open={!isEmpty(feedbackSourcesError)}
        message={t('Error_loading_list_of_sources')}
        handleClose={resetFeedbackSourcesError}
        severity="error"
      />

      <CustomDialog
        open={deleteId >= 0}
        cancelBtnLabel={t('Cancel')}
        dialogDescriptionText={t(
          'Are_you_sure_you_want_to_remove_reason_for_use_?',
        )}
        dialogTitleText={t('Reason_for_use')}
        handleAgreeCallback={deleteSources}
        handleCloseCallback={() => setDeleteId(-1)}
        okBtnLabel={t('Ok')}
      />
    </Box>
  );
};

FeedbackSources.propTypes = {
  /**
   * List of available sources
   */
  sourcesList: PropTypes.array,
  /**
   * List of User's added feedback sources
   */
  feedbackSources: PropTypes.array,
  /**
   * Sources heading label
   */
  sourcesHeading: PropTypes.string,
  /**
   * true when sources list loading
   */
  feedbackSourcesLoading: PropTypes.bool,
  /**
   * true when sources list loaded
   */
  feedbackSourcesLoaded: PropTypes.bool,
  /**
   * Error object while loading list of feedback sources
   */
  feedbackSourcesError: PropTypes.object,
  /**
   * Callback function to reset feedback sources error
   */
  resetFeedbackSourcesError: PropTypes.func,
  /**
   * Mapping of section name with section key
   */
  sectionNameListMapping: PropTypes.object,
  /**
   * Callback function to update feedback
   */
  saveFeedback: PropTypes.func,
  /**
   * true while updating/adding sources
   */
  paiUpdateFeedbackSourcesLoading: PropTypes.bool,
  /**
   * true when successfully added/updated sources
   */
  paiUpdateFeedbackSourcesLoaded: PropTypes.bool,
  /**
   * Error object while adding/updating sources
   */
  paiUpdateFeedbackSourcesError: PropTypes.object,
  /**
   * Callback function to reset success/error status
   */
  resetUpdatePaiFeedbackSectionStatus: PropTypes.func,
  /**
   * Callback function to set service notes data
   */
  setServiceNotes: PropTypes.func,
  /**
   * reset source field if true
   */
  resetFields: PropTypes.bool,
  /**
   * show date with tinezone provided
   */
  timezone: PropTypes.string,
  loaderImage: PropTypes.string,

  deletePaiFeedbackSectionEntry: PropTypes.func,
};
