import React from 'react';
import PropTypes from 'prop-types';

import { makeStyles, MenuItem, FormControl, Select } from '../thirdParty';

export const BreadCrumbDropDown = ({
  dorpdownValue,
  maximumWidth,
  borderBottomColor,
  borderBottomThickness,
  dropDownFontSize,
  dropDownFontWeight,
  dropDownPadding,
}) => {
  const useStyles = makeStyles(theme => ({
    formControl: {
      margin: theme.spacing(1),
      maxWidth: maximumWidth,
      borderBottom: `${borderBottomThickness} solid ${borderBottomColor}`,
    },
    selectEmpty: {
      fontSize: dropDownFontSize,
      fontWeight: dropDownFontWeight,
    },
    select: {
      padding: dropDownPadding,
    },
  }));

  const classes = useStyles();
  const [value, setValue] = React.useState('');

  const handleChange = event => {
    setValue(event.target.value);
  };

  return (
    <div>
      <FormControl className={classes.formControl}>
        <Select
          value={value || dorpdownValue[0]}
          onChange={handleChange}
          displayEmpty
          className={classes.selectEmpty}
          inputProps={{ 'aria-label': 'Without label' }}
          classes={{ select: classes.select }}
        >
          {dorpdownValue.map((itemsInDropDown, index) => (
            <MenuItem
              value={itemsInDropDown}
              // eslint-disable-next-line react/no-array-index-key
              key={itemsInDropDown + index}
              dense
            >
              {itemsInDropDown}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </div>
  );
};

BreadCrumbDropDown.propTypes = {
  /**
   * The values in dropdown
   */
  dorpdownValue: PropTypes.array.isRequired,
  /**
   * Maximum width of the dropdown
   */
  maximumWidth: PropTypes.number,
  /**
   * Bottom border color of the dropdown
   */
  borderBottomColor: PropTypes.string,
  /**
   * Bottom border thickness of the dropdown
   */
  borderBottomThickness: PropTypes.string,
  /**
   * Font size of the dropdown
   */
  dropDownFontSize: PropTypes.string,
  /**
   * Font weight of the dropdown
   */
  dropDownFontWeight: PropTypes.number,
  /**
   * padding of the dropdown
   */
  dropDownPadding: PropTypes.string,
};

BreadCrumbDropDown.defaultProps = {
  maximumWidth: 99,
  borderBottomColor: '#0078d4',
  borderBottomThickness: '2px',
  dropDownFontSize: '0.885rem',
  dropDownFontWeight: 550,
  dropDownPadding: 0,
};
