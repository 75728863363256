import React from 'react';
import PropTypes from 'prop-types';
import makeStyles from '@mui/styles/makeStyles';
import { isEmpty, get } from 'lodash';
import { useTranslation } from 'react-i18next';
import { Box, Grid, EditIcon } from '../thirdParty';
import { IconButtonWithLoader } from '../IconButtonWithLoader';
import { ListItem } from '../listItem';
import { DeleteIconButton } from '../DeleteIconButton';
import { NoDataText } from '../NoDataText';
import { convertToDtMnthYr } from '../dateUtils';

const useStyles = makeStyles(theme => ({
  listItemWrapper: {
    maxWidth: '100%',
  },
  listItem: {
    alignItems: 'center',
  },
  gridItem: {
    flexBasis: '12%',
    padding: '0px 12px',
    wordBreak: 'break-word',
  },
  gridItemWidth: {
    flexBasis: '15%',
  },
  gridItemLabel: {
    marginRight: 10,
    color: theme.color.originalBlack,
    opacity: 0.7,
  },
}));

/**
 * Primary UI component for user interaction
 */
export const FeedbackPartsTable = props => {
  const { parts, setDeleteId, noDataText, timezone, editPartFields } = props;
  const { t } = useTranslation();
  const classes = useStyles(props);
  return !isEmpty(parts) ? (
    <div>
      <Box
        component="div"
        className={`${classes.listItemWrapper} ${classes.listItemHeading}`}
      >
        <ListItem>
          <Grid container className={classes.listItem}>
            <Grid
              item
              className={`${classes.gridItem} ${classes.gridItemWidth}`}
            ></Grid>
            <Grid item className={classes.gridItem}>
              {t('Category')}
            </Grid>
            <Grid
              item
              className={`${classes.gridItem} ${classes.gridItemWidth}`}
            >
              {t('Parts_Category_Name')}
            </Grid>
            <Grid item className={classes.gridItem}>
              {t('P/N')}
            </Grid>
            <Grid item className={classes.gridItem}>
              {t('S/N')}
            </Grid>
            <Grid item className={classes.gridItem}>
              {t('Action')}
            </Grid>
            <Grid
              item
              className={`${classes.gridItem} ${classes.gridItemWidth}`}
            >
              {t('Comments')}
            </Grid>
          </Grid>
        </ListItem>
      </Box>
      {parts.map(part => (
        <Box component="div" className={classes.listItemWrapper}>
          <ListItem>
            <Grid container className={classes.listItem}>
              <Grid
                item
                className={`${classes.gridItem} ${classes.gridItemWidth}`}
              >
                {convertToDtMnthYr(part.ts, timezone)}
              </Grid>
              <Grid item className={classes.gridItem}>
                <Grid container wrap="nowrap">
                  <Grid item>{get(part, 'recommCategory.dn')}</Grid>
                </Grid>
              </Grid>
              <Grid
                item
                className={`${classes.gridItem} ${classes.gridItemWidth}`}
              >
                <Grid container wrap="nowrap">
                  <Grid item>{get(part, 'partName')}</Grid>
                </Grid>
              </Grid>
              <Grid item className={classes.gridItem}>
                <Grid container wrap="nowrap">
                  <Grid item>{get(part, 'partNo')}</Grid>
                </Grid>
              </Grid>
              <Grid item className={classes.gridItem}>
                <Grid container wrap="nowrap">
                  <Grid item>{get(part, 'serialNo')}</Grid>
                </Grid>
              </Grid>
              <Grid item className={classes.gridItem}>
                <Grid container wrap="nowrap">
                  <Grid item>{get(part, 'sts.dn')}</Grid>
                </Grid>
              </Grid>
              <Grid
                item
                className={`${classes.gridItem} ${classes.gridItemWidth}`}
              >
                <Grid container wrap="nowrap">
                  <Grid item>{get(part, 'comment')}</Grid>
                </Grid>
              </Grid>
              {get(part, 'sts.id') === 'PLAN' && (
                <Grid item>
                  <IconButtonWithLoader
                    UnMarkedIcon={EditIcon}
                    buttonPadding={6}
                    buttonSize="18px"
                    iconColor="rgba(33, 33, 33, 0.8)"
                    onClickHandler={() => editPartFields(part)}
                    unMarkedTitle={t('Edit')}
                  />
                </Grid>
              )}
              {setDeleteId && (
                <Grid item>
                  <DeleteIconButton
                    onClickHandler={() => setDeleteId(part.id)}
                  />
                </Grid>
              )}
            </Grid>
          </ListItem>
        </Box>
      ))}
    </div>
  ) : (
    <NoDataText text={noDataText}></NoDataText>
  );
};

FeedbackPartsTable.propTypes = {
  /**
   * list of added parts
   */
  parts: PropTypes.array,
  setDeleteId: PropTypes.bool,
  noDataText: PropTypes.string,
  timezone: PropTypes.string,
  editPartFields: PropTypes.func,
};
