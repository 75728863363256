import React from 'react';
import { StatusChip } from '../StatusChip';

export const recommendationFeedbackStatus = Object.freeze({
  confirm: 'CONFIRM',
  none: 'NONE',
});

export const getConfirmedStatusChip = feedback => (
  <span>
    {feedback && feedback.status === recommendationFeedbackStatus.confirm ? (
      <StatusChip type="confirmed" size="small" />
    ) : null}
  </span>
);
