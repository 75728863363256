/**
 *
 * PaiSelectableFilterChips
 *
 */

import React from 'react';
import makeStyles from '@mui/styles/makeStyles';
import PropTypes from 'prop-types';
import _ from 'lodash';
import { useTranslation } from 'react-i18next';

import { PaiChipArray } from '../PaiChipArray';

const useStyles = makeStyles({
  array: props => ({
    '& > *': {
      margin: 2,
    },
    display: 'flex',
    flexWrap: props.expanded ? 'wrap' : 'nowrap',
  }),
  expandedChipTitle: {
    margin: '0 4px 2px',
  },
});

function PaiSelectableFilterChips({ filter, chips, setFilter, expanded }) {
  const classes = useStyles({ expanded });
  const { t } = useTranslation();
  const onChipClick = key => (chip, isSelected) => {
    const spread = filter[key] || [];
    const newArray = isSelected
      ? filter[key].filter(o => o !== chip.value)
      : [...spread, chip.value];
    const newFilter = { ...filter, [key]: newArray };
    setFilter(newFilter);
  };

  return _.map(_.omit(chips, 'ageInDays'), (value, key) => {
    const filteredList = value.filter(o => o.value !== 'All');
    const selectedTruthArray = filteredList.map(
      item => filter[key]?.find(o => o === item.value) !== undefined,
    );
    const keyTranslationMap = {
      workInProgress: 'Bookmarked',
      condition: 'Condition',
      benefits: 'Benefits',
      urgency: 'Urgency',
      haveRecommendations: 'Recommendation',
      resolutionType: 'Resolution_type',
      assetCategory: 'Equipment_Category',
      activities: 'Activities',
      workOrder: 'Work_order',
    };
    return (
      <div>
        {expanded && (
          <div className={classes.expandedChipTitle}>
            {t(keyTranslationMap[key]) || t(key)}
          </div>
        )}
        <div className={classes.array}>
          <PaiChipArray
            list={filteredList}
            selected={selectedTruthArray}
            isHidden={o => o.value === 'All'}
            onChipClick={onChipClick(key)}
            category={key}
          />
        </div>
      </div>
    );
  });
}

PaiSelectableFilterChips.propTypes = {
  /**
   * The filter chips object
   */
  filter: PropTypes.object,
  /**
   * List of items for chips
   */
  chips: PropTypes.object,
  /**
   * To set the filter object
   */
  setFilter: PropTypes.func,
  /**
   * If the filter is expanded or collapsed
   */
  expanded: PropTypes.bool,
};

PaiSelectableFilterChips.defaultProps = {
  expanded: false,
};

export { PaiSelectableFilterChips };
