import React from 'react';
import { SvgIcon } from '@mui/material';

function HealthAndSafety(props) {
  return (
    <SvgIcon
      {...props}
      width="58"
      height="58"
      viewBox="10 17 35 25"
      fill="none"
    >
      <rect width="58" height="58" fill="white" />
      <path
        d="M29 13C29 13 24.4219 17.8108 17 18.6123V34.1123C17 34.1123 17 40.9002 29 44C40.9991 40.9002 41 34.1123 41 34.1123V18.6123C33.5781 17.8108 29 13 29 13ZM36.2121 31.7941H31.2752V36.6648H26.6438V31.7941H21.7069V27.2247H26.6438V22.3539H31.2752V27.2247H36.2121V31.7941Z"
        fill="#162C73"
      />
    </SvgIcon>
  );
}
HealthAndSafety.displayName = 'HealthAndSafety';
HealthAndSafety.muiName = 'SvgIcon';

export default HealthAndSafety;
