/* eslint-disable indent */
/* eslint-disable no-nested-ternary */
import React, { useState, useMemo } from 'react';
import PropTypes from 'prop-types';
import makeStyles from '@mui/styles/makeStyles';
import { Grid, Box, ExpandMoreIcon, ExpandLessIcon } from '../thirdParty';
import { ResolutionTypeChipWithCount } from '../ResoltutionTypeChipWithCount';

const useStyles = makeStyles(theme => ({
  labelWrapper: {
    cursor: 'pointer',
  },
  label: props => ({
    fontSize:
      props.size === 'large'
        ? '16px'
        : props.size === 'small'
        ? '13px'
        : '12px',
    fontWeight:
      props.size === 'large' ? 400 : props.size === 'small' ? 900 : 700,
    fontColor: theme.color.black,
    opacity: props.size === 'x-small' ? 1 : 0.9,
  }),
  labelIcon: props => ({
    backgroundColor: theme.color.darkGrey1,
    marginRight: '5px',
    width: props.size === 'large' ? 22 : 16,
    height: props.size === 'large' ? 22 : 16,
  }),
  displayNone: {
    display: 'none',
  },
  open: props => ({
    marginLeft: props.size === 'large' ? 29 : 20,
    width: '100%',
  }),
  labelIconWrapper: props => ({
    height: props.size === 'large' ? 'auto' : 16,
  }),
  subLabel: props => ({
    fontSize: props.size === 'large' ? '16px' : '13px',
    fontWeight: props.size === 'large' ? 400 : 900,
    fontColor: theme.color.black,
    opacity: 0.9,
    marginLeft: 10,
  }),
}));

/**
 * Primary UI component for user interaction
 */
export const ExpandableContainer = props => {
  const { label, subLabel, expanded, children, size, resolutionTypesCount } =
    props;
  const classes = useStyles({ size });
  const [open, setOpen] = useState(expanded);
  useMemo(() => {
    if (props?.forceUpdate) setOpen(expanded);
  }, [expanded]);

  return (
    <Grid container spacing={1}>
      <Grid
        container
        item
        alignItems="center"
        onClick={() => {
          if (props.onExpandClick) {
            props.onExpandClick();
          }
          setOpen(!open);
        }}
        className={classes.labelWrapper}
      >
        <Box component="span" className={classes.labelIconWrapper}>
          {open ? (
            <ExpandLessIcon className={classes.labelIcon} />
          ) : (
            <ExpandMoreIcon className={classes.labelIcon} />
          )}
        </Box>
        <Box component="span" className={classes.label}>
          {label}
        </Box>
        <ResolutionTypeChipWithCount
          resolutionTypesCount={resolutionTypesCount}
        />
        <Box component="span" className={classes.subLabel}>
          {subLabel}
        </Box>
      </Grid>
      {open && (
        <Grid className={classes.open} container item>
          {children}
        </Grid>
      )}
    </Grid>
  );
};

ExpandableContainer.propTypes = {
  /**
   * Label to be diaplayed
   */
  label: PropTypes.string,
  /**
   * subLabel to be displayed
   */
  subLabel: PropTypes.string,
  /**
   * Content to be shown when expanded
   */
  children: PropTypes.node,
  /**
   * Expand container when true
   */
  expanded: PropTypes.bool,
  forceUpdate: PropTypes.bool,
  /**
   * size of the icon and text
   */
  size: PropTypes.oneOf(['large', 'small', 'x-small']),
  /**
   * Function that will be executed when the icon is clicked
   */
  onExpandClick: PropTypes.func,
  /**
   * Resolution type name and its count as array of Object
   */
  resolutionTypesCount: PropTypes.arrayOf(PropTypes.Object),
};

ExpandableContainer.defaultProps = {
  size: 'large',
};
