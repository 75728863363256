import React from 'react';
import PropTypes from 'prop-types';
import makeStyles from '@mui/styles/makeStyles';
import { useTranslation } from 'react-i18next';

const useStyles = makeStyles(theme => ({
  resolutionTypeCount: {
    fontSize: '0.875rem',
    fontWeight: 800,
    color: ({ textColor }) => `${theme.color[textColor]}`,
  },
}));

export const ResolutionTypeTotalCountInDialog = ({
  resolutionTypesCountInDialog,
  textColor,
}) => {
  const classes = useStyles({ textColor });
  const { t } = useTranslation();
  return (
    <div className={classes.resolutionTypeCount}>
      {resolutionTypesCountInDialog &&
        resolutionTypesCountInDialog.map((resolutionTypeObject, index) => (
          <span>
            {t(resolutionTypeObject.resolutionType)} (
            {resolutionTypeObject.count}){' '}
            {index < resolutionTypesCountInDialog.length - 1 && '| '}
          </span>
        ))}
    </div>
  );
};

ResolutionTypeTotalCountInDialog.propTypes = {
  /**
   * Resolution type name and its count as array of Object
   */
  resolutionTypesCountInDialog: PropTypes.arrayOf(PropTypes.Object),
  /**
   * text color for resolution type
   */
  textColor: PropTypes.string,
};
