/* eslint-disable no-param-reassign */
import React, { useEffect } from 'react';
import {
  orderBy,
  get,
  isEmpty,
  isNil,
  forOwn,
  flatten,
  chain,
  isArray,
  sortBy,
  uniqBy,
} from 'lodash';
import { enc, SHA256 } from 'crypto-js';
import { BenefitIcons } from '../views/react-common-components';
import getUomSymbol, {
  convertUnitBasedOnSourceAndTargetUnitFamily,
} from '../views/containers/ParameterLive/uomUtils';
import { getDateWithoutTime } from './dateUtils';
import { withStyles, Checkbox, Radio } from '../ThirdParty';
import { getUserId, getUserEmail, getLoggedUserEmailId } from '../auth';
import {
  RECENTLY_VIEWED_MAX_DAYS,
  DELIMITER,
  sectionNameListMapping,
  ppRegexFilter,
  benefitTypesPosMapping,
  CONFIRM_TEXT,
  availableAsset,
  availableInventoryAsset,
  assetSummary,
  sortOrder,
  sortOrderInventory,
  PORTFOLIO_PERFORMANCE_CATEGORY,
  PORTFOLIO_INVENTORY_CATEGORY,
  EXCLUDED_STATUS,
  INVENTORY_LEGEND_COLOR_MAP,
  SERIAL_NUM_PREFIX,
  sortOrderForSiteSummaryReport,
  ADDITIONAL_FILTER_SORT_ORDER,
  startInPage,
  TEMP_CONSUMP_KEY,
  liveConnect,
} from '../constants';
import envConfig from './getEnv';
// import { EquipmentReportsBenefitIcons } from '../views/containers/SiteDetails/EquipmentsReportBenefitsIcons';

export const updateRecentlyViewed = (
  recentlyViewedKey,
  id,
  type,
  name,
  contractCode,
  org,
  url,
  category = '',
  siteName = '',
) => {
  const recentlyViewed =
    JSON.parse(localStorage.getItem(recentlyViewedKey)) || [];

  const index = recentlyViewed.findIndex(
    value => value.id === id && value.contractCode === contractCode,
  );

  const viewedData = {
    id,
    type,
    name,
    category,
    siteName,
    contractCode,
    contract: org ? `${contractCode}, ${org}` : contractCode,
    url,
    lastViewed: new Date().getTime(),
  };

  if (index > -1) recentlyViewed[index] = viewedData;
  else recentlyViewed.push(viewedData);

  // Removing old key, this line can be remove later
  localStorage.removeItem(recentlyViewedKey.replace('pai:', 'lc:'));

  localStorage.setItem(
    recentlyViewedKey,
    JSON.stringify(
      filterDataByDate(recentlyViewed, 'lastViewed', RECENTLY_VIEWED_MAX_DAYS),
    ),
  );
};

export const getLiveConnectURL = (siteuuid, contractCode, assetClassCode) =>
  `${
    envConfig.beta ? '/beta' : ''
  }/liveConnect/${siteuuid}/${contractCode}/data-browser?assetClassCode=${assetClassCode}`;

export const getSelectedSite = site => {
  const siteData = site.split(DELIMITER);
  return {
    id: siteData[0],
    siteName: siteData[1] || '',
    org: siteData[2] || '',
    siteCode: siteData[3] || '',
    city: siteData[4] || '',
    state: siteData[5] || '',
    zipCode: siteData[6] || '',
  };
};

export const filterDataByDate = (data, key, days) => {
  const sortedData = orderBy(data, [key], ['desc']);
  let totalDays = 0;
  const filteredData = [];

  for (let i = 0; i < sortedData.length; i += 1) {
    if (totalDays === days) break;
    filteredData.push(sortedData[i]);
    if (
      sortedData[i + 1] &&
      getDateWithoutTime(sortedData[i][key]) >
        getDateWithoutTime(sortedData[i + 1][key])
    ) {
      totalDays += 1;
    }
  }
  return filteredData;
};

export const getRecommendations = recommendations =>
  recommendations.map(recommendation => ({
    label: get(recommendation, 'recommCode.dn', ''),
    description: get(recommendation, 'recommText', ''),
    currentStage: recommendation.currentStage,
    numStages: recommendation.numStages,
    feedback: recommendation.feedback,
    recommendatinTypes: get(recommendation, 'resolutionTypes', []).map(
      resolutionType => resolutionType.dn,
    ),
    showChip: recommendation.feedback === 'CONFIRM',
    urgency: recommendation.urgency.id,
  }));

// get resolution type with count
export const getResolutionTypewithCount = recommendation => {
  const data = recommendation.reduce((acc, resolutionTypeItems) => {
    if (!isEmpty(resolutionTypeItems.resolutionTypes)) {
      resolutionTypeItems.resolutionTypes.forEach(resolutionType => {
        acc[resolutionType.dn] = (acc[resolutionType.dn] || 0) + 1;
      });
    }
    return acc;
  }, {});
  return data;
};

// Get resolution type for equipment details page
// eslint-disable-next-line consistent-return
export const getResolutionType = resolution => {
  if (!isEmpty(resolution) && !isNil(resolution))
    return resolution.map(resoln => resoln.dn);
};

// Get resolution type id for equipment details page filter
// eslint-disable-next-line consistent-return
export const getResolutionTypeId = resolution => {
  if (!isEmpty(resolution) && !isNil(resolution))
    return resolution.map(resoln => resoln.id);
};

// resolutionType as string for filter
export const getResolutionTypeWithNonEmptyItem = recommendations => {
  const resolutionTypesForARecommendation = [];
  recommendations.forEach(recomm => {
    if (!isEmpty(recomm.resolutionTypes) && !isNil(recomm.resolutionTypes)) {
      recomm.resolutionTypes.forEach(resolutionType => {
        resolutionTypesForARecommendation.push(resolutionType.dn);
      });
    }
  });
  return resolutionTypesForARecommendation;
};

export const getFormattedResolutionTypeCount = resolutionData => {
  const formattedResolutionTypeCount = [];
  forOwn(resolutionData, (value, key) => {
    const resolutionTypeCount = { resolutionType: '', count: 0 };
    resolutionTypeCount.resolutionType = key;
    resolutionTypeCount.count = value;
    formattedResolutionTypeCount.push(resolutionTypeCount);
  });
  return formattedResolutionTypeCount;
};

export const getAssetCountByResolutionType = recommendation =>
  recommendation.map(paiResolutionType =>
    paiResolutionType.resolutionTypes.map(reslType => reslType.dn),
  );

export const getFirstN = (obj, n) =>
  chain(obj)
    .keys()
    .takeRight(n)
    .reduce((memo, current) => {
      memo[current] = obj[current];
      return memo;
    }, {})
    .value();
/**
 * Get benefits from assets
 * @param {Array} assets
 * @returns {Array} array of objects with count and type
 */
export const getBenifits = assets => {
  const allPrgmObjectives = flatten(
    assets.map(asset => asset.pai.benefitsWithCount),
  );

  const benefits = allPrgmObjectives.reduce((acc, cur) => {
    const { id } = cur.type;
    if (acc[id]) {
      acc[id].count += 1;
    } else {
      acc[id] = {
        count: 1,
        pos: benefitTypesPosMapping[id] || 100,
        type: cur.type,
      };
    }
    return acc;
  }, {});
  return Object.values(benefits).sort((a, b) => {
    if (a.pos > b.pos) {
      return 1;
    }
    if (a.pos < b.pos) {
      return -1;
    }
    return 0;
  });
};

/**
 * Get label array for statusbar
 * @param {Array} benefits
 * @returns {Array} Array of objects with label, count and icon
 */
export const getBenefitSummaryArr = benefits =>
  benefits.map(benefit => {
    const benefitSummaryArr = {
      label: benefit.type.dn,
      count: benefit.count,
      icon: <BenefitIcons benefit={benefit.type.id} />,
    };
    return benefitSummaryArr;
  });

/**
 * Get all resolution types for a PAI
 * @param {Array} recommendations
 * @returns {Array} Array of strings with all resolution types for the equipment
 */
export const getResolutionTypesForAnAsset = recommendations => {
  const resolutionTypesArr = [];
  recommendations.forEach(reco => {
    reco.resolutionTypes.forEach(resolutionType => {
      if (!resolutionTypesArr.includes(resolutionType.dn)) {
        resolutionTypesArr.push(resolutionType.dn);
      }
    });
  });
  return resolutionTypesArr;
};

export const getConfirmCount = recommendations =>
  recommendations.reduce(
    (acc, recomm) =>
      recomm.feedback.toLowerCase() === CONFIRM_TEXT ? acc + 1 : acc,
    0,
  );

export const feedbackCount = (label, feedbackType, empty) =>
  !isEmpty(feedbackType)
    ? `${!empty ? ' | ' : ''}${label} ${feedbackType.length}`
    : '';

export const getFeedbacksCountString = (t, feedbacks) => {
  let feedbacksCountString = '';
  feedbacksCountString += feedbackCount(
    feedbacks?.source?.length === 1 ? t('Check') : t('Checks'),
    !isEmpty(feedbacks[sectionNameListMapping.sources])
      ? feedbacks[sectionNameListMapping.sources]
      : feedbacks.source,
    isEmpty(feedbacksCountString),
  );

  feedbacksCountString += feedbackCount(
    feedbacks?.workstatus?.length === 1 ? t('Work_order') : t('Work_orders'),
    !isEmpty(feedbacks[sectionNameListMapping.workstatus])
      ? feedbacks[sectionNameListMapping.workstatus]
      : feedbacks.workstatus,
    isEmpty(feedbacksCountString),
  );

  return feedbacksCountString;
};

/**
 * Get header label and value from PI data
 * @param {object} PIData
 * @param {string} UOM
 * @returns {array} [headerLabel, headerValue]
 */
export const getPiHeaderLabelAndValue = (PIData, UOM) => {
  let headerLabel = '';
  let headerValue = '';
  const ppdata = PIData.ppList.filter(pp => !ppRegexFilter.test(pp.pp));
  if (ppdata.length > 0) {
    headerLabel = getUomSymbol(ppdata[0].unitFamily, UOM);
    headerValue = convertUnitBasedOnSourceAndTargetUnitFamily(
      ppdata[0].unitFamily,
      UOM,
      ppdata[0].val,
    );
  }
  return [headerLabel, headerValue];
};

export const sortData = (data, sortKey, order = 'desc') =>
  isArray(data) ? orderBy(data, sortKey, order) : data;

export const isFeedbackEmpty = feedback =>
  isEmpty(feedback[sectionNameListMapping.workstatus]) &&
  isEmpty(feedback[sectionNameListMapping.sources]);

export const feedbackObj = {
  source: [],
  workstatus: [],
  recommendations: [],
  comments: [],
  parts: [],
  recomm: [],
};

export const getFeedbackObj = (feedbacks, fdback, recom = []) => {
  feedbacks?.reduce((acc, feed) => {
    const source = feed.feedback[sectionNameListMapping.sources] || [];
    const workStatus = feed.feedback[sectionNameListMapping.workstatus] || [];
    const recommendations =
      feed.feedback[sectionNameListMapping.recommendations] || [];
    const comments = feed.feedback[sectionNameListMapping.comments] || [];
    const parts = feed.feedback[sectionNameListMapping.parts] || [];
    acc.source = [...acc.source, ...source];
    acc.workstatus = [...acc.workstatus, ...workStatus];
    acc.recommendations = [...acc.recommendations, ...recommendations];
    acc.comments = [...acc.comments, ...comments];
    acc.parts = [...acc.parts, ...parts];
    return acc;
  }, fdback);
  if (!isEmpty(recom))
    recom?.reduce((acc, rec) => {
      const recomm = rec || {};
      acc.recomm = [...acc.recomm, recomm];
      return acc;
    }, fdback);
  return fdback;
};

export const filterSitesWithInsightSubfamily = (siteList, insightSubfamily) =>
  siteList?.filter(ele => {
    const elementWithRequiredInsight = ele?.sum?.filter(
      sumEl => sumEl?.subFamily?.id === insightSubfamily,
    );
    return elementWithRequiredInsight.length > 0;
  });

export const getSiteListDataForCategory = (siteList, insightSubfamily) => {
  const filteredSiteList = filterSitesWithInsightSubfamily(
    siteList,
    insightSubfamily,
  );
  return filteredSiteList?.map(ele => {
    const flattenData = getFlattenArray(ele?.sum)?.filter(
      obj => obj.label === insightSubfamily,
    );
    return { ...ele, equipInsight: flattenData?.[0]?.data };
  });
};

export const getEquipmentCategory = (siteList, insightSubfamily) => {
  const equipmentCategories = [];
  const flattenArray = getSiteListDataForCategory(siteList, insightSubfamily);
  flattenArray?.forEach(obj =>
    obj?.equipInsight?.forEach(ele => equipmentCategories.push(ele)),
  );
  return uniqBy(
    sortBy(equipmentCategories, o => o.pos),
    o => o.dn,
  ).map(o => o.dn);
};

export const getEquipmentCategoryWithId = (siteList, insightSubfamily) => {
  const equipmentCategories = [];
  const flattenArray = getSiteListDataForCategory(siteList, insightSubfamily);
  flattenArray?.forEach(obj =>
    obj?.equipInsight?.forEach(ele => equipmentCategories.push(ele)),
  );
  return uniqBy(
    sortBy(equipmentCategories, o => o.pos),
    o => o.id,
  ).map(o => ({ id: o.id, dn: o.dn }));
};

export const getFlattenArray = dataArr =>
  Array.from(new Set(dataArr.map(s => s?.subFamily?.id))).map(lab => ({
    label: lab,
    data: dataArr
      .filter(s => s?.subFamily?.id === lab)
      .map(edition => edition.assetCats)
      .flat(),
  }));

export const getPortfolioData = svcBundles =>
  svcBundles?.map(ele => {
    const flattenEquipmentInsightData = getFlattenArray(ele?.summary)?.filter(
      obj => obj.label === PORTFOLIO_PERFORMANCE_CATEGORY,
    );
    const flattenEquipmentInventoryData = getFlattenArray(ele?.summary)?.filter(
      obj => obj.label === PORTFOLIO_INVENTORY_CATEGORY,
    );
    return {
      ...ele,
      equipInsight: flattenEquipmentInsightData?.[0]?.data,
      equipInventoryInsight: flattenEquipmentInventoryData?.[0]?.data,
      hasInventory: flattenEquipmentInventoryData?.length > 0,
    };
  });

export const getRequiredState = (dataList, summaryNode, filterString) => {
  const subFamilyData = getSingleSubfamilyData(
    dataList,
    summaryNode,
    filterString,
  );
  return getAssetSummaryData(subFamilyData?.[0]?.[summaryNode]);
};

export const getSingleSubfamilyData = (dataList, summaryNode, filterString) =>
  dataList
    ?.map(data => ({
      ...data,
      [summaryNode]: data?.[summaryNode]?.filter(
        sum => sum?.subFamily?.id === filterString,
      ),
    }))
    .filter(data => data?.[summaryNode]?.length > 0);

export const getAssetSummaryData = assetSummaryData =>
  assetSummaryData?.[0]?.assetCats?.[0]?.assetSum?.reduce((acc, cur) => {
    acc[cur[0]] = cur;
    return acc;
  }, {});

export const getAssetSummaryDataTrends = assetSum => {
  const arr = assetSum?.reduce((acc, cur) => {
    acc[cur[0]] = cur;
    return acc;
  }, {});
  return arr;
};

export const getInsightCategoryData = (data, category) =>
  data?.filter(sum => sum?.subFamily?.id === category);

export const getInventoryLegend = data =>
  data
    ?.filter(assetSumEl => !EXCLUDED_STATUS.includes(assetSumEl[0]))
    .map(assetSumEl => ({
      key: assetSumEl[0],
      color: INVENTORY_LEGEND_COLOR_MAP[assetSumEl[0]],
      label: assetSumEl[1],
    }));

export const formatAssetData = (data, isInventoryAsset = false) => {
  const asset = {
    URGENT: 0,
    ONB: 0,
    CONN: 0,
    NODATA: 0,
    GOOD: 0,
    PLAN: 0,
    CRITICAL: 0,
    'NOT-CONN': 0,
    total: 0,
    existsAsset: 0,
  };
  const inventoryAsset = {
    OUTOFDATE: 0,
    'ONB-INV': 0,
    'CONN-INV': 0,
    'NODATA-INV': 0,
    UPTODATE: 0,
    UPDAVBL: 0,
    'NOT-CONN-INV': 0,
    total: 0,
    existsAsset: 0,
  };
  const requiredAsset = isInventoryAsset ? inventoryAsset : asset;
  const assetsAvailable = isInventoryAsset
    ? availableInventoryAsset
    : availableAsset;
  return data?.reduce((acc, feed) => {
    acc[feed[0]] = parseInt(feed[2], 10);
    acc.total += assetsAvailable.includes(feed[0]) && parseInt(feed[2], 10);
    acc.existsAsset +=
      assetsAvailable.includes(feed[0]) && parseInt(feed[2], 10) > 0 && 1;
    return acc;
  }, requiredAsset);
};

const checkboxObj = (colorCode, radio = false) => ({
  color: colorCode,
  '& .MuiIconButton-label': {
    position: 'relative',
    zIndex: 0,
  },
  '&$checked': {
    color: colorCode,
  },
  '&:not($checked) .MuiIconButton-label:after': {
    content: '""',
    left: 2,
    top: 2,
    height: radio ? 13 : 12,
    width: radio ? 13 : 12,
    position: 'absolute',
    backgroundColor: !radio && colorCode,
    zIndex: -1,
    ...(radio && { borderRadius: '50%' }),
  },
  '&:not($checked).Mui-disabled .MuiIconButton-label:after': {
    content: '""',
    left: 2,
    top: 2,
    height: radio ? 13 : 12,
    width: radio ? 13 : 12,
    position: 'absolute',
    backgroundColor: !radio && '#e9e9e9',
    zIndex: -1,
    ...(radio && { borderRadius: '50%' }),
  },
});
export const GoodCheckbox = withStyles({
  root: checkboxObj(assetSummary?.GOOD?.color),
  checked: {},
})(props => <Checkbox color="default" {...props} />);

export const UrgentCheckbox = withStyles({
  root: checkboxObj(assetSummary?.URGENT?.color),
  checked: {},
})(props => <Checkbox color="default" {...props} />);

export const PlanCheckbox = withStyles({
  root: checkboxObj(assetSummary?.PLAN?.color),
  checked: {},
})(props => <Checkbox color="default" {...props} />);

export const YetCheckbox = withStyles({
  root: checkboxObj(assetSummary?.['NOT-CONN']?.color),
  checked: {},
})(props => <Checkbox color="default" {...props} />);

export const NoDataCheckbox = withStyles({
  root: checkboxObj(assetSummary?.NODATA?.color),
  checked: {},
})(props => <Checkbox color="default" {...props} />);

export const GoodRadio = withStyles({
  root: checkboxObj(assetSummary?.GOOD?.color, true),
  checked: {},
})(props => <Radio color="default" {...props} />);

export const UrgentRadio = withStyles({
  root: checkboxObj(assetSummary?.URGENT?.color, true),
  checked: {},
})(props => <Radio color="default" {...props} />);

export const PlanRadio = withStyles({
  root: checkboxObj(assetSummary?.PLAN?.color, true),
  checked: {},
})(props => <Radio color="default" {...props} />);

export const YetRadio = withStyles({
  root: checkboxObj(assetSummary?.['NOT-CONN']?.color, true),
  checked: {},
})(props => <Radio color="default" {...props} />);

export const NoDataRadio = withStyles({
  root: checkboxObj(assetSummary?.NODATA?.color, true),
  checked: {},
})(props => <Radio color="default" {...props} />);

export const statusSorting = (data, array = false, inventory = false) => {
  const sortOrderOption = inventory ? sortOrderInventory : sortOrder;
  if (array)
    data.sort(
      (a, b) =>
        sortOrderOption[a?.[0]] - sortOrderOption[b?.[0]] ||
        a?.[0].localeCompare(b?.[0]),
    );
  else {
    data.sort(
      (a, b) => sortOrderOption[a] - sortOrderOption[b] || a.localeCompare(b),
    );
  }
  return data;
};

export const additionalFilterSorting = data => {
  data?.sort(
    (a, b) => ADDITIONAL_FILTER_SORT_ORDER[a] - ADDITIONAL_FILTER_SORT_ORDER[b],
  );
  return data;
};

export const inventoryStatusSorting = data => {
  data?.sort((a, b) => sortOrderInventory[a.key] - sortOrderInventory[b.key]);
  return data;
};

export const statusObjSorting = data => {
  data.sort((a, b) => {
    const aData = assetSummary[a?.urgency?.id || a?.insightStatus?.id]?.state;
    const bData = assetSummary[b?.urgency?.id || b?.insightStatus?.id]?.state;
    return sortOrder[aData] - sortOrder[bData] || aData.localeCompare(bData);
  });
  return data;
};

export const getInventoryCheckboxStyles = colorCode => ({
  color: colorCode,
  '& .MuiIconButton-label': {
    position: 'relative',
    zIndex: 0,
  },
  '&$checked': {
    color: colorCode,
  },
  '&:not($checked) .MuiIconButton-label:after': {
    content: '""',
    left: 2,
    top: 2,
    height: 12,
    width: 12,
    position: 'absolute',
    backgroundColor: colorCode,
    zIndex: -1,
  },
  '&:not($checked).Mui-disabled .MuiIconButton-label:after': {
    content: '""',
    left: 2,
    top: 2,
    height: 12,
    width: 12,
    position: 'absolute',
    backgroundColor: '#e9e9e9',
    zIndex: -1,
  },
});

export const getInventoryFilterState = (inventoryLegends, equipStatusArr) => {
  const inventoryFilterState = {};
  inventoryLegends
    .map(legend => legend.key)
    .forEach(key => {
      inventoryFilterState[key] =
        (equipStatusArr?.length && equipStatusArr?.includes(key)) || false;
    });
  return inventoryFilterState;
};

export const isNullOrEmptyString = str => str === null || !str?.trim().length;
export const isNullOrEmptyOrZero = val =>
  val === null || !val?.trim().length || val?.toString() === '0';

export const encloseSerialModelNum = dataObj => {
  let enclosedData = '';
  if (dataObj?.model) {
    enclosedData = `(${dataObj?.model}`;
    enclosedData = dataObj?.serialNum
      ? `${enclosedData}, ${SERIAL_NUM_PREFIX}: ${dataObj?.serialNum})`
      : `${enclosedData})`;
  } else {
    enclosedData =
      dataObj?.serialNum && `(${SERIAL_NUM_PREFIX}: ${dataObj?.serialNum})`;
  }
  return enclosedData;
};

export const getSystemDescription = asset => {
  let systemDescription = '';
  if (asset?.systemDesc) {
    systemDescription = `${asset?.systemDesc}`;
    systemDescription = asset?.system
      ? `${systemDescription} (${asset?.system})`
      : `${systemDescription}`;
  } else {
    systemDescription = asset?.system && `(${asset?.system})`;
  }
  return systemDescription;
};

export const localeLanguage = t => ({
  name: 'localeLanguage',
  months: [
    [t('January'), t('Jan')],
    [t('February'), t('Feb')],
    [t('March'), t('Mar')],
    [t('April'), t('Apr')],
    [t('May'), t('May')],
    [t('June'), t('Jun')],
    [t('July'), t('Jul')],
    [t('August'), t('Aug')],
    [t('September'), t('Sep')],
    [t('October'), t('Oct')],
    [t('November'), t('Nov')],
    [t('December'), t('Dec')],
  ],
  weekDays: [
    [t('Saturday'), t('Sat')],
    [t('Sunday'), t('Sun')],
    [t('Monday'), t('Mon')],
    [t('Tuesday'), t('Tue')],
    [t('Wednesday'), t('Wed')],
    [t('Thursday'), t('Thu')],
    [t('Friday'), t('Fri')],
  ],
  digits: ['0', '1', '2', '3', '4', '5', '6', '7', '8', '9'],
  meridiems: [
    [t('AM'), t('am')],
    [t('PM'), t('pm')],
  ],
});

export const getFilterProfileAttribute = (
  filterProfileData,
  selectedFilterProfile,
) =>
  filterProfileData?.filterProfiles?.find(
    profile => profile?.filterCode === selectedFilterProfile,
  )?.attributes;

export const getFilterProfileListForCategory = (filterAttributes, category) => {
  const requiredFilterAttr = filterAttributes?.filter(
    filterAttr => filterAttr?.attributeName === category,
  );
  if (requiredFilterAttr?.length) {
    return [...requiredFilterAttr[0]?.attributeValues];
  }
  return [];
};

export const getGroupName = (groupArray, groupId) =>
  groupArray?.find(grp => grp?.groupId === groupId);

export const statusObjSortingForSiteSummaryReport = data =>
  data
    .slice()
    .sort(
      (a, b) =>
        sortOrderForSiteSummaryReport[a] - sortOrderForSiteSummaryReport[b],
    );

export const getCategoryName = (equipmentCategory, catId) =>
  equipmentCategory?.find(equipCat => equipCat.id === catId)?.dn;

export const isEncoded = uri => {
  uri = uri || '';

  return uri !== decodeURIComponent(uri);
};
export const fullyDecodeURI = uri => {
  while (isEncoded(uri)) {
    uri = decodeURIComponent(uri);
  }
  return uri;
};
export const groupElements = (array, n) =>
  [...Array(Math.ceil(array?.length / n))].map((el, i) =>
    array.slice(i * n, (i + 1) * n),
  );

export const useOnClickOutside = (ref, handler) => {
  useEffect(() => {
    const listener = event => {
      if (!ref.current || ref.current.contains(event.target)) {
        return;
      }
      handler(event);
    };
    document.addEventListener('mousedown', listener);
    document.addEventListener('touchstart', listener);
    return () => {
      document.removeEventListener('mousedown', listener);
      document.removeEventListener('touchstart', listener);
    };
  }, [ref, handler]);
};

export const favoriteTransObj = t => ({
  toDoList: t('TODO_LIST'),
  siteSummary: t('SITE_SUMMARY'),
  subscription: t('Subscription'),
  site: t('Site'),
  equipment: t('Equipment_Details'),
  organization: t('Organization'),
  portFolioTrend: t('Portfolio'),
  portFolioTrendReport: t('Equipment_insight_trend_report'),
});

export const getDateBlocks = (start, end, maxDays) => {
  const result = [];
  const s = new Date(start);
  while (s <= end) {
    const e = new Date(s.getFullYear(), s.getMonth(), s.getDate() + maxDays);
    result.push({ start: new Date(s), end: e <= end ? e : new Date(end) });
    s.setDate(s.getDate() + maxDays + 1);
  }
  return result;
};

export const getStartInOptions = (tcAccess, liveAccess) => {
  const filters = [];

  if (!liveAccess) filters.push(item => item.key !== liveConnect);
  if (!tcAccess) filters.push(item => item.key !== TEMP_CONSUMP_KEY);

  return startInPage.filter(item => filters.every(filter => filter(item)));
};

export const sha256Encryption = inputString => {
  const hash = SHA256(inputString).toString();
  const hexResult = hash.toString(enc.Hex);
  return hexResult;
};

export const FA_PREFIX =
  'e723d02598a21f41cf45b26381397e928fd0d7695262e75208bf532496260597ca12b12b21e410e7b1c36232bb6450c8977cf6e7e7e3592535352ffcb91b9d86e723d02598a21f41cf45b26381397e928fd0d7695262e75208bf532496260597bc7331f153631b2b122379bdd7e282edd23f0b3b81ef9dda6109100de284e7a3';
export const getLoggedUserInfo = () => {
  const domainSplitter = '.com';
  const nonFedEmail = getUserEmail();
  const fedEmail = getLoggedUserEmailId();
  const loggedUserEmail = !nonFedEmail ? fedEmail : nonFedEmail;
  const domainNameValue = loggedUserEmail?.split('@')[1];
  const selectedDomainName = sha256Encryption(
    `${FA_PREFIX}${domainNameValue?.split(domainSplitter)[0]}`,
  );
  const userIdValue = sha256Encryption(
    `${getUserId()}${domainNameValue?.split(domainSplitter)[0]}`,
  );
  return {
    domainName: selectedDomainName,
    userId: userIdValue,
  };
};
export const firebaseAnalyticsPageIds = {
  home: {
    pageId: 'PORTFOLIO_SCREEN',
  },
  'site-details/pai/active': {
    pageId: 'TODO_LIST_SCREEN',
  },
  'site-list': {
    pageId: 'SITE_LIST_SCREEN',
  },
  'site-details/ais/active': {
    pageId: 'SITE_SUMMARY_SCREEN',
  },
  'equipment-details/pai/details': {
    pageId: 'EQUIPMENT_SCREEN',
  },
  'temperature-and-consumption': {
    pageId: 'TEMPERATURE_AND_CONSUMPTION_SCREEN',
  },
  'live-connect': {
    pageId: 'LIVE_CONNECT_SCREEN',
  },
};

export const startInObj = {
  todoList: 'todoList',
  siteSummary: 'siteSummary',
  tempConsump: 'tempConsump',
  liveConnect: 'liveConnect',
};

export const defaultStatInfunc = (
  liveAccess,
  tcAccess,
  resolvedDefaultStartIn,
) => {
  const { liveConnect: live, tempConsump, todoList, siteSummary } = startInObj;

  const conditions = {
    [todoList]: todoList,
    [siteSummary]: siteSummary,
    [live]: liveAccess ? live : null,
    [tempConsump]: tcAccess ? tempConsump : null,
  };
  return conditions[resolvedDefaultStartIn] || todoList;
};
