import moment from 'moment';
// import 'moment-timezone';
import _ from 'lodash';

/**
 * Returns Time formatted as hh:mm in the specified time zone
 * For Mobile View
 * @param {number} epoch - Time in epoch
 * @param {string} timeZone - The timezone
 */
export const convertToTimeZonehhmm = (epoch, timeZone) => {
  if (epoch === 0) {
    return '-';
  }
  const time = moment(epoch);
  return `${time.tz(timeZone).format('HH:mm')} Hr`;
};

/**
 * Format for sitepoints
 * @param {number} epoch - Time in epoch
 * @param {string} timeZone - The timezone
 */
export const convertForSite = (epoch, timeZone) => {
  if (epoch === 0) {
    return '-';
  }
  const time = moment(epoch);
  return `${time.tz(timeZone).format('HH:mm')} Hr`;
};

/**
 * Returns date for api requests
 */
export const getDateForCaching = () => {
  const currentDate = new Date();
  const formattedDate = moment(currentDate).format('DD-MM-YYYY');
  return `${formattedDate}/${Math.floor(currentDate.getHours() / 2)}`;
};

/**
 * Returns time as follows - 17:15 Hr, 14 Apr, 2020
 * @param {number} epoch - Time in epoch
 * @param {string} timeZone - The timezone
 */
export const convertToTimeZoneHHMMDDMMMYYYY = (epoch, timeZone) => {
  const momentTime = moment(epoch);
  let time;
  let date;
  if (timeZone) {
    time = momentTime.tz(timeZone).format('HH:mm');
    date = momentTime.tz(timeZone).format('DD MMM, YYYY');
  } else {
    time = momentTime.format('HH:mm');
    date = momentTime.format('DD MMM, YYYY');
  }
  return {
    time,
    date,
    formatted: epoch <= 0 ? '-' : `${time} Hr, ${date}`,
  };
};

export const getLatestTime = rowTimeArr => {
  const maxTime = _.max(rowTimeArr);
  return maxTime;
};

export const getOldestTime = rowTimeArr => {
  const minTime = _.min(rowTimeArr);
  return minTime;
};

/**
 * Returns difference between 2 dates
 * @param {string} startDate - Date in YYYY-MM-DD format
 * @param {string} endDate - Date in YYYY-MM-DD format
 */
export const getDifferenceBetweenDates = (startDate, endDate) =>
  Math.round(
    (Date.parse(endDate) - Date.parse(startDate)) / (1000 * 60 * 60 * 24),
  );

export const daysFromNow = date =>
  Math.round((Date.now() - Date.parse(date)) / (1000 * 60 * 60 * 24));

/**
 * Returns difference between 2 dates
 * @param {object} date - Date obj
 * @param {string} delimeter - Delimeter to separate dates
 */
export const formatDateToYYYYMMDD = (date, delimeter) => {
  const d = new Date(date);
  let month = (d.getMonth() + 1).toString();
  let day = d.getDate().toString();
  const year = d.getFullYear();

  if (month.length < 2) month = `0${month}`;
  if (day.length < 2) day = `0${day}`;

  return [year, month, day].join(delimeter);
};

export const monthYearFormatter = (locale = 'en') =>
  new Intl.DateTimeFormat(locale, {
    year: 'numeric',
    month: 'short',
  });

export const dayFormatter = (locale = 'en') =>
  new Intl.DateTimeFormat(locale, { day: 'numeric' });

export const monthFormatter = (locale = 'en') =>
  new Intl.DateTimeFormat(locale, { month: 'short' });

export const getDateWithoutTime = date =>
  moment(moment(date).format('MM/DD/YYYY')).toDate().getTime();

export const convertToLastLogonTime = epoch => {
  if (!epoch) {
    return '-';
  }
  return moment.utc(moment.unix(epoch)).format('HH:mm, DD MMM, YYYY, z');
};

/**
 * @param {ms} epoch
 * @param {ms} threshold
 * @returns boolean
 */
export const isRecentTime = (epoch, threshold) => {
  const isRecent = Date.now() - epoch <= threshold;
  return isRecent;
};
