/**
 *
 * CustomDialog
 *
 */

import React from 'react';
import PropTypes from 'prop-types';
import {
  Dialog,
  MuiDialogTitle,
  MuiDialogContent,
  MuiDialogContentText,
  MuiDialogActions,
  Button,
  makeStyles,
} from '../thirdParty';

const useStyles = makeStyles({
  dialogPaper: {
    minHeight: 200,
    minWidth: 360,
  },
});

export const CustomDialog = ({
  open,
  dialogTitleText,
  dialogDescriptionText,
  okBtnLabel,
  cancelBtnLabel,
  handleCloseCallback,
  handleAgreeCallback,
}) => {
  const classes = useStyles();
  const cancelBtn = React.useRef(null);
  return (
    <Dialog
      open={open}
      onClose={handleCloseCallback}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      fullWidth
      maxWidth="xs"
      classes={{ paper: classes.dialogPaper }}
      TransitionProps={{
        onEntering: () => cancelBtn.current.focus(),
      }}
    >
      <MuiDialogTitle id="alert-dialog-title">{dialogTitleText}</MuiDialogTitle>
      <MuiDialogContent>
        <MuiDialogContentText id="alert-dialog-description">
          {dialogDescriptionText}
        </MuiDialogContentText>
      </MuiDialogContent>
      <MuiDialogActions>
        <Button onClick={handleAgreeCallback} style={{ color: '#f50057' }}>
          {okBtnLabel}
        </Button>
        <Button
          onClick={handleCloseCallback}
          color="primary"
          ref={cancelBtn}
          autoFocus
        >
          {cancelBtnLabel}
        </Button>
      </MuiDialogActions>
    </Dialog>
  );
};

CustomDialog.propTypes = {
  /**
   * Open the dialog
   */
  open: PropTypes.bool,
  /**
   * Title of dialog
   */
  dialogTitleText: PropTypes.string,
  /**
   * Description of dialog
   */
  dialogDescriptionText: PropTypes.string,
  /**
   * Label for ok button
   */
  okBtnLabel: PropTypes.string,
  /**
   * Label for cancel button
   */
  cancelBtnLabel: PropTypes.string,
  /**
   * Callback handler on click of cancel button
   */
  handleCloseCallback: PropTypes.func,
  /**
   * Callback handler on click of ok button
   */
  handleAgreeCallback: PropTypes.func,
};

CustomDialog.whyDidYouRender = true;

export default CustomDialog;
