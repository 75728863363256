/**
 * Number Filter Constants
 */
export const ScalerFilter = Object.freeze({
  /* EMPTY: 'empty', */
  EQUALS: 'equals',
  NOT_EQUAL: 'notEqual',
  LESS_THAN: 'lessThan',
  LESS_THAN_OR_EQUAL: 'lessThanOrEqual',
  GREATER_THAN: 'greaterThan',
  GREATER_THAN_OR_EQUAL: 'greaterThanOrEqual',
});

const comparator = (left, right) => {
  if (left === right) {
    return 0;
  }
  if (left < right) {
    return 1;
  }
  return -1;
};

const nullComparator = (selectedOption, filterValue, gridValue) => {
  if (gridValue == null) {
    const nullValue = this.canNullsPassFilter(selectedOption);

    /* if (selectedOption === ScalerFilter.EMPTY) {
      return 0;
    } */

    if (selectedOption === ScalerFilter.EQUALS) {
      return nullValue ? 0 : 1;
    }

    if (selectedOption === ScalerFilter.GREATER_THAN) {
      return nullValue ? 1 : -1;
    }

    if (selectedOption === ScalerFilter.GREATER_THAN_OR_EQUAL) {
      return nullValue ? 1 : -1;
    }

    if (selectedOption === ScalerFilter.LESS_THAN_OR_EQUAL) {
      return nullValue ? -1 : 1;
    }

    if (selectedOption === ScalerFilter.LESS_THAN) {
      return nullValue ? -1 : 1;
    }

    if (selectedOption === ScalerFilter.NOT_EQUAL) {
      return nullValue ? 1 : 0;
    }
  }

  const actualComparator = comparator;
  return actualComparator(filterValue, gridValue);
};

const makeNull = value => {
  const valueNoType = value;
  if (value === null || value === undefined || valueNoType === '') {
    return null;
  }
  return value;
};

export const stringToFloat = value => {
  if (typeof value === 'number') {
    return value;
  }

  let filterText = makeNull(value);

  if (filterText && filterText.trim() === '') {
    filterText = null;
  }

  let newFilter;

  if (filterText !== null && filterText !== undefined) {
    newFilter = parseFloat(filterText);
  } else {
    newFilter = null;
  }

  return newFilter;
};

/**
 * Returns true or false based on the inputs
 * @param {string} filterType - Eg: ScalerFilter.GREATER_THAN
 * @param {number} value - The value to be compared on
 * @param {number} filterValue - The filter value
 */
export const individualConditionPasses = (filterType, value, filterValue) => {
  const compareResult = nullComparator(
    filterType,
    stringToFloat(filterValue),
    stringToFloat(value),
  );
  if (filterType === ScalerFilter.EQUALS) {
    return compareResult === 0;
  }
  if (filterType === ScalerFilter.GREATER_THAN) {
    return compareResult > 0;
  }
  if (filterType === ScalerFilter.GREATER_THAN_OR_EQUAL) {
    return compareResult >= 0;
  }
  if (filterType === ScalerFilter.LESS_THAN_OR_EQUAL) {
    return compareResult <= 0;
  }
  if (filterType === ScalerFilter.LESS_THAN) {
    return compareResult < 0;
  }
  if (filterType === ScalerFilter.NOT_EQUAL) {
    return compareResult !== 0;
  }
  throw new Error(`Unexpected type of filter: ${filterType}`);
};
