import React from 'react';
import PropTypes from 'prop-types';
import makeStyles from '@mui/styles/makeStyles';
import { isEmpty, get } from 'lodash';
import { useTranslation } from 'react-i18next';
import { Grid, Box } from '../thirdParty';
import { HistoryChip } from '../HistoryChip';
import { NoDataText } from '../NoDataText';
import { convertToDtMnthYr } from '../dateUtils';
import { VerifiedIcon } from '../verifiedIcon';
import { REC_BY_AI } from '../constants';

const useStyles = makeStyles(() => ({
  historyChipWrapper: {
    margin: '2px 5px 2px 0px',
    maxWidth: '100%',
    display: 'flex',
    alignItems: 'center',
  },
}));

/**
 * Primary UI component for user interaction
 */
export const FeedbackRecommendationsChip = props => {
  const { recommendations, noDataText, timezone, filterCheck } = props;
  const classes = useStyles(props);
  const { t } = useTranslation();
  return !isEmpty(recommendations) ? (
    <Grid container alignItems="center" className={classes.root}>
      {recommendations.map(recommendation => (
        <Box>
          {((!filterCheck?.deleted && !get(recommendation, 'deleted')) ||
            filterCheck?.deleted) && (
            <Grid item className={classes.historyChipWrapper}>
              <HistoryChip
                type="additionalFixes"
                label={`${convertToDtMnthYr(
                  recommendation.ts,
                  timezone,
                )}         ${get(recommendation, 'recommCode.dn')}`}
                deleted={get(recommendation, 'deleted')}
              />
              {recommendation.origin === REC_BY_AI && (
                <Box display="flex">
                  <VerifiedIcon title={t('Confirmed')} label="verified" />
                </Box>
              )}
            </Grid>
          )}
        </Box>
      ))}
    </Grid>
  ) : (
    <NoDataText text={noDataText}></NoDataText>
  );
};

FeedbackRecommendationsChip.propTypes = {
  /**
   * list of added recommendations
   */
  recommendations: PropTypes.array,
  noDataText: PropTypes.string,
  timezone: PropTypes.string,
  filterCheck: PropTypes.object,
};
