import React from 'react';
import PropTypes from 'prop-types';

import {
  Button,
  FormControl,
  IconButton,
  OutlinedInput,
  InputAdornment,
  Typography,
  Dialog,
  MuiDialogTitle,
  MuiDialogContent,
  MuiDialogActions,
  makeStyles,
} from '../thirdParty';
import { CloseIcon } from '../icons';

import LoadingIndicator from '../LoadingIndicator';
import { FavoriteDropdown } from '../FavoriteDropdown';

const useStyles = makeStyles(theme => ({
  root: {
    margin: 0,
    padding: 15,
    backgroundColor: theme.palette.secondary.main,
    borderBottom: `1px solid ${theme.palette.primary.main}`,
  },
  formControl: {
    maxWidth: '315px',
    borderRadius: 6,
  },
  itemStyle: {
    marginTop: '12px',
  },
  headerStyle: {
    fontWeight: 600,
    fontSize: '1rem',
    display: 'flex',
    justifyContent: 'flex-end',
  },
  buttonPosition: {
    display: 'flex',
    justifyContent: 'flex-end',
    padding: '4.8px 0px',
  },
  buttonSpacing: {
    marginRight: '10px',
    border: `1px solid ${theme.palette.primary.main}`,
  },
  fieldHeading: {
    fontWeight: 600,
    fontSize: '0.75rem',
    fontFamily: 'Roboto',
  },
  buttonProgress: {
    color: theme.palette.primary.main,
    position: 'absolute',
    top: '61%',
    left: '59%',
    marginTop: 106,
    marginLeft: -5,
  },
  textField: {
    padding: '9.5px 7px',
    width: '229px',
  },
  folderNameTextField: {
    padding: '9.5px 7px',
    width: '270px',
  },
  horizontalSeparator: {
    margin: '23px -8px 23px -8px',
    boxShadow: `0 3px 0px -1px ${theme.color.darkGrey}`,
    height: '3px',
  },
  folderToggle: {
    fontWeight: 700,
    display: 'flex',
    justifyContent: 'center',
    marginTop: '5px',
    fontSize: '0.875rem',
    fontFamily: 'Roboto',
  },
  toggleButton: {
    display: 'flex',
    justifyContent: 'center',
  },
  errorStyle: {
    display: 'flex',
    justifyContent: 'flex-start',
    flexWrap: 'wrap',
    color: 'red',
    marginTop: '2px',
  },
  textSizeSmall: {
    fontSize: '0.75rem',
  },
  errorMessageTextSize: {
    fontSize: '0.875rem',
    fontFamily: 'Roboto',
  },
  dividers: {
    padding: '9px 18px 56px 15px',
    width: '334px',
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(0.5),
    top: theme.spacing(1.08),
  },
  favHeading: {
    color: theme.palette.primary.main,
  },
}));

const circularProgressSizes = {
  sm: 24,
  md: 32,
  lg: 40,
};

export const FavoritePopover = ({
  folderList,
  handleFolderChange,
  selectedFolderValue,
  popperOpen,
  onSaveClickHandler,
  onClose,
  variant,
  inProgress,
  handleFavoriteNameTextInput,
  inputFavoriteText,
  isButtonDisabled,
  resetClickHandler,
  folderNameInputHandler,
  errorMessageFavoriteName,
  errorMessageFolderName,
  handleClearText,
  folderNameInputText,
  handleClearFolderName,
  handleToggle,
  toggleFlag,
  popoverLabels,
  loaderImage,
  favoriteType,
}) => {
  const classes = useStyles();
  const textDisabled = favoriteType === 'trends' ? true : '';
  return (
    <div>
      <Dialog
        onClose={onClose}
        aria-labelledby="customized-dialog-title"
        open={popperOpen}
      >
        <MuiDialogTitle
          id="customized-dialog-title"
          onClose={onClose}
          className={classes.root}
        >
          <Typography variant="h6" className={classes.favHeading}>
            {popoverLabels.heading}
          </Typography>
          <div className={classes.headerStyle}>
            {onClose ? (
              <IconButton
                aria-label="close"
                className={classes.closeButton}
                onClick={onClose}
                size="large"
              >
                <CloseIcon />
              </IconButton>
            ) : null}
          </div>
        </MuiDialogTitle>
        <MuiDialogContent dividers className={classes.dividers}>
          <div>
            <div className={classes.itemStyle}>
              <div className={classes.fieldHeading}>
                {popoverLabels.favoriteName}
              </div>
              <div>
                <FormControl variant="outlined">
                  <OutlinedInput
                    id="outlined-adornment-favorite"
                    type="text"
                    onChange={handleFavoriteNameTextInput}
                    required
                    error={!!errorMessageFavoriteName}
                    inputProps={{
                      className: classes.textField,
                      classes: {
                        root: classes.root,
                      },
                    }}
                    value={inputFavoriteText}
                    disabled={textDisabled}
                    endAdornment={
                      <InputAdornment position="end">
                        <IconButton
                          onClick={handleClearText}
                          edge="end"
                          title={popoverLabels.clear}
                          size="large"
                          disabled={textDisabled}
                        >
                          <CloseIcon />
                        </IconButton>
                      </InputAdornment>
                    }
                  />
                </FormControl>
              </div>
            </div>
            {errorMessageFavoriteName.length > 0 ? (
              <div className={classes.errorStyle}>
                <span className={classes.errorMessageTextSize}>
                  {errorMessageFavoriteName}
                </span>
              </div>
            ) : (
              ''
            )}
            <div className={classes.horizontalSeparator}></div>
            {!toggleFlag && (
              <div className={classes.itemStyle}>
                <div className={classes.fieldHeading}>
                  {popoverLabels.selectFolder}
                </div>
                <div>
                  <FavoriteDropdown
                    itemsInDropDown={folderList}
                    handleChange={handleFolderChange}
                    selectedValue={selectedFolderValue}
                  />
                </div>
              </div>
            )}
            {toggleFlag && (
              <div className={classes.itemStyle}>
                <div className={classes.fieldHeading}>
                  {popoverLabels.folderName}
                </div>
                <div>
                  <FormControl variant="outlined">
                    <OutlinedInput
                      id="outlined-adornment-favorite"
                      type="text"
                      onChange={e => {
                        folderNameInputHandler(e);
                      }}
                      inputProps={{
                        className: classes.textField,
                      }}
                      error={!!errorMessageFolderName}
                      value={folderNameInputText}
                      placeholder={popoverLabels.folderInputPlaceHolder}
                      endAdornment={
                        <InputAdornment position="end">
                          <IconButton
                            onClick={handleClearFolderName}
                            edge="end"
                            title={popoverLabels.clear}
                            size="large"
                          >
                            <CloseIcon />
                          </IconButton>
                        </InputAdornment>
                      }
                    />
                  </FormControl>
                </div>
              </div>
            )}
            {errorMessageFolderName.length > 0 ? (
              <div className={classes.errorStyle}>
                <span className={classes.errorMessageTextSize}>
                  {errorMessageFolderName}
                </span>
              </div>
            ) : (
              ''
            )}
            <div className={classes.folderToggle}>{popoverLabels.or}</div>
            <div className={classes.toggleButton}>
              <Button
                color="primary"
                variant="outlined"
                onClick={handleToggle}
                className={classes.textSizeSmall}
              >
                {!toggleFlag
                  ? popoverLabels.createNew
                  : popoverLabels.selectFolder}
              </Button>
            </div>
          </div>
        </MuiDialogContent>
        <MuiDialogActions>
          <div className={classes.buttonPosition}>
            <Button
              disabled={isButtonDisabled || false}
              onClick={onSaveClickHandler}
              color="primary"
              variant="outlined"
              autoFocus={
                !!(!errorMessageFolderName && !errorMessageFavoriteName)
              }
              className={classes.buttonSpacing}
            >
              {popoverLabels.save}
            </Button>
            <Button
              onClick={resetClickHandler}
              variant="outlined"
              className={classes.buttonSpacing}
            >
              {popoverLabels.reset}
            </Button>
            <Button
              onClick={onClose}
              variant="outlined"
              className={classes.buttonSpacing}
            >
              {popoverLabels.cancel}
            </Button>
            {inProgress && (
              <LoadingIndicator
                size={circularProgressSizes[variant]}
                className={classes.buttonProgress}
                loaderImage={loaderImage}
              />
            )}
          </div>
        </MuiDialogActions>
      </Dialog>
    </div>
  );
};

FavoritePopover.propTypes = {
  /**
   * List of folder name array
   */
  folderList: PropTypes.array.isRequired,
  /**
   * call back method forFolder name change event
   */
  handleFolderChange: PropTypes.func,
  /**
   * Default selected folder name string
   */
  selectedFolderValue: PropTypes.string.isRequired,
  /**
   * Boolean value for popover open close
   */
  popperOpen: PropTypes.bool,
  /**
   * Call back method for save button click
   */
  onSaveClickHandler: PropTypes.func,
  /**
   * Call back method for close button click
   */
  onClose: PropTypes.func,
  /**
   * Varint for loader size
   */
  variant: PropTypes.string.isRequired,
  /**
   * Boolean value indicating in progress
   */
  inProgress: PropTypes.bool.isRequired,
  /**
   * Call back method for reset click
   */
  resetClickHandler: PropTypes.func,
  /**
   * Boolean value for button disabled
   */
  isButtonDisabled: PropTypes.bool,
  /**
   * Call back method for favorite name change
   */
  handleFavoriteNameTextInput: PropTypes.func,
  /**
   * User input for vavorite
   */
  inputFavoriteText: PropTypes.string,
  /**
   * Call back method for folder name change
   */
  folderNameInputHandler: PropTypes.func,
  /**
   * Error message string for favorite
   */
  errorMessageFavoriteName: PropTypes.string,
  /**
   * Error message for folder name
   */
  errorMessageFolderName: PropTypes.string,
  /**
   * Call back method for clearing text in favorite input
   */
  handleClearText: PropTypes.func,
  /**
   * User input for folder name in string
   */
  folderNameInputText: PropTypes.string,
  /**
   * Call back method for folder name change
   */
  handleClearFolderName: PropTypes.func,
  /**
   * Call back method for toggling between folder select and input
   */
  handleToggle: PropTypes.func,
  /**
   * Boolean value for toggling folder text input and select
   */
  toggleFlag: PropTypes.bool,
  /**
   * Object containing all the labels
   */
  popoverLabels: PropTypes.object,
  loaderImage: PropTypes.string,
  favoriteType: PropTypes.string,
};

FavoritePopover.defaultProps = {
  errorMessageFavoriteName: 'Please enter favorite name',
  folderNameInputText: 'Please enter folder name',
  toggleFlag: false,
  isButtonDisabled: false,
  popperOpen: false,
  popoverLabels: {
    heading: 'Favorite',
    favoriteName: 'Favorite Name',
    selectFolder: 'Select Folder',
    folderName: 'Folder Name',
    or: 'OR',
    createNew: 'Create New',
    reset: 'Reset',
    save: 'Save',
    cancel: 'Cancel',
    close: 'Close',
    clear: 'Clear text',
  },
};
