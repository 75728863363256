import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import makeStyles from '@mui/styles/makeStyles';
import { ResolutionTypeMultiple as ResolutionTypeIcons } from '../ResolutionTypeMultipleIcon';

const useStyles = makeStyles(theme => ({
  iconAlignment: {
    display: 'flex',
  },
  elementStyle: {
    display: 'flex',
    justifyContent: 'space-around',
    marginLeft: '24px',
    alignItems: 'center',
  },
  label: {
    fontSize: '0.75rem',
    color: theme.color.lightGrey,
    marginLeft: '8px',
    fontWeight: 600,
  },
  icons: {
    fontSize: '0.20rem',
  },
  count: {
    marginLeft: '8px',
    fontSize: '1.125rem',
    fontWeight: 600,
  },
  seperator: {
    borderLeft: `3px solid ${theme.color.black60}`,
    height: '15px',
    marginRight: '3px',
  },
}));

export const ResolutionTypeSummary = ({
  fieldVisitCount,
  remoteFixCount,
  bothCount,
}) => {
  const classes = useStyles();
  const { t } = useTranslation();
  return (
    <div className={classes.iconAlignment}>
      <div className={classes.elementStyle}>
        <ResolutionTypeIcons type="Field Visit" />
        <div className={classes.label}>{t('Field_Visit')}</div>
        <div className={classes.count}>{fieldVisitCount}</div>
      </div>
      <div className={classes.elementStyle}>
        <ResolutionTypeIcons type="Remote Fix" />
        <div className={classes.label}>{t('Remote_Fix')}</div>
        <div className={classes.count}>{remoteFixCount}</div>
      </div>
      <div className={classes.elementStyle}>
        <ResolutionTypeIcons type="Both Remote & Field visit" />
        <div className={classes.label}>
          {t('Remote_Fix')} &amp; {t('Field_Visit')}
        </div>
        <div className={classes.count}>{bothCount}</div>
      </div>
    </div>
  );
};

ResolutionTypeSummary.propTypes = {
  /**
   * type is resolutionType either Field or Remote
   */
  fieldVisitCount: PropTypes.number,
  remoteFixCount: PropTypes.number,
  bothCount: PropTypes.number,
};

ResolutionTypeSummary.defaultProps = {};
