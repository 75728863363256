import React, { useMemo } from 'react';
import makeStyles from '@mui/styles/makeStyles';
import PropTypes from 'prop-types';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import { isEmpty } from 'lodash';
import ListItemText from '@mui/material/ListItemText';
import ToggleButton from '@mui/material/ToggleButton';
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContentText from '@mui/material/DialogContentText';
import { useTranslation } from 'react-i18next';
import Checkbox from '@mui/material/Checkbox';
import { Card, CardContent, Box, Button } from '../thirdParty';
import { Search } from '../Search';

const useStyles = makeStyles(theme => ({
  root: {
    alignItems: 'center',
    display: 'flex',
    fontWeight: 700,
  },
  titleContainer: {
    paddingBottom: '0px',
    padding: `${theme.spacing(2)} ${theme.spacing(2)} ${theme.spacing(
      0,
    )} ${theme.spacing(2)}`,
  },
  icon: {
    opacity: 1,
    fontSize: '35px',
    color: theme.palette.primary.main,
    marginLeft: '50px',
    cursor: 'pointer',
  },
  addButton: {
    position: 'absolute',
    right: theme.spacing(7),
    color: theme.color.white,
    textTransform: 'none',
  },
  searchContainer: {
    marginLeft: -theme.spacing(1.5),
    marginRight: theme.spacing(1.5),
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
  listTitle: {
    fontSize: '14px',
    fontWeight: 600,
  },
  listContent: {
    marginTop: '2px',
    fontSize: '12px',
  },
  categoryWrapper: {
    flexWrap: 'wrap',
    width: '90%',
  },
  selectedRow: {
    border: `2px solid ${theme.palette.primary.main} !important`,
    padding: `0px ${theme.spacing(1)}`,
    cursor: 'pointer',
  },
  category: {
    marginRight: theme.spacing(1),
    marginBottom: theme.spacing(1),
    background: theme.palette.primary.main,
    color: theme.color.white,
    fontSize: '12px',
    width: 'auto',
    cursor: 'pointer',
    padding: theme.spacing(0.5),
    borderRadius: `${theme.spacing(0.5)} !important`,
    '& .MuiToggleButton-label': {
      textTransform: 'none',
    },
    '&.MuiToggleButton-root:hover': {
      background: theme.palette.primary.main,
      color: theme.color.white,
    },
  },
  label: {
    whiteSpace: 'nowrap',
  },
  cardWrapper: {
    display: 'flex',
    '&.MuiCardContent-root:last-child': {
      paddingBottom: theme.spacing(1),
    },
  },
  contentList: {
    '&.MuiCardContent-root': {
      padding: theme.spacing(0),
    },
  },
  listWrapper: {
    padding: '2px 4px',
    width: '100%',
  },
  listItem: {
    cursor: 'pointer',
    padding: `0px ${theme.spacing(1)}`,
    borderBottom: '1px solid rgba(0, 0, 0, 0.12)',
    '&:last-child': {
      borderBottom: 'none',
    },
  },
  titleWrapper: {
    fontSize: '16px',
    marginBottom: theme.spacing(1),
  },
  checkboxWrapper: {
    display: 'flex',
    alignItems: 'flex-start',
    '& .MuiCheckbox-root': {
      paddingTop: '3px',
    },
  },
  dialogPaper: {
    minWidth: '350px',
    width: '50vw',
    height: '90vh',
  },
  notFound: {
    fontSize: '12px',
    textAlign: 'center',
    marginTop: theme.spacing(2),
    fontWeight: 'normal',
  },
  searchCount: {
    fontSize: '12px',
    fontWeight: 'normal',
    marginTop: theme.spacing(1),
  },
  filterSection: {
    padding: `${theme.spacing(0)} ${theme.spacing(2)}`,
  },
  contentWrapper: {
    padding: `${theme.spacing(1)} ${theme.spacing(2)}`,
  },
  addPartName: {
    borderBottom: `3px solid ${theme?.color?.blue}`,
    paddingBottom: theme.spacing(0.5),
    cursor: 'pointer',
    fontWeight: '500',
    minWidth: 182,
  },
  addPartNameDisbaled: {
    borderBottom: `3px solid rgba(0, 0, 0, 0.38)`,
    paddingBottom: theme.spacing(0.5),
    cursor: 'not-allowed',
    fontWeight: '500',
    color: 'rgba(0, 0, 0, 0.38)',
    paddingRight: theme.spacing(3),
  },
}));

function FeedbackPartsModal({
  parts,
  recommendationCategories,
  iconColor,
  closeIconColor,
  isSearchBox,
  isIconEnabled,
  setSelectedCategory,
  setSelectedPart,
  setPartName,
  partName,
  editId,
}) {
  const classes = useStyles();
  const { t } = useTranslation();
  const [open, setOpen] = React.useState(false);
  const [searchText, setSearchText] = React.useState('');
  const [formats, setFormats] = React.useState([]);
  const [filterRecommendations, setFilterRecommendations] = React.useState([]);
  const [checkAll, setCheckAll] = React.useState(true);
  const [selected, setSelected] = React.useState(-1);

  const handleFormat = (event, newFormats) => {
    setSelected(-1);
    setFormats(newFormats);
  };

  const handleSelectRow = obj => {
    if (selected === obj.id) {
      setSelected(-1);
      setSelectedCategory('');
      setSelectedPart('');
      setPartName('');
    } else {
      setSelected(obj.id);
      setSelectedCategory(obj.recommCategoryCode);
      setSelectedPart(obj.id);
      setPartName(obj.partName);
    }
  };

  const handleCheckboxChange = event => {
    setSelected(-1);
    setCheckAll(event.target.checked);
    if (event.target.checked) setFormats([]);
    else setFormats(recommendationCategories.map(a => a.recommCategoryCode));
  };

  const handleClose = e => {
    e.stopPropagation();
    e.preventDefault();
    setSelected(-1);
    setCheckAll(true);
    setFormats([]);
    setSearchText('');
    setFilterRecommendations([]);
    setOpen(false);
  };

  const handleSearch = e => {
    if (selected > -1) setSelected(-1);
    setSearchText(e);
  };

  const onAddClick = e => {
    e.stopPropagation();
    e.preventDefault();
    setOpen(true);
  };

  const getFilteredRecommendations = parts?.filter(
    name =>
      name?.partName?.toLowerCase().includes(searchText?.toLowerCase()) &&
      !formats.includes(name?.recommCategoryCode),
  );

  const descriptionElementRef = React.useRef(null);
  React.useEffect(() => {
    if (open) {
      const { current: descriptionElement } = descriptionElementRef;
      if (descriptionElement !== null) {
        descriptionElement.focus();
      }
    }
  }, [open]);

  useMemo(() => {
    if (isEmpty(filterRecommendations) && !isEmpty(parts)) {
      setFilterRecommendations(parts);
    }
  }, [parts]);

  useMemo(() => {
    setFilterRecommendations(getFilteredRecommendations);
    if (formats?.length === 0) setCheckAll(true);
    else setCheckAll(false);
  }, [formats]);

  useMemo(() => {
    setFilterRecommendations(getFilteredRecommendations);
  }, [searchText]);

  const NotFound = () =>
    recommendationCategories?.length === formats?.length && !searchText ? (
      <Box className={classes.notFound}>{t('No_Category_selected')}</Box>
    ) : (
      <Box className={classes.notFound}>
        {t('No_such_part_found_in_our_database')}
      </Box>
    );

  const OtherCategory = () => (
    <Box>
      {searchText && (
        <Card variant="outlined">
          <CardContent
            className={`${classes.cardWrapper} ${classes.contentList}`}
          >
            <List className={classes.listWrapper}>
              <ListItem
                className={
                  selected === 0 ? classes.selectedRow : classes.listItem
                }
                alignItems="flex-start"
                key={0}
                onClick={() =>
                  handleSelectRow({
                    id: 0,
                    recommCategoryCode: 'Othrs',
                    partName: searchText,
                  })
                }
              >
                <ListItemText
                  primary={
                    <Box className={classes.listTitle}>{searchText}</Box>
                  }
                  secondary={
                    <Box className={classes.listContent}>{t('Others')}</Box>
                  }
                />
              </ListItem>
            </List>
          </CardContent>
        </Card>
      )}
    </Box>
  );

  return (
    <Box className={classes.root}>
      <Box>
        <Box
          onClick={editId === -1 && onAddClick}
          className={
            editId >= 0 ? classes.addPartNameDisbaled : classes.addPartName
          }
        >
          {partName || t('Click_to_Add_Part_Name')}
        </Box>
      </Box>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="form-dialog-title"
        aria-describedby="scroll-dialog-description"
        classes={{ paper: classes.dialogPaper }}
      >
        <DialogTitle id="form-dialog-title" className={classes.titleContainer}>
          <Box className={classes.titleWrapper}>
            {t('Select_Parts')}
            {selected > -1 && (
              <Button
                onClick={e => {
                  handleClose(e);
                }}
                className={classes.addButton}
                size="small"
                variant="contained"
                color="primary"
              >
                {t('Add')}
              </Button>
            )}
            <IconButton
              aria-label="close"
              className={classes.closeButton}
              onClick={handleClose}
              size="large"
            >
              <CloseIcon />
            </IconButton>
          </Box>
        </DialogTitle>
        <Box class={classes.filterSection}>
          <Box className={classes.searchContainer}>
            <Search
              isSearchBox={isSearchBox}
              isIconEnabled={isIconEnabled}
              iconColor={iconColor}
              closeIconColor={closeIconColor}
              onChange={e => handleSearch(e)}
              isCloseIconEnabled={searchText.length > 0}
              onCloseClick={e => handleSearch(e)}
            />
          </Box>
          {recommendationCategories.length > 0 && (
            <Card variant="outlined">
              <CardContent className={classes.cardWrapper}>
                <ToggleButtonGroup
                  value={formats}
                  onChange={handleFormat}
                  aria-label=""
                  className={classes.categoryWrapper}
                >
                  {recommendationCategories?.map(obj => (
                    <ToggleButton
                      className={classes.category}
                      value={obj.recommCategoryCode}
                      aria-label=""
                    >
                      {obj.recoCategoryDisplayName}
                    </ToggleButton>
                  ))}
                </ToggleButtonGroup>
                <Box className={classes.checkboxWrapper}>
                  <Checkbox
                    checked={checkAll}
                    color="primary"
                    onChange={handleCheckboxChange}
                    inputProps={{ 'aria-label': 'controlled' }}
                  />
                  <ListItemText
                    className={classes.label}
                    primary={`${t('All')}`}
                  />
                </Box>
              </CardContent>
            </Card>
          )}

          {!isEmpty(filterRecommendations) ? (
            <Box className={classes.searchCount}>{`${t('Search_from')} ${
              filterRecommendations.length
            } ${t('Parts')}`}</Box>
          ) : (
            <NotFound />
          )}
        </Box>
        <DialogContent className={classes.contentWrapper}>
          <DialogContentText
            id="scroll-dialog-description"
            ref={descriptionElementRef}
            tabIndex={-1}
          >
            {!isEmpty(filterRecommendations) ? (
              <Card variant="outlined">
                <CardContent
                  className={`${classes.cardWrapper} ${classes.contentList}`}
                >
                  <List className={classes.listWrapper}>
                    {filterRecommendations?.map(obj => (
                      <ListItem
                        className={
                          selected === obj.id
                            ? classes.selectedRow
                            : classes.listItem
                        }
                        alignItems="flex-start"
                        key={obj.id}
                        onClick={() => handleSelectRow(obj)}
                      >
                        <ListItemText
                          primary={
                            <Box className={classes.listTitle}>
                              {obj.partName}
                            </Box>
                          }
                          secondary={
                            <Box className={classes.listContent}>
                              {obj.recoCategoryDisplayName}
                            </Box>
                          }
                        />
                      </ListItem>
                    ))}
                  </List>
                </CardContent>
              </Card>
            ) : (
              <OtherCategory />
            )}
          </DialogContentText>
        </DialogContent>
      </Dialog>
    </Box>
  );
}

FeedbackPartsModal.propTypes = {
  /**
   * list of parts
   */
  parts: PropTypes.array,
  /**
   * list of parts
   */
  recommendationCategories: PropTypes.array,
  /**
   * Color of the icon
   */
  iconColor: PropTypes.string,
  /**
   * selected part name
   */
  partName: PropTypes.string,
  /**
   * Search box for table enable flag
   */
  isSearchBox: PropTypes.bool,
  /**
   * Search icon enable flag
   */
  isIconEnabled: PropTypes.bool,
  /**
   * Callback handler to set selected category
   */
  setSelectedCategory: PropTypes.func,
  /**
   * Callback handler to set selected partname
   */
  setPartName: PropTypes.func,
  /**
   * Callback handler to set selected part id
   */
  setSelectedPart: PropTypes.func,
  /**
   * Edit id for part
   */
  editId: PropTypes.number,
  /**
   * Color of the close icon
   */
  closeIconColor: PropTypes.string,
};

FeedbackPartsModal.defaultProps = {
  iconColor: '#8FC740',
  closeIconColor: '#9e9e9e',
};

export default React.memo(FeedbackPartsModal);
