/**
 *
 * Feedbacks
 *
 */

import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { isEmpty, pickBy, isBoolean, sortBy } from 'lodash';
import { makeStyles, Box } from '../thirdParty';
import { FeedbackNoIssue } from '../FeedbackNoIssue';
import { FeedbackComments } from '../FeedbackComments';
import { FeedbackRecommendations } from '../FeedbackRecommendations';
import { FeedbackParts } from '../FeedbackParts';
import { FeedbackSnackBar } from '../FeedbackSnackBar';
import { ContainerLoader } from '../ContainerLoader';
import { ProgressButton } from '../ProgressButton';

const useStyles = makeStyles(theme => ({
  root: {
    fontWeight: '500',
    color: theme.color.primary,
  },
  container: {
    borderRadius: 10,
    backgroundColor: theme.color.white,
    backgroundSize: 'cover',
    padding: 20,
    fontSize: 12,
  },
  addBtn: {
    marginTop: 40,
  },
}));

export const Feedbacks = ({
  sectionHeading,
  noIssueHeading,
  recommendationsHeading,
  noIssueExists,
  feedbackComments,
  recommendations,
  saveFeedback,
  sectionNameListMapping,
  resetUpdatePaiFeedbackSectionStatus,
  paiUpdateFeedbackNoIssueExistsLoading,
  paiUpdateFeedbackNoIssueExistsLoaded,
  paiUpdateFeedbackNoIssueExistsError,
  paiUpdateFeedbackCommentsLoading,
  paiUpdateFeedbackCommentsLoaded,
  paiUpdateFeedbackCommentsError,
  paiUpdateFeedbackPartsLoading,
  paiUpdateFeedbackPartsLoaded,
  paiUpdateFeedbackPartsError,
  recommendationCategories,
  additionalRecommendations,
  recommendationCategoriesLoading,
  recommendationCategoriesError,
  resetRecommendationCategoriesError,
  recommendationTechTypes,
  recommendationTechTypesLoading,
  recommendationTechTypesError,
  resetRecommendationTechTypesError,
  recommendationCodes,
  recommendationCodesLoading,
  recommendationCodesError,
  resetRecommendationCodesError,
  paiUpdateFeedbackRecommendationsLoading,
  paiUpdateFeedbackRecommendationsLoaded,
  paiUpdateFeedbackRecommendationsError,
  paiUpdateFeedbacksLoading,
  paiUpdateFeedbacksLoaded,
  paiUpdatefeedbacksError,
  onCategoryChange,
  onTechTypeChange,
  showUpdateButton,
  timezone,
  noIssueExistsDisabled,
  deletePaiFeedbackSectionEntry,
  feedbackParts,
  partsHeading,
  parts,
  partsLoading,
  partsLoaded,
  partsError,
  resetPartsError,
  onPartsCategoryChange,
  loaderImage,
}) => {
  const classes = useStyles();
  const { t } = useTranslation();

  const [feedbacks, setFeedbacks] = useState({});
  const [error, setError] = React.useState(false);

  const filterData = () =>
    pickBy(feedbacks, value => !isEmpty(value) || isBoolean(value));

  const saveFeedbacks = () => {
    saveFeedback('feedbacks', filterData());
  };

  const setFeedbacksData = data => {
    setFeedbacks({ ...feedbacks, ...data });
  };

  const partsCategory = sortBy(
    parts.filter(
      (a, i) =>
        parts.findIndex(s => a.recommCategoryCode === s.recommCategoryCode) ===
        i,
    ),
    o => o.recoCategoryDisplayName,
  );

  const sortedParts = sortBy(parts, o => o.partName);

  const sortedRepairs = sortBy(additionalRecommendations, o => o.repairType);

  const sortedRecommendationCategories = sortBy(
    recommendationCategories,
    o => o.recommCategoryDisplay,
  );

  return (
    <Box component="div" className={classes.root}>
      <Box component="div" className={classes.heading}>
        {sectionHeading}
      </Box>
      <ContainerLoader
        open={paiUpdateFeedbacksLoading}
        loaderImage={loaderImage}
      >
        <Box component="div" className={classes.container}>
          <FeedbackRecommendations
            loaderImage={loaderImage}
            recommendationsHeading={recommendationsHeading}
            recommendations={recommendations}
            sectionNameListMapping={sectionNameListMapping}
            recommendationCategories={sortedRecommendationCategories}
            additionalRecommendations={sortedRepairs}
            recommendationCategoriesLoading={recommendationCategoriesLoading}
            recommendationCategoriesError={recommendationCategoriesError}
            resetRecommendationCategoriesError={
              resetRecommendationCategoriesError
            }
            recommendationCodes={recommendationCodes}
            recommendationCodesLoading={recommendationCodesLoading}
            recommendationCodesError={recommendationCodesError}
            resetRecommendationCodesError={resetRecommendationCodesError}
            resetUpdatePaiFeedbackSectionStatus={
              resetUpdatePaiFeedbackSectionStatus
            }
            paiUpdateFeedbackRecommendationsLoading={
              paiUpdateFeedbackRecommendationsLoading
            }
            paiUpdateFeedbackRecommendationsLoaded={
              paiUpdateFeedbackRecommendationsLoaded
            }
            paiUpdateFeedbackRecommendationsError={
              paiUpdateFeedbackRecommendationsError
            }
            onCategoryChange={onCategoryChange}
            onTechTypeChange={onTechTypeChange}
            saveFeedback={saveFeedback}
            setFeedbacks={setFeedbacksData}
            setFeedbacksError={setError}
            resetFields={paiUpdateFeedbacksLoaded}
            deletePaiFeedbackSectionEntry={deletePaiFeedbackSectionEntry}
            recommendationTechTypes={recommendationTechTypes}
            recommendationTechTypesLoading={recommendationTechTypesLoading}
            recommendationTechTypesError={recommendationTechTypesError}
            resetRecommendationTechTypesError={
              resetRecommendationTechTypesError
            }
            disabled={noIssueExists}
            timezone={timezone}
          />
          <FeedbackNoIssue
            loaderImage={loaderImage}
            noIssueExists={noIssueExists}
            noIssueHeading={noIssueHeading}
            saveFeedback={saveFeedback}
            sectionNameListMapping={sectionNameListMapping}
            resetUpdatePaiFeedbackSectionStatus={
              resetUpdatePaiFeedbackSectionStatus
            }
            paiUpdateFeedbackNoIssueExistsLoading={
              paiUpdateFeedbackNoIssueExistsLoading
            }
            paiUpdateFeedbackNoIssueExistsLoaded={
              paiUpdateFeedbackNoIssueExistsLoaded
            }
            paiUpdateFeedbackNoIssueExistsError={
              paiUpdateFeedbackNoIssueExistsError
            }
            setFeedbacks={setFeedbacksData}
            disabled={noIssueExistsDisabled}
          />
          <FeedbackComments
            loaderImage={loaderImage}
            feedbackComments={feedbackComments}
            saveFeedback={saveFeedback}
            sectionNameListMapping={sectionNameListMapping}
            resetUpdatePaiFeedbackSectionStatus={
              resetUpdatePaiFeedbackSectionStatus
            }
            paiUpdateFeedbackCommentsLoading={paiUpdateFeedbackCommentsLoading}
            paiUpdateFeedbackCommentsLoaded={paiUpdateFeedbackCommentsLoaded}
            paiUpdateFeedbackCommentsError={paiUpdateFeedbackCommentsError}
            setFeedbacks={setFeedbacksData}
            resetFields={paiUpdateFeedbacksLoaded}
            timezone={timezone}
            deletePaiFeedbackSectionEntry={deletePaiFeedbackSectionEntry}
          />
          <FeedbackParts
            loaderImage={loaderImage}
            feedbackParts={feedbackParts}
            partsHeading={partsHeading}
            parts={sortedParts}
            partsLoading={partsLoading}
            partsLoaded={partsLoaded}
            partsError={partsError}
            resetPartsError={resetPartsError}
            saveFeedback={saveFeedback}
            sectionNameListMapping={sectionNameListMapping}
            recommendationCategories={partsCategory}
            recommendationCategoriesLoading={recommendationCategoriesLoading}
            recommendationCategoriesError={recommendationCategoriesError}
            paiUpdateFeedbackPartsLoading={paiUpdateFeedbackPartsLoading}
            paiUpdateFeedbackPartsLoaded={paiUpdateFeedbackPartsLoaded}
            paiUpdateFeedbackPartsError={paiUpdateFeedbackPartsError}
            resetRecommendationCategoriesError={
              resetRecommendationCategoriesError
            }
            onPartsCategoryChange={onPartsCategoryChange}
            resetUpdatePaiFeedbackSectionStatus={
              resetUpdatePaiFeedbackSectionStatus
            }
            deletePaiFeedbackSectionEntry={deletePaiFeedbackSectionEntry}
            timezone={timezone}
          />
          {showUpdateButton && (
            <Box component="div" className={classes.addBtn}>
              <ProgressButton
                onClick={saveFeedbacks}
                buttonText={t('UPDATE')}
                disabled={isEmpty(filterData()) || error}
                loaderImage={loaderImage}
              />
            </Box>
          )}
        </Box>
      </ContainerLoader>
      <FeedbackSnackBar
        open={!isEmpty(paiUpdatefeedbacksError)}
        message={t('Error_updating_feedbacks')}
        handleClose={() => resetUpdatePaiFeedbackSectionStatus('feedbacks')}
        severity="error"
      />

      <FeedbackSnackBar
        open={paiUpdateFeedbacksLoaded}
        message={t('Successfully_updated_feedbacks')}
        handleClose={() => resetUpdatePaiFeedbackSectionStatus('feedbacks')}
        severity="success"
      />
    </Box>
  );
};

Feedbacks.propTypes = {
  /**
   * heading label of Feedbacks section
   */
  sectionHeading: PropTypes.string,
  /**
   * No issue exists label
   */
  noIssueHeading: PropTypes.string,
  /**
   * true if no issue exists
   */
  noIssueExists: PropTypes.bool,
  /**
   * Callback function to update feedback
   */
  saveFeedback: PropTypes.func,
  /**
   * Mapping of section name with section key
   */
  sectionNameListMapping: PropTypes.object,
  /**
   * true while adding/updating no issue exists
   */
  paiUpdateFeedbackNoIssueExistsLoading: PropTypes.bool,
  /**
   * true when successfully added/updated no issue exists
   */
  paiUpdateFeedbackNoIssueExistsLoaded: PropTypes.bool,
  /**
   * Error object while adding/updating no issue exists
   */
  paiUpdateFeedbackNoIssueExistsError: PropTypes.object,
  /**
   * List of added comments
   */
  feedbackComments: PropTypes.array,
  /**
   * true while adding/updating comments
   */
  paiUpdateFeedbackCommentsLoading: PropTypes.bool,
  /**
   * true when successfully added/updated comments
   */
  paiUpdateFeedbackCommentsLoaded: PropTypes.bool,
  /**
   * Error object while adding/updating comments
   */
  paiUpdateFeedbackCommentsError: PropTypes.object,
  paiUpdateFeedbackPartsLoading: PropTypes.bool,
  paiUpdateFeedbackPartsLoaded: PropTypes.bool,
  paiUpdateFeedbackPartsError: PropTypes.object,
  /**
   * recommendations label
   */
  recommendationsHeading: PropTypes.string,
  /**
   * list ofrecommendationCategories
   */
  recommendationCategories: PropTypes.array,
  /**
   * list of additionalRecommendations
   */
  additionalRecommendations: PropTypes.array,
  /**
   * show loader if true
   */
  recommendationCategoriesLoading: PropTypes.bool,
  /**
   * Show error snackbar if error is not empty
   */
  recommendationCategoriesError: PropTypes.object,
  /**
   * Callback handler to reset recommnendation categories error
   */
  resetRecommendationCategoriesError: PropTypes.func,
  /**
   * list of recommendation codes
   */
  recommendationTechTypes: PropTypes.array,
  /**
   * show loader if true
   */
  recommendationTechTypesLoading: PropTypes.bool,
  /**
   * Show error snackbar if error is not empty
   */
  recommendationTechTypesError: PropTypes.object,
  /**
   * Callback handler to reset recommnendation codes error
   */
  resetRecommendationTechTypesError: PropTypes.func,
  /**
   * list of recommendation codes
   */
  recommendationCodes: PropTypes.array,
  /**
   * show loader if true
   */
  recommendationCodesLoading: PropTypes.bool,
  /**
   * Show error snackbar if error is not empty
   */
  recommendationCodesError: PropTypes.object,
  /**
   * Callback handler to reset recommnendation codes error
   */
  resetRecommendationCodesError: PropTypes.func,
  /**
   * list of added recommendations
   */
  recommendations: PropTypes.array,
  /**
   * category change handler
   */
  onCategoryChange: PropTypes.func,
  /**
   * techtype change handler
   */
  onTechTypeChange: PropTypes.func,
  /**
   * true while adding/updating recommendation
   */
  paiUpdateFeedbackRecommendationsLoading: PropTypes.bool,
  /**
   * true when successfully added/updated recommendation
   */
  paiUpdateFeedbackRecommendationsLoaded: PropTypes.bool,
  /**
   * Error object while adding/updating recommendation
   */
  paiUpdateFeedbackRecommendationsError: PropTypes.object,
  /**
   * Callback function to reset success/error status
   */
  resetUpdatePaiFeedbackSectionStatus: PropTypes.func,
  /**
   * true while adding feedbacks
   */
  paiUpdateFeedbacksLoading: PropTypes.bool,
  /**
   * true when feedbacks saved successfully
   */
  paiUpdateFeedbacksLoaded: PropTypes.bool,
  /**
   * Error while adding feedbacks
   */
  paiUpdatefeedbacksError: PropTypes.object,
  /**
   * show update button on section if true
   */
  showUpdateButton: PropTypes.bool,

  timezone: PropTypes.string,

  noIssueExistsDisabled: PropTypes.bool,

  deletePaiFeedbackSectionEntry: PropTypes.func,

  feedbackParts: PropTypes.array,

  partsHeading: PropTypes.string,

  parts: PropTypes.array,

  onPartsCategoryChange: PropTypes.func,

  partsLoading: PropTypes.bool,

  partsLoaded: PropTypes.bool,

  partsError: PropTypes.object,

  resetPartsError: PropTypes.func,
  loaderImage: PropTypes.string,
};
